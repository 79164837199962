import "./ContactUs.css";

import React, {useState} from "react";
import {FormControl, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";

const ContactUs = (props) => {
    
    const [name, setName] = useState("");
    
    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const [email, setEmail] = useState("");

    const handleSetEmail = (event) => {
        setEmail(event.target.value);
    }

    const [subject, setSubject] = useState("");

    const handleSetSubject = (event) => {
        setSubject(event.target.value);
    }

    const [body, setBody] = useState("");

    const handleSetBody = (event) => {
        setBody(event.target.value);
    }
    
    const handleSendMessage = () => {
        const data = new FormData();
        data.append("email", email);
        data.append("name", name);
        data.append("subject", subject);
        data.append("body", body);
        
        axios.post("/api/Application/contactUs", data, props.formConfig)
            .then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
        })
        
        props.close();
    }
    
    return (
        <div className={"contact-container"}>
            <FormControl>
                <TextField sx={{margin: 1, maxWidth: 250}} label={"Your name"} size={"small"} onChange={handleSetName}/>
            </FormControl>
            <FormControl>
                <TextField sx={{margin: 1, maxWidth: 250}} label={"Your email"} size={"small"} onChange={handleSetEmail}/>
            </FormControl>
            <FormControl>
                <TextField sx={{margin: 1, maxWidth: 250}} label={"Subject"} size={"small"} onChange={handleSetSubject}/>
            </FormControl>
            <FormControl>
                <TextField sx={{margin: 1, maxWidth: 250}} label={"Message"} size={"small"} onChange={handleSetBody} multiline rows={6}/>
            </FormControl>
            <Button className={"contact-buttons"} size={"small"} variant={"contained"} onClick={handleSendMessage}>Send</Button>
            <Button className={"contact-buttons"} size={"small"} variant={"contained"} onClick={props.close}>Close</Button>
        </div>
    )
}

export default ContactUs;