import "./UpdateLocation.css";

import {React, useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContentText, DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import {darkTheme, lightTheme} from "../../../../Theme";

const UpdateLocation = (props) => {

    const [pastures, setPastures] = useState([]);
    const [barns, setBarns] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        axios.get("/api/Ranches/getPastures", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setPastures(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [props.updated])

    useEffect(() => {
        axios.get("/api/Ranches/getBarns", {
            params: {
                id: props.id
            }, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setBarns(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [props.updated])

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures, props.updated]);

    const [location, setLocation] = useState(null);

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [name, setName] = useState();

    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const updateLocation = () => {
        const type = location.split(" ")[0];
        const id = location.split(" ")[1];

        const data = new FormData();
        data.append("id", id);
        data.append("name", name);
        console.log(location)
        console.log(type)
        console.log(id)
        if (type.toLowerCase() == "pasture") {
            axios.post("/api/Ranches/updatePasture", data, props.formConfig)
                .then(response => {
                    console.log(response.data);
                    setMessage(response.data);
                    handleOpenMessage();
                    props.handleSetUpdated();
                }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpenMessage();
            })
        } 
        if (type.toLowerCase() == "barn"){
            axios.post("/api/Ranches/updateBarn", data, props.formConfig)
                .then(response => {
                    console.log(response.data);
                    setMessage(response.data);
                    handleOpenMessage();
                    props.handleSetUpdated();
                }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpenMessage();
            })
        }
    }

    const [message, setMessage] = useState("");
    const [openMessage, setOpenMessage] = useState(false);

    const handleOpenMessage = () => {
        setOpenMessage(true);
    }

    const handleCloseMessage = () => {
        setOpenMessage(false);
    }

    const inputStyle = {
        margin: "0 5px 10px 5px",
        width: "85%",
    }

    return (
        <>
            <div
                style={props.darkMode ? {
                    backgroundColor: darkTheme.palette.containerBackground.main,
                    boxShadow: "5px 5px 5px #262626"
                } : {
                    backgroundColor: lightTheme.palette.containerBackground.main,
                    boxShadow: "5px 5px 5px darkgray"
                }}
                className={"update-location-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"update-location-title-container"}>
                    <Typography variant={"h3"}>Rename</Typography>
                </div>
                <FormControl sx={inputStyle}>
                    <InputLabel id={"location"}>Choose a location</InputLabel>
                    <Select labelId={"location"}
                            label={"Choose a barn or pasture"}
                            size={"small"}
                            onChange={handleSetLocation}>
                        <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or barn</MenuItem>
                        {locations && locations.map((location, i) =>
                            <MenuItem key={i}
                                      value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={inputStyle}>
                    <TextField size={"small"} value={name} onChange={handleSetName}/>
                </FormControl>
                <Button sx={{alignSelf: "flex-end", margin: "10px"}} variant={"contained"}
                        onClick={updateLocation}>Rename</Button>
            </div>
            <Dialog open={openMessage} onClose={handleCloseMessage} sx={{paddingTop: 5}}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateLocation;