import './Product.css';

import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {darkTheme, lightTheme} from "../../../Theme";

const Product = (props) => {

    const [prices, setPrices] = useState([])
    
    useEffect(() => {
        axios.get('api/Stripe/pricesByProduct', {params: {id: props.id}, headers:{
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPrices(response.data);
            }).catch(error => {
                console.log(error);
        })
    }, []);
    
    function insertDecimal(num) {
        return (Number((num/100).toFixed(2))).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
    
    const [product, setProduct] = useState({});
    
    const handleSetProduct = (event) => {
        const price = event.target.value;
        const newProduct = {
            name: props.product.name,
            priceId: price.id,
            price: price.unitAmount,
            amount: price.transformQuantity?.divideBy
        }
        if(newProduct.amount == null) {
            newProduct.amount = 1;
        }
        setProduct(newProduct);
    }

    const inputStyle = {
        margin: 1,
        width: "65%"
    }
    
    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "product-container product-container-dark" : "product-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"product-header-container"}>
                <Typography variant={"h3"}>
                    {props.product.name}
                </Typography>
            </div>
            <Typography sx={{margin: "10px 20px"}}>{props.product.description}</Typography>
            <FormControl sx={inputStyle}>
                <Select size={"small"} onChange={handleSetProduct}>
                    {prices && prices.reverse().map(price =>
                        price.type == 'recurring' ? <MenuItem key={price.id} className={"product-price"}
                                                              value={price}>{insertDecimal(price.unitAmount)}/{price.recurring?.intervalCount} {price.recurring?.interval}</MenuItem> :
                            price.transformQuantity?.divideBy ?
                                <MenuItem key={price.id} className={"product-price"} value={price}>{insertDecimal(price.unitAmount)} / {price.transformQuantity?.divideBy}</MenuItem> :
                                <MenuItem key={price.id} className={"product-price"} value={price}>{insertDecimal(price.unitAmount)}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <Button sx={{alignSelf: "flex-end", marginRight: "20%"}}  variant={"contained"} size={"small"}onClick={props.handleAddToCart.bind(this, product)}>Add to cart</Button>
        </div>
    )
}

export default Product;