import "./NewPlayerPopUp.css";

import React from "react";
import {Typography} from "@mui/material";
import {Link} from "react-router-dom";

const NewPlayerPopUp = (props) => {

    const paragraphStyle = {
        margin: "10px"
    }
    
    return <div>
        <Typography sx={paragraphStyle}>
            If you like genetics, or want to learn more about them, you’re in the right place! This game boasts the most
            complex and realistic genetics system of any horse game in existence. There’s a lot you can do here!
        </Typography>
        <Typography sx={paragraphStyle}>
            That being said, the game does have a pretty steep learning curve. We highly recommend you start out slow in
            the game, and spend some time reading the Wiki, in particular these pages:
        </Typography>
        <div className={"welcome-popup-links-container"}>
            <Link className={"welcome-popup-link"} to={"https://wiki.legacyhorsegame.com/en/overview"}>Overview</Link>
            <Link className={"welcome-popup-link"} to={"https://wiki.legacyhorsegame.com/en/body-condition-score"}>Horse Condition Management</Link>
        </div>
        <Typography sx={paragraphStyle}>
            If you need help beyond the Wiki, you can post a question in the Forum or our <Link className={"welcome-popup-link"}
            to={"https://discord.gg/f2ZC4NyjuN"}>Discord</Link>. For faster responses, the developers are most active in
            Discord, and the community there is very friendly and helpful too.
        </Typography>
        <Typography sx={paragraphStyle}>
            Before you get started though, please be aware that the game is currently in Alpha testing. That means there
            are bugs and incomplete features, and some things about the game will change in the coming months. We expect
            to be in Alpha until about August 2024. Of importance though, once the Alpha phase is over, the game will be
            wiped clean before re-opening. This means all horses will be deleted from the game, but ranches will remain
            so that you can log in and start fresh in the full release. If you would rather not deal with all this, it
            is best that you wait until August to really begin playing. That being said, the game is mostly complete
            now, and you might enjoy playing to learn what you want to do. It’s totally up to you.
        </Typography>
        <Typography sx={paragraphStyle}>
            One related thing to note: the artwork in the game isn’t 100% complete yet. You may come across a horse that
            is missing ‘pieces’, such as its mane and tail, or maybe pattern. These do not need to be reported as bugs.
            We periodically add artwork in, so over time your horse will become complete!
        </Typography>
        <Typography sx={paragraphStyle}>
            If you do encounter a bug though, please use the ‘Support’ button in the top menu bar to submit a ticket.
            That will help us make the game the best it can be!
        </Typography>
        <Typography sx={paragraphStyle}>
            That’s all for now. We hope you enjoy the game!
        </Typography>
        <Typography sx={paragraphStyle}>
            Whitney & Lydia
        </Typography>
    </div>
}

export default NewPlayerPopUp