import "./Customization.css";

import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import aws from "aws-sdk";
import {ListObjectsV2Command, S3Client} from "@aws-sdk/client-s3";
import {Body} from "../../../../../enums/Body";
import axios from "axios";

const Customization = (props) => {
    const inputStyle = {
        margin: "0 5px 10px 5px",
        width: "90%",
    }

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const endpoint = new aws.Endpoint("nyc3.digitaloceanspaces.com");
    const s3 = new S3Client({
        forcePathStyle: false, // Configures to use subdomain/virtual calling format.
        endpoint: endpoint,
        region: "us-east-1",
        credentials: {
            accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
        }
    });

    const [backgrounds, setBackgrounds] = useState([]);

    useEffect(async () => {
        const params = {
            Bucket: 'legacyhorsegame',
            Prefix: `background/`
        };

        const {Contents, IsTruncated, NextContinuationToken} = await s3.send(new ListObjectsV2Command(params));
        setBackgrounds(Contents);
        backgrounds.shift();
    }, []);

    const [background, setBackground] = useState("background/default.jpg");

    const handleSetBackground = (event) => {
        setBackground(event.target.value);
    }

    const [backTops, setBackTops] = useState([]);
    const [backBottoms, setBackBottoms] = useState([]);
    const [heads, setHeads] = useState([]);
    const [horse, setHorse] = useState(null);

    useEffect(async () => {

        if (horse) {
            const params = {
                Bucket: 'legacyhorsegame',
                Prefix: `tack/${Body[horse.bodyType].toLowerCase()}/`
            };

            const {Contents, IsTruncated, NextContinuationToken} = await s3.send(new ListObjectsV2Command(params));
            setBackTops(Contents.filter(c => c.Key.includes("saddles/")));
            setBackBottoms(Contents.filter(c => c.Key.includes("saddlepad/")));
            setHeads(Contents.filter(c => c.Key.includes("bridle/") || c.Key.includes("halter/")));
        }
    }, [horse]);

    const [maneTail, setManeTail] = useState("back");

    const handleSetManeTail = (event) => {
        setManeTail(event.target.value);
    }

    const [backTop, setBackTop] = useState(null);

    const handleSetBackTop = (event) => {
        setBackTop(event.target.value);
    }

    const [backBottom, setBackBottom] = useState(null);

    const handleSetBackBottom = (event) => {
        setBackBottom(event.target.value);
    }

    const [head, setHead] = useState(null);

    const handleSetHead = (event) => {
        setHead(event.target.value);
    }

    const [legs, setLegs] = useState(null);

    const handleSetLegs = (event) => {
        setLegs(event.target.value);
    }

    useEffect(() => {
        setBackground("background/default.jpg");
        setBackTop("");
        setBackBottom("");
        setHead("");
        setManeTail("back");
        setLegs("");
        axios.get('/api/Horses/artwork', {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorse(response.data);
            });
    }, [props.id, updated]);

    const canvasRef = useRef();
    const containerRef = useRef();

    function checkImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = function () {
                console.log("image exists");
                resolve(image);
            };

            image.onerror = function () {
                console.log("image doesn't exist");
                reject(new Error(`Error loading image: ${url}`));
            };

            image.src = url;
            image.setAttribute('crossorigin', 'anonymous');
        });
    }

    useEffect(async () => {
        const canvas = canvasRef.current;

        if (horse != null && canvas != null) {

            canvas.width = containerRef.current.offsetWidth * .65;
            canvas.height = canvas.width * .56;

            const imageURLs = [];

            imageURLs.push(`https://media.legacyhorsegame.com/${background}`);
            imageURLs.push(`https://media.legacyhorsegame.com/${horse.color.toLowerCase()}.png`);
            horse.sooty && horse.sooty != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.sooty.toLowerCase()}`);
            horse.pangare && imageURLs.push(`https://media.legacyhorsegame.com/${horse.pangare.toLowerCase()}.png`);
            horse.dapples != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.dapples.toLowerCase()}`);
            horse.forehead && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.forehead.toLowerCase()}.png`);
            horse.nose != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.nose.toLowerCase()}.png`);
            horse.leftFore != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.leftFore.toLowerCase()}.png`);
            horse.rightFore != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.rightFore.toLowerCase()}.png`);
            horse.leftHind != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.leftHind.toLowerCase()}.png`);
            horse.rightHind != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.rightHind.toLowerCase()}.png`);
            horse.pattern != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.pattern.toLowerCase()}.png`);
            horse.leopard != null && imageURLs.push(`https://media.legacyhorsegame.com/leopard/${horse.leopard.toLowerCase()}.png`);
            horse.roaning != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.roaning.toLowerCase()}.png`);
            imageURLs.push(`https://media.legacyhorsegame.com/mane/${maneTail}/${horse.mane.toLowerCase()}.png`);
            horse.pattern != null && imageURLs.push(`https://media.legacyhorsegame.com/manepatterns/${maneTail}/${horse.pattern.substr(9).toLowerCase()}.png`);
            horse.leopardMane != null && imageURLs.push(`https://media.legacyhorsegame.com/maneappy/${maneTail}/${horse.leopardMane.toLowerCase()}`);
            horse.leopardTail != null && imageURLs.push(`https://media.legacyhorsegame.com/tailappy/${maneTail}/${horse.leopardTail.toLowerCase()}`);
            backBottom != null && imageURLs.push(`https://media.legacyhorsegame.com/${backBottom}`);
            backTop != null && imageURLs.push(`https://media.legacyhorsegame.com/${backTop}`);
            head != null && imageURLs.push(`https://media.legacyhorsegame.com/${head}`);

            async function loadImages() {
                const canvas = canvasRef.current;
                if (horse != null && canvas != null) {
                    const context = canvas.getContext('2d');

                    for (const url of imageURLs) {
                        try {
                            const loadedImage = await checkImage(url);
                            context.drawImage(loadedImage, 0, 0, canvas.width, canvas.height);
                        } catch (error) {
                            console.error(error.message);
                        }
                    }
                }
            }

            await loadImages();

        }
    }, [horse, maneTail, backTop, backBottom, head, background]);

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    
    const openMessage = () => {
        setIsOpen(true);
    }
    
    const closeMessage = () => {
        setIsOpen(false);
    }

    const handleSaveChanges = () => {
        const data = new FormData();
        data.append("id", horse.horseId);
        data.append("backTop", backTop);
        data.append("backBottom", backBottom);
        data.append("head", head);
        data.append("background", background);

        axios.post("api/horses/customize", data, props.formConfig)
            .then(response => {
                console.log(response)
                setMessage(response.data);
                openMessage();
                props.handleSetUpdated();
            }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                openMessage();
        })
    }

    const warningTextStyle = {
        color: "red",
        margin: 2
    }

    return (
        <>
            <div className={props.darkMode ? "customization-container-dark" : "customization-container"}>
                <div className={"customization-header-container"}>
                    <Typography variant={"h3"}>Customization</Typography>
                </div>
                {horse && <div className={"customization-content-container"} ref={containerRef}>
                    <div className={"customization-form-container"}>
                        {!horse.isBackgroundActive ?
                            <Typography sx={warningTextStyle}>You must apply a background credit to this horse under
                                your
                                account page to save these changes.</Typography> : ""}
                        <FormControl sx={inputStyle}>
                            <InputLabel id={"background-select"} shrink>Choose background</InputLabel>
                            <Select id={"background-select"}
                                    size={"small"}
                                    onChange={handleSetBackground}
                                    defaultValue={"default"}>
                                <MenuItem value={"default"} disabled>Choose background</MenuItem>
                                {backgrounds && backgrounds.map((background, i) =>
                                    <MenuItem key={i}
                                              value={background.Key}>{background.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {!horse.isTackActive ?
                            <Typography sx={warningTextStyle}>You must apply a tack credit to this horse under your
                                account page to save these changes.</Typography> : ""}
                        <FormControl sx={inputStyle}>
                            <InputLabel id={"back-top-select"}>Choose Saddle</InputLabel>
                            <Select id={"back-top-select"}
                                    size={"small"}
                                    onChange={handleSetBackTop}
                                    defaultValue={"default"}>
                                <MenuItem value={"default"} disabled>Choose Saddle</MenuItem>
                                <MenuItem value={null}>None</MenuItem>
                                {backTops && backTops.map(bt =>
                                    <MenuItem key={bt.ETag}
                                              value={bt.Key}>{bt.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl sx={inputStyle}>
                            <InputLabel id={"back-bottom-select"}>Choose Saddle Blanket</InputLabel>
                            <Select id={"back-bottom-select"}
                                    size={"small"}
                                    onChange={handleSetBackBottom}
                                    defaultValue={"default"}>
                                <MenuItem value={"default"} disabled>Choose Saddle Blanket</MenuItem>
                                <MenuItem value={null}>None</MenuItem>
                                {backBottoms && backBottoms.map(bb =>
                                    <MenuItem key={bb.ETag}
                                              value={bb.Key}>{bb.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl sx={inputStyle}>
                            <InputLabel id={"head-select"}>Choose Bridle</InputLabel>
                            <Select id={"head-select"} size={"small"}
                                    onChange={handleSetHead}
                                    defaultValue={"default"}>
                                <MenuItem value={"default"} disabled>Choose Saddle</MenuItem>
                                <MenuItem value={null}>None</MenuItem>
                                {heads && heads.map(h =>
                                    <MenuItem key={h.ETag}
                                              value={h.Key}>{h.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Button sx={{alignSelf: "flex-end"}} variant={"contained"}
                                disabled={!horse.isBackgroundActive && !horse.isTackActive}
                                onClick={handleSaveChanges}>Save</Button>
                    </div>
                    {containerRef && <canvas ref={canvasRef} width={300} height={300 * .56}></canvas>}
                </div>}
            </div>
            <Dialog open={isOpen} onClose={closeMessage}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    {message}
                    <Button onClick={closeMessage}>Close</Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Customization