import "./UpcomingShows.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Months} from "../../../../enums/Months";

const UpcomingShows = (props) => {
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [shows, setShows] = useState([]);

    useEffect(() => {
        axios.get('/api/Shows/FutureShowsByHorse', {
            params: {
                id: props.id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setShows(response.data.sort(function(a, b){
                    return parseInt(a.eventDate) - parseInt(b.eventDate);
                }));
            })
    }, [props.id, updated]);
    
    const scratchFromShow = (id) => {
        const data = new FormData();

        data.append("showId", id);
        data.append("horseId", props.id);
        
        axios.post("api/shows/scratchFromShow", data, props.formConfig)
            .then(function (response) {
                console.log(response);
                handleSetUpdated();
            }).catch(function (error) {
            console.log(error);
        });
    }

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    return (
        <div className={props.darkMode ? "upcoming-shows-container-dark" : "upcoming-shows-container"}>
            <div className={"upcoming-shows-title"}>
                <Typography variant={"h3"}>Upcoming Shows</Typography>
            </div>
            <Box sx={{ overflow: "auto", padding: "20px" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Table style={{padding: 20}}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: "10%"}}>Date</TableCell>
                                <TableCell sx={{width: "80%", textAlign: "center"}}>Title</TableCell>
                                {props.isOwner && <TableCell sx={{width: "5%", textAlign: "center"}}>Scratch</TableCell>}
                                <TableCell sx={{width: "5%", textAlign: "center"}}>View</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shows && shows.map((show, i) =>
                                <TableRow key={i} >
                                    <TableCell sx={{width: "10%"}} className={"show-date"}>{formatDate(show.eventDate)}</TableCell>
                                    <TableCell sx={{width: "80%", textAlign: "center"}} className={"show-title"}>{show.title}</TableCell>
                                    {props.isOwner && <TableCell sx={{width: "5%", textAlign: "center"}}>
                                       <Button variant={"contained"} onClick={scratchFromShow.bind(this, show.id)}>Remove</Button>
                                    </TableCell>}
                                    <TableCell sx={{width: "5%", textAlign: "center"}}>
                                        <Link to={`/shows/${show.id}`}>
                                            <Button variant={"contained"} >View</Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </div>
    )
}

export default UpcomingShows;