import "./Horse.css";

import React, {useEffect, useState} from 'react';

import Photo from "./photo/Photo";
import Profile from "./general/Profile";
import Conformation from "./conformation/Conformation";
import {Link, useLocation, useParams, useNavigate} from "react-router-dom";
import Training from "./training/Training";
import Title from "./photo/Title";
import axios from "axios";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab} from "@mui/material";
import Breeding from "./breeding/Breeding";
import Health from "./health/Health";
import Shows from "./shows/Shows";
import Album from "./photo/Album";
import Notes from "./notes/Notes";
import ColorCode from "./photo/ColorCode";

const Horse = (props) => {
    
    const { id } = useParams();

    const location = useLocation();
    const history = useNavigate();
    
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    
    const ids = localStorage.getItem("ids") != null ? localStorage.getItem("ids").split(",") : null;
    
    let prev;
    let next;

    if(ids != "undefined" && ids != null){
        const current = ids.indexOf(id);
        if(current === ids.length - 1){
            prev = ids[current - 1];
            next = ids[0];
        } else if(current === 0) {
            prev = ids[ids.length - 1];
            next = ids[current + 1];
        } else {
            prev = ids[current - 1];
            next = ids[current + 1];
        }
    } else {
        prev = id - 1;
        next = parseInt(id) + 1;
    }

    const [updated, setUpdated] = useState(0);
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }
    
    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };
   
    const [horse, setHorse] = useState(null);
    const [owner, setOwner] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [disableBreeding, setDisableBreeding] = useState(false);
    const [availableBreedings, setAvailableBreedings] = useState(0);
    
    useEffect(() => {
        axios.get('/api/Horses/Horse', {
            params: {
                id: id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorse(response.data);
            });
    }, [id, updated]);

    useEffect(() => {
        if(horse){
            let actualFrequency = horse.fertility.frequency;

            //stallion youth fertility penalty
            if(horse.age < 36){
                actualFrequency -= 5;
            } else if(horse.age < 48){
                actualFrequency -= 4;
            } else if(horse.age < 60){
                actualFrequency -= 2;
            } else if(horse.age < 72){
                actualFrequency -= 1;
            }

            //stallion age fertility decline penalty
            const lessBreedings = Math.floor(((horse.age - (horse.fertility.declineAgeStart * 12)) / 12) / 2);
            const minAvailable = Math.round(horse.fertility.declineRate * 6);

            if(lessBreedings > 0){
                actualFrequency -= lessBreedings;
            }

            if(actualFrequency < minAvailable){
                actualFrequency = minAvailable;
            }

            //breeding season
            const month = props.date % 12;
            if(month <= 4 || month >= 10){
                actualFrequency =  Math.trunc(actualFrequency - 7);
            }

            if(actualFrequency < 0){
                actualFrequency = 0;
            }
            
            const available = actualFrequency - horse.fertility.breedings;

            setAvailableBreedings(available <= 0 ? 0 : available);
            
            if(horse.gender === 0){
                if(horse.fertility.pregnant || horse.fertility.bred ||
                    horse.fertility.breedings > 0 || horse.age < 36) {
                    setDisableBreeding(true);
                } else {
                    setDisableBreeding(false);
                }
            } else {
                if(available <= 0 || horse.age < 24 || horse.gelded) {
                    setDisableBreeding(true);
                } else {
                    setDisableBreeding(false);
                }
            }
        }
    }, [horse]);

    useEffect(() => {
        if(horse != null){
            axios.get('/api/Account/owner', {
                params: {
                    id: horse.ownerId
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
                .then(response => {
                    setOwner(response.data);
                    setIsOwner(props.user != undefined && response.data.id == props.user.id);
                });
        }
    }, [horse]);
    
    const [selectedTab, setSelectedTab] = useState(tab || "profile");
    
    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        updateUrlParams(newTab);
    }

    const [textColor, setTextColor] = useState('black');
    
    // const textColor = () => {
    //     switch (horse.background){
    //         case 'autumn_01':
    //             return "white";
    //         case 'barn_01':
    //             return "white";
    //         case "spooky-house":
    //             return "white";
    //         case "dark_01":
    //             return "white";
    //         case "arena_01":
    //             return "white";
    //         default:
    //             return "black";
    //     }
    // }

    return (
        <>
            {horse && <div className={"horse"}>
                <div className={"horse-photo-container"}>
                    {horse.colorCode > -1 && horse.colorCode != null ? <ColorCode color={horse.colorCode} size={"150px"}/> : ""}
                    <div className={"nav-horse"}>
                        <Link className={"link-horse"} style={{color: textColor}} to={`/horses/${prev}`}>
                            Previous
                        </Link>
                        <Link className={"link-horse"} style={{color: textColor}} to={`/horses/${next}`}>
                            Next
                        </Link>
                    </div>
                    <Title horse={horse} textColor={textColor} api={props.api} token={props.token}/>
                    <Photo id={horse.horseId} width={'30%'} right={'45px'} top={'30%'} imgWidth={'20%'} flex={'row nowrap'} updated={updated} api={props.api} token={props.token} setTextColor={setTextColor}/>
                </div>
                <TabContext value={selectedTab}>
                    <Box width={"100%"} sx={{color: "white"}}>
                        <TabList variant={"scrollable"} className={"horse-tabs"} onChange={handleChangeTab} scrollButtons={"auto"} textColor={"inherit"}>
                            <Tab label="General" value={"profile"}/>
                            <Tab label="Conformation" value={"conformation"} />
                            <Tab label="Training" value={"training"} />
                            <Tab label="Health" value={"health"} />
                            <Tab label="Breeding" value={"breeding"} />
                            <Tab label="Events" value={"events"} />
                            <Tab label="Album" value={"album"} />
                            <Tab label="Notes" value={"notes"} />
                        </TabList>
                    </Box>
                    <TabPanel className={"horse-tab"} value={"profile"}>
                        {horse && <Profile id={id} email={props.email != null ? props.email : ""} owner={owner} horse={horse} isOwner={isOwner}
                                           registered={horse.registered} ranchId={horse.ranchId} currentRanch={props.ranchId}
                                           token={props.token} config={props.config} formConfig={props.formConfig} api={props.api} handleSetUpdated={handleSetUpdated} darkMode={props.darkMode}/>}
                    </TabPanel>
                    <TabPanel className={"horse-tab"} value={"conformation"}>
                        <Conformation id={id} owner={owner} api={props.api} token={props.token} />
                    </TabPanel>
                    <TabPanel className={"horse-tab"} value={"training"}>
                        <Training id={id} owner={owner} ranchId={props.ranchId} api={props.api} config={props.config} token={props.token} formConfig={props.formConfig} darkMode={props.darkMode}/>
                    </TabPanel>
                    <TabPanel className={"horse-tab"} value={"health"}>
                        <Health id={id} owner={owner} isOwner={isOwner} api={props.api} formConfig={props.formConfig} token={props.token} config={props.config} darkMode={props.darkMode}/>
                    </TabPanel>
                    <TabPanel className={"horse-tab"} style={{padding: 0}} value={"breeding"}>
                        {horse && <Breeding id={id} isOwner={isOwner} gender={horse.gender} age={horse.age} breedingDisabled={disableBreeding} api={props.api} config={props.config}
                                            available={availableBreedings} horse={horse} ranchId={props.ranchId} token={props.token} darkMode={props.darkMode} isTest={props.isTest}
                                            formConfig={props.formConfig} email={props.email} date={props.date} publicStud={horse.fertility.publicStud} setUpdated={setUpdated} updated={updated}
                        />}
                    </TabPanel>
                    <TabPanel className={"horse-tab"} value={"events"}>
                        {horse && <Shows id={horse.horseId} ranchId={props.ranchId} isOwner={isOwner} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>}
                    </TabPanel>
                    <TabPanel className={"horse-tab"} value={"album"}>
                        {horse && <Album id={horse.horseId} horse={horse} owner={owner} isOwner={isOwner} api={props.api} token={props.token} formConfig={props.formConfig} darkMode={props.darkMode}/>}
                    </TabPanel>
                    <TabPanel className={"horse-tab"} value={"notes"}>
                        {horse && isOwner && <Notes id={horse.horseId} owner={owner} isOwner={isOwner} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>}
                    </TabPanel>
                </TabContext>
            </div>}
        </>
    );
}

export default Horse