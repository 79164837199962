export const Breeds = [
    "Grade",
    //mini
    "Miniature",
    "Fallabella",
    "Shetland",
    //pony
    "Pony Of The Americas",
    "Welsh Pony",
    "Fell Pony",
    "Connemara",
    //light
    "Arabian",
    "Morgan",
    "Icelandic",
    "Akhal Teke",
    "Paso Fino",
    //Saddle
    "Thoroughbred",
    "Tennessee Walker",
    "Rocky Mountain Horse",
    "Saddlebred",
    "Hackney",
    "Standardbred",
    "Barb",
    //stock
    "Quarter Horse",
    "American Paint",
    "Appaloosa",
    "Mustang",
    //Warmblood
    "Hanoverian",
    "Holsteiner",
    "Oldenburger",
    "Dutch Warmblood",
    "Gelderland",
    "Irish Sport Horse",
    "Trakehner",
    //Cob
    "Irish Cob",
    "Welsh Cob",
    "Fjord",
    "Haflinger",
    //Baroque
    "Andalusian",
    "Friesian",
    "Lusitano",
    "Lipizzan",
    "Knabstrup",
    //Draft
    "Brabant",
    "Clydesdale",
    "Percheron",
    "Shire",
    "Noriker",
    "Irish Draught",
    //crosses
    "Anglo Arab",
    "Appendix",
    "Morab",
    "Quarab",
    "National Show Horse",
    "Warlander",
    "Moreisian",
    "Georgian Grande",
    "Azteca",
    "Spanish Norman"
];