import React, {useEffect, useState} from 'react';

import "./Carousel.css";
import {Link} from "react-router-dom";
import HorsePhoto from "./HorsePhoto";
const Carousel = (props) => {
    
    return(
      <div className={"carouselContainer"}>
          <div className={"leftImg"}>
              <Link className={"horse-link"} to={`/horses/${props.ids[1]}`}><HorsePhoto id={props.ids[1]}
                                                                                      api={props.api}
                                                                                      token={props.token}/></Link>
          </div>
          <div className={"centerImg"}>
              <Link className={"horse-link"} to={`/horses/${props.ids[2]}`}><HorsePhoto id={props.ids[2]} api={props.api} token={props.token}/></Link>
          </div>
          <div className={"rightImg"}>
              <Link className={"horse-link"} to={`/horses/${props.ids[3]}`}><HorsePhoto id={props.ids[3]} api={props.api} token={props.token}/></Link>
          </div>
      </div>
    );
}

export default Carousel;