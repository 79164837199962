import "./Comparisons.css";

import React, {useEffect, useState} from "react";
import ComparisonCard from "./ComparisonCard";
import axios from "axios";
import {Typography} from "@mui/material";

const Comparisons = (props) => {

    const [horses, setHorses] = useState([]);

    useEffect(() => {
        axios.get("api/Ranches/getHorses", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setHorses(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.id]);
    
    return (
        <div className={"comparisons-container"}>
            <Typography variant={"h5"}>Select two horses to compare their information.</Typography>
            <div className={"comparisons-sub-container"}>
                <ComparisonCard horses={horses} token={props.token} api={props.api} darkMode={props.darkMode}/>
                <ComparisonCard horses={horses} token={props.token} api={props.api} darkMode={props.darkMode}/>
            </div>
        </div>
    )
}

export default Comparisons