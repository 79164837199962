import "./ComparisonCard.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Personality from "../../../horse/general/character/Personality";
import Talents from "../../../horse/general/character/Talents";
import ComparisonTraits from "./ComparisonTraits";
import {darkTheme, lightTheme} from "../../../../Theme";
import Stat from "../../../horse/conformation/Stat";
import ComparisonConformationStat from "./ComparisonConformationStat";

const ComparisonCard = (props) => {

    const [id, setId] = useState(null);

    const handleSetId = (event) => {
        setId(event.target.value);
    }

    const [horse, setHorse] = useState(null);

    useEffect(() => {
        if (id) {
            axios.get("/api/horses/comparison", {
                params: {
                    id: id
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            }).then(response => {
                setHorse(response.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [id]);

    const [tests, setTests] = useState([]);

    const getKit = (tested, a1, a2) => {
        let allele1;
        let allele2;
        if (tested) {
            switch (a1) {
                case 1:
                    allele1 = "W1";
                    break;
                case 2:
                    allele1 = "SAB1";
                    break;
                case 10:
                    allele1 = "W10";
                    break;
                case 14:
                    allele1 = "W14";
                    break;
                case 20:
                    allele1 = "W20";
                    break;
                default:
                    allele1 = "n";
            }
            switch (a2) {
                case 1:
                    allele2 = "W1";
                    break;
                case 2:
                    allele2 = "SAB1";
                    break;
                case 10:
                    allele2 = "W10";
                    break;
                case 14:
                    allele2 = "W14";
                    break;
                case 20:
                    allele2 = "W20";
                    break;
                default:
                    allele2 = "n";
            }
            return allele1 + "/" + allele2;
        }
        return "?";
    }
    
    useEffect(() => {
        if(horse){
            const tests = [
                {
                    test: "HERDA",
                    result: horse.illnesses.scidTested ?
                        (horse.illnesses?.scidA == 1 ? "s" : "N") + "/" + (horse.illnesses?.scidB == 1 ? "s" : "N")
                        : "?"
                },
                {
                    test: "SCID",
                    result: horse.illnesses.herdaTested ?
                        (horse.illnesses?.herdaA == 1 ? "he" : "N") + "/" + (horse.illnesses?.herdaB == 1 ? "he" : "N")
                        : "?"
                },
                {
                    test: "Gaits",
                    result: horse.gaited.tested ?
                        (horse.gaited?.geneA == 1 ? "A" : "C") + "/" + (horse.gaited?.geneB == 1 ? "A" : "C")
                        : "?"
                },
                {
                    test: "Agouti",
                    result: horse.color.agoutiTested ? (horse.color?.agA == 1 ? "A" : "a") + "/" + (horse.color?.agB == 1 ? "A" : "a") : "?"
                },
                {
                    test: "Extension",
                    result: horse.color.extensionTested ? (horse.color?.extA == 1 ? "E" : "e") + "/" + (horse.color?.extB == 1 ? "E" : "e") : "?"
                },
                {
                    test: "Cream",
                    result: horse.color.creamTested ? (horse.color?.crmA == 2 ? "Cr" : horse.color?.crmA == 1 ? "Prl" : "n") + "/" + (horse.color?.crmB == 2 ? "Cr" : horse.color?.crmB == 1 ? "Prl" : "n") : "?"
                },
                {
                    test: "Dun",
                    result: horse.color.dunTested ? (horse.color?.dunA == 1 ? "D" : "n") + "/" + (horse.color?.dunB == 1 ? "D" : "n") : "?"
                },
                {
                    test: "Champagne",
                    result: horse.color.champagneTested ? (horse.color?.chpnA == 1 ? "Ch" : "n") + "/" + (horse.color?.chpnB == 1 ? "Ch" : "n") : "?"
                },
                {
                    test: "Silver",
                    result: horse.color.silverTested ? (horse.color?.slvrA == 1 ? "Z" : "n") + "/" + (horse.color?.slvrB == 1 ? "Z" : "n") : "?"
                },
                {
                    test: "Gray",
                    result: horse.color.grayTested ? (horse.color?.gryA == 1 ? "G" : "n") + "/" + (horse.color?.gryB == 1 ? "G" : "n") : "?"
                },
                {
                    test: "Leopard",
                    result: horse.leopard.leopardTested ? (horse.leopard?.lpA == 1 ? "Lp" : "n") + "/" + (horse.leopard?.lpB == 1 ? "Lp" : "n") : "?"
                },
                {
                    test: "PATN1",
                    result: horse.leopard.patn1Tested ? (horse.leopard?.patn1A == 1 ? "PATN1" : "n") + "/" + (horse.leopard?.patn1B == 1 ? "PATN1" : "n") : "?"
                },
                {
                    test: "KIT",
                    result: getKit(horse.patterns.kitTested, horse.patterns.ktA, horse.patterns.ktB)
                },
                {
                    test: "Roan",
                    result: horse.roaning.roaningTested ? (horse.roaning?.rnA == 1 ? "Rn" : "n") + "/" + (horse.roaning?.rnB == 1 ? "Rn" : "n") : "?"
                },
                {
                    test: "Splash",
                    result: horse.patterns.splashedTested ? (horse.patterns?.splA == 1 ? "SW1" : "n") + "/" + (horse.patterns?.splB == 1 ? "SW1" : "n") : "?"
                },
                {
                    test: "Tobiano",
                    result: horse.patterns.tobianoTested ? (horse.patterns?.tbA == 1 ? "TO" : "n") + "/" + (horse.patterns?.tbB == 1 ? "TO" : "n") : "?"
                },
                {
                    test: "Frame",
                    result: horse.patterns.frameTested ? (horse.patterns?.frmA == 1 ? "O" : "n") + "/" + (horse.patterns?.frmB == 1 ? "O" : "n") : "?"
                }
            ]
            
            setTests(tests);
        }
    }, [horse]);
    
    const [horseConformation, setHorseConformation] = useState(null);

    useEffect(() => {
        axios.get('/api/Horses/Conformation', {params: {id: id}, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorseConformation(response.data)
            }).catch(error => {
                console.log(error);
        });
    }, [id]);

    return (
        <div className={"comparison-card-container"}>
            <FormControl sx={{width: "75%", margin: 2}}>
                <Select size={"small"} variant={"outlined"} label={"Choose a horse"} onChange={handleSetId}>
                    {props.horses.map(horse =>
                        <MenuItem key={horse.horseId} value={horse.horseId}>{horse.regName}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {horse && <div style={props.darkMode ? {
                backgroundColor: darkTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px #262626"
            } : {
                backgroundColor: lightTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px darkgray"
            }}
                className={"comparison-card-horse-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"comparison-card-horse-header-container"}>
                    <Typography variant={"h3"}>{horse.regName}</Typography>
                </div>
                <ComparisonTraits id={id} token={props.token} api={props.api} darkMode={props.darkMode}/>
                {horse.gaited.finalValue == 3 ? <img className={"comparison-gaits-img"}
                                                           src={props.darkMode ? "https://media.legacyhorsegame.com/misc/gaits/gaits_orange_3.png" : "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/gaits/gaits_3.png"}
                                                           alt={"Three gaits"}/> : ""}
                {horse.gaited.finalValue == 4 ? <img className={"comparison-gaits-img"}
                                                           src={props.darkMode ? "https://media.legacyhorsegame.com/misc/gaits/gaits_orange_4.png" : "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/gaits/gaits_4.png"}
                                                           alt={"Four gaits"}/> : ""}
                {horse.gaited.finalValue == 5 ? <img className={"comparison-gaits-img"}
                                                           src={props.darkMode ? "https://media.legacyhorsegame.com/misc/gaits/gaits_orange_5.png" : "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/gaits/gaits_5.png"}
                                                           alt={"Five gaits"}/> : ""}
                <div className={"comparison-charts"}>
                    <div style={{width: "45%", minWidth: "250px"}}>
                        <Personality name={`horse-${horse.horseId}-personality`} id={id} token={props.token}
                                     api={props.api} darkMode={props.darkMode}/>
                    </div>
                    <div style={{width: "45%", minWidth: "250px"}}>
                        <Talents name={`horse-${horse.horseId}-talents`} id={id} token={props.token} api={props.api}
                                 darkMode={props.darkMode}/>
                    </div>
                </div>
                <div className={"comparison-tests-table-container"}>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Test</TableCell>
                                <TableCell align={"center"}>Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tests.map(t =>
                                <TableRow>
                                    <TableCell>{t.test}</TableCell>
                                    <TableCell align={"center"}>
                                        {t.result}
                                    </TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                </div>
                <div className={"comparison-conformation-container"}>
                    <ComparisonConformationStat title={"Knee Action"} low={"Low"} mid={"Moderate"} high={"High"} stat={horseConformation.kneeAction?.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Hock Action"} low={"Low"} mid={"Moderate"} high={"High"} stat={horseConformation.hockAction?.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Stride"} low={"Short"} midLeft={"Average"} midRight={"Long"} high={"Forges"} stat={horseConformation.stride?.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Head Profile"} low={"Dished"} mid={"Straight"} high={"Roman"} stat={horseConformation.allConformation?.headProfile.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Head Size"} low={"Small"} mid={"Ideal"} high={"Large"} stat={horseConformation.allConformation?.headSize.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Nostril Size"} low={"Small"} mid={"Average"} high={"Large"} stat={horseConformation.allConformation?.nostrilSize.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Neck Length"} low={"Short"} mid={"Average"} high={"Long"} stat={horseConformation.allConformation?.neckLength.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Neck Position"} low={"Low"} mid={"Middle"} high={"High"} stat={horseConformation.allConformation?.neckPosition.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Neck Curve"} low={"Ewed"} midLeft={"Straight"} midRight={"Arched"} high={"Swanned"} stat={horseConformation.allConformation?.neckCurve.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Crest"} low={"None"} mid={"Small"} high={"Large"} stat={horseConformation.allConformation?.crest.finalValue == 2 ? 100 : horseConformation.allConformation?.crest.finalValue == 1 ? 50 : 0} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Shoulder Length"} low={"Short"} mid={"Ideal"} high={"Long"} stat={horseConformation.allConformation?.shoulderLength.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Shoulder Slope"} low={"Upright"} mid={"Middle"} high={"Laid Back"} stat={horseConformation.allConformation?.shoulderSlope.finalValue} darkMode={props.darkMode} />
                    <ComparisonConformationStat title={"Chest Width"} low={"Narrow"} mid={"Ideal"} high={"Wide"} stat={horseConformation.allConformation?.chestWidth.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Humerus Length"} low={"Short"} mid={"Average"} high={"Long"} stat={horseConformation.allConformation?.humerusLength.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Humerus Angle"} low={"Flat"} mid={"Moderate"} high={"Steep"} stat={horseConformation.allConformation?.humerusAngle.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Foreleg Proportion"} low={"Short"} mid={"Equal"} high={"Long"} stat={horseConformation.allConformation?.foreleg.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Knee Front"} low={"Knock"} mid={"Straight"} high={"Bowed"} stat={horseConformation.allConformation?.kneeFront.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Knee Side"} low={"Over"} mid={"Straight"} high={"Back"} stat={horseConformation.allConformation?.kneeSide.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Pastern Angle"} low={"Upright"} mid={"Ideal"} high={"Sloped"} stat={horseConformation.allConformation?.pasternAngle.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Pastern Length"} low={"Short"} mid={"Ideal"} high={"Long"} stat={horseConformation.allConformation?.pasternLength.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Topline"} low={"Downhill"} mid={"Level"} high={"Uphill"} stat={horseConformation.allConformation?.topline.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Back Length"} low={"Short"} mid={"Average"} high={"Long"} stat={horseConformation.allConformation?.backLength.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Back Curve"} low={"Roached"} mid={"Ideal"} high={"Swayed"} stat={horseConformation.allConformation?.backCurve.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Ribcage"} low={"Narrow"} mid={"Average"} high={"Wide"} stat={horseConformation.allConformation?.ribcage.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Hip Angle"} low={"Flat"} mid={"Ideal"} high={"Steep"} stat={horseConformation.allConformation?.hipAngle.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Hip Length"} low={"Short"} mid={"Adequate"} high={"Long"} stat={horseConformation.allConformation?.hipLength.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Hip Width"} low={"Narrow"} mid={"Ideal"} high={"Wide"} stat={horseConformation.allConformation?.hipWidth.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Loin"} low={"Short"} mid={"Average"} high={"Long"} stat={horseConformation.allConformation?.loin.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Leg Proportion"} low={"Short"} mid={"Ideal"} high={"Long"} stat={horseConformation.allConformation?.legProportion.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Hock Angle"} low={"Post-legged"} mid={"Ideal"} high={"Sickle"} stat={horseConformation.allConformation?.hockAngle.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Hoof Size"} low={"Small"} mid={"Ideal"} high={"Large"} stat={horseConformation.allConformation?.hoofSize.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Muscle Mass"} low={"Light"} mid={"Average"} high={"Heavy"} stat={horseConformation.allConformation?.muscleMass.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Bone"} low={"Fine Boned"} mid={"Average"} high={"Big Boned"} stat={horseConformation.allConformation?.bone.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Tracking"} low={"Toed In"} mid={"Straight"} high={"Toed Out"} stat={horseConformation.allConformation?.tracking.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Tail Set"} low={"Low"} mid={"Middle"} high={"High"} stat={horseConformation.allConformation?.tailSet.finalValue} darkMode={props.darkMode}/>
                    <ComparisonConformationStat title={"Feathering"} low={"None"} high={"Feathered"} stat={horseConformation.allConformation?.feathering.finalValue == "Heavy" ? 100 : horseConformation.allConformation?.feathering.finalValue == "Light" ? 50 : 0} darkMode={props.darkMode}/>
                </div>
            </div>}
        </div>
    )
}

export default ComparisonCard