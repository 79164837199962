import "./LocationsList.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../../Theme";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import axios from "axios";

const LocationsList = (props) => {
    
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        axios.get("api/ranches/barnsandpastures", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setLocations(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);
    
    return (
        <div style={props.darkMode ? {
            backgroundColor: darkTheme.palette.containerBackground.main,
            boxShadow: "5px 5px 5px #262626"
        } : {
            backgroundColor: lightTheme.palette.containerBackground.main,
            boxShadow: "5px 5px 5px darkgray"
        }} className={"locations-list-container"}>
            <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"locations-list-title-container"}>
                <Typography variant={"h3"}>Locations</Typography> 
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align={"center"}>Type</TableCell>
                        <TableCell align={"center"}>Current Occupancy</TableCell>
                        <TableCell align={"center"}>Total Occupancy</TableCell>
                        <TableCell align={"center"}>Max Occupancy</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locations && locations.map((location, i) =>
                        <TableRow key={i}>
                            <TableCell>
                                {location.name}
                            </TableCell>
                            <TableCell align={"center"}>
                                {location.type}
                            </TableCell>
                            <TableCell align={"center"}>
                                {location.currentOccupancy}
                            </TableCell>
                            <TableCell align={"center"}>
                                {location.totalOccupancy}
                            </TableCell>
                            <TableCell align={"center"}>
                                {location.maxOccupancy}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default LocationsList