import "./TackCredits.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Autocomplete, FormControl, Select, TextField, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import axios from "axios";

const TackCredits = (props) => {

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleUpdate();
    }

    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }

    const [horses, setHorses] = useState([]);

    useEffect(() => {
        if(ranch != null){
            axios.get("/api/Ranches/getHorsesWithoutTack", {
                params: {
                    id: ranch.ranchId
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }})
                .then(response => {
                    setHorses(response.data);
                });
        }
    }, [ranch, updated])


    const [horse, setHorse] = useState(null);

    const handleSetHorse = (event, values) => {
        setHorse(values);
    }

    const handleApplyTackCredit = () => {
        axios.post("api/Horses/activateTack", horse.horseId, props.config)
            .then(response => {
                console.log(response);
                setHorse("");
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const selectStyle = {
        margin: 1,
        width: "65%"
    }

    const btnStyle = {
        alignSelf: "flex-end",
        margin: "10px 30px"
    }

    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "background-credits-container background-credits-container-dark" : "background-credits-container"}>
            <div className={"background-credits-header-container"}>
                <Typography variant={"h3"}>Tack Credits</Typography>
            </div>
            <FormControl sx={selectStyle}>
                <Select label={"Ranch"} onChange={handleSetRanch} size={"small"}>
                    {props.ranches.map(r =>
                        <MenuItem key={r.ranchId} value={r}>{r.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {ranch && <Autocomplete sx={selectStyle}
                                    required
                                    label={"Horse"}
                                    options={horses}
                                    getOptionLabel={horse => horse.regName}
                                    onChange={handleSetHorse}
                                    value={horse}
                                    renderInput={(params) => (
                                        <TextField {...params} label={"Horse"}
                                                   variant={"outlined"} size={"small"}/>)}/>}
            {horse && <Button sx={btnStyle} variant={"contained"} onClick={handleApplyTackCredit}>Add</Button>}
        </div>
    )
}

export default TackCredits