import "./LocationManager.css";

import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox, Dialog, DialogActions, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import axios from "axios";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";
import {darkTheme, lightTheme} from "../../../Theme";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {Gender} from "../../../enums/Gender";
import {Link} from "react-router-dom";


const LocationManager = (props) => {

    const [locationChange, setLocationChange] = useState(0);
    
    const handleLocationChanged = () => {
        setLocationChange(locationChange + 1);
    }
    
    const [searchAges, setSearchAges] = useState([]);
    
    const handleSetSearchAges = (age) => {
        if(searchAges.includes(age)){
            setSearchAges(searchAges.filter(a => a != age));
        } else {
            setSearchAges([...searchAges, age]);
        }
    }

    const [genders, setGenders] = useState([]);

    const handleSetGenders = (gender) => {
        if(genders.includes(gender)){
            setGenders(genders.filter(g => g !== gender));
        } else {
            setGenders([...genders, gender]);
        }
    }

    const [searchPastures, setSearchPastures] = useState([]);

    const handleSetPastures = (pasture) => {
        if(searchPastures.includes(pasture)){
            setSearchPastures(searchPastures.filter(p => p !== pasture));
        } else {
            setSearchPastures([...searchPastures, pasture]);
        }
    }

    const [searchBarns, setSearchBarns] = useState([]);

    const handleSetBarns = (barn) => {
        if(searchBarns.includes(barn)){
            setSearchBarns(searchBarns.filter(p => p != barn));
        } else {
            setSearchBarns([...searchBarns, barn]);
        }
    }
    
    const [sortBy, setSortBy] = useState(null);
    
    const handleSetSortBy = (event) => {
        setSortBy(event.target.value);
    }
    
    const [allPastures, setAllPastures] = useState([]);
    const [allBarns, setAllBarns] = useState([]);

    useEffect(() => {
        axios.get("/api/Ranches/allPastures", {
            params: {
                ranchId: props.ranchId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setAllPastures(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [locationChange])

    useEffect(() => {
        axios.get("/api/Ranches/allBarns", {
            params: {
                ranchId: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setAllBarns(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [locationChange])
    
    const [horses, setHorses] = useState([]);

    useEffect(() => {
        const searchDto = {
            ranchId: props.ranchId,
            ages: searchAges,
            genders: genders,
            pastures: searchPastures,
            barns: searchBarns,
            sortBy: sortBy
        }
        
        axios.post('/api/Ranches/searchHorsesByLocation', searchDto, props.config)
            .then(response => {
                setHorses(response.data);
            });
    }, [searchBarns, searchPastures, searchAges, genders, sortBy, locationChange]);

    const [pastures, setPastures] = useState([]);
    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get("/api/Ranches/getPasturesNotFull", {
            params: {
                id: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [locationChange])

    useEffect(() => {
        axios.get("/api/Ranches/getBarnsNotFull", {
            params: {
                id: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [locationChange])

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);
    
    const [message, setMessage] = useState("");
    
    const [messageOpen, setMessageOpen] = useState(false);
    
    const openMessage = () => {
        setMessageOpen(true);
    }

    const closeMessage = () => {
        setMessageOpen(false);
    }
    
    const handleMoveHorse = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("location", event.target.value);
        
        axios.post("api/Ranches/setLocation", data, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            console.log(response);
            handleLocationChanged();
        }).catch(error => {
            console.log(error);
        })
    }
    
    const [horseIds, setHorseIds] = useState([]);
    
    const handleSetHorseIds = (id) => {
        if(horseIds.includes(id)){
            setHorseIds(horseIds.filter(id => id !== id));
        } else {
            setHorseIds([...horseIds, id]);
        }
    }
    
    const setAllHorseIds = (event) => {
        for(let i = 0; i < horses.length; i++){
            setHorseIds([...horseIds, horses[i].horseId]);
        }
    }
    
    const bulkMoveHorses = (event) => {
        const value = event.target.value;
        
        const locationsDto = {
            ids: horseIds,
            location: value
        }

        axios.post("api/Ranches/bulkUpdateLocation", locationsDto, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            console.log(response);
            handleLocationChanged();
        }).catch(error => {
            console.log(error);
            setMessage(error.response.data);
            openMessage();
        })
    }

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }
    
    const clearFilters = () => {
        setSearchAges([]);
        setGenders([]);
        setSearchPastures([]);
        setSearchBarns([]);
        setSortBy(null);
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const calculateAge = (age) => {
        const months = age % 12;
        const years = Math.floor(age / 12);

        return years + " years, " + months + " months";
    }

    const female = (<img className={"location-manager-gender-icon"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"} alt={"female"}/>)
    const male = (<img className={"location-manager-gender-icon"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"} alt={"male"}/>)
    const gelding = (<img className={"location-manager-gender-icon"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }
    
    const ages = [
        "Suckling",
        "Weanling",
        "Mature",
        "Breeding Age"
    ]
    
    const filters = (
        <div className={"location-manager-filters-container"}>
            <div className={"location-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Age</Typography>
                {ages.map((a, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={searchAges.includes(a.toLowerCase().replace(/ /g, ""))}
                              onChange={() => handleSetSearchAges(a.toLowerCase().replace(/ /g, ""))}
                    />} label={a}/>
                )}
            </div>
            <div className={"location-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Gender</Typography>
                {Gender.map((g, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={genders.includes(g.toLowerCase())}
                              onChange={() => handleSetGenders(g.toLowerCase())}
                    />} label={g}/>
                )}
            </div>
            <div className={"location-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Barns</Typography>
                {allBarns.map((b, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={searchBarns.includes(b.barnId)}
                              onChange={() => handleSetBarns(b.barnId)}
                    />} label={b.name}/>
                )}
            </div>
            <div className={"location-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Pastures</Typography>
                {allPastures.map((p, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={searchPastures.includes(p.pastureId)}
                              onChange={() => handleSetPastures(p.pastureId)}
                    />} label={p.name}/>
                )}
            </div>
            <div className={"location-manager-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <>
            <div className={"location-manager-container"}>
                {filtersOpen ? filters : ""}
                <Toolbar sx={{
                    position: "relative",
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main,
                    width: "100%",
                    padding: 0,
                    margin: 0
                }}>
                    <FilterList onClick={openFilters}/>
                    <TablePagination
                        sx={{alignSelf: "flex-end"}}
                        rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                        component="div"
                        count={horses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Toolbar>
                {horses &&
                    <TableContainer>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Horse</TableCell>
                                    <TableCell align={"center"}>Gender</TableCell>
                                    <TableCell align={"center"}>Age</TableCell>
                                    <TableCell align={"center"}>Current Location</TableCell>
                                    <TableCell align={"center"}>New Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align={"center"}></TableCell>
                                    <TableCell>Select multiple</TableCell>
                                    <TableCell align={"center"}>-</TableCell>
                                    <TableCell align={"center"}>-</TableCell>
                                    <TableCell align={"center"}>-</TableCell>
                                    <TableCell align={"center"}>
                                        <FormControl>
                                            <Select labelId={"location"}
                                                    label={"Location"}
                                                    size={"small"}
                                                    sx={{minWidth: 200, margin: 1}}
                                                    onChange={bulkMoveHorses}
                                            >
                                                {locations && locations.map((location, i) =>
                                                    <MenuItem key={i}
                                                              value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                {horses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((h, index) =>
                                    <TableRow key={h.horseId}>
                                        <TableCell align={"center"}>
                                            <Checkbox checked={horseIds.includes(h.horseId)} 
                                                      onChange={() => {
                                                          handleSetHorseIds(h.horseId)
                                                      }}/>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/horses/${h.horseId}`}>
                                                {h.regName}
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            align={"center"}>{h.gelded ? gelding : h.gender == 1 ? male : female}</TableCell>
                                        <TableCell align={"center"}>{calculateAge(h.age)}</TableCell>
                                        <TableCell
                                            align={"center"}>{h.barn != undefined ? h.barn.name : h.pasture.name}</TableCell>
                                        <TableCell align={"center"}>
                                            <FormControl>
                                                <Select labelId={"location"}
                                                        label={"Choose a location"}
                                                        size={"small"}
                                                        sx={{minWidth: 200, margin: 1}}
                                                        onChange={handleMoveHorse.bind(this, h.horseId)}>
                                                    <MenuItem key={0} defaultValue={undefined}>Select a pasture or
                                                        barn</MenuItem>
                                                    {locations && locations.map(location =>
                                                        <MenuItem key={location.name}
                                                                  value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <TablePagination
                    sx={{alignSelf: "flex-end"}}
                    rowsPerPageOptions={[50, 100, 500]}
                    component="div"
                    count={horses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/*<Button className={"move-locations-btn"} sx={{float: "right", marginRight: "20px"}} variant={"contained"} onClick={handleMoveHorses}>Move Horses</Button>*/}
            </div>

            <Dialog open={messageOpen} onClose={closeMessage}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={closeMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LocationManager