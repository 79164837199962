import "./ForumActivity.css";

import React, {useEffect, useState} from "react";
import Activity from "./Activity";
import axios from "axios";
import PostCard from "../../forum/PostCard";

const ForumActivity = (props) => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get("api/forum/recent", {
            params: {
                userId: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setPosts(response.data)
        }).catch(error => {
            console.log(error);
        })
    }, []);
    
    return (
        <div className={"forum-activity-container"}>
            <div className={"forum-activity-posts-container"}>
                {posts.slice(0, 5).map(post =>
                    <PostCard post={post} user={props.user} formConfig={props.formConfig} darkMode={props.darkMode}/>
                )}
            </div>
            <Activity user={props.user} api={props.api} token={props.token} darkMode={props.darkMode}/>
        </div>
    )
}

export default ForumActivity