import React from "react";
import PublicNotes from "./public/PublicNotes";
import PrivateNotes from "./private/PrivateNotes";
import DividerTitle from "../../DividerTitle";
const Notes = (props) => {

    return (
        <div>
            {props.isOwner && <PrivateNotes id={props.id} api={props.api} owner={props.owner} isOwner={props.isOwner}
                                            config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>}
            {props.isOwner && <DividerTitle title={"Public Notes"} darkMode={props.darkMode}/>}
            <PublicNotes id={props.id} owner={props.owner} isOwner={props.isOwner} api={props.api}
                         formConfig={props.formConfig} config={props.config} darkMode={props.darkMode}/>
        </div>
    )
}

export default Notes;