import React, {useEffect, useState} from 'react';

import "./Profile.css";

import Management from "./management/Management";
import Traits from "./traits/Traits";
import Personality from "./character/Personality";
import Talents from "./character/Talents";
import Information from "./info/Information";
import CurrentCondition from "./condition/CurrentCondition";
import ConditionChange from "./condition/ConditionChange";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select, 
    Typography,
    Divider
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import DividerTitle from "../../DividerTitle";
import SalesHistory from "./history/SalesHistory";
function Profile(props){
    
    console.log(props.isOwner)

    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {
            params: {
                id: props.currentRanch
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data);
            });
    }, []);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {
            params: {
                id: props.currentRanch
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data);
            });
    }, []);

    const [locations, setLocations] = useState();

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);

    const [location, setLocation] = useState();

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    const [message, setMessage] = useState();

    const purchaseHorse = async () => {
        var data = new FormData();
        data.append("horseId", props.horse.horseId);
        data.append("buyerEmail", props.email);
        data.append("sellerRanchId", props.horse.ranchId);
        data.append("buyerRanchId", props.currentRanch);
        data.append("salesId", props.horse.saleId);
        data.append("location", location);

        if(location != undefined){
            await axios.post('/api/Sales/purchase', data, props.formConfig)
                .then(function (response) {
                    console.log(response);
                    setMessage(response.data);
                    handleClose();
                    handleOpenConfirmation();
                }).catch(function (error) {
                    console.log(error);
                    setMessage(error.response.data);
                    handleOpenConfirmation();
                });
        } else {
            setMessage("Please choose a pasture or barn. If you do not have an option, please create an additional pasture or barn.");
            handleOpenConfirmation();
        }
        props.handleSetChanged();
    }
    
    const management = (
        <>
            <DividerTitle title={"Management"} darkMode={props.darkMode}/>
            <Management email={props.email} id={props.id} horse={props.horse} ranchId={props.ranchId} 
                         owner={props.owner} isOwner={props.isOwner} darkMode={props.darkMode}
                         token={props.token} api={props.api} config={props.config} formConfig={props.formConfig}
                         handleSetUpdated={props.handleSetUpdated}
            />
        </>
    )

    const formatDollars = (amount) => {
        return (amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumSignificantDigits: Math.trunc(Math.abs(amount)).toFixed().length
        })
    }

    const forSale = () => {
        if(!props.isOwner && props.horse?.sale?.isForSale && !props.horse.sale.isAuctioned){
            return (
                <>
                    <div className={"for-sale-container"}>
                        <Typography sx={{fontWeight: "bold", marginRight: "10px", fontSize: "1.2em"}}>{formatDollars(props.horse?.sale?.price)}</Typography>
                        <Divider sx={{flexGrow: 1}} />
                        <Button sx={{marginLeft: "15px"}} variant={"contained"} onClick={handleOpen}>Purchase</Button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Choose a location</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                            <FormControl>
                                <InputLabel>Choose a barn/pasture</InputLabel>
                                <Select labelId={"pasture"} label={"Choose a pasture"} size={"small"} value={location} sx={{minWidth: 200, margin: 1}} onChange={handleSetLocation}>
                                    <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or barn</MenuItem>
                                    {locations && locations.map(location =>
                                        <MenuItem key={location.name} value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId} >{location.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </DialogContentText>
                        <DialogActions>
                            <Button variant={"contained"} onClick={purchaseHorse}>Purchase</Button>
                            <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                        <DialogTitle>Message</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                            {message}
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleCloseConfirmation} variant={"contained"}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )
        }
    }
    
    return (
        <div className={"profile-container"}>
            <div className={"profile-sub-container"}>
                <div className={"information-container"}>
                    <Information id={props.id} owner={props.owner} ranchId={props.ranchId} date={props.date} updated={props.updated} darkMode={props.darkMode} api={props.api} token={props.token} formConfig={props.formConfig}/>
                    <CurrentCondition horse={props.horse} updated={props.updated} darkMode={props.darkMode}/>
                    {props.horse.ranchId == props.ranchId && <ConditionChange id={props.id} api={props.api} token={props.token} darkMode={props.darkMode}/>}
                    {forSale()}
                </div>
                <div className={"charts"}>
                    <Personality name={"personality"} darkMode={props.darkMode} id={props.id} api={props.api} token={props.token}/>
                    <Talents name={"talents"} darkMode={props.darkMode} id={props.id} api={props.api} token={props.token}/>
                </div>
                <Traits id={props.id} darkMode={props.darkMode} api={props.api} token={props.token}/>  
            </div>
            {props.ranchId == props.currentRanch ? management : ""}
            <DividerTitle title={"Sales History"} darkMode={props.darkMode}/>
            <SalesHistory id={props.id} api={props.api} token={props.token} />
        </div>
    )
}
export default Profile;