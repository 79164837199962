import "./Announcements.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Button,
    Checkbox,
    FormControl,
    TextField, Typography
} from "@mui/material";
import ReactQuill from "react-quill";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import DOMPurify from "dompurify";
import Popup from "./Popup";
import {Edit} from "@mui/icons-material";
import dayjs from "dayjs";
import {darkTheme, lightTheme} from "../../Theme";

const Announcements = (props) => {

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        axios.get("/api/Announcements/current", props.config)
            .then(response => {
                setAnnouncements(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [updated]);

    const [popups, setPopups] = useState([]);

    useEffect(() => {
        axios.get("/api/Announcements/popups", {
            params: {
                id: props.email
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setPopups(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [updated]);

    const [title, setTitle] = useState(null);

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const [content, setContent] = useState(null);

    const handleSetContent = (content, delta, source, editor) => {
        setContent(content);
    }

    const [isActive, setIsActive] = useState(false);

    const handleSetIsActive = (event) => {
        setIsActive(event.target.checked);
    }

    const [isPopup, setIsPopup] = useState(false);

    const handleSetIsPopup = (event) => {
        setIsPopup(event.target.checked);
    }

    const [isPinned, setIsPinned] = useState(false);

    const handleSetIsPinned = (event) => {
        setIsPinned(event.target.checked);
    }

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [announcementId, setAnnouncementId] = useState(null);

    const handleSelectAnnouncement = (announcement) => {
        setAnnouncementId(announcement.announcementId);
        setTitle(announcement.title);
        setContent(announcement.content);
        setIsActive(announcement.isPinned);
        setIsPopup(announcement.isPopup);
        setIsPinned(announcement.isPinned);
        setStartDate(announcement.startDate);
        setEndDate(announcement.endDate);

        handleOpen();
    }

    const handleAnnouncement = () => {
        const data = new FormData();
        data.append("title", title);
        data.append("content", content);
        data.append("isActive", isActive);
        data.append("isPinned", isPinned);
        data.append("isPopup", isPopup);
        data.append("start", startDate);
        data.append("end", endDate);

        if (announcementId != null) {
            data.append("id", announcementId);
            axios.post("api/Announcements/edit", data, props.formConfig)
                .then(response => {
                    console.log(response);
                    handleSetUpdated();
                    handleClose();
                }).catch(error => {
                console.log(error);
            })
        } else {
            axios.post("api/Announcements/new", data, props.formConfig)
                .then(response => {
                    console.log(response);
                    handleSetUpdated();
                    handleClose();
                }).catch(error => {
                console.log(error);
            })
        }
    }

    const announcementForm = (
        <div>
            <FormControl>
                <TextField sx={{marginBottom: 2, width: '100%'}} label={"Post title"} size={"small"}
                           value={title} onChange={handleSetTitle}/>
            </FormControl>
            <ReactQuill theme="snow" value={content} onChange={handleSetContent}/>
            <FormControl sx={{
                display: "flex",
                flexFlow: "row wrap",
                alignItems: "center",
                justifySelf: "end",
                alignSelf: "end"
            }}>
                <p>Ignore End Date</p>
                <Checkbox size={"small"} checked={isActive} onChange={handleSetIsActive}/>
            </FormControl>
            <FormControl sx={{
                display: "flex",
                flexFlow: "row wrap",
                alignItems: "center",
                justifySelf: "end",
                alignSelf: "end"
            }}>
                <p>Make Popup</p>
                <Checkbox size={"small"} checked={isPopup} onChange={handleSetIsPopup}/>
            </FormControl>
            <FormControl sx={{
                display: "flex",
                flexFlow: "row wrap",
                alignItems: "center",
                justifySelf: "end",
                alignSelf: "end"
            }}>
                <p>Pin</p>
                <Checkbox size={"small"} checked={isPinned} onChange={handleSetIsPinned}/>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker size={"small"} variant={"outlined"} label={"Due Date"}
                                value={dayjs(startDate)} onChange={(newValue) => setStartDate(newValue)}/>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker size={"small"} variant={"outlined"} label={"Due Date"}
                                value={dayjs(endDate)} onChange={(newValue) => setEndDate(newValue)}/>
            </LocalizationProvider>
            <div className={"new-thread-editing-buttons"}>
                <Button sx={{marginRight: 2, marginTop: 2}} onClick={handleClose} variant={"contained"}>Cancel</Button>
                <Button sx={{marginRight: 2, marginTop: 2}} onClick={handleAnnouncement} autoFocus color={"primary"}
                        variant={"contained"}>
                    Post
                </Button>
            </div>
        </div>
    )

    return (
        <div className={"announcements-container"}>
            {props.admin &&
                <Button sx={{alignSelf: "flex-end"}} variant={"contained"} onClick={handleOpen}>Open</Button>}
            {open ? announcementForm : ""}
            {announcements && announcements.map((a, i) =>
                <div key={i}
                     style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                     className={props.darkMode ? "announcement-container announcement-container-dark" : "announcement-container"}>
                    <div
                        style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                        className={"announcement-title-container"}>
                        <Typography variant={"h3"}>{a.title}</Typography>
                        <div style={{display: "flex", flexFlow: "row nowrap"}}>
                            <Typography>{new Date(a.startDate).toLocaleDateString('en-US', {
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                            }) + " " + new Date(a.startDate).toLocaleTimeString('en-US', {
                                hour: "numeric",
                                minute: "numeric"
                            })}</Typography>
                            {props.admin && <Edit sx={{marginTop: "5px", marginLeft: "10px"}}
                                                  onClick={handleSelectAnnouncement.bind(this, a)}/>}
                        </div>
                    </div>
                    <div className={"announcement-content"}>
                        <Typography sx={{color: props.darkMode ? "white" : "black"}} dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(a.content, {
                                USE_PROFILES: {html: true}
                            })
                        }}/>
                    </div>
                </div>
            )}
            {popups && popups.map(a =>
                <Popup key={a.announcementId} announcement={a} email={props.email} formConfig={props.formConfig}/>
            )}
        </div>
    )
}

export default Announcements