import "./Canister.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {
    Autocomplete,
    Button,
    Dialog, DialogActions, DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Select,
    TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Photo from "../../horse/photo/Photo";
import {Link} from "react-router-dom";

const Canister = (props) => {
    
    const [privateRanches, setPrivateRanches] = useState([]);

    useEffect(() => {
        axios.get("api/Ranches/privateSemen", {
            params: {
                id: props.semen.semenId,
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setPrivateRanches(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.updated]);

    const [dam, setDam] = useState(null);

    const handleSetDam = (event, values) => {
        setDam(values);
    };

    const [straws, setStraws] = useState(0);

    const handleSetStraws = (event) => {
        setStraws(event.target.value)
    }

    const handleBreed = () => {
        const formData = new FormData();
        formData.append("sireId", props.semen.studId);
        formData.append("damId", dam.horseId);
        formData.append("semenId", props.semen.semenId);
        formData.append("straws", straws);
        formData.append("email", props.email);

        axios.post('/api/Health/artificialBreeding', formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                setMessage(response.data);
                props.handleSetUpdated();
                handleOpen();
                setStraws("");
                setDam(null);
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
            });
    }

    const [forSale, setForSale] = useState(null);

    const handleSetForSale = (event) => {
        setForSale(event.target.value);
    }

    const [price, setPrice] = useState(null);

    const handleSetPrice = (event) => {
        setPrice(event.target.value);
    }

    const handleSellStraws = () => {
        if(recipient == null){
            const data = new FormData();
            data.append("id", props.semen.semenId);
            data.append("sales", forSale);
            data.append("price", price)

            axios.post("/api/health/sellStraws", data, props.formConfig)
                .then(response => {
                    console.log(response);
                    setMessage("Set " + forSale * 4 + " straws for sale.")
                    setForSale(null);
                    handleOpen();
                    props.handleSetUpdated();
                    setForSale("");
                }).catch(error => {
                console.log(error);
            })
        } else {
            const data = new FormData();
            data.append("recipient", recipient.ranchId);
            data.append("amount", forSale);
            data.append("price", price);
            data.append("stud", props.semen.studId);
            data.append("semen", props.semen.semenId);

            axios.post("api/Health/privateSellSemen", data, props.formConfig)
                .then(response => {
                    console.log(response);
                    setMessage(response.data);
                    handleOpen();
                    props.handleSetUpdated();
                    setForSale("");
                }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
            })
        }
    }

    const [remove, setRemove] = useState(null);

    const handleSetRemove = (event) => {
        setRemove(event.target.value);
    }
    
    const [recipientToRemove, setRecipientToRemove] = useState(null);
    
    const handleSetRecipientToRemove = (event, values) => {
        setRecipientToRemove(values)
    }

    const handleRemoveStraws = () => {
        const data = new FormData();
        data.append("id", props.semen.semenId);
        data.append("sales", remove);
        
        if(recipientToRemove){
            data.append("recipient", recipientToRemove.ranchId);
        }

        axios.post("/api/health/unsellStraws", data, props.formConfig)
            .then(response => {
                console.log(response);
                setMessage("Removed straws from sale.")
                handleOpen();
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }
    const handleDumpCanister = () => {
        handleCloseConfirmDump();
        const id = props.semen.semenId;
        axios.post("/api/Health/deleteSemen", id, props.config)
            .then(response => {
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [recipient, setRecipient] = useState(null);

    const handleSetRecipient = (event, values) => {
        setRecipient(values);
    }

    const [openConfirmDump, setOpenConfirmDump] = useState(false);

    const handleOpenConfirmDump = () => {
        setOpenConfirmDump(true);
    }

    const handleCloseConfirmDump = () => {
        setOpenConfirmDump(false);
    }

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    
    const titleTextStyle = {
        fontWeight: "bold",
        marginRight: "10px"
    }
    
    const textStyle = {
        marginLeft: "10px"
    }
    
    const formInputStyle = {
        width: "30%",
        margin: "5px 10px"
    }

    const smallFormInputStyle = {
        width: "75px",
        margin: "5px 10px"
    }
    
    const getSumOfPrivateSaleSemen = () => {
        return props.semen.strawsForPrivateSale.reduce(function (accumulator, currentValue){
            return accumulator + currentValue.amount;
        }, 0)
    }
    
    const getNumberOfStrawsForSale = () => {
        if(recipientToRemove === null){
            return props.semen.strawsForSale - getSumOfPrivateSaleSemen();
        } else {
            return props.semen.strawsForPrivateSale.find(s => s.ranchId === recipientToRemove.ranchId).amount;
        }
    }
    
    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={"canister-container"}
            >
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"canister-title-container"}>
                    <Typography variant={"h3"}>{props.semen.studName}</Typography>
                </div>
                <div className={"canister-sub-container"}>
                    <div className={"canister-photo-container"}>
                        <Link to={`/horses/${props.semen.studId}`}>
                            <Photo className={"horse-image"} id={props.semen.studId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/>
                        </Link>
                    </div>
                    <div className={"canister-info-container"}>
                        <div className={"canister-straws-info-container"}>
                            <Typography sx={titleTextStyle}>Available Straws</Typography>
                            <Divider sx={{flexGrow: 1}}/>
                            <Typography
                                sx={textStyle}>{(props.semen.availableStraws) * 4}</Typography>
                        </div>
                        <div className={"canister-straws-info-container"}>
                            <Typography sx={titleTextStyle}>Amount For Sale</Typography>
                            <Divider sx={{flexGrow: 1}}/>
                            <Typography sx={textStyle}>{(props.semen.strawsForSale - getSumOfPrivateSaleSemen()) * 4}</Typography>
                        </div>
                        <div className={"canister-straws-info-container"}>
                            <Typography sx={titleTextStyle}>Amount For Private Sale</Typography>
                            <Divider sx={{flexGrow: 1}}/>
                            <Typography sx={textStyle}>{getSumOfPrivateSaleSemen() * 4}</Typography>
                        </div>
                        <div className={"canister-form-container"}>
                            <FormControl sx={smallFormInputStyle}>
                                <Select size={"small"} label={"Straws"} value={straws} onChange={handleSetStraws}>
                                    {Array.apply(null, {length: (props.semen.availableStraws) + 1}).map(Number.call, Number).slice(1, 4).map(amount =>
                                        <MenuItem key={amount} value={amount}>{amount * 4}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl sx={formInputStyle}>
                                <Autocomplete required
                                              value={dam}
                                              label={"Recipient"}
                                              options={props.mares}
                                              getOptionLabel={mare => mare.regName}
                                              onChange={handleSetDam}
                                              renderInput={(params) => (
                                                  <TextField {...params} label={"Mare"}
                                                             variant={"outlined"} size={"small"}/>)}/>
                            </FormControl>
                            <Button variant={"contained"} onClick={handleBreed}>Breed</Button>
                        </div>
                        <div className={"canister-form-container"}>
                            <FormControl sx={smallFormInputStyle}>
                                <Select size={"small"} label={"Straws"} value={forSale} onChange={handleSetForSale}>
                                    {Array.apply(null, {length: (props.semen.availableStraws) + 1}).map(Number.call, Number).map(amount =>
                                        <MenuItem key={amount} value={amount}>{amount * 4}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl sx={{width: 150}}>
                                <TextField size={"small"} label={"Price/4 Straws"}
                                           onChange={handleSetPrice}/>
                            </FormControl>
                            <FormControl sx={formInputStyle}>
                                <Autocomplete required
                                              label={"Recipient"}
                                              options={props.ranches}
                                              getOptionLabel={ranch => ranch.name}
                                              onChange={handleSetRecipient}
                                              renderInput={(params) => (
                                                  <TextField {...params}
                                                             label={"Recipient ranch"}
                                                             variant={"outlined"}
                                                             size={"small"}/>
                                              )}/>
                            </FormControl>
                            <Button variant={"contained"} onClick={handleSellStraws}>Sell</Button>
                        </div>
                        <div className={"canister-form-container"}>
                            <FormControl sx={formInputStyle}>
                                <Autocomplete required
                                              label={"Recipient"}
                                              options={privateRanches}
                                              getOptionLabel={ranch => ranch.name}
                                              onChange={handleSetRecipientToRemove}
                                              renderInput={(params) => (
                                                  <TextField {...params}
                                                             label={"Recipient ranch"}
                                                             variant={"outlined"}
                                                             size={"small"}/>
                                              )}/>
                            </FormControl>
                            <FormControl sx={smallFormInputStyle}>
                                <Select size={"small"} label={"Straws"} onChange={handleSetRemove}>
                                    {Array.apply(null, {length: getNumberOfStrawsForSale() + 1}).map(Number.call, Number).map(amount =>
                                        <MenuItem key={amount} value={amount}>{amount * 4}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <Button variant={"contained"} onClick={handleRemoveStraws}>Remove</Button>
                        </div>
                        <Button sx={{alignSelf: "flex-start"}} variant={"contained"} color={"warning"}
                                onClick={handleOpenConfirmDump}>Dump</Button>
                    </div>
                </div>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmDump} onClose={handleCloseConfirmDump}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Are you sure you want to dump the semen of {props.semen.stud?.regName}? This cannot be undone.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleDumpCanister} variant={"contained"}>Confirm</Button>
                    <Button onClick={handleCloseConfirmDump} variant={"contained"}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Canister