import React, {useEffect, useState} from "react";
import axios from "axios";
import PostCard from "./PostCard";
import {Pagination} from "@mui/material";

const ArchivedPosts = (props) => {

    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get("/api/Forum/archived", {
            params: {
                id: props.user.id,
                page: pageNumber
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        })
            .then(response => {
                setPosts(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        let newPostIds = [];

        if(posts){
            for (let i = 0; i < posts.length; i++) {
                newPostIds.push(posts[i].postId.toString());
            }
        }

        localStorage.setItem("postIds", newPostIds);
    }, [posts]);

    const [totalPosts, setTotalPosts] = useState(1);

    useEffect(() => {
        axios.get("/api/Forum/archivedTotal", {
            params: {
                id: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        })
            .then(response => {
                setTotalPosts(response.data);
            }).catch(error => {
            console.log(error);
        })
    });

    return (
        <div style={{display: "flex", flexFlow: "column nowrap", alignItems: "center"}}>
            <Pagination sx={{alignSelf: "flex-end"}}
                        variant={"outlined"}
                        shape={"rounded"}
                        boundaryCount={2}
                        page={pageNumber}
                        count={Math.ceil(totalPosts / 15)}
                        onChange={handleSetPageNumber}
            />
            {posts.map(post =>
                <PostCard post={post} user={props.user} formConfig={props.formConfig}/>
            )}
            <Pagination sx={{alignSelf: "flex-end"}}
                        variant={"outlined"}
                        shape={"rounded"}
                        boundaryCount={2}
                        page={pageNumber}
                        count={Math.ceil(totalPosts / 15)}
                        onChange={handleSetPageNumber}
            />
        </div>
    )
}

export default ArchivedPosts