import "./Inbox.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {AddBox, Close, Send} from "@mui/icons-material";
import {
    Autocomplete, Chip,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import * as moment from "moment";
import {Link} from "react-router-dom";
import {darkTheme, lightTheme} from "../../../Theme";
import NewMessage from "./NewMessage";

const Inbox = (props) => {

    const [messages, setMessages] = useState([]);

    useEffect(() => {
        axios.get("api/Messages/received", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setMessages(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.id]);

    useEffect(() => {
        const ids = [];
        for (let i = 0; i < messages.length; i++) {
            ids.push(messages[i].mailThreadId.toString())
        }
        props.handleSetMessageIds(ids);
    }, [messages]);

    const [isNewMessageOpen, setIsNewMessageOpen] = useState(false);

    const openNewMessage = () => {
        setIsNewMessageOpen(true);
    }

    const closeNewMessage = () => {
        setIsNewMessageOpen(false);
    }

    return (
        <>
            {!isNewMessageOpen && <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={"inbox-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"inbox-header-container"}>
                    <AddBox onClick={openNewMessage}/>
                </div>
                <Table>
                    <TableBody>
                        {messages.map(t =>
                            <TableRow>
                                <TableCell>
                                    <Link key={t.mailThreadId}
                                          to={`/users/${t.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].sender.displayName}`}>
                                        {t.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].sender.displayName}
                                    </Link>
                                </TableCell>
                                <TableCell align={"center"}>
                                    <Link key={t.mailThreadId} to={`/messages/${t.mailThreadId}`}>
                                        <Typography sx={{fontWeight: t.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].isRead ? 400 : 600}}>{t.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].title}</Typography>
                                    </Link>
                                </TableCell>
                                <TableCell align={"right"}>
                                    {moment(t.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].dateTime).format("MMMM DD, YYYY: h:mm:ss a")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {messages && messages.length == 0 ? <Typography>No messages.</Typography> : ""}
            </div>}
            {isNewMessageOpen && <NewMessage id={props.id} config={props.config} closeNewMessage={closeNewMessage} darkMode={props.darkMode}/>}
        </>
    )
}

export default Inbox