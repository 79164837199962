import "./GeneticTests.css";
import {Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";

const HealthTests = (props) => {

    const headerTextStyle = {
        fontFamily: "Roboto, san-serif",
        fontWeight: "bolder"
    }

    const testTextStyle = {
        fontFamily: "Roboto Light, san-serif",
        fontWeight: "bold"
    }

    const resultTextStyle = {
        fontFamily: "Roboto Light, san-serif"
    }

    const [isAllTested, setIsAllTested] = useState(false);

    useEffect(() => {
        setIsAllTested(props.horse.illnesses.scidTested && props.horse.illnesses.herdaTested &&
            props.horse.gaited.tested)
    }, [props.horse, props.updated]);

    const [scid, setScid] = useState(false);
    const handleSelectScid= () => {
        setScid(!scid);
        scid ? setTotal(total - 25) : setTotal(total + 25);
    }

    const getScid = () => {
        if(props.horse.illnesses.scidTested){
            return (props.horse.illnesses?.scidA == 1 ? "s" : "N") + "/" + (props.horse.illnesses?.scidB == 1 ? "s" : "N");
        } else {
            return "?";
        }
    }
    
    const [herda, setHerda] = useState(false);
    const handleSelectHerda= () => {
        setHerda(!scid);
        herda ? setTotal(total - 25) : setTotal(total + 25);
    }

    const getHerda = () => {
        if(props.horse.illnesses.herdaTested){
            return (props.horse.illnesses?.herdaA == 1 ? "he" : "N") + "/" + (props.horse.illnesses?.herdaB == 1 ? "he" : "N");
        } else {
            return "?";
        }
    }

    const [gaited, setGaited] = useState(false);
    const handleSelectGaited= () => {
        setGaited(!gaited);
        gaited ? setTotal(total - 25) : setTotal(total + 25);
    }

    const getGaited = () => {
        if(props.horse.gaited.tested){
            return (props.horse.gaited?.geneA == 1 ? "A" : "C") + "/" + (props.horse.gaited?.geneB == 1 ? "A" : "C");
        } else {
            return "?";
        }
    }
    
    const handleSelectAll = (event) => {
        setScid(!scid);
        setHerda(!herda);
        setGaited(!gaited);
        
        let amount = 0;
        scid ? amount -= 25 : amount += 25;
        herda ? amount -= 25 : amount += 25;
        gaited ? setTotal(total - 25) : setTotal(total + 25);
        
        setTotal(total + amount);
    }

    const [total, setTotal] = useState(0);

    const handleSubmitTest = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append("id", props.horse.horseId);
        data.append("scid", scid);
        data.append("herda", herda);
        data.append("gaited", gaited);
        data.append("total", total);

        await axios.post('/api/Health/healthTest', data, props.formConfig)
            .then(function (response){
                console.log(response.data);
                props.handleUpdated();
            }).catch(function(error){
                console.log(error);
            });
    }
    
    const test = (gene, result, checked, handle, disabled) => {
        return (
            <TableRow>
                {!isAllTested && <TableCell>
                    <Checkbox size={"small"} checked={checked} onChange={handle} disabled={disabled || !props.isOwner}/>
                </TableCell>}
                <TableCell>
                    <Typography sx={testTextStyle}>{gene}</Typography>
                </TableCell>
                <TableCell>
                    <Typography sx={resultTextStyle}>{result}</Typography>
                </TableCell>
            </TableRow>
        )
    }
    
    return(
        <div className={props.darkMode ? "genetic-test-container-dark" : "genetic-test-container"}>
            <div className={"genetic-test-title"}>
                <Typography variant={"h3"}>Other</Typography>
            </div>
            <div className={"genetic-test-data"}>
                {!isAllTested && <div className={"genetic-test-interface"}>
                    <Typography sx={{fontFamily: "Roboto Light, sans-serif", fontWeight: "bolder", padding: "5px"}}>Total:
                        ${total}</Typography>
                    <Button sx={{minWidth: "20px"}} variant={"contained"} onClick={handleSubmitTest}>Test</Button>
                </div>}
                <Table sx={{width: "95%"}} size={isAllTested ? "" : "small"}>
                    <TableHead>
                        <TableRow>
                            {!isAllTested && <TableCell>
                                <Checkbox
                                    size={"small"}
                                    onChange={handleSelectAll}
                                    inputProps={{
                                        'aria-label': 'select all tests',
                                    }}
                                    disabled={props.horse.illnesses.herdaTested || props.horse.illnesses.scidTested || props.horse.gaited.tested || !props.isOwner}
                                />
                            </TableCell>}
                            <TableCell>
                                <Typography sx={headerTextStyle}>Gene</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={headerTextStyle}>Result</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {test("SCID", getScid(), scid, handleSelectScid, props.horse.illnesses.scidTested)}
                        {test("HERDA", getHerda(), herda, handleSelectHerda, props.horse.illnesses.herdaTested)}
                        {test("Gaited", getGaited(), gaited, handleSelectGaited, props.horse.gaited.tested)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default HealthTests;