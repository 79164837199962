import React, {useEffect, useState} from 'react';
import "./Welcome.css";
import Introduction from "./Introduction";
import Carousel from "./Carousel";
import axios from "axios";
import Photo from "../horse/photo/Photo";
import {Link} from "react-router-dom";
import {CookieConsent, Cookies} from "react-cookie-consent";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Box, MobileStepper, Paper, Typography, useTheme} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import Button from "@mui/material/Button";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const Welcome = (props) => {

    const [ids, setIds] = useState(null);

    useEffect(() => {
        axios.get('/api/Horses/random', props.config)
            .then(response => {
                setIds(response.data);
            });
    }, []);

    function handleAccept() {
        Cookies.set("myCookieConsent", true, { expires: 150 });
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true
    };
    
    function patternExamples() {
        const images = [];
        
        for(var i = 1; i < 26; i++){
            images.push("https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/patternexamples/" + String(i).padStart(2, '0') + ".jpg");
        }
        
        return images;
    }
    
    const bodyTypeImages = [
        {
            label: "Draft horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/draft.jpg"
        },
        {
            label: "Baroque horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/baroque.jpg"
        },
        {
            label: "Cob horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/cob.jpg"
        },
        {
            label: "Saddle horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/saddle.jpg"
        },
        {
            label: "Warmblood horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/warmblood.jpg"
        },
        {
            label: "Stock horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/stock.jpg"
        },
        {
            label: "Light horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/light.jpg"
        },
        {
            label: "Pony horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/pony.jpg"
        },
        {
            label: "Mini horse body type",
            imgPath: "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/bodytypes/mini.jpg"
        },
    ]
    
    const theme = useTheme();
    
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = bodyTypeImages.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    return (
        <div className={"welcome-container"}>
            <img className={"banner"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/welcome-banner.jpg"} alt={"Horses"} />
            <div className={"welcome"}>
                <Introduction/>
                <div className={"welcome-img"}>
                    <img className={"click-me-img"} src={"https://media.legacyhorsegame.com/misc/clickMe.png"} alt={"click me"} />
                    {ids && <Link className={"horse-link"} to={`/horses/${ids[0]}`}><Photo id={ids[0]} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/></Link>}
                </div>
            </div>
            <Typography sx={{alignSelf: "center"}} variant={"h3"}>IN GAME HORSES</Typography>
            <Typography sx={{alignSelf: "center", fontSize: "18px"}}>Click image to view</Typography>
            {ids && <Carousel api={props.api} token={props.token} ids={ids}/>}
            <Typography sx={{alignSelf: "center"}} variant={"h3"}>55 breeds over nine body types</Typography>
            <Box sx={{ width: "75%", minWidth: "300px", flexGrow: 1, margin: "50px", alignSelf: "center" }}>
                {/*<Paper
                    square
                    elevation={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                    }}
                >
                    <Typography>{bodyTypeImages[activeStep].label}</Typography>
                </Paper>*/}
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {bodyTypeImages.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    sx={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={step.imgPath}
                                    alt={step.label}
                                />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </Box>
            <Typography sx={{alignSelf: "center"}} variant={"h3"}>Millions of possible color & pattern combinations!</Typography>
            <div>
                {patternExamples().map((example, i) =>
                    <img key={i} className={"pattern-example"} src={example} alt={"White pattern example"} />
                )}
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="myCookieConsent"
                style={{ background: "#212121" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                We use cookies to ensure you get the best experience on our website. By continuing to browse the site, you agree to our use of cookies.{" "}
            </CookieConsent>
        </div>
        )
};

export default Welcome;