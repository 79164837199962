import React, {useEffect, useState} from "react";
import axios from "axios";
import PostCard from "./PostCard";
import {Pagination} from "@mui/material";

const PopularPosts = (props) => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get("/api/Forum/popular", {
            params: {
                id: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        })
            .then(response => {
                setPosts(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        let newPostIds = [];

        if(posts){
            for (let i = 0; i < posts.length; i++) {
                newPostIds.push(posts[i].postId.toString());
            }
        }

        localStorage.setItem("postIds", newPostIds);
    }, [posts]);

    return (
        <div>
            {posts.map(post =>
                <PostCard post={post} user={props.user} formConfig={props.formConfig}/>
            )}
        </div>
    )
}

export default PopularPosts;