export const Discipline =  [
    "Barrel Racing",
    "Cross Country",
    "Cutting",
    "Dressage",
    "Endurance",
    "Flat Racing",
    "Gaited Pleasure",
    "In Hand Jumping",
    "Pulling",
    "Reining",
    "Show Jumping",
    "Saddleseat",
    "Trot Racing",
    "In Hand",
    "Pace Racing"
]