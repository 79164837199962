import "./CurrentCondition.css";

import React from "react";
import {Typography} from "@mui/material";

const CurrentCondition = (props) => {
    
    /*const stepTextStyle = {
        fontFamily: "Roboto Thin, san-serif",
        fontWeight: "bold",
        marginTop: "-24px",
        transform: "translateX(-50%)"
    }*/

    const stepTextStyle = {
        position: "absolute",
        top: "-24px",
        left: "50%",
        fontFamily: "Roboto Thin, san-serif",
        fontWeight: "bold",
        transform: "translateX(-50%)",
        alignSelf: "center"
    }
    
    return (
        <div className={props.darkMode ? "current-condition-bar-dark" : "current-condition-bar"}>
            <Typography sx={{fontFamily: "Roboto Light, sans-serif", fontSize: "14px", fontWeight: "bolder", marginLeft: "5px", color: "black", zIndex: 2}}>Condition</Typography>
            <div style={{left: props.horse.condition.currentValue + '%'}} className={"current-condition-indicator"} />
            <div className={"step"} >
                <Typography sx={stepTextStyle}>E</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '11%'}} className={"step"} >
                <Typography sx={stepTextStyle} >VT</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '22%'}} className={"step"} >
                <Typography sx={stepTextStyle} >T</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '33%'}} className={"step"} >
                <Typography sx={stepTextStyle} >MT</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '44%'}} className={"step"} >
                <Typography sx={stepTextStyle} >M</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '56%'}} className={"step"} >
                <Typography sx={stepTextStyle} >MF</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '67%'}} className={"step"} >
                <Typography sx={stepTextStyle} >Fl</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '78%'}} className={"step"} >
                <Typography sx={stepTextStyle} >Ft</Typography>
                <div className={"step-indicator"}/>
            </div>
            <div style={{left: '89%'}} className={"step"} >
                <Typography sx={stepTextStyle}>EF</Typography>
                {/*<div className={"step-indicator"}/>*/}
            </div>
        </div>
    )
}

export default CurrentCondition