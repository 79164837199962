import "./Loans.css";

import React from "react";
import NewLoan from "./NewLoan";
import ExistingLoan from "./ExistingLoan";

const Loans = (props) => {
    
    
    return (
        <div className={"loans-container"}>
            <NewLoan ranch={props.ranch} handleRefresh={props.handleRefresh} formConfig={props.formConfig} darkMode={props.darkMode}/>
            <ExistingLoan ranch={props.ranch} handleRefresh={props.handleRefresh} config={props.config} darkMode={props.darkMode}/>
        </div>
    )
}

export default Loans