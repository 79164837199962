import "./PlayerInfo.css";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {Box} from "@mui/material";
import {useParams} from "react-router-dom";

const PlayerInfo = (props) => {
    
    const [source, setSource] = useState(`https://media.legacyhorsegame.com/profileimages/${props.user.displayName}.png`);

    const handleMissingImage = () => {
        setSource("https://media.legacyhorsegame.com/misc/defaultUserImage.png")
    }
    
    useEffect(() => {
        setSource(`https://media.legacyhorsegame.com/profileimages/${props.user.displayName}.png`)
    }, [props.user])
    
    return(
        <div className={"player-info"}>
            {props.user && <>
                <div className={"profile-image-cropper"}>
                    <img className={"profile-image"}
                         src={source}
                         alt={props.user.displayName}
                         onError={handleMissingImage}
                    />
                </div>
                <div>
                    <Typography sx={{textAlign: "center", fontSize: 28, fontWeight: 600}}>{props.user.displayName}</Typography>
                    <Typography >{props.user.bio}</Typography>
                </div>
            </>}
        </div>
    )
}

export default PlayerInfo;