import "./Injury.css";

import {React} from "react";
import Button from "@mui/material/Button";
import {Months} from "../../../../enums/Months";
import {Divider, Typography} from "@mui/material";

const Injury = (props) => {
    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    return (
        <div className={"injury-container"}>
            <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", fontWeight: "bold", marginRight: "10px"
            }}>{formatDate(props.currentDate)}</Typography>
            <Divider sx={{flexGrow: 1}}/>
            <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", margin: "0 10px"
            }}>{props.title}</Typography>
            <Divider sx={{flexGrow: 1}}/>
            {props.isOwner && <div className={"treatments"}>
                <Button variant={"contained"} sx={{height: 20, minWidth: 60, fontSize: 10, marginRight: 2}}
                        onClick={props.treatHorse.bind(this, props.title)} disabled={props.treated || props.severe}>Treat</Button>
                <Button variant={"contained"} sx={{height: 20, minWidth: 60, fontSize: 10}} onClick={props.callVet.bind(this, props.title)}
                        disabled={props.treated}>Call Vet</Button>
            </div>}
        </div>
    )
}

export default Injury;