import "./GeneticTests.css";

import React, {useEffect, useState} from "react";
import ColorTests from "./ColorTests";
import PatternTests from "./PatternTests";
import HealthTests from "./HealthTests";
import axios from "axios";

const GeneticTests = (props) => {

    const [updated, setUpdated] = useState(0);
    
    const handleUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const [horse, setHorse] = useState();

    useEffect(() => {
        axios.get('/api/Health/geneticTests', {params: {id: props.id}, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorse(response.data);
            })
    }, [props.id, updated]);
    
    return(
        <div className={"genetic-tests-container"}>
            {horse && <>
                <ColorTests horse={horse} isOwner={props.isOwner} darkMode={props.darkMode} api={props.api}
                            formConfig={props.formConfig} token={props.token} handleUpdated={handleUpdated}
                            updated={updated}
                />
                <PatternTests horse={horse} isOwner={props.isOwner} darkMode={props.darkMode} api={props.api}
                              formConfig={props.formConfig} token={props.token} handleUpdated={handleUpdated}
                              updated={updated}
                />
                <HealthTests horse={horse} isOwner={props.isOwner} darkMode={props.darkMode} api={props.api}
                             formConfig={props.formConfig} token={props.token} handleUpdated={handleUpdated}
                             updated={updated}
                />
            </>}
        </div>
    )
}

export default GeneticTests;