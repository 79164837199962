import React, {useEffect, useRef, useState} from 'react';

import "./Photo.css";

import axios from "axios";
import {Breeds} from "../../../enums/Breeds";
import {Body} from "../../../enums/Body";

const Photo = (props) => {

    const id = props.id;

    const [horse, setHorse] = useState(null);

    useEffect(() => {
        axios.get('/api/Horses/artwork', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorse(response.data);
            });
    }, [id, props.updated]);
    
    const [titleSrc, setTitleSrc] = useState(null);
    const [futurityTitleSrc, setFuturityTitleSrc] = useState(null);
    const [veteranTitleSrc, setVeteranTitleSrc] = useState(null);
    const [specialTitleSrc, setSpecialTitleSrc] = useState(null);

    useEffect(() => {
        if(horse != null){
            if(horse.isWorldChamp){
                setTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/world.png');
            } else if(horse.isGrandChamp){
                setTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/grand.png');
            } else if(horse.isReserveChamp){
                setTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/reserve.png');
            } else if(horse.isRegionalChamp){
                setTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/regional.png');
            } else if(horse.isJuniorChamp){
                setTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/junior.png');
            }
            if(horse.isFuturityChamp){
                setFuturityTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/futurity.png');
            }
            if(horse.isVeteranChamp){
                setVeteranTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/veteran.png');
            }
            if(horse.isSpecialChamp){
                setSpecialTitleSrc('https://media.legacyhorsegame.com/misc/titleribbons/special.png');
            }
        }
    }, [horse]);

    const imageRef = useRef(null);

    useEffect(() => {
        if(props.setTextColor){
            if (imageRef.current) {
                const image = imageRef.current;
                if (image) {
                    image.crossOrigin = 'anonymous';
                    image.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            canvas.width = image.width;
                            canvas.height = image.height;
                            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            const data = imageData.data;

                            let r, g, b, avg;
                            let colorSum = 0;

                            for (let x = 0, len = data.length; x < len; x += 4) {
                                r = data[x];
                                g = data[x + 1];
                                b = data[x + 2];

                                avg = Math.floor((r + g + b) / 3);
                                colorSum += avg;
                            }

                            const brightness = Math.floor(colorSum / (image.width * image.height));
                            props.setTextColor(brightness < 128 ? 'white' : 'black');
                        }
                    };
                }
            }
        }
    }, [horse]);

    return (
        <>
            {horse && <div className={"photo-container"}>
                {/*<img className={"head"} src={`https://media.legacyhorsegame.com/tack/${Body[horse.bodyType].toLowerCase()}/misc/jester_hat.png`}
                      alt={"april fools"}/>*/}
                {horse.isLeased ?
                    <img className={"for-sale-img"} src={"https://media.legacyhorsegame.com/misc/onleasebanner.png"}
                         alt={"for lease"}/> : ""}
                {horse.isForSale ? <img className={"for-sale-img"}
                                        src={horse.isAuctioned ? "https://media.legacyhorsegame.com/misc/forauctionbanner.png" :
                                            horse.isForSale ? "https://media.legacyhorsegame.com/misc/forsalebanner.png" : ""}
                                        alt={"for sale"}/> : ""}
                {horse.isPregnant && horse.isPregChecked && !horse.isNursing ?
                    <img className={"pregnant"} src={"https://media.legacyhorsegame.com/misc/pregnancy.png"}
                         alt={"pregnant"}/> : ""}
                {horse.isPregnant && horse.isPregChecked && horse.isNursing ?
                    <img className={"nursing"} src={"https://media.legacyhorsegame.com/misc/pregnantnursing.png"}
                         alt={"pregnant and nursing"}/> : horse.isNursing ?
                        <img className={"nursing"} src={"https://media.legacyhorsegame.com/misc/nursing.png"}
                             alt={"nursing"}/> : ""}
                {!horse.isPregnant && horse.isNursing ?
                    <img className={"nursing"} src={"https://media.legacyhorsegame.com/misc/nursing.png"}
                         alt={"nursing"}/> : ""}
                {horse.isFoal &&
                    <img className={"pregnant"} src={"https://media.legacyhorsegame.com/misc/foal.png"} alt={"foal"}/>}
                {horse.isWeanling &&
                    <img className={"pregnant"} src={"https://media.legacyhorsegame.com/misc/weanling.png"}
                         alt={"weanling"}/>}
                {horse.head &&
                    <img className={"head"} src={`https://media.legacyhorsegame.com/${horse.head}`} alt={""}/>}
                {horse.backTop &&
                    <img className={"back-top"} src={`https://media.legacyhorsegame.com/${horse.backTop}`} alt={""}/>}
                {horse.backBottom &&
                    <img className={"back-bottom"} src={`https://media.legacyhorsegame.com/${horse.backBottom}`}
                         alt={""}/>}
                {horse.leopardMane && <img className={"appy-mane"}
                                           src={`https://media.legacyhorsegame.com/maneappy/${horse.maneTail}/${horse.leopardMane.toLowerCase()}`}
                                           alt={""}/>}
                {horse.leopardTail && <img className={"appy-tail"}
                                           src={`https://media.legacyhorsegame.com/tailappy/${horse.leopardTail.toLowerCase()}`}
                                           alt={""}/>}
                {horse.patternMane && <img className={"mane-white"}
                                           src={`https://media.legacyhorsegame.com/manepatterns/${horse.maneTail}/${horse.patternMane.substr(9).toLowerCase()}.png`}
                                           alt={""}/>}
                {horse.mane && <img className={"mane"}
                                    src={`https://media.legacyhorsegame.com/mane/${horse.maneTail}/${
                                        horse.mane.toString().toLowerCase() + ".png"}`}
                                    alt={""}/>}
                {horse.leopard && <img className={"leopard"}
                                       src={`https://media.legacyhorsegame.com/leopard/${horse.leopard.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.pattern && <img className={"pinto"}
                                       src={`https://media.legacyhorsegame.com/${horse.pattern.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.roaning && <img className={"roan"}
                                       src={`https://media.legacyhorsegame.com/${horse.roaning.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.leftFore && <img className={"left-fore"}
                                        src={`https://media.legacyhorsegame.com/markings/${horse.leftFore.toLowerCase()}.png`}
                                        alt={""}/>}
                {horse.rightFore && <img className={"right-fore"}
                                         src={`https://media.legacyhorsegame.com/markings/${horse.rightFore.toLowerCase()}.png`}
                                         alt={""}/>}
                {horse.leftHind && <img className={"left-hind"}
                                        src={`https://media.legacyhorsegame.com/markings/${horse.leftHind.toLowerCase()}.png`}
                                        alt={""}/>}
                {horse.rightHind && <img className={"right-hind"}
                                         src={`https://media.legacyhorsegame.com/markings/${horse.rightHind.toLowerCase()}.png`}
                                         alt={""}/>}
                {horse.nose && <img className={"nose"}
                                    src={`https://media.legacyhorsegame.com/markings/${horse.nose.toLowerCase()}.png`}
                                    alt={""}/>}
                {horse.forehead && <img className={"forehead"}
                                        src={`https://media.legacyhorsegame.com/markings/${horse.forehead.toLowerCase()}.png`}
                                        alt={""}/>}
                {horse.dapples && <img className={"dapples"}
                                       src={`https://media.legacyhorsegame.com/${horse.dapples.toLowerCase()}`}
                                       alt={""}/>}
                {horse.sooty && <img className={"sooty"}
                                     src={`https://media.legacyhorsegame.com/${horse.sooty.toLowerCase()}`}
                                     alt={""}/>}
                {horse.pangare && <img className={"pangare"}
                                       src={`https://media.legacyhorsegame.com/${horse.pangare.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.color && <img className={"base"}
                                     src={`https://media.legacyhorsegame.com/${horse.color.toLowerCase()}.png`}
                                     alt={""}/>}
                <div style={{
                    right: props.right,
                    left: props.left,
                    top: props.top,
                    width: props.width,
                    flexFlow: props.flex,
                    transform: `translateX(${props.translateX})`
                }} className={"photo-titles-container"}>
                    {titleSrc && (horse.isWorldChamp || horse.isGrandChamp || horse.isReserveChamp || horse.isRegionalChamp || horse.isJuniorChamp) ? <img style={{width: props.imgWidth}} src={titleSrc}
                                      alt={titleSrc.split('/')[titleSrc.split('/').length - 1].split('.')[0]}/> : ""}
                    {futurityTitleSrc && horse.isFuturityChamp ? <img style={{width: props.imgWidth}} src={futurityTitleSrc}
                                             alt={futurityTitleSrc.split('/')[futurityTitleSrc.split('/').length - 1].split('.')[0]}/> : ""}
                    {veteranTitleSrc && horse.isVeteranChamp ? <img style={{width: props.imgWidth}} src={veteranTitleSrc}
                                            alt={veteranTitleSrc.split('/')[veteranTitleSrc.split('/').length - 1].split('.')[0]}/> : ""}
                    {specialTitleSrc && horse.isSpecialChamp ? <img style={{width: props.imgWidth}} src={specialTitleSrc}
                                            alt={specialTitleSrc.split('/')[specialTitleSrc.split('/').length - 1].split('.')[0]}/> : ""}
                </div>
                <img ref={imageRef} className={"background"} src={`https://media.legacyhorsegame.com/${horse.background}`}
                     alt={"background"}/>
            </div>}
        </>
    )
}

export default Photo;