import "./Register.css";
import React, {useState} from "react";
import {
    Autocomplete,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import {Colors} from "../../../../../enums/Colors";
import {Patterns} from "../../../../../enums/Patterns";
import Typography from "@mui/material/Typography";

const Register = (props) => { 
    const [regName, setRegName] = useState("");

    const handleSetRegName = (event => {
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u; // This regex matches a range of emoji characters

        if (!emojiRegex.test(event.target.value)) {
            setRegName(event.target.value);
        }

    });

    const [prefix, setPrefix] = useState(true);
    
    const handleSetPrefix = (event) => {
        setPrefix(event.target.checked);
    }

    const [color, setColor] = useState("");
    const handleSetColor = (event, values) =>  {
        setColor(values.replace(/ /g,''));
    }


    const [pattern, setPattern] = useState("");
    const handleSetPattern = (event, values) =>  {
        setPattern(values.replace(/ /g,''));
    }
    
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState("Registration successful.");
    
    const handleRegisterName = () => {
        handleCloseConfirmation();

        if(regName === ""){
            setMessage("Please include a registered name.");
            handleOpen();
            return;
        }
        
        if(color === "" || pattern === ""){
            setMessage("Please select a color & pattern. (You can choose 'None' if the horse does not have a pattern.");
            handleOpen();
            return;
        }
        
        const formData = new FormData;
        
        formData.append("id", props.id);
        formData.append("name", regName);
        formData.append("prefix", prefix);
        formData.append("color", color);
        formData.append("pattern", pattern);
        
        axios.post("/api/Horses/register", formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                handleOpen();
                setDisabled(true);
                props.handleSetUpdated();
            }).catch(function (error) {
                setMessage(error.response.data);
                handleOpen();
                console.log(error);
        });
    }
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }
    
    return (
        <>
            <div className={props.darkMode ? "register-container-dark" :"register-container"}>
                <div className={"register-title-container"}>
                    <Typography variant={"h3"}>Register</Typography>
                </div>
                <FormControl sx={{margin: 1, width: "90%"}}>
                    <TextField
                        label={"Register Horse"}
                        size={"small"}
                        value={regName}
                        onChange={handleSetRegName}
                        inputProps={{maxLength: 28}}/>
                </FormControl>
                <FormControl sx={{alignSelf: "flex-start", marginLeft: "5%"}}>
                    <FormControlLabel control={<Checkbox checked={prefix} onChange={handleSetPrefix}/>}
                                      label="Add Prefix"/>
                </FormControl>
                <FormControl sx={{margin: 1, width: "90%"}}>
                    <Autocomplete required
                                  label={"Color"}
                                  options={[...Colors].sort()}
                                  getOptionLabel={color => color}
                                  onChange={handleSetColor}
                                  renderInput={(params) => (<TextField {...params} label={"Color"} variant={"outlined"} size={"small"}/>)}/>
                </FormControl>
                <FormControl sx={{margin: 1, width: "90%"}}>
                    <Autocomplete required
                                  options={["None", ...Patterns].sort(function (a, b){
                                      if(a === 'None' || b === 'None'){
                                          return 0;
                                      }
                                      if (a < b)
                                          return -1;
                                      if (a > b)
                                          return 1;
                                      return 0;
                                  })}
                                  getOptionLabel={pattern => pattern}
                                  onChange={handleSetPattern}
                                  renderInput={(params) => (<TextField {...params} label={"Pattern"} variant={"outlined"} size={"small"}/>)}/>
                </FormControl>
                <Button sx={{margin: 2, width: "80px", alignSelf: "flex-end"}} variant={"contained"} disabled={disabled}
                        onClick={handleOpenConfirmation}>Register</Button>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Confirm Registration</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Registering a horse is final. Would you like to name this horse {regName}?
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleRegisterName} variant={"contained"}>Confirm</Button>
                    <Button onClick={handleCloseConfirmation} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Register