import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useParams} from "react-router-dom";
import axios from "axios";

const ResetPassword = (props) => {
    const { email } = useParams();
    const { token } = useParams();

    const [password, setPassword] = useState("");

    const handleSetPassword = (event) => {
        setPassword(event.target.value);
    }
    
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const handleSetPasswordConfirmation = (event) => {
        setPassword(event.target.value);
        if(passwordConfirmation !== password){
            handleClosePassConfirmationWarning();
        }
    }
    
    const handleSubmitForm = () => {
        const params = {
            email: email,
            token: token,
            password: password
        }
        
        axios.post("/api/Account/passwordReset", params, props.config)
            .then(response => {
                console.log(response.data);
                handleOpenMessage();
            }).catch(error => {
                console.log(error);
        })
    }

    const [messageOpen, setMessageOpen] = useState(false);
    const [message, setMessage] = useState("Password reset successful.")

    const handleOpenMessage = () => {
        setMessageOpen(true);
    }

    const handleCloseMessage = () => {
        setMessageOpen(false);
    }

    const [passConfirmationWarningOpen, setPassConfirmationWarningOpen] = useState(false);

    const handleOpenPassConfirmationWarning = () => {
        setPassConfirmationWarningOpen(true);
    }

    const handleClosePassConfirmationWarning = () => {
        setPassConfirmationWarningOpen(false);
    }
    
    return (
        <div>
            <TextField label={"Password"} variant={"outlined"} size={"small"} margin={"normal"} required type={"password"} onChange={handleSetPassword}/>
            <TextField label={"Password"} variant={"outlined"} size={"small"} margin={"normal"} required type={"password"} onChange={handleSetPasswordConfirmation}/>
            <Button sx={{margin: 1, maxWidth: 70}} type={"submit"} onClick={handleSubmitForm} variant={"contained"}>Reset</Button>
            <Dialog open={passConfirmationWarningOpen} onClose={handleClosePassConfirmationWarning} >
                <DialogTitle>Password match error</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Passwords do not match.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClosePassConfirmationWarning} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={messageOpen} onClose={handleCloseMessage} sx={{padding: '20px'}}>
                <DialogTitle>Error</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ResetPassword;