import './Ribbon.css';

import React from "react";

const Ribbon = (props) => {
      return (
          <div className={"ribbon-container"}>
              <p className={"total-wins"}>{props.total}</p>
              <img className={"ribbon"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/ribbons/" + props.placement + ".png"} alt={"blue ribbon"}/>
          </div>
      )
}

export default Ribbon;