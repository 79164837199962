import "./RecentMail.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../../Theme";
import {Link} from "react-router-dom";

const RecentMail = (props) => {

    const [recent, setRecent] = useState([]);

    useEffect(() => {
        axios.get("api/Messages/recent", {
            params: {
                id: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setRecent(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    return (
        <div className={"recent-mail-container"}>
            <Table sx={props.darkMode ? {
                backgroundColor: darkTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px #262626"
            } : {
                backgroundColor: lightTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px darkgray"
            }}>
                <TableHead
                    sx={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}>
                    <TableRow>
                        <TableCell sx={{marginLeft: "10px"}}>Subject</TableCell>
                        <TableCell sx={{width: "20%"}} align={"center"}>
                            Sender
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recent.map((thread, i) =>
                        <TableRow key={i}>
                            <TableCell sx={{marginLeft: "10px"}}>
                            <Link
                                to={`/messages/${thread.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].mailMessageId}`}>
                                   <Typography sx={{fontWeight: thread.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].isRead ? 400 : 600}}>{thread.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].title}</Typography>
                            </Link>
                            </TableCell>
                            <TableCell align={"center"}>
                                <Typography sx={{marginRight: "10px"}}>{thread.messages.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))[0].sender.displayName}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default RecentMail