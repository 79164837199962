import "./ColorCode.css"

import React from "react";
import {ColorCodes} from "../../../enums/ColorCodes";

const ColorCode = (props) => {    
    return (
        <div style={{
            background: `linear-gradient(to bottom right, transparent 0%, transparent 50%, ${ColorCodes[props.color]} 50%, ${ColorCodes[props.color]} 100%)`,
            width: props.size,
            height: props.size
        }} 
             className={"color-code-container"}/>
    )
}

export default ColorCode