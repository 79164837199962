export const Patterns = [
    "Splash",
    "Tobiano",
    "Sabino",
    "Frame",
    "Overo",
    "Tovero",
    "Leopard",
    "Snowcap",
    "Blanket",
    "Varnish Leopard",
    "Varnish Snowcap",
    "Varnish Blanket",
    "Pintaloosa",
    "Roan",
    "Rabicano",
    "Roan Splash",
    "Roan Tobiano",
    "Roan Sabino",
    "Roan Frame",
    "Roan Overo",
    "Roan Tovero",
    "Roan Leopard",
    "Roan Snowcap",
    "Roan Blanket",
    "Roan Varnish Leopard",
    "Roan Varnish Snowcap",
    "Roan Varnish Blanket",
    "Roan Pintaloosa",
    "Rabicano Splash",
    "Rabicano Tobiano",
    "Rabicano Sabino",
    "Rabicano Frame",
    "Rabicano Overo",
    "Rabicano Tovero",
    "Rabicano Leopard",
    "Rabicano Snowcap",
    "Rabicano Blanket",
    "Rabicano Varnish Leopard",
    "Rabicano Varnish Snowcap",
    "Rabicano Varnish Blanket",
    "Rabicano Pintaloosa",
    "Snowflake",
    "Roan Snowflake",
    "Rabicano Snowflake",
    "Fewspot",
    "Varnish Pintaloosa",
    "Varnish",
    "Varnish Snowflake"
]