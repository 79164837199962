import "./Notification.css";

import React from "react";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {Button} from "@mui/material";
import {darkTheme, lightTheme} from "../../../../Theme";
import {CloseRounded} from "@mui/icons-material";
import {Months} from "../../../../enums/Months";

const Notification = (props) => {
    
    const handleReadNotification = (id) => {
        axios.post('api/Application/markNotificationRead', id, props.config)
            .then(response =>{
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    return (
        <div style={props.darkMode ? {
            backgroundColor: "#2B2B2B",
            boxShadow: "5px 5px 5px #262626"
        } : {
            backgroundColor: "#EEEEEE",
            boxShadow: "5px 5px 5px darkgray"
        }} className={"notification-container"}>
            <div className={"notification-content-container"}>
                <Typography sx={{marginRight: "10px", fontWeight: "bold"}}>{formatDate(props.notification.date)}</Typography>
                {props.notification.horseId != null ?
                    <Link to={`/horses/${props.notification.horseId}`}>
                        <Typography >{props.notification.message}</Typography>
                    </Link> :
                    <Typography>{props.notification.message}</Typography>}
            </div>
            {!props.notification.read ? <CloseRounded onClick={handleReadNotification.bind(this, props.notification.notificationId)}/> : ""}
        </div>
    )
}

export default Notification