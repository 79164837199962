import './CategoryCard.css';

import React, {useEffect, useState} from "react";
import SubCategory from "./SubCategory";
import SubCategoryCard from "./SubCategoryCard";
import axios from "axios";
import {Link} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, TextField, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../../Theme";
import {Close} from "@mui/icons-material";

const CategoryCard = (props) => {
    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [title, setTitle] = useState("");

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleAddSubCategory = () => {
        const data = new FormData();
        data.append("email", props.email);
        data.append("title", title);
        data.append("catId", props.category.categoryId);
        axios.post("/api/Forum/newSubCategory", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleClose();
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <div className={"category-card-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"category-card-header-container"}>
                    <Typography variant={"h3"}>{props.category.title}</Typography>
                </div>
                <div className={"category-card-subcat-container"}>
                    {props.category.subCategories.map((subcat, i) => 
                        <div key={i} style={{width: '100%'}}>
                           <Link to={`/forum/${subcat.subCategoryId}`}>
                               <SubCategoryCard subcategory={subcat}/>
                           </Link>
                            {i < props.category.subCategories.length - 1 ? <Divider sx={{width: "100%", marginBottom: "5px"}}/> : ""}
                        </div>
                    )}
                </div>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add SubCategory</DialogTitle>
                <FormControl sx={{display: "flex", flexFlow: "row nowrap"}}>
                    <TextField sx={{margin: 1, maxWidth: 250}} label={"SubCategory title"} size={"small"}
                               onChange={handleSetTitle}/>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                    <Button onClick={handleAddSubCategory} autoFocus color={"primary"} variant={"contained"}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CategoryCard;