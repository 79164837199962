import "./CustomizeRanch.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    Select,
    TextField,
    Typography
} from "@mui/material";
import UploadImage from "./UploadImage";
import Button from "@mui/material/Button";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import show from "../../shows/Show";

const CustomizeRanch = (props) => {
    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }
    
    const [showPrefix, setShowPrefix] = useState(true);
    const [prefix, setPrefix] = useState("");
    
    const handleSetPrefix = (event) => {
        setPrefix(event.target.value);
    }
    
    const setRanchPrefix = () => {
        const data = new FormData();
        
        data.append("ranchId", ranch.ranchId);
        data.append("prefix", prefix);
        
        axios.post("api/Ranches/setPrefix", data, props.formConfig)
            .then(response => {
                console.log(response);
                setMessage(response.data);
                setShowPrefix(false);
                handleOpen();
            }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
        })
    }

    const [bio, setBio] = useState("");

    const handleSetBio = (event) => {
        setBio(event.target.value);
    }

    const handleUpdateBio = () => {
        const data = new FormData;
        data.append("id", ranch.ranchId);
        data.append("bio", bio);

        axios.post('/api/Ranches/updateBio', data, props.formConfig)
            .then(response => {
                console.log(response);
                setMessage(response.data);
                handleOpen();
            }).catch(error => {
            console.log(error);
            setMessage(error.response.data);
            handleOpen();
        });
    }

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const sectionTitleStyle = {
        fontWeight: "bold",
        fontSize: "16px",
        marginBottom: "10px"
    }

    const btnStyle = {
        alignSelf: "flex-end"
    }

    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={props.darkMode ? "ranch-customization-container ranch-customization-container-dark" : "ranch-customization-container"}>
                <div className={"ranch-customization-header-container"}>
                    <Typography variant={"h3"}>Ranch Profile</Typography>
                </div>
                <FormControl sx={{width: "65%"}}>
                    <Select label={"Ranch"} onChange={handleSetRanch} size={"small"}>
                        {props.ranches.map(r =>
                            <MenuItem key={r.ranchId} value={r}>{r.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {ranch && <>
                    <div className={"ranch-customization-uploads-container"}>
                        <div style={{width: "45%"}} className={"ranch-customization-sub-container"}>
                            <Typography sx={sectionTitleStyle}>
                                Change Profile Image
                            </Typography>
                            <UploadImage fileName={ranch.ranchId} config={props.config} bucket={"ranchimages"}
                                         formConfig={props.formConfig}/>
                        </div>
                        <div style={{width: "45%"}} className={"ranch-customization-sub-container"}>
                            <Typography sx={sectionTitleStyle}>
                                Change Banner Image
                            </Typography>
                            <UploadImage fileName={ranch.ranchId} config={props.config} bucket={"ranchbanner"}
                                         formConfig={props.formConfig}/>
                        </div>
                    </div>
                    {ranch.prefix == null && showPrefix ? <div className={"ranch-customization-sub-container"}>
                        <Typography sx={sectionTitleStyle}>
                            Change Prefix
                        </Typography>
                        <TextField sx={{width: "100%", marginBottom: "10px"}}
                                   size={"small"} onChange={handleSetPrefix}/>
                        <Button sx={btnStyle} variant={"contained"} size={"small"}
                                onClick={setRanchPrefix}>Submit</Button>
                    </div> : ""}
                    <div className={"ranch-customization-sub-container"}>
                        <Typography sx={sectionTitleStyle}>
                            Change Bio
                        </Typography>
                        <TextField sx={{width: "100%", marginBottom: "10px"}} rows={10} multiline
                                   onChange={handleSetBio}
                                   defaultValue={props.user.bio}/>
                        <Button sx={btnStyle} variant={"contained"} size={"small"}
                                onClick={handleUpdateBio}>Submit</Button>
                    </div>
                </>}
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomizeRanch