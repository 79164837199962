import "./Message.css";

import React, {useEffect} from "react";
import {Divider, Typography} from "@mui/material";
import * as moment from "moment";
import {Link} from "react-router-dom";
import axios from "axios";

const Message = (props) => {

    useEffect(() => {
        if(!props.message.isRead){
            axios.post("api/Messages/markRead", props.message.mailMessageId, props.config)
                .then(response => {
                    console.log(response);
                }).catch(error => {
                console.log(error);
            })
        }
    }, []);

    return (
        <div className={"mail-message-container"}>
            <div className={"mail-message-header-container"}>
                <Typography
                    sx={{flexGrow: 1, fontWeight: "bolder", fontSize: "18px"}}>{props.message.title}</Typography>
                <Typography
                    sx={{flexGrow: 0}}>{moment(props.message.dateTime).format("MMMM DD, YYYY: h:mm:ss a")}</Typography>
            </div>
            <Divider sx={{marginBottom: "10px"}}/>
            <div className={"mail-message-from-container"}>
                <Typography sx={{
                    marginRight: "20px",
                    fontSize: "16px",
                    fontWeight: "bolder"
                }}>From:</Typography>
                <Link to={`/users/${props.message.sender.displayName}`}>
                    <Typography sx={{
                        fontSize: "16px"
                    }}>{props.message.sender.displayName}</Typography>
                </Link>
            </div>
            <div className={"mail-message-to-container"}>
                <Typography sx={{
                    marginRight: "20px",
                    fontSize: "16px",
                    fontWeight: "bold"
                }}>To:</Typography>
                {props.message.recipients.map((recipient, i) => <Link to={`/users/${recipient.displayName}`}>
                    <Typography key={i} sx={{
                        marginRight: "5px",
                        fontSize: "16px"
                    }}>{recipient.displayName}{i != props.message.recipients.length - 1 ? "," : ""}</Typography>
                </Link>)}
            </div>
            <Divider sx={{marginBottom: "15px"}}/>
            <Typography sx={{marginBottom: "15px"}}>{props.message.body}</Typography>
            <Divider sx={{marginBottom: "10px"}}/>
        </div>
    )
}

export default Message