import "./Message.css";

import React from "react";
import {Typography} from "@mui/material";
import {lightTheme} from "../../Theme";
import moment from "moment";
const Message = (props) => {
    
    const getTimeSent = (timestamp) => {
        
        if(moment.utc(timestamp).local().month() == moment.utc().local().month() && moment.utc(timestamp).local().day() == moment.utc().local().day() && moment.utc(timestamp).local().year() == moment.utc().local().year()){
            return moment.utc(timestamp).local().format('LT');
        }
        
        return moment.utc(timestamp).local().format('l');
    }
    
    return (
        <div style={props.message.authorId == props.user.id ? {backgroundColor: lightTheme.palette.primary.light, alignSelf: "flex-end"} : {backgroundColor: "#d9d9d9"}} className={"chat-message-container"}>
            <div className={"chat-message-info-container"}>
                <Typography>{props.message.author}</Typography>
                <Typography>{getTimeSent(props.message.timestamp)}</Typography>
            </div>
            <Typography>{props.message.body}</Typography>
        </div>
    )
}

export default Message