import "./Transactions.css";

import React, {useState} from "react";
import {Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {Months} from "../../../enums/Months";

const Transactions = (props) => {

    const row = props.transaction;
    
    const [open, setOpen] = useState(false);

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }

    return (
        <>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell sx={{width: "5%"}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp onClick={setOpen.bind(this, true)}/> : <KeyboardArrowDown onClick={setOpen.bind(this, false)}/>}
                    </IconButton>
                </TableCell>
                <TableCell sx={{width: "80%"}} component="th" scope="row">
                    {formatDate(row.date)}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.total}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open || props.description != ""} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Transactions
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell sx={{width: "10%"}} align="center">Income</TableCell>
                                        <TableCell sx={{width: "10%"}} align="center">Expense</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.transactions.map((transaction, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{transaction.description}</TableCell>
                                            <TableCell component="th" scope="row" align={"center"}>
                                                {transaction.amount > 0 ? transaction.amount : ""}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align={"center"}>
                                                {transaction.amount < 0 ? transaction.amount : ""}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default Transactions