import {createTheme} from "@mui/material/styles";
import {grey} from "@mui/material/colors";

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#044242',
        },
        secondary: {
            main: '#DB9200',
        },
        containerBackground: {
            main: '#f8f8f8'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1060,
            lg: 1200,
            xl: 1536,
        }
    },
    typography: {
        p: {
            fontFamily: "Roboto, sans-serif",
        },
        h2: {
            fontFamily: "Raleway, sans-serif",
            fontSize: "36px"
        },
        h3: {
            fontFamily: "Raleway, sans-serif",
            fontSize: "28px",
            fontWeight: "bolder",
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: {
                        variant: 'navigation'
                    },
                    style: {
                        minWidth: "20px",
                        textTransform: "uppercase",
                        margin: "5px",
                        padding: "5px"
                    },
                },
            ],
            styleOverrides: {
                contained: {
                    minWidth: "100px",
                    textTransform: "uppercase",
                    margin: "5px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: "#d9d9d9"
                }
            }
        }
    },
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#0C5757',
        },
        secondary: {
            main: '#DB9200',
        },
        background: {
            default: "#414042",
        },
        text: {
            primary: '#fff',
            secondary: grey[500],
        },
        containerBackground: {
            main: '#3A3A3A'
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1060,
            lg: 1200,
            xl: 1536,
        }
    },
    typography: {
        p: {
            fontFamily: "Roboto, sans-serif",
        },
        h2: {
            fontFamily: "Raleway, sans-serif",
            fontSize: "36px"
        },
        h3: {
            fontFamily: "Raleway, sans-serif",
            fontSize: "28px",
            fontWeight: "bolder"
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    minWidth: "100px",
                    textTransform: "uppercase",
                    margin: "5px"
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#414042',
                },
                list: {
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#414042',
                        color: '#FFF',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "#e3e3e3"
                },
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#414042',
                },
                list: {
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#414042',
                        color: '#FFF',
                    },
                },
                popupIndicator: {
                    color: "white"
                }
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: "1px solid #e3e3e3",
                }
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#ccc"
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        color: "#e6aa07"
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.2,
                    backgroundColor: "#fff",
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: "#e6aa07"
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    "&.Mui-checked": {
                        color: "#e6aa07"
                    }
                }
            }
        }
    },
});