import "./Horses.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import HorseCard from "./HorseCard";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Pagination,
    Tab,
    TextField
} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Breeds} from "../../enums/Breeds";
import {ColorCodes} from "../../enums/ColorCodes";
import Typography from "@mui/material/Typography";
import {Gender} from "../../enums/Gender";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";
import {Jobs} from "../../enums/Jobs";

const Horses = (props) => {
    const ranchId = props.ranchId;

    const location = useLocation();
    const history = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };

    const [selectedLocation, setSelectedLocation] = useState(tab);

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [breeds, setBreeds] = useState([]);

    const handleSetBreeds = (breed) => {
        if (breeds.includes(breed)) {
            setBreeds(breeds.filter(b => b != breed));
        } else {
            setBreeds([...breeds, breed]);
        }
    }

    const [genders, setGenders] = useState([]);

    const handleSetGenders = (gender) => {
        if (genders.includes(gender)) {
            setGenders(genders.filter(g => g !== gender));
        } else {
            setGenders([...genders, gender]);
        }
    }

    const [searchAges, setSearchAges] = useState([]);

    const handleSetSearchAges = (age) => {
        if (searchAges.includes(age)) {
            setSearchAges(searchAges.filter(a => a != age));
        } else {
            setSearchAges([...searchAges, age]);
        }
    }

    const [jobs, setJobs] = useState([]);

    const handleSetJobs = (job) => {
        if (jobs.includes(job)) {
            setJobs(jobs.filter(j => j != job));
        } else {
            setJobs([...jobs, job]);
        }
    }

    const [searchStatuses, setSearchStatuses] = useState([]);

    const handleSetSearchStatuses = (status) => {
        if (searchStatuses.includes(status)) {
            setSearchStatuses(searchStatuses.filter(s => s != status));
        } else {
            setSearchStatuses([...searchStatuses, status]);
        }
    }

    const [name, setName] = useState(null);

    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const [colorCodes, setColorCodes] = useState([]);

    const handleSetColorCode = (colorCode) => {
        if (colorCodes.includes(colorCode)) {
            setColorCodes(colorCodes.filter(c => c != colorCode));
        } else {
            setColorCodes([...colorCodes, colorCode]);
        }
    }

    const [ids, setIds] = useState([]);

    useEffect(() => {
        const searchDto = {
            ranch: ranchId,
            location: selectedLocation,
            genders: genders,
            breeds: breeds,
            ages: searchAges,
            name: name,
            statuses: searchStatuses,
            colorCodes: colorCodes,
            jobs: jobs,
            pageNumber: pageNumber
        }
        const getHorses = async () => {
            axios.post('api/Horses/myHorsesIds', searchDto, props.config)
                .then(response => {
                    setIds(response.data);
                    localStorage.setItem("ids", response.data)
                });
        }

        const timer = setTimeout(() => {
            getHorses();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [ranchId, selectedLocation, genders, breeds, searchAges, name, searchStatuses, colorCodes, jobs]);

    const [horses, setHorses] = useState([]);

    useEffect(() => {
        const searchDto = {
            ranch: ranchId,
            location: selectedLocation,
            genders: genders,
            breeds: breeds,
            ages: searchAges,
            name: name,
            statuses: searchStatuses,
            colorCodes: colorCodes,
            jobs: jobs,
            pageNumber: pageNumber
        }
        const getHorses = async () => {
            axios.post('/api/horses/searchMyHorses', searchDto, props.config)
                .then(response => {
                    setHorses(response.data);
                });
        }

        const timer = setTimeout(() => {
            getHorses();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [ranchId, selectedLocation, genders, breeds, searchAges, name, searchStatuses, colorCodes, jobs, pageNumber]);

    useEffect(() => {
        const searchDto = {
            ranch: ranchId,
            location: selectedLocation,
            genders: genders,
            breeds: breeds,
            ages: searchAges,
            name: name,
            statuses: searchStatuses,
            colorCodes: colorCodes,
            jobs: jobs,
            pageNumber: pageNumber
        }
        const getHorsesPages = async () => {
            axios.post('/api/horses/searchMyHorsesPages', searchDto, props.config)
                .then(response => {
                    setPagesTotal(response.data)
                }).catch(error => {
                console.log(error);
            });
        }

        const timer = setTimeout(() => {
            getHorsesPages();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [ranchId, selectedLocation, genders, breeds, searchAges, name, searchStatuses, colorCodes, jobs, pageNumber]);

    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPastures', {
            params: {
                id: ranchId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setPastures(response.data);
            });
    }, []);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('api/Ranches/getBarns', {
            params: {
                id: props.ranchId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setBarns(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [])

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const [selectedTab, setSelectedTab] = useState(tab || "all");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        setSelectedLocation(newTab);
        updateUrlParams(newTab);
    }

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const ages = [
        "Suckling",
        "Weanling",
        "Mature",
        "Breeding Age"
    ]

    const statuses = [
        {
            name: "Pregnant",
            value: "pregnant"
        },
        {
            name: "Has Suckling",
            value: "hasSuckling"
        },
        {
            name: "Emaciated",
            value: "emaciated"
        },
        {
            name: "Fat",
            value: "fat"
        },
        {
            name: "Extremely Fat",
            value: "extremelyFat"
        },
        {
            name: "In Training",
            value: "inTraining"
        },
        {
            name: "On Exerciser",
            value: "exerciser"
        },
    ]

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const filterInputStyle = {
        margin: 1,
    }

    const clearFilters = () => {
        setBreeds([]);
        setSearchAges([]);
        setGenders([]);
        setSearchStatuses([]);
        setJobs([]);
        setColorCodes([]);
        setName("");
    }

    const filters = (
        <div className={"search-my-horses-filters-all-container"}>
            <div className={"search-my-horses-filters-container"}>
                <div className={"search-my-horses-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Age</Typography>
                    {ages.map((a, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                        <Checkbox sx={filterCheckboxStyle}
                                  checked={searchAges.includes(a.toLowerCase().replaceAll(/ /g, ""))}
                                  onChange={() => handleSetSearchAges(a.toLowerCase().replaceAll(/ /g, ""))}
                        />} label={a}/>
                    )}
                </div>
                <div className={"search-my-horses-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Gender</Typography>
                    {Gender.map((g, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                        <Checkbox sx={filterCheckboxStyle} checked={genders.includes(g.toLowerCase())}
                                  onChange={() => handleSetGenders(g.toLowerCase())}
                        />} label={g}/>
                    )}
                </div>
                <div className={"search-my-horses-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Status</Typography>
                    {statuses.map((s, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                        <Checkbox sx={filterCheckboxStyle} checked={searchStatuses.includes(s.value)}
                                  onChange={() => handleSetSearchStatuses(s.value)}
                        />} label={s.name}/>
                    )}
                </div>
                <div className={"search-my-horses-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Breed</Typography>
                    <div className={"search-my-horses-filter-items-container"}>
                        {[...Breeds].sort().map((breed, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={breeds.includes(breed.replaceAll(/ /g, ""))}
                                          onChange={() => handleSetBreeds(breed.replaceAll(/ /g, ""))}
                                />} label={breed}/>
                        )}
                    </div>
                </div>
                <div className={"search-my-horses-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Job</Typography>
                    <div className={"search-my-horses-filter-items-container"}>
                        {Jobs.map((job, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={jobs.includes(job.replaceAll(/ /g, ""))}
                                          onChange={() => handleSetJobs(job.replaceAll(/ /g, ""))}
                                />} label={job}/>
                        )}
                    </div>
                </div>
                <div className={"search-my-horses-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Color Code</Typography>
                    <div className={"search-my-horses-filter-items-container"}>
                        {ColorCodes.map((color, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle}
                                          checked={colorCodes.includes(color.replaceAll(/ /g, ""))}
                                          onChange={() => handleSetColorCode(color.replaceAll(/ /g, ""))}
                                />} label={color}/>
                        )}
                    </div>
                </div>
                <div className={"search-my-horses-filter-sub-container"}>
                    <FormControl sx={filterInputStyle}>
                        <TextField label={"Name"} size={"small"} value={name} onChange={handleSetName}/>
                    </FormControl>
                </div>
            </div>
            <div className={"search-my-horses-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <div className={"horses-page"}>
            {selectedTab.includes("barn") ? <img className={"horses-banner"}
                                                 src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/barn-banner.jpg"}
                                                 alt={"horses"}/> : <img className={"horsesBanner"}
                                                                         src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/home-banner.jpg"}
                                                                         alt={"horses"}/>}
            <TabContext value={selectedTab} sx={{position: "relative"}}>
                <Box width={"100%"}>
                    <TabList variant={"scrollable"} className={"horses-tabs"} onChange={handleChangeTab}
                             textColor={"white"}>
                        <Tab label={"All Horses"} value={"all"}/>
                        {pastures && pastures.map((pasture, i) =>
                            <Tab key={i} label={pasture.name} aria-label={pasture.name}
                                 value={"pasture " + pasture.pastureId.toString()}/>
                        )}
                        {barns && barns.map((barn, i) =>
                            <Tab key={i} label={barn.name} aria-label={barn.name}
                                 value={"barn " + barn.barnId.toString()}/>
                        )}
                    </TabList>
                </Box>
                {filtersOpen ? filters : ""}
                <div className={"page-filters-pages-container"}>
                    <FilterList onClick={openFilters}/>
                    <Pagination variant={"outlined"}
                                shape={"rounded"}
                                boundaryCount={2}
                                page={pageNumber}
                                count={pagesTotal}
                                onChange={handleSetPageNumber}
                    />
                </div>
                <TabPanel sx={props.darkMode ? {backgroundColor: "#414042"} : {}} className={"horses-tab"}
                          value={"all"}>
                    <div className={"horses-section"}>
                        {horses && horses.slice(indexStart, indexEnd).map(horse =>
                            <Link className={"horses-link"} to={`/horses/${horse.horseId}`}>
                                <HorseCard key={horse.horseId} horse={horse} id={horse.horseId} api={props.api}
                                           gelded={horse.gelded}/>
                            </Link>
                        )}
                    </div>
                </TabPanel>
                {pastures && pastures.map(pasture =>
                    <TabPanel sx={props.darkMode ? {backgroundColor: "#414042"} : {}} className={"horses-tab"}
                              value={"pasture " + pasture.pastureId.toString()}>
                        <div className={"horses-section"}>
                            {horses.slice(indexStart, indexEnd).map(horse =>
                                <Link className={"horses-link"} to={`/horses/${horse.horseId}`}>
                                    <HorseCard key={horse.horseId} horse={horse} id={horse.horseId} api={props.api}
                                               gelded={horse.gelded}/>
                                </Link>
                            )}
                        </div>
                    </TabPanel>
                )}
                {barns && barns.map(barn =>
                    <TabPanel sx={props.darkMode ? {backgroundColor: "#414042"} : {}} className={"horses-tab"}
                              value={"barn " + barn.barnId.toString()}>
                        <div className={"horsesSection"}>
                            {horses.slice(indexStart, indexEnd).map(horse =>
                                <Link key={horse.horseId} className={"horses-link"} to={`/horses/${horse.horseId}`}>
                                    <HorseCard horse={horse} id={horse.horseId} api={props.api}
                                               gelded={horse.gelded}/>
                                </Link>
                            )}
                        </div>
                    </TabPanel>
                )}
                <Pagination variant={"outlined"}
                            shape={"rounded"}
                            boundaryCount={2}
                            page={pageNumber}
                            count={pagesTotal}
                            onChange={handleSetPageNumber}
                />
            </TabContext>
        </div>

    )
}

export default Horses;