import "./HorseInfoItem.css";

import React from 'react';
import {Divider, Typography} from "@mui/material";

function HorseInfoItem(props) {

    return (
        <div className={"info-item"}>
            <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", fontWeight: "bold", marginRight: "10px"
            }}
            >{props.title}</Typography>
            <Divider sx={{flexGrow: 1}}/>
            <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", marginLeft: "10px"
            }}>{props.info}</Typography>
        </div>
    )
}

export default HorseInfoItem;