import "./MessageThread.css";

import React, {useEffect, useState} from "react";
import Message from "./Message";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Close, Delete, Favorite, Reply, ReplyAll, Send} from "@mui/icons-material";
import {darkTheme, lightTheme} from "../../../Theme";
import {Autocomplete, Button, ButtonGroup, Chip, FormControl, TextField, Typography} from "@mui/material";
import DividerTitle from "../../DividerTitle";

const MessageThread = (props) => {

    const {id} = useParams();

    const [prevThreadId, setPrevThreadId] = useState();
    const [nextThreadId, setNextThreadId] = useState();

    useEffect(() => {
        const index = props.messageIds.indexOf(id.toString());
        setPrevThreadId(props.messageIds[index - 1]);
        setNextThreadId(props.messageIds[index + 1]);
    }, [props.messageIds, id]);
    
    const [updated, setUpdated] = useState(0);
    
    const handleUpdated = () => {
        setUpdated(updated + 1);
    }

    const [thread, setThread] = useState(null);

    useEffect(() => {
        axios.get("api/Messages/thread", {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setThread(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [id, updated]);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('api/Account/otherUsers', {
            params: {
                email: props.email
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setUsers(response.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const [recipients, setRecipients] = useState([]);

    const handleSetRecipients = (event, value) => {
        setRecipients(value);
    }

    const [subject, setSubject] = useState("");

    const handleSetSubject = (event) => {
        setSubject(event.target.value);
    }

    const [content, setContent] = useState("");

    const handleSetContent = (event) => {
        setContent(event.target.value);
    }

    const [isNewMessageOpen, setIsNewMessageOpen] = useState(false);

    const openReply = () => {
        setRecipients(users.filter(u => u.id == thread.messages.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))[0].senderId));
        setIsNewMessageOpen(true);
    }

    const openReplyAll = () => {
        setRecipients(users.filter(u => u.id != props.id && (thread.messages.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))[0].recipients.some(r => r.id === u.id) || u.id === thread.messages.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))[0].senderId)));
        setIsNewMessageOpen(true);
    }

    const closeNewMessage = () => {
        setIsNewMessageOpen(false);
    }

    const newMessageInputStyle = {
        width: "95%",
        margin: 1
    }

    const sendMessage = () => {
        const message = {
            mailThreadId: id,
            senderId: props.id,
            recipients: recipients,
            title: subject,
            body: content
        }

        axios.post("api/Messages/send", message, props.config)
            .then(response => {
                console.log(response);
                closeNewMessage();
                response.data ? props.history("/inbox") : handleUpdated();
            }).catch(error => {
            console.log(error);
        })
    }
    
    const deleteMessage = () => {
        axios.post("api/Messages/delete", id, props.config)
            .then(response => {
                console.log(response);
                props.history("/inbox");
            }).catch(error => {
            console.log(error);
        })
    }

    const navigateThread = (id) => {
        console.log(id)
        props.history(`/messages/${id}`);
    }

    return (
        <div>
            <img className={"mail-messages-banner"} src={"https://media.legacyhorsegame.com/banners/mail-banner.jpg"}
                 alt={"Mailboxes"}/>
            <DividerTitle/>
            <div className={"message-thread-container"}>
                {isNewMessageOpen && <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                    className={"message-thread-new-message-form-container"}>
                    <div
                        style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                        className={"message-thread-new-message-form-header-container"}>
                        <Typography sx={{flexGrow: 1, textAlign: "center", marginRight: "20px"}} variant={"h3"}>
                            New Message
                        </Typography>
                        <Close onClick={closeNewMessage}/>
                    </div>
                    <FormControl sx={newMessageInputStyle}>
                        <Autocomplete
                            multiple
                            required
                            value={recipients} // Ensure this is connected to your state
                            options={users}
                            getOptionLabel={(option) => option.displayName}
                            onChange={handleSetRecipients}
                            renderTags={(recipients, getTagProps) =>
                                recipients.map((option, index) => (
                                    <Chip variant="outlined" label={option.displayName} {...getTagProps({index})} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Recipient" variant="outlined" size="small"/>
                            )}
                        />
                    </FormControl>
                    <FormControl sx={newMessageInputStyle}>
                        <TextField size={"small"} label={"Subject"} onChange={handleSetSubject}/>
                    </FormControl>
                    <FormControl sx={newMessageInputStyle}>
                        <TextField multiline minRows={10} maxRows={15} onChange={handleSetContent}/>
                    </FormControl>
                    {/*<ReactQuill theme="snow" value={content} onChange={handleSetContent}/>*/}
                    <Send sx={{alignSelf: "flex-end", margin: "10px 40px 20px 0"}} onClick={sendMessage}/>
                </div>}
                {thread && <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                    className={"message-thread-subcontainer"}>
                    <div
                        style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                        className={"message-thread-header-container"}>
                        <ButtonGroup>
                            <Button size={"small"} variant={"contained"} disabled={prevThreadId == null} onClick={navigateThread.bind(this, prevThreadId)}>Prev</Button>
                            <Link to={`/inbox`}>
                                <Button size={"small"} variant={"contained"}>Inbox</Button>
                            </Link>
                            <Button size={"small"} variant={"contained"} disabled={nextThreadId == null} onClick={navigateThread.bind(this, nextThreadId)}>Next</Button>
                        </ButtonGroup>
                        <div className={"message-thread-tools-container"}>
                            <Reply sx={{margin: 1}} onClick={openReply}/>
                            <ReplyAll sx={{margin: 1}} onClick={openReplyAll}/>
                            {/*<Favorite sx={{margin: 1}}/>*/}
                            <Delete sx={{margin: 1}} onClick={deleteMessage}/>
                        </div>
                    </div>
                    {thread.messages.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)).map(message =>
                        <Message message={message} config={props.config}/>
                    )}
                </div>}
            </div>
        </div>
    )
}

export default MessageThread