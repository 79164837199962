import "./Comment.css";

import React, {useEffect, useRef, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../../Theme";
import moment from "moment";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import DOMPurify from "dompurify";
import {Close, EditNote, Reply, ThumbDown, ThumbUp} from "@mui/icons-material";
import axios from "axios";
import Button from "@mui/material/Button";
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize';
import {ImageDrop} from 'quill-image-drop-module'
import aws from "aws-sdk";
import {PutObjectCommand, S3Client} from "@aws-sdk/client-s3";

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop)

const endpoint = new aws.Endpoint("nyc3.digitaloceanspaces.com");
const s3 = new S3Client({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: endpoint,
    region: "us-east-1",
    credentials: {
        accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
    }
});

const handleUpload = async (filename, blob) => {
    const params = {
        Body: blob,
        Bucket: 'legacyhorsegame',
        Key: `forum/${filename}`,
        ContentType: 'image/png',
        ContentLength: blob.size,
        ACL: "public-read",
        CacheControl: "no-cache"
    };

    s3.send(new PutObjectCommand(params)).then(result => {
        console.log(result)
    }).catch(error => {
        console.log(error);
    });
}

var quillCommentObj;
var quillCommentEditObj;

const uploadFiles = (uploadFileObj, filename, quillObj) => {

    console.log(quillObj)
    
    var currentdate = new Date();
    var fileNamePredecessor = currentdate.getDate().toString() + currentdate.getMonth().toString() + currentdate.getFullYear().toString() + currentdate.getTime().toString();

    filename = fileNamePredecessor + "_" + filename;

    if (uploadFileObj != '') {
        handleUpload(filename, uploadFileObj).then((response) => {
            const range = quillObj.current.getEditor().getSelection();

            const res = `https://media.legacyhorsegame.com/forum/${filename}`;

            setTimeout(() => {
                quillObj.current.getEditor().insertEmbed(range.index, 'image', res);
            }, 1000)
        }).catch((error) =>
            console.log(error)
        );
    }
}

const imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file: any = input.files[0];
        
        await uploadFiles(file, file.name, quillCommentObj);
    };
}

const imageEditHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file: any = input.files[0];

        uploadFiles(file, file.name, quillCommentEditObj);
    };
}

const quillModules = {
    toolbar: {
        container: [
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['link', 'image'],
            ['clean'],
            [{'color': []}]
        ],
        handlers: {
            image: imageHandler
        },
        imageDrop: true,
        imageResize: {
            displaySize: true,
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        }
    }
}

const quillEditModules = {
    toolbar: {
        container: [
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['link', 'image'],
            ['clean'],
            [{'color': []}]
        ],
        handlers: {
            image: imageEditHandler
        },
        imageDrop: true,
        imageResize: {
            displaySize: true,
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        }
    }
}

const Comment = (props) => {

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetUpdated();
    }

    quillCommentObj = useRef(null);
    quillCommentEditObj = useRef(null);
    
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    
    const openEditor = () => {
        setIsEditorOpen(true);
    }

    const closeEditor = () => {
        setIsEditorOpen(false);
    }

    const [commentContent, setCommentContent] = useState(DOMPurify.sanitize(props.comment.content, {
        USE_PROFILES: {html: true}
    }));
    
    const handleSetCommentContent = (content, delta, source, editor) => {
        setCommentContent(content);
    }

    const editComment = () => {
        const data = new FormData();
        data.append("commentId", props.comment.commentId);
        data.append("userId", props.user.id);
        data.append("authorId", props.comment.author.id);
        data.append("content", commentContent);

        axios.post("/api/Forum/editComment", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
                closeEditor();
            }).catch(error => {
            console.log(error);
        })
    }

    const [replies, setReplies] = useState([]);

    useEffect(() => {
        axios.get("api/forum/replies", {
            params: {
                id: props.comment.commentId,
                userId: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setReplies(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.comment]);

    const upVote = () => {
        const data = new FormData();
        data.append("commentId", props.comment.commentId);
        data.append("userId", props.user.id);

        axios.post("api/Forum/upVoteComment", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const downVote = () => {
        const data = new FormData();
        data.append("commentId", props.comment.commentId);
        data.append("userId", props.user.id);

        axios.post("api/Forum/downVoteComment", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [content, setContent] = useState("");
    const handleSetContent = (content, delta, source, editor) => {
        setContent(content);
    }

    const archiveComment = () => {
        const data = new FormData();
        data.append("id", props.comment.postId);
        data.append("isActive", !event.target.checked);
        
        axios.post("/api/Forum/archiveComment", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const createNewComment = () => {
        const data = new FormData();
        data.append("postId", props.comment.postId);
        data.append("commentId", props.comment.commentId);
        data.append("userId", props.user.id);
        data.append("content", content);

        axios.post("/api/Forum/newComment", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const iconStyle = {
        margin: 1,
        color: "black"
    }

    const checkBoxStyle = {
        margin: "2px",
        alignSelf: "flex-end"
    }

    const commentEditor = (
        <div className={"comment-editor"}>
            <Close sx={{alignSelf: "flex-end", marginBottom: "10px"}} onClick={closeEditor}/>
            <ReactQuill style={props.darkMode ? {
                backgroundColor: "#414042",
                color: "white",
                width: '100%',
                height: "300px"
            } : {width: '100%', height: "300px"}} ref={quillCommentEditObj} modules={quillEditModules}
                        value={commentContent} onChange={handleSetCommentContent}/>
            <Button sx={{marginRight: 2, marginTop: 2, alignSelf: "flex-end"}} onClick={editComment} autoFocus
                    color={"primary"}
                    variant={"contained"}>
                Post
            </Button>
        </div>
    )

    const editor = (
        <div className={"comment-editor"}>
            <Close sx={{alignSelf: "flex-end", marginBottom: "10px"}} onClick={handleClose}/>
            <ReactQuill style={props.darkMode ? {
                backgroundColor: "#414042",
                color: "white",
                width: '100%',
                height: "300px"
            } : {width: '100%', height: "300px"}} value={content} ref={quillCommentObj} modules={quillModules} onChange={handleSetContent}/>
            <Button sx={{marginRight: 2, marginTop: 2, alignSelf: "flex-end"}} onClick={createNewComment} autoFocus
                    color={"primary"}
                    variant={"contained"}>
                Post
            </Button>
        </div>
    )

    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={"comment-container"}>
            <div
                style={{backgroundColor: props.comment.isActive ? (props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main) : "lightgray"}}
                className={"comment-title-container"}>
                <div className={"comment-title-info-container"}>
                    <IconButton sx={{p: 0}}>
                        <Avatar alt="Remy Sharp"
                                src={`https://media.legacyhorsegame.com/profileimages/${props.comment.author.displayName}.png`}
                                sx={{height: {md: 70}, width: {md: 70}}}/>
                    </IconButton>
                    <div className={"comment-title-info-text-container"}>
                        <Typography>{moment(props.comment.timestamp).format('MMMM DD, YYYY, h:mm a')}</Typography>
                        <Link to={`/users/${props.comment.author.displayName}`}>
                            <Typography sx={{fontWeight: "bold"}}>{props.comment.author.displayName}</Typography>
                        </Link>
                        {props.comment.isEdited ? <Typography sx={{fontSize: "12px"}}>Edited</Typography> : ""}
                    </div>
                </div>
                <div className={"comment-title-btn-container"}>
                    <IconButton sx={iconStyle} onClick={handleOpen}>
                        <Reply sx={{color: "black"}}/>
                    </IconButton>
                    <IconButton sx={iconStyle} onClick={upVote}>
                        <ThumbUp sx={{color: props.comment.upVoted ? lightTheme.palette.primary.main : "black"}}/>
                    </IconButton>
                    <Typography>{props.comment.voteCount}</Typography>
                    <IconButton sx={iconStyle} onClick={downVote}>
                        <ThumbDown sx={{color: props.comment.downVoted ? lightTheme.palette.primary.main : "black"}}/>
                    </IconButton>
                    {props.user.id == props.comment.author.id && !props.comment.isLocked ? <IconButton onClick={openEditor}>
                        <EditNote sx={iconStyle}/>
                    </IconButton> : ""}
                </div>
            </div>
            <div className={"comment-content-container"}>
                {isEditorOpen ? commentEditor : props.comment.isActive ? <Typography dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(props.comment.content, {
                            USE_PROFILES: {html: true}
                        })
                    }}/> :
                    <Typography>Comment has been removed by an admin.</Typography>}
            </div>
            {props.user.admin ?
                <FormControl sx={checkBoxStyle}>
                    <FormControlLabel
                        control={<Checkbox size={"small"} checked={!props.comment.isActive} onChange={archiveComment}/>}
                        label={"Archive"}/>
                </FormControl> : ""}
            {open && !props.post.isLocked ? editor : ""}
            <div className={"comment-replies-container"}>
                {replies.map(reply =>
                    <Comment comment={reply} user={props.user} post={props.post} token={props.token} api={props.api}
                             config={props.config} formConfig={props.formConfig} handleSetUpdated={handleSetUpdated}
                             darkMode={props.darkMode}/>
                )}
            </div>
        </div>
    )
}

export default Comment