import "./HorseCard.css";

import React from "react";
import Photo from "../horse/photo/Photo";
import {Breeds} from "../../enums/Breeds";
import ColorCode from "../horse/photo/ColorCode";

const HorseCard = (props) => {

    const displaySire = () => {
        if(props.horse.sire != null){
            return props.horse.sire.regName;
        }
        return "Unknown";
    }

    const displayDam = () => {
        if(props.horse.dam != null){
            return props.horse.dam.regName;
        }
        return "Unknown";
    }
    
    const female = (<img className={"horse-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"} alt={"mare"}/>)
    const male = (<img className={"horse-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"} alt={"stallion"}/>)
    const gelding = (<img className={"horse-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    return (
        <div className={"horse-card-container"} >
            <div className={"horse-card-photo-container"}>
                {props.horse.gender === 0 ? female : props.horse.gelded ? gelding : male}
                {props.horse.colorCode > -1 ? <ColorCode color={props.horse.colorCode} size={"50px"}/> : ""}
                <Photo className={"horse-image"} id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api} setTextColor={null}/>
            </div>
            <div className={"horse-card-name-container"}>
                <p className={"horse-card-prefix"}>{props.horse.prefix}</p>
                <p className={"horse-card-name"}>{props.horse.regName}</p>
            </div>
            <p className={"horse-card-breed"}>{Breeds[props.horse.breed]}</p>
            <p className={"horse-card-parents"}>{displaySire()} x {displayDam()}</p>
        </div>
    )
}

export default HorseCard;