import "./PrivateSemenCard.css";

import React, {useState} from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {Breeds} from "../../../enums/Breeds";
import Photo from "../../horse/photo/Photo";

const PrivateSemenCard = (props) => {
    const denySale = (semen) => {
        const data = new FormData();

        data.append("buyerId", props.semen.ranch.ranchId);
        data.append("semen", props.semen.parentId);

        axios.post("api/Health/denyPrivateSemenSale", data, props.formConfig)
            .then(response => {
                console.log(response.data);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const acceptSale = (semen) => {
        const data = new FormData();

        data.append("privateSemen", props.semen.semenId);
        data.append("semen", props.semen.parentId);

        axios.post("api/Health/acceptPrivateSemen", data, props.formConfig)
            .then(response => {
                console.log(response.data);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }


    return (
        <>
            <div className={props.darkMode ? "private-semen-card-container private-semen-card-container-dark" : "private-semen-card-container"}>
                <Link to={`/horses/${props.semen.stud.horseId}`}>
                    <Photo id={props.semen.stud.horseId} api={props.api} token={props.token}/>
                </Link>
                <div className={"private-semen-info-container"}>
                    <div className={"private-semen-horse-info"}>
                        <div className={"private-semen-name-container"}>
                            <p className={"private-semen-prefix"}>{props.semen.stud.prefix}</p>
                            <p className={"private-semen-name"}>{props.semen.stud.regName}</p>
                        </div>
                        <p className={"private-semen-breed"}>{Breeds[props.semen.stud.breed]}</p>
                        <p className={"private-semen-price"}>{(props.semen.price * props.semen.totalAmount).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumSignificantDigits: Math.trunc(Math.abs(props.price)).toFixed().length
                        })}</p>
                    </div>
                    <div className={"private-semen-purchase-info"}>
                        <Button variant={"contained"} size={"small"} onClick={acceptSale}>Accept</Button>
                        <Button variant={"contained"} size={"small"} onClick={denySale}>Deny</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivateSemenCard;