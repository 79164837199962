import React, {useEffect, useState} from 'react'; 
import axios from "axios";
import Chart from 'chart.js/auto';

function Personality(props){
    const id = props.id;

    const [horse, setHorse] = useState(null);

    useEffect(() => {
        axios.get('/api/Horses/Personality', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorse(response.data);
            });
    }, [id]);

    useEffect(() => {
        if (horse != null) {
            const data = {
                labels: [
                    'Desire To Please',
                    'Energy',
                    'Playfulness',
                    'Responsiveness',
                    'Spookiness',
                    'Submissiveness'
                ],
                datasets: [{
                    data: [
                        horse.desireToPlease.finalValue,
                        horse.energy.finalValue,
                        horse.playfulness.finalValue,
                        horse.responsiveness.finalValue,
                        horse.spookiness.finalValue,
                        horse.submissiveness.finalValue
                    ]
                }]
            }

            const config = {
                type: 'radar',
                data: data,
                options: {
                    layout: {
                        padding: "10px"
                    },
                    elements: {
                        line: {
                            borderWidth: 1,
                            backgroundColor: "#DB920080",
                            borderColor: "#DB9200",
                            borderJoinStyle: "rounded",
                        },
                        point: {
                            pointStyle: false,
                        }
                    },
                    scales: {
                        r: {
                            min: 0,
                            max: 1,
                            ticks: {
                                display: false,
                                count: 10,
                                stepSize: .1,
                            },
                            grid: {
                                circular: true,
                                color: props.darkMode ? "#646464" : "#e3e3e3",
                            },
                            angleLines: {
                                color: props.darkMode ? "#646464" : "#e3e3e3",
                            },
                            pointLabels: {
                                color: props.darkMode ? "#fff" : "black"
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            }
            
            if(Chart.getChart(props.name)){
                Chart.getChart(props.name).destroy();
            }
            
            new Chart(document.getElementById(props.name), config);
        }
    }, [horse, props.darkMode]);

    return (
        <>
            <canvas id={props.name}></canvas>
        </>
    )
}

export default Personality