import "./GeneticTests.css";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";

const ColorTests = (props) => {

    const headerTextStyle = {
        fontFamily: "Roboto, san-serif",
        fontWeight: "bolder"
    }

    const testTextStyle = {
        fontFamily: "Roboto Light, san-serif",
        fontWeight: "bold"
    }

    const resultTextStyle = {
        fontFamily: "Roboto Light, san-serif"
    }
    
    const [isAllTested, setIsAllTested] = useState(false);
    
    useEffect(() => {
        setIsAllTested(props.horse.color.agoutiTested && props.horse.color.extensionTested &&
            props.horse.color.creamTested && props.horse.color.champagneTested &&
            props.horse.color.dunTested && props.horse.color.silverTested &&
            props.horse.color.grayTested)
    }, [props.horse, props.updated]);

    const [agouti, setAgouti] = useState(false);
    const handleSelectAgouti = () => {
        setAgouti(!agouti);
        agouti ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getAgouti = () => {
        if (props.horse.color.agoutiTested) {
            return (props.horse.color?.agA == 1 ? "A" : "a") + "/" + (props.horse.color?.agB == 1 ? "A" : "a");
        } else {
            return "?";
        }
    }

    const [extension, setExtension] = useState(false);
    const handleSetExtension = () => {
        setExtension(!extension);
        extension ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getExtension = () => {
        if (props.horse.color.extensionTested) {
            return (props.horse.color?.extA == 1 ? "E" : "e") + "/" + (props.horse.color?.extB == 1 ? "E" : "e");
        } else {
            return "?";
        }
    }

    const [cream, setCream] = useState(false);
    const handleSetCream = () => {
        setCream(!cream);
        cream ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getCream = () => {
        if (props.horse.color.creamTested) {
            return (props.horse.color?.crmA == 2 ? "Cr" : props.horse.color?.crmA == 1 ? "Prl" : "n") + "/" + (props.horse.color?.crmB == 2 ? "Cr" : props.horse.color?.crmB == 1 ? "Prl" : "n");
        } else {
            return "?";
        }
    }

    const [champagne, setChampagne] = useState(false);
    const handleSetChampagne = () => {
        setChampagne(!champagne);
        champagne ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getChampagne = () => {
        if (props.horse.color.champagneTested) {
            return (props.horse.color?.chpnA == 1 ? "Ch" : "n") + "/" + (props.horse.color?.chpnB == 1 ? "Ch" : "n");
        } else {
            return "?";
        }
    }

    const [dun, setDun] = useState(false);
    const handleSetDun = () => {
        setDun(!dun);
        dun ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getDun = () => {
        if (props.horse.color.dunTested) {
            return (props.horse.color?.dunA == 1 ? "D" : "n") + "/" + (props.horse.color?.dunB == 1 ? "D" : "n");
        } else {
            return "?";
        }
    }

    const [silver, setSilver] = useState(false);
    const handleSetSilver = () => {
        setSilver(!silver);
        silver ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getSilver = () => {
        if (props.horse.color.silverTested) {
            return (props.horse.color?.slvrA == 1 ? "Z" : "n") + "/" + (props.horse.color?.slvrB == 1 ? "Z" : "n");
        } else {
            return "?";
        }
    }

    const [gray, setGray] = useState(false);
    const handleSetGray = () => {
        setGray(!gray);
        gray ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getGray = () => {
        if (props.horse.color.grayTested) {
            return (props.horse.color?.gryA == 1 ? "G" : "n") + "/" + (props.horse.color?.gryB == 1 ? "G" : "n");
        } else {
            return "?";
        }
    }

    const handleSelectAll = () => {
        setAgouti(!agouti);
        setExtension(!extension);
        setCream(!cream);
        setChampagne(!champagne);
        setDun(!dun);
        setSilver(!silver);
        setGray(!gray);

        let amount = 0;
        agouti ? amount -= 10 : amount += 10;
        extension ? amount -= 10 : amount += 10;
        cream ? amount -= 10 : amount += 10;
        champagne ? amount -= 10 : amount += 10;
        dun ? amount -= 10 : amount += 10;
        silver ? amount -= 10 : amount += 10;
        gray ? amount -= 10 : amount += 10;

        setTotal(total + amount);
    }

    const test = (gene, result, checked, handle, disabled) => {
        return (
            <TableRow>
                {!isAllTested && <TableCell>
                    <Checkbox size={"small"} checked={checked} onChange={handle} disabled={disabled || !props.isOwner}/>
                </TableCell>}
                <TableCell sx={testTextStyle}>
                    {gene}
                </TableCell>
                <TableCell sx={resultTextStyle} align={"center"}>
                    {result}
                </TableCell>
            </TableRow>
        )
    }

    const handleSubmitTest = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append("id", props.horse.horseId);
        data.append("agouti", agouti);
        data.append("extension", extension);
        data.append("cream", cream);
        data.append("champagne", champagne);
        data.append("dun", dun);
        data.append("silver", silver);
        data.append("gray", gray);
        data.append("total", total);

        await axios.post('/api/Horses/colorTest', data, props.formConfig)
            .then(function (response) {
                console.log(response.data);
                props.handleUpdated();
            }).catch(function (error) {
                console.log(error);
            });
    }

    const [total, setTotal] = useState(0);

    return (
        <div className={props.darkMode ? "genetic-test-container-dark" : "genetic-test-container"}>
            <div className={"genetic-test-title"}>
                <Typography variant={"h3"}>Colors</Typography>
            </div>
            <div className={"genetic-test-data"}>
                {props.isOwner && !isAllTested ? <div className={"genetic-test-interface"}>
                    <Typography sx={{fontFamily: "Roboto Light, sans-serif", fontWeight: "bolder", padding: "5px"}}>Total:
                        ${total}</Typography>
                    <Button sx={{minWidth: "20px"}} variant={"contained"} onClick={handleSubmitTest}>Test</Button>
                </div> : ""}
                <Table sx={{width: "95%"}} size={isAllTested ? "" : "small"}>
                    <TableHead>
                        <TableRow>
                            {!isAllTested && <TableCell>
                                <Checkbox
                                    size={"small"}
                                    onChange={handleSelectAll}
                                    inputProps={{
                                        'aria-label': 'select all tests',
                                    }}
                                    disabled={props.horse.color.agoutiTested || props.horse.color.extensionTested ||
                                    props.horse.color.creamTested || props.horse.color.champagneTested ||
                                    props.horse.color.dunTested || props.horse.color.silverTested ||
                                    props.horse.color.grayTested || !props.isOwner ? true : false}
                                />
                            </TableCell>}
                            <TableCell sx={headerTextStyle}>
                                Gene
                            </TableCell>
                            <TableCell sx={headerTextStyle} align={"center"}>
                                Result
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {test("Agouti", getAgouti(), agouti, handleSelectAgouti, props.horse.color.agoutiTested)}
                        {test("Extension", getExtension(), extension, handleSetExtension, props.horse.color.extensionTested)}
                        {test("Cream", getCream(), cream, handleSetCream, props.horse.color.creamTested)}
                        {test("Dun", getDun(), dun, handleSetDun, props.horse.color.dunTested)}
                        {test("Silver", getSilver(), silver, handleSetSilver, props.horse.color.silverTested)}
                        {test("Champagne", getChampagne(), champagne, handleSetChampagne, props.horse.color.champagneTested)}
                        {test("Gray", getGray(), gray, handleSetGray, props.horse.color.grayTested)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default ColorTests