import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <div>
            <List>
                <ListItem>
                    <ListItemText primary={"1. One account per player:"}
                                  secondary={"Players are allowed to have only one account. Multiple accounts may result in penalties, including temporary or permanent bans."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"2. Respectful language:"}
                                  secondary={"Profanity, offensive language, and inappropriate content in usernames, messages, or any other form of communication are not allowed."}
                                      />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"3. Fair play:"}
                                  secondary={"Cheating, exploiting bugs, or using any third-party software to gain an unfair advantage is strictly prohibited."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"4. Security"}
                                  secondary={"We take reasonable measures to protect your personal information from unauthorized access, " +
                                      "disclosure, and misuse. However, no security system is perfect, and we cannot guarantee the security of your information."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"5. Account security:"}
                                  secondary={"Players are responsible for maintaining the security of their accounts. Sharing account details or attempting to access another player's account is against the rules."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"6. Harassment and bullying:"}
                                  secondary={"Harassment, bullying, or any form of disruptive behavior towards other players is not tolerated."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"7. Inappropriate content:"}
                                  secondary={"Creating or sharing content that is sexually explicit, violent, or otherwise inappropriate is not allowed."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Account trading/selling:"}
                                  secondary={"Trading or selling game accounts, items, or in-game currency for real-world money is prohibited."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Impersonating:"}
                                  secondary={"Impersonating a staff member, developer, or another player is not allowed."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Reporting system:"}
                                  secondary={"Players should use the provided reporting system to report rule violations. False reports may result in penalties."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Constructive feedback:"}
                                  secondary={"Players are encouraged to provide feedback and suggestions in a constructive manner. Disruptive criticism or constant negativity may result in moderation."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Ranch resets:"}
                                  secondary={"Players are not allowed to sell their horses to another ranch or account and then immediately buy back the horses after resetting the ranch."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Transferring funds between ranches:"}
                                  secondary={"In-game cash is not allowed to be transferred between ranches owned by the same player account."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Transferring horses between ranches:"}
                                  secondary={"Horses are allowed to be transferred between ranches owned by the same player, but not for any monetary gain."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Rule updates:"}
                                  secondary={"We reserve the right to update or modify the rules as necessary. Players should regularly check for any rule changes."}
                    />
                </ListItem>
            </List>
        </div>
    )
}

export default PrivacyPolicy