import "./Profile.css";

import React from "react";
import {Typography} from "@mui/material";

const Profile = (props) => {
    
    
    return (
        <div className={"ranch-profile-container"}>
            <Typography>{props.ranch.bio ? props.ranch.bio : "Update your ranch bio in account management."}</Typography>
        </div>
    )
}

export default Profile