export const ColorCodes = [
    "MintCream",
    "Black",
    "DarkOrange",
    "DarkBlue",
    "LightBlue",
    "DarkGreen",
    "Yellow",
    "Maroon",
    "Red",
    "Indigo",
    "YellowGreen",
    "DeepPink",
    "LightCoral",
    "Gold",
    "DarkCyan",
    "BlueViolet",
    "Aqua",
    "Grey",
    "PaleGreen",
    "LightPink"
]