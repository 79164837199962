import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import DOMPurify from "dompurify";
import axios from "axios";

const Popup = (props) => {
    
    const [isOpen, setIsOpen] = useState(!props.announcement.isRead);
    
    const handleOpen = () => {
        setIsOpen(true);
    }
    
    const handleClose = () => {
        setIsOpen(false);
    }
    
    const handleConfirmRead = () => {
        const data = new FormData();
        data.append("user", props.email);
        data.append("announcement", props.announcement.announcementId);
        
        axios.post("api/Announcements/confirm", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleClose();
            }).catch(error => {
                console.log(error);
        })
    }
    
    return (
        <Dialog open={isOpen}>
            <DialogTitle>{props.announcement.title}</DialogTitle>
            <DialogContent>
                <div className={"post-content"} dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.announcement.content, {
                        USE_PROFILES: {html: true}
                    })
                }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleConfirmRead}>Mark Read</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Popup