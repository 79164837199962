import "./User.css";

import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";

const User = (props) => {

    const [source, setSource] = useState(`https://media.legacyhorsegame.com/profileimages/${props.user.displayName}.png`);

    const handleMissingImage = () => {
        setSource("https://media.legacyhorsegame.com/misc/defaultUserImage.png")
    }

    useEffect(() => {
        setSource(`https://media.legacyhorsegame.com/profileimages/${props.user.displayName}.png`)
    }, [props.user])

    return(
        <div className={"user-search-container"}>
            <div className={"user-search-title-container"}>
                <Typography sx={{fontSize: "18px"}} variant={"h3"}>{props.user.displayName}</Typography>
            </div>
            <div className={"user-search-background"}>
                <div className={"user-search-image-cropper"}>
                    <img className={"user-search-image"}
                         src={source}
                         alt={props.user.displayName}
                         onError={handleMissingImage}
                    />
                </div>
            </div>
        </div>
    )
}

export default User;