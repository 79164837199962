import "./GeneticTests.css";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";

const PatternTests = (props) => {

    const headerTextStyle = {
        fontFamily: "Roboto, san-serif",
        fontWeight: "bolder"
    }

    const testTextStyle = {
        fontFamily: "Roboto Light, san-serif",
        fontWeight: "bold"
    }

    const resultTextStyle = {
        fontFamily: "Roboto Light, san-serif"
    }

    const [isAllTested, setIsAllTested] = useState(false);

    useEffect(() => {
        setIsAllTested(props.horse.leopard.leopardTested && props.horse.leopard.patn1Tested &&
            props.horse.patterns.kitTested && props.horse.roaning.roaningTested &&
            props.horse.patterns.splashedTested && props.horse.patterns.tobianoTested &&
            props.horse.patterns.frameTested)
    }, [props.horse, props.updated]);

    const [lp, setLp] = useState(false);
    const handleSelectLp = () => {
        setLp(!lp);
        lp ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getLp = () => {
        if (props.horse.leopard.leopardTested) {
            return (props.horse.leopard?.lpA == 1 ? "Lp" : "n") + "/" + (props.horse.leopard?.lpB == 1 ? "Lp" : "n");
        } else {
            return "?";
        }
    }

    const [patn, setPatn] = useState(false);
    const handleSetPatn = () => {
        setPatn(!patn);
        patn ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getPatn = () => {
        if (props.horse.leopard.patn1Tested) {
            return (props.horse.leopard?.patn1A == 1 ? "PATN1" : "n") + "/" + (props.horse.leopard?.patn1B == 1 ? "PATN1" : "n");
        } else {
            return "?";
        }
    }

    const [kit, setKit] = useState(false);
    const handleSetKit = () => {
        setKit(!kit);
        kit ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getKit = () => {
        let allele1;
        let allele2;
        if (props.horse.patterns.kitTested) {
            switch (props.horse.patterns.ktA) {
                case 1:
                    allele1 = "W1";
                    break;
                case 2:
                    allele1 = "SAB1";
                    break;
                case 10:
                    allele1 = "W10";
                    break;
                case 14:
                    allele1 = "W14";
                    break;
                case 20:
                    allele1 = "W20";
                    break;
                default:
                    allele1 = "n";
            }
            switch (props.horse.patterns.ktB) {
                case 1:
                    allele2 = "W1";
                    break;
                case 2:
                    allele2 = "SAB1";
                    break;
                case 10:
                    allele2 = "W10";
                    break;
                case 14:
                    allele2 = "W14";
                    break;
                case 20:
                    allele2 = "W20";
                    break;
                default:
                    allele2 = "n";
            }
            return allele1 + "/" + allele2;
        }
        return "?";
    }

    const [roan, setRoan] = useState(false);
    const handleSetRoan = () => {
        setRoan(!roan);
        roan ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getRoan = () => {
        if (props.horse.roaning.roaningTested) {
            return (props.horse.roaning?.rnA == 1 ? "Rn" : "n") + "/" + (props.horse.roaning?.rnB == 1 ? "Rn" : "n");
        } else {
            return "?";
        }
    }

    const [splashed, setSplashed] = useState(false);
    const handleSetSplashed = () => {
        setSplashed(!splashed);
        splashed ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getSplashed = () => {
        if (props.horse.patterns.splashedTested) {
            return (props.horse.patterns?.splA == 1 ? "SW1" : "n") + "/" + (props.horse.patterns?.splB == 1 ? "SW1" : "n");
        } else {
            return "?";
        }
    }

    const [tobiano, setTobiano] = useState(false);
    const handleSetTobiano = () => {
        setTobiano(!tobiano);
        tobiano ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getTobiano = () => {
        if (props.horse.patterns.tobianoTested) {
            return (props.horse.patterns?.tbA == 1 ? "TO" : "n") + "/" + (props.horse.patterns?.tbB == 1 ? "TO" : "n");
        } else {
            return "?";
        }
    }

    const [frame, setFrame] = useState(false);
    const handleSetFrame = () => {
        setFrame(!frame);
        frame ? setTotal(total - 10) : setTotal(total + 10);
    }

    const getFrame = () => {
        if (props.horse.patterns.frameTested) {
            return (props.horse.patterns?.frmA == 1 ? "O" : "n") + "/" + (props.horse.patterns?.frmB == 1 ? "O" : "n");
        } else {
            return "?";
        }
    }

    const handleSelectAll = () => {
        setLp(!lp);
        setPatn(!patn);
        setKit(!kit);
        setRoan(!roan);
        setSplashed(!splashed);
        setTobiano(!tobiano);
        setFrame(!frame);

        let amount = 0;
        lp ? amount -= 10 : amount += 10;
        patn ? amount -= 10 : amount += 10;
        kit ? amount -= 10 : amount += 10;
        roan ? amount -= 10 : amount += 10;
        splashed ? amount -= 10 : amount += 10;
        tobiano ? amount -= 10 : amount += 10;
        frame ? amount -= 10 : amount += 10;

        setTotal(total + amount);
    }

    const test = (gene, result, checked, handle, disabled) => {
        return (
            <TableRow>
                {!isAllTested && <TableCell>
                    <Checkbox size={"small"} checked={checked} onChange={handle} disabled={disabled || !props.isOwner}/>
                </TableCell>}
                <TableCell sx={testTextStyle}>
                    {gene}
                </TableCell>
                <TableCell sx={resultTextStyle}>
                    {result}
                </TableCell>
            </TableRow>
        )
    }

    const handleSubmitTest = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append("id", props.horse.horseId);
        data.append("lp", lp);
        data.append("patn", patn);
        data.append("kit", kit);
        data.append("roan", roan);
        data.append("splashed", splashed);
        data.append("tobiano", tobiano);
        data.append("frame", frame);
        data.append("total", total)

        await axios.post('/api/Horses/patternTest', data, props.formConfig)
            .then(function (response) {
                console.log(response);
                props.handleUpdated();
            }).catch(function (error) {
                console.log(error);
            });
    }

    const [total, setTotal] = useState(0);

    return (
        <div className={props.darkMode ? "genetic-test-container-dark" : "genetic-test-container"}>
            <div className={"genetic-test-title"}>
                <Typography variant={"h3"}>Patterns</Typography>
            </div>
            <div className={"genetic-test-data"}>
                {!isAllTested && <div className={"genetic-test-interface"}>
                    <Typography sx={{fontFamily: "Roboto Light, sans-serif", fontWeight: "bolder", padding: "5px"}}>Total:
                        ${total}</Typography>
                    <Button sx={{minWidth: "20px"}} variant={"contained"} onClick={handleSubmitTest}>Test</Button>
                </div>}
                <Table sx={{width: "95%"}} size={isAllTested ? "" : "small"}>
                    <TableHead>
                        <TableRow>
                            {!isAllTested && <TableCell>
                                <Checkbox
                                    size={"small"}
                                    onChange={handleSelectAll}
                                    inputProps={{
                                        'aria-label': 'select all tests',
                                    }}
                                    disabled={props.horse.leopard.leopardTested || props.horse.leopard.patn1Tested ||
                                    props.horse.patterns.kitTested || props.horse.roaning.roaningTested ||
                                    props.horse.patterns.splashedTested || props.horse.patterns.tobianoTested ||
                                    props.horse.patterns.frameTested || !props.isOwner ? true : false}
                                />
                            </TableCell>}
                            <TableCell sx={headerTextStyle}>
                                Gene
                            </TableCell>
                            <TableCell sx={headerTextStyle}>
                                Result
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.horse && test("Leopard", getLp(), lp, handleSelectLp, props.horse.leopard.leopardTested)}
                        {props.horse && test("PATN1", getPatn(), patn, handleSetPatn, props.horse.leopard.patn1Tested)}
                        {props.horse && test("KIT", getKit(), kit, handleSetKit, props.horse.patterns.kitTested)}
                        {props.horse && test("Roan", getRoan(), roan, handleSetRoan, props.horse.roaning.roaningTested)}
                        {props.horse && test("Splashed", getSplashed(), splashed, handleSetSplashed, props.horse.patterns.splashedTested)}
                        {props.horse && test("Tobiano", getTobiano(), tobiano, handleSetTobiano, props.horse.patterns.tobianoTested)}
                        {props.horse && test("Frame Overo", getFrame(), frame, handleSetFrame, props.horse.patterns.frameTested)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default PatternTests