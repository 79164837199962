import './Store.css';

import React, {useEffect, useState} from "react";
import axios from "axios";
import Product from "./Product";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Subscription from "./Subscription";
import GiftSubscription from "./GiftSubscription";
import {darkTheme, lightTheme} from "../../../Theme";

const Store = (props) => {
    
    const [products, setProducts] = useState([]);
    
    useEffect(() => {
        axios.get("api/Stripe/products", props.config)
            .then(response => {
                setProducts(response.data);
            }).catch(error => {
                console.log(error);
        })
    }, []);

    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        axios.get("api/Stripe/subscriptions", props.config)
            .then(response => {
                setSubscriptions(response.data);
            }).catch(error => {
                console.log(error);
        })
    }, []);

    const [giftSubscriptions, setGiftSubscriptions] = useState([]);

    useEffect(() => {
        axios.get("api/Stripe/giftSubscriptions", props.config)
            .then(response => {
                setGiftSubscriptions(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, []);
    
    const handleCheckOut = () => {
        axios.post('/api/Stripe/checkoutSession', cart, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
                "email": props.email
            }
        }).then(response => {
                window.location.replace(response.data);
            }).catch(error => {
                console.log(error);
        })
    }
    
    const [cart, setCart] = useState([]);
    
    const handleAddToCart = (item) => {
        setCart([...cart, item]);
    }

    function insertDecimal(num) {
        return (Number((num/100).toFixed(2))).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
    
    const warningTextStyle = {
        color: "darkred",
        fontSize: "28px",
        fontWeight: "800",
        padding: "20px 30px"
    }
    
    return (
        <div>
            <div className={"store-container"}>
                <Typography sx={warningTextStyle}>Warning! Anything purchased prior to the end of alpha wipe will not be reimbursed post wipe. Purchases at this point are meant to support the development of the game only.</Typography>
                <div className={"products-container"}>
                    {subscriptions && subscriptions.map(product =>
                        <Subscription key={product.id} ranches={props.ranches} product={product} token={props.token} api={props.api} email={props.email}/>
                    )}
                </div>
                <div className={"products-container"}>
                    {products && products.map(product =>
                        <Product key={product.id} id={product.id} product={product} token={props.token} api={props.api} handleAddToCart={handleAddToCart} />
                    )}
                </div>
                <div className={"products-container"}>
                    {giftSubscriptions && giftSubscriptions.map(product =>
                        <GiftSubscription key={product.id} product={product} users={props.users} token={props.token} api={props.api} email={props.email}/>
                    )}
                </div>
                <Typography sx={warningTextStyle}>Warning! Anything purchased prior to the end of alpha wipe will not be reimbursed post wipe. Purchases at this point are meant to support the development of the game only.</Typography>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                    className={props.darkMode ? "cart-table-container cart-table-container-dark" : "cart-table-container"}>
                    <div
                        style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                        className={"cart-table-header-container"}>
                        <Typography variant={"h3"}>
                            Cart
                        </Typography>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow sx={{fontWeight: "bolder"}}>
                                <TableCell>Product</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cart.map(product =>
                                <TableRow>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.amount}</TableCell>
                                    <TableCell>{insertDecimal(product.price)}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell sx={{fontWeight: "bold"}}>Total</TableCell>
                                <TableCell>{insertDecimal(cart.map((product) => product.price).reduce((sum, i) => sum + i, 0))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <Button onClick={handleCheckOut} variant={"contained"}
                        sx={{margin: 2, alignSelf: "end"}}>Checkout</Button>
            </div>
        </div>
    )
}

export default Store;