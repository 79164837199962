import "./Shows.css";

import React, {useEffect, useState} from "react";
import ShowList from "./past/ShowList";
import DividerTitle from "../../DividerTitle";
import axios from "axios";
import {Discipline} from "../../../enums/Discipline";
import {Months} from "../../../enums/Months";
import UpcomingShows from "./upcoming/UpcomingShows";
import Trophy from "./awards/Trophy";
import DisciplineRibbons from "./awards/DisciplineRibbons";

const Shows = (props) => {

    const [awards, setAwards] = useState([]);

    useEffect(() => {
        axios.get('/api/Shows/Awards', {
            params:  {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setAwards(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [props.id]);
    
    const [horsePlacements, setHorsePlacements] = useState([]);

    useEffect(() => {
        axios.get('/api/Shows/placements', {
            params:  {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorsePlacements(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [props.id])

    const type = [
        "futurity",
        "breeders",
        "veteran",
        "world",
        "grandprix",
        "oldschool",
        "grandslam",
        "puissance",
        "longestrace",
        "derby",
        "stampede",
        "hollywood",
        "gentlegiants"
    ]

    const placements = [
        "first",
        "second",
        "third"
    ]

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    return (
        <div>
            <UpcomingShows id={props.id} isOwner={props.isOwner} ranchId={props.ranchId} formConfig={props.formConfig} api={props.api} darkMode={props.darkMode}/>
            {awards && awards.length > 0 ? <>
                <DividerTitle title={"Trophies"} darkMode={props.darkMode}/>
                <div className={"awards-container"}>
                    {awards.map(award =>
                        <Trophy key={award.awardId} type={type[award.type]}
                                date={formatDate(award.date)}
                                placement={placements[award.placement]} discipline={Discipline[award.discipline]}/>
                    )}
                </div>
            </> : ""}
            <DividerTitle title={"Discipline Information"} darkMode={props.darkMode}/>
            <div className={"awards-container"}>
                {horsePlacements.sort(p => Discipline[p.discipline]).map(p => 
                    <DisciplineRibbons id={props.id}
                                       token={props.token} 
                                       api={props.api} 
                                       title={Discipline[p.discipline]} 
                                       firsts={p.firsts}
                                       seconds={p.seconds} 
                                       thirds={p.thirds}
                                       points={p.points}
                                       earnings={p.earnings}
                                       titles={p.titles}
                                       darkMode={props.darkMode}
                    />
                )}
            </div>   
            <DividerTitle title={"Past Shows"} darkMode={props.darkMode} />
            <ShowList darkMode={props.darkMode} id={props.id} api={props.api} config={props.config}/>
        </div>
    )
}

export default Shows