import "./NewPublicNote.css"

import React, {useState} from "react";
import axios from "axios";
import {FormControl, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";

const NewPrivateNote = (props) => {

    const [title, setTitle] = useState("");

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const [content, setContent] = useState("");

    const handleSetContent = (content, delta, source, editor) => {
        setContent(DOMPurify.sanitize(content, {
            USE_PROFILES: {html: true}
        }))
    }

    const handleNewNote = () => {
        const data = new FormData();
        data.append("horseId", props.id);
        data.append("email", props.email);
        data.append("title", title);
        data.append("content", content);
        data.append("isPublic", true);

        axios.post('api/Application/newNote', data, props.formConfig)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    return (
        <div className={"new-public-note-container"}>
            <FormControl sx={{width: "100%", paddingBottom: "20px"}} >
                <TextField label={"Title"} variant={"outlined"} size={"small"} onChange={handleSetTitle}/>
            </FormControl>
            <ReactQuill style={props.darkMode ? {backgroundColor: "#414042", color: "white", width: '100%', height: "300px"} : {width: '100%', height: "300px"}} value={content} onChange={handleSetContent}/>
            <Button sx={{alignSelf: "flex-end", marginTop: "15px"}} variant={"contained"} onClick={handleNewNote}>Save</Button>
        </div>
    )
}

export default NewPrivateNote;