import "./SemenStorage.css";

import {React, useEffect, useState} from "react";
import axios from "axios";
import Canister from "./Canister";
import DividerTitle from "../../DividerTitle";
import PrivateSemenCard from "./PrivateSemenCard";
import {darkTheme, lightTheme} from "../../../Theme";

const Studs = (props) => {
    const [updated, setUpdated] = useState(0);
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }

    const [semens, setSemens] = useState([]);

    useEffect(() => {
        axios.get("/api/Health/semens", {
            params: {
                ranchId: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setSemens(response.data);
            }).catch(error => {
            console.log(error)
        })
    }, [updated]);

    const [broodmares, setBroodmares] = useState([]);

    useEffect(() => {
        axios.get("/api/Horses/getMares", {
            params: {
                id: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setBroodmares(response.data);
            }).catch(error => {
            console.log(error)
        })
    }, [updated]);

    const [ranches, setRanches] = useState([]);

    function filterRanches(ranches) {
        return ranches.filter(r => r.name != "Golden Acres").filter(r => r.name != "Rainbow Bridge");
    }

    useEffect(() => {
        axios.get('/api/Ranches', props.config)
            .then(response => {
                setRanches(filterRanches(response.data));
            }).catch(error => {
            console.log(error);
        })
    }, [])

    const [privateSemens, setPrivateSemens] = useState([]);

    useEffect(() => {
        axios.get("/api/Health/getPrivateSaleSemen", {
            params: {
                id: props.ranchId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            },
            maxRedirects: 0
        })
            .then(response => {
                setPrivateSemens(response.data);
            }).catch( error => {
            console.log(error)
        })
    }, [updated]);

    return (
        <div>
            <div style={{padding: "50px"}}>
                {semens && semens.map(semen =>
                    <Canister semen={semen}
                              email={props.email}
                              mares={broodmares}
                              ranches={ranches}
                              api={props.api}
                              token={props.token}
                              config={props.config}
                              formConfig={props.formConfig}
                              updated={updated}
                              handleSetUpdated={handleSetUpdated}
                              darkMode={props.darkMode}/>
                )}
            </div>
            <DividerTitle title={"Private Semen"} darkMode={props.darkMode} />
            <div style={{backgroundColor: props.dark ? 
                    darkTheme.palette.containerBackground.main : 
                    lightTheme.palette.containerBackground.main}} 
                 className={"semen-storage-private-sales-container"}>
                {privateSemens && privateSemens.map(semen =>
                    <PrivateSemenCard semen={semen}
                                      api={props.api}
                                      token={props.token}
                                      formConfig={props.formConfig}
                                      handleSetUpdated={handleSetUpdated}
                                      darkMode={props.darkMode}/>
                )}
            </div>
        </div>
    )
}

export default Studs;