import "./Messages.css";

import React from "react";
import {useState} from "react";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Inbox from "./Inbox";
import Outbox from "./Outbox";
import Trash from "./Trash";

const Messages = (props) => {

    const [selectedTab, setSelectedTab] = useState("inbox");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
    }
    
    return (
        <div>
            <img className={"mail-messages-banner"} src={"https://media.legacyhorsegame.com/banners/mail-banner.jpg"}
                 alt={"Mailboxes"}/>
            <TabContext value={selectedTab} sx={{position: "relative"}}>
                <Box width={"100%"}>
                    <TabList variant={"scrollable"} className={"horses-tabs"} onChange={handleChangeTab}
                             textColor={"white"}>
                        <Tab label={"Inbox"} value={"inbox"}/>
                        <Tab label={"Sent"} value={"outbox"}/>
                        <Tab label={"Trash"} value={"trash"}/>
                    </TabList>
                </Box>
                <TabPanel sx={{width: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center"}} value={"inbox"}>
                    <Inbox id={props.id} api={props.api} token={props.token} config={props.config} darkMode={props.darkMode} handleSetMessageIds={props.handleSetMessageIds}/>
                </TabPanel>
                <TabPanel sx={{width: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center"}} value={"outbox"}>
                    <Outbox id={props.id} api={props.api} token={props.token} config={props.config} darkMode={props.darkMode} handleSetMessageIds={props.handleSetMessageIds}/>
                </TabPanel>
                <TabPanel sx={{width: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center"}} value={"trash"}>
                    <Trash id={props.id} api={props.api} token={props.token} config={props.config} darkMode={props.darkMode} handleSetMessageIds={props.handleSetMessageIds}/>
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default Messages;