import "./CreditsList.css";

import React from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Divider, Typography} from "@mui/material";

const CreditsList = (props) => {
    
    const titleStyle = {
        fontWeight: "bold",
        marginRight: 1
    }
    
    const textStyle = {
        marginLeft: 1
    }
    
    const dividerStyle = {
        flexGrow: 1
    }
    
    return (
        <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "credits-list-container credits-list-container-dark" : "credits-list-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"credits-list-header-container"}>
                <Typography variant={"h3"}>
                    Credits
                </Typography>
            </div>
            <div className={"credits-list-credits-container"}>
                <Typography sx={titleStyle}>Import</Typography>
                <Divider sx={dividerStyle}/>
                <Typography sx={textStyle}>{props.user.importCredits}</Typography>
            </div>
            <div className={"credits-list-credits-container"}>
                <Typography sx={titleStyle}>Custom</Typography>
                <Divider sx={dividerStyle}/>
                <Typography sx={textStyle}>{props.user.customImportCredits}</Typography>
            </div>
            <div className={"credits-list-credits-container"}>
                <Typography sx={titleStyle}>Background</Typography>
                <Divider sx={dividerStyle}/>
                <Typography sx={textStyle}>{props.user.backgroundCredits}</Typography>
            </div>
            <div className={"credits-list-credits-container"}>
                <Typography sx={titleStyle}>Tack</Typography>
                <Divider sx={dividerStyle}/>
                <Typography sx={textStyle}>{props.user.tackCredits}</Typography>
            </div>
        </div>
    )
}

export default CreditsList