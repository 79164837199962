import "./CurrentLevel.css";

const CurrentLevel = (props) => {
    
    
    return (
        <div style={props.darkMode ? {backgroundColor: '#58595b'} : {}} className={"current-level-container"}>
            <p className={"current-level-discipline"}>{props.discipline}</p>
            <p className={"current-level-level"}>{props.level}</p>
        </div>
    )
}

export default CurrentLevel