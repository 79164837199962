import React, {useEffect, useRef, useState} from 'react';

import "./Stat.css";
import {darkTheme, lightTheme} from "../../../Theme";

const Stat = (props) => {
    
    const statBar = useRef(null);
    
    const [stat, setStat] = useState(props.stat);

    useEffect(() => {
        setStat(props.stat < (statBar.current.offsetWidth - 10) / statBar.current.offsetWidth ? props.stat + '%' : `calc(${props.stat}% - 10px)`)
    }, [statBar.current, props])
    
    const [statHovered, setStatHovered] = useState(false);

    const handleMouseEnterStat = () => {
        setStatHovered(true);
    };

    const handleMouseLeaveStat = () => {
        setStatHovered(false);
    };

    return(
        <div className={"stat"} onMouseEnter={handleMouseEnterStat} onMouseLeave={handleMouseLeaveStat}>
            <div className={"stat-title"}>{props.title}</div>
            <div style={props.darkMode ? {backgroundColor: darkTheme.palette.primary.main} : {backgroundColor: lightTheme.palette.primary.main}} className={"stat-bar"} ref={statBar}>
                {statHovered && <div className={"stat-number-display"} style={props.stat > 96 ? {float: "left"} : {}}>
                    <p>{props.stat}</p>
                </div>}
                <div className={"stat-indicator"} style={{left: stat}} />
            </div>
            <div className={"stat-labels"}>
                <div className={"stat-label-low"}>{props.low}</div>
                <div className={"stat-label-mid-left"}>{props.midLeft}</div>
                <div className={"stat-label-mid"}>{props.mid}</div>
                <div className={"stat-label-mid-right"}>{props.midRight}</div>
                <div className={"stat-label-high"}>{props.high}</div>
            </div>
        </div>
    )

}

export default Stat;