import "./CustomizeUser.css";

import React, {useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {TextField, Typography} from "@mui/material";
import UploadImage from "./UploadImage";
import Button from "@mui/material/Button";
import axios from "axios";

const CustomizeUser = (props) => {

    const [bio, setBio] = useState("");

    const handleSetBio = (event) => {
        setBio(event.target.value);
    }

    const handleUpdateBio = () => {
        const data = new FormData;
        data.append("email", props.user.email);
        data.append("bio", bio);

        axios.post('/api/Account/changeBio', data, props.formConfig)
            .then(response => {
                console.log(response);
            }).catch(error => {
            console.log(error);
        });
    }
    
    const sectionTitleStyle = {
        fontWeight: "bold",
        fontSize: "16px",
        marginBottom: "10px"
    }
    
    const btnStyle = {
        alignSelf: "flex-end"
    }
    
    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "user-customization-container user-customization-container-dark" : "user-customization-container"}>
            <div className={"user-customization-header-container"}>
                <Typography variant={"h3"}>User Profile</Typography>
            </div>
            <div className={"user-customization-sub-container"}>
                <Typography sx={sectionTitleStyle}>
                    Change Profile Image
                </Typography>
                <UploadImage fileName={props.user.displayName} config={props.config} bucket={"profileimages"}
                             formConfig={props.formConfig}/>
            </div>
            <div className={"user-customization-sub-container"}>
                <Typography sx={sectionTitleStyle}>
                    Change Bio
                </Typography>
                <TextField sx={{width: "100%", marginBottom: "10px"}} rows={10}  multiline onChange={handleSetBio} defaultValue={props.user.bio} />
                <Button sx={btnStyle} variant={"contained"} size={"small"} onClick={handleUpdateBio}>Submit</Button>
            </div>
        </div>
    )
}

export default CustomizeUser