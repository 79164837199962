import "./ComparisonTrait.css";

import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";

const ComparisonTrait = (props) => {
    const [maxStatHovered, setMaxStatHovered] = useState(false);

    const handleMouseEnterMaxStat = () => {
        setMaxStatHovered(true);
    };

    const handleMouseLeaveMaxStat = () => {
        setMaxStatHovered(false);
    };
    
    const handleClickMaxStat = () => {
        setMaxStatHovered(true);
    }
    
    const handleCloseMaxStat = () => {
        setMaxStatHovered(false);
    }
    
    const [currentStatHovered, setCurrentStatHovered] = useState(false);

    const handleMouseEnterCurrentStat = () => {
        setCurrentStatHovered(true);
    };

    const handleMouseLeaveCurrentStat = () => {
        setCurrentStatHovered(false);
    };

    return (
        <div className={props.darkMode ? "comparison-trait-bar comparison-trait-bar-dark" : "comparison-trait-bar"}  onMouseEnter={handleMouseEnterMaxStat} onMouseLeave={handleMouseLeaveMaxStat} >
            <div className={"comparison-trait-title"}>
                <Typography sx={{
                    fontFamily: "Raleway, sans-serif",
                    fontWeight: "bold",
                    fontSize: "14px",
                    paddingLeft: "10px",
                }} >{props.title}</Typography>
            </div>
            {/*{maxStatHovered && <div className={"comparison-trait-number-display"}>
                <Typography sx={{fontFamily: "Roboto, sans-serif", fontWeight: "bold", fontSize: "1em", marginRight: "10px"}}>{props.potentialStat}</Typography>
            </div>}*/}
            <div className={"comparison-trait-indicator-potential"} style={{left: props.potentialStat + '%'}} aria-label={props.potentialStat}>MAX</div>
            <div className={"comparison-trait-indicator-current"} style={{left: props.currentStat + '%'}} aria-label={props.currentStat} onMouseEnter={handleMouseEnterCurrentStat} onMouseLeave={handleMouseLeaveCurrentStat}/>
        </div>
    )
}

export default ComparisonTrait;