import "./General.css";

import React, {useState} from "react";
import DividerTitle from "../../DividerTitle";
import Notifications from "./notifications/Notifications";
import CustomImport from "./imports/CustomImport";
import FoundationImport from "./imports/FoundationImport";
import NewLocation from "./locations/NewLocation";
import UpdateLocation from "./locations/UpdateLocation";
import DeleteLocation from "./locations/DeleteLocation";
import LocationsList from "./locations/LocationsList";
import Loans from "./loans/Loans";
import Comparisons from "./comparision/Comparisons";

const General = (props) => {
    
    const [locationsUpdated, setLocationsUpdated] = useState(false);

    const handleSetUpdatedLocations = () => {
        setLocationsUpdated(!locationsUpdated);
    }
    
    return (
        <div>
            <Notifications id={props.id} config={props.config} api={props.api} darkMode={props.darkMode}/>
            <DividerTitle title={"Imports"}/>
            <div className={"general-imports-container"}>
                <FoundationImport ranchId={props.id} user={props.user} credits={props.user.importCredits} 
                                  locationsUpdated={locationsUpdated} token={props.token} api={props.api}
                                  formConfig={props.formConfig} darkMode={props.darkMode}
                                  handleRefresh={props.handleRefresh}/>
                <CustomImport ranchId={props.id} user={props.user} credits={props.user.customImportCredits} 
                              locationsUpdated={locationsUpdated} token={props.token} api={props.api} 
                              formConfig={props.formConfig} darkMode={props.darkMode}
                              handleRefresh={props.handleRefresh}/>
            </div>
            <DividerTitle title={"Locations Management"} darkMode={props.darkMode}/>
            <div className={"general-locations-container"}>
                <NewLocation id={props.id} handleSetUpdated={handleSetUpdatedLocations} api={props.api} token={props.token} formConfig={props.formConfig} darkMode={props.darkMode}/>
                <UpdateLocation id={props.id} updated={locationsUpdated} handleSetUpdated={handleSetUpdatedLocations} api={props.api} token={props.token} formConfig={props.formConfig} darkMode={props.darkMode}/>
                <DeleteLocation id={props.id} updated={locationsUpdated} handleSetUpdated={handleSetUpdatedLocations} api={props.api} token={props.token} config={props.config} darkMode={props.darkMode}/>
                <LocationsList id={props.id} updated={locationsUpdated} api={props.api} token={props.token} darkMode={props.darkMode}/>
            </div>
            <DividerTitle title={"Loans"} darkMode={props.darkMode}/>
            <Loans ranch={props.ranch} handleRefresh={props.handleRefresh} formConfig={props.formConfig}
                    config={props.config} darkMode={props.darkMode}/>
            {props.ranch.active ? <>
                <DividerTitle title={"Comparisons"} darkMode={props.darkMode}/>
                <Comparisons id={props.id} api={props.api} token={props.token} darkMode={props.darkMode}/>
            </> : ""}
        </div>
    )
}

export default General