export const Condition = [
    "Emaciated",
    "Very Thin",
    "Thin",
    "Moderately Thin",
    "Moderate",
    "Moderately Fat",
    "Fleshy",
    "Fat",
    "Extremely Fat"
]