import "./Breed.css";

import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select
} from "@mui/material";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

const Breed = (props) => {

    const [message, setMessage] = useState();
    const [studs, setStuds] = useState([]);
    
    useEffect(() => {
        axios.get('/api/Horses/getStuds', {
            params: {
                id: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setStuds(response.data);
            });    
    }, [props]);
    
    const [sire, setSire] = useState();
    
    const handleSetSire = (event) => {
        setSire(event.target.value);
    }
    
    const handleBreed = async () => {
        const formData = new FormData();
        formData.append("damId", props.id);
        formData.append("sireId", sire.horseId);
        formData.append("email", props.email);

        await axios.post('/api/Health/breed', formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                setMessage(response.data);
                props.handleSetUpdated();
                handleOpen();
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
            });
    }
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    
    return (
        <>
            <div className={"breed-container"}>
                <FormControl sx={{width: "60%", minWidth: "200px", margin: "5px"}}>
                    <InputLabel id={"stud"}>Stallion</InputLabel>
                    <Select labelId={"stud"} value={sire} onChange={handleSetSire} size={"small"}>
                        {studs.map(s =>
                            <MenuItem key={s.horseId} value={s}>{s.regName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Button variant={"contained"} onClick={handleBreed}>Breed</Button>
            </div>
            
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Message</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Breed;