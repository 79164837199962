import "./Rankings.css";

import React, {useState} from "react";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import HorsePoints from "./HorsePoints";
import HorseEarnings from "./HorseEarnings";
import ParentEarnings from "./ParentsEarnings";
import BreederEarnings from "./BreederEarnings";
import BreedsRanked from "./BreedsRanked";
import {useLocation, useNavigate} from "react-router-dom";

const Rankings = (props) => {

    const location = useLocation();
    const history = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };


    const [selectedTab, setSelectedTab] = useState(tab || "horsePoints");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        updateUrlParams(newTab);
    }
    
    return (
        <div>
            <img className={"horsesBanner"} src={"https://media.legacyhorsegame.com/banners/trophies-banner.jpg"} alt={"trophies"}/>
            <TabContext value={selectedTab} >
                <Box width={"100%"}>
                   <TabList variant={"scrollable"} className={"rankings-tabs"} onChange={handleChangeTab} centered  textColor={"white"}>
                       <Tab label={"Horses By Points"} value={"horsePoints"}/>
                       <Tab label={"Horses By Earnings"} value={"horseEarnings"}/>
                       <Tab label={"Sires By Earnings"} value={"sires"}/>
                       <Tab label={"Dams By Earnings"} value={"dams"}/>
                       <Tab label={"Breeders By Earnings"} value={"breederEarnings"}/>
                       <Tab label={"Breeds"} value={"rankedBreeds"}/>
                    </TabList>
                </Box>
                <TabPanel className={"rankings-tab"} value={"horsePoints"}>
                    <HorsePoints api={props.api} token={props.token} />
                </TabPanel>
                <TabPanel className={"rankings-tab"} value={"horseEarnings"}>
                    <HorseEarnings api={props.api} token={props.token} />
                </TabPanel>
                <TabPanel className={"rankings-tab"} value={"sires"}>
                    <ParentEarnings gender={"Stallion"} api={props.api} token={props.token} />
                </TabPanel>
                <TabPanel className={"rankings-tab"} value={"dams"}>
                    <ParentEarnings gender={"Mare"} api={props.api} token={props.token} />
                </TabPanel>
                <TabPanel className={"rankings-tab"} value={"breederEarnings"}>
                    <BreederEarnings api={props.api} token={props.token} />
                </TabPanel>
                <TabPanel className={"rankings-tab"} value={"rankedBreeds"}>
                    <BreedsRanked config={props.config} />
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default Rankings