import React, {useEffect, useState} from 'react';

import "./Trait.css";
import axios from "axios";
import Typography from "@mui/material/Typography";


const Trait = (props) => {
    const [maxStatHovered, setMaxStatHovered] = useState(false);

    const handleMouseEnterMaxStat = () => {
        setMaxStatHovered(true);
    };

    const handleMouseLeaveMaxStat = () => {
        setMaxStatHovered(false);
    };
    
    const handleClickMaxStat = () => {
        setMaxStatHovered(true);
    }
    
    const handleCloseMaxStat = () => {
        setMaxStatHovered(false);
    }
    
    const [currentStatHovered, setCurrentStatHovered] = useState(false);

    const handleMouseEnterCurrentStat = () => {
        setCurrentStatHovered(true);
    };

    const handleMouseLeaveCurrentStat = () => {
        setCurrentStatHovered(false);
    };

    return (
        <div className={props.darkMode ? "trait-bar-dark" : "trait-bar"} onMouseEnter={handleMouseEnterMaxStat}
             onMouseLeave={handleMouseLeaveMaxStat}>
            <div className={"trait-title"}>
                <Typography sx={{
                    fontFamily: "Raleway, sans-serif",
                    fontWeight: "bold",
                    fontSize: "20px",
                    paddingLeft: "10px",
                    zIndex: 2
                }}>{props.title}</Typography>
            </div>
            {/*{maxStatHovered && <div className={"trait-number-display"}>
                <Typography sx={{fontFamily: "Roboto, sans-serif", fontWeight: "bold", fontSize: "1em", marginRight: "10px"}}>{props.potentialStat}</Typography>
            </div>}*/}
            <div className={"trait-indicator-potential"} style={{left: props.potentialStat + '%'}}
                 aria-label={props.potentialStat}>MAX
            </div>
            <div className={"trait-indicator-current"} style={{left: props.currentStat + '%'}}
                 aria-label={props.currentStat} onMouseEnter={handleMouseEnterCurrentStat}
                 onMouseLeave={handleMouseLeaveCurrentStat}/>
            <div style={{left: '10%'}} className={"trait-step-indicator"}/>
            <div style={{left: '20%'}} className={"trait-step-indicator"}/>
            <div style={{left: '30%'}} className={"trait-step-indicator"}/>
            <div style={{left: '40%'}} className={"trait-step-indicator"}/>
            <div style={{left: '50%'}} className={"trait-step-indicator"}/>
            <div style={{left: '60%'}} className={"trait-step-indicator"}/>
            <div style={{left: '70%'}} className={"trait-step-indicator"}/>
            <div style={{left: '80%'}} className={"trait-step-indicator"}/>
            <div style={{left: '90%'}} className={"trait-step-indicator"}/>
        </div>
    )
}

export default Trait;