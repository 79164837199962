import "./SaleCard.css";
import "./AuctionCard.css";

import React, {useEffect, useState} from "react";
import Photo from "../horse/photo/Photo";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../enums/Breeds";
import {Months} from "../../enums/Months";

const AuctionCard = (props) => {
    
    const displaySire = () => {
        if(props.horse.sire != null){
            return props.horse.sire.regName;
        }
        return "Unknown";
    }

    const displayDam = () => {
        if(props.horse.dam != null){
            return props.horse.dam.regName;
        }
        return "Unknown";
    }

    const [bid, setBid] = new useState(0);
    
    const handleSetBid = (event) => {
        setBid(event.target.value);
    }
    
    const handleBidOnHorse = () => {
        const data = new FormData;
        data.append("bid", bid);
        data.append("salesId", props.horse.saleId);
        data.append("location", location);
        data.append("ranchId", props.ranchId);
        data.append("horseId", props.horse.horseId);

        axios.post('api/Sales/bid', data, props.formConfig)
            .then(response => {
                console.log(response.data);
                props.handleSetChanged();
            }).catch(error => {
            console.log(error);
            setMessage(error.response.data);
            handleBidErrorOpen();
        });
        handleClose();
    }

    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {params: {id: props.ranchId},
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                console.log(response.data);
                setPastures(response.data);
            });
    }, [props]);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {params: {id: props.ranchId},
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data);
            });
    }, [props]);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);

    const [location, setLocation] = useState();

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [bidErrorOpen, setBidErrorOpen] = useState(false);

    const handleBidErrorOpen = () => {
        setBidErrorOpen(true);
    }

    const handleBidErrorClose = () => {
        setBidErrorOpen(false);
    }

    const [message, setMessage] = useState();
    
/*    const [bidder, setBidder] = useState({});

    useEffect(() => {
        axios.get("api/Ranches/ranch", { params: {id: props.horse.sale?.ranchId}, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setBidder(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.horse]);*/

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }

    const female = (<img className={"auction-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"}/>)
    const male = (<img className={"auction-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"}/>)
    const gelding = (<img className={"auction-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    return (
        <div className={"auction-card-container"} >
            <Link to={`/horses/${props.horse.horseId}`}>
                {props.horse.gender == 0 ? female : props.horse.gelded ? gelding : male}
                <Photo className={"horse-image"} id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/>
            </Link>
            <div className={"auction-info-container"}>
                <div className={"auction-name-container"}>
                    <p className={"auction-prefix"}>{props.horse.prefix}</p>
                    <p className={"auction-name"}>{props.horse.regName}</p>
                </div>
                <p className={"auction-breed"}>{Breeds[props.horse.breed]}</p>
                <p className={"auction-parents"}>{displaySire()} x {displayDam()}</p>
                <p className={"auction-end-date"}>End date: {formatDate(props.horse.sale.auctionEndDate)}</p>
                {/*{bidder && <p className={"current-bidder"}><b>Current Bidder: </b> {bidder.name}</p>}*/}
            </div>
            <div className={"auction-purchase-info"}>
                <p className={"auction-price"}>{(props.horse.sale.currentBid).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(props.horse.sale.currentBid)).toFixed().length
                })}</p>
                <Button variant={"contained"} onClick={handleOpen}>Bid</Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Choose a location</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    <FormControl>
                        <TextField label={"Bid amount"} variant={"outlined"} size={"small"} onChange={handleSetBid} type={"number"}/>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Choose a barn/pasture</InputLabel>
                        <Select labelId={"pasture"} label={"Choose a pasture"} size={"small"} value={location} sx={{minWidth: 200, margin: 1}} onChange={handleSetLocation}>
                            <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or barn</MenuItem>
                            {locations && locations.map(location =>
                                <MenuItem key={location.name} value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId} >{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleBidOnHorse}>Bid</Button>
                    <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={bidErrorOpen} onClose={handleBidErrorClose}>
                    <DialogTitle>Choose a location</DialogTitle>
                    <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                        {message}
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={handleBidErrorClose} variant={"contained"}>Close</Button>
                    </DialogActions>
            </Dialog>
        </div>
    )
}

export default AuctionCard;