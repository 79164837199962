import "./CreditExchange.css";

import React, {useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Dialog, DialogActions, DialogTitle, FormControl, InputLabel, Select, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import axios from "axios";

const CreditExchange = (props) => {

    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }

    const [creditType, setCreditType] = useState(null);

    const handleSetCreditType = (event) => {
        setCreditType(event.target.value);
    }

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleTransferCredits = () => {
        const data = new FormData();
        data.append("ranchId", ranch.ranchId);
        data.append("type", creditType);

        axios.post("/api/Application/exchangeCashForCredits", data, props.formConfig)
            .then(response => {
                console.log(response);
                setMessage("Credits successfully exchanged.");
                handleClose();
                handleOpen();
                props.handleUpdate();
            }).catch(error => {
            console.log(error);
            setMessage(error.response.data);
            handleOpen();
        })
    }
    
    const inputStyle = {
        margin: 1,
        width: "65%"
    }

    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={props.darkMode ? "credit-transfers-container credit-transfers-container-dark" : "credit-transfers-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"credit-transfers-header-container"}>
                    <Typography variant={"h3"}>
                        Exchange
                    </Typography>
                </div>
                <FormControl sx={inputStyle}>
                    <Select label={"Ranch"} onChange={handleSetRanch} size={"small"}>
                        {props.ranches.map(r =>
                            <MenuItem key={r.ranchId} value={r}>{r.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={inputStyle}>
                    <InputLabel id={"barn"}>Choose a credit type</InputLabel>
                    <Select size={"small"} value={creditType} onChange={handleSetCreditType}>
                        <MenuItem disabled value={""}>Credit type</MenuItem>
                        <MenuItem value={"import"}>Import</MenuItem>
                        <MenuItem value={"background"}>Background</MenuItem>
                        <MenuItem value={"tack"}>Tack</MenuItem>
                        {/*<MenuItem value={"mane tail"}>Main & Tail</MenuItem>*/}
                    </Select>
                </FormControl>
                <Button sx={{alignSelf: "flex-end", marginRight: "50px"}} variant={"contained"}
                        onClick={handleTransferCredits}>Exchange</Button>
            </div>
            <Dialog sx={{paddingLeft: 4, paddingRight: 4}} open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Confirmation</DialogTitle>
                <p style={{padding: 20}}>{message}</p>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreditExchange