import "./Illnesses.css"

import React from "react";
import Illness from "./Illness";
import {Typography} from "@mui/material";

const Illnesses = (props) => {
    
    return (
        <div className={props.darkMode ? "illnesses-container-dark" : "illnesses-container"}>
            <div className={"illnesses-title-container"}>
                <Typography variant={"h3"}>Illnesses</Typography>
            </div>
            {props.illnesses && <>
                {props.illnesses.asthma ? <Illness title={"Asthma"}/> : ""}
                {props.illnesses.metaSyndrome ? <Illness title={"Metabolic Syndrome"} /> : ""}
                {props.illnesses.equineHypohidrosis ?<Illness title={"Equine Hypohidrosis"} /> : ""}
                {props.illnesses.keratoma ? <Illness title={"Keratoma"} currentDate={props.date} /> : ""}
                {props.illnesses.colic ? <Illness title={"Colic"} currentDate={props.date} /> : ""}
                {props.illnesses.majorRespitory ? <Illness title={"Major Respiratory Illness"} currentDate={props.date} timePassed={props.illnesses.majorRespIllnessTime} /> : props.illnesses.modRespIllness ? <Illness title={"Moderate Respiratory Illness"} currentDate={props.date} timePassed={props.illnesses.majorRespIllnessTime} /> : ""}
                {props.injuries.majorMetaLaminitis ? <Illness title={"Metabolic Laminitis"} /> : ""}
            </>}
        </div>
    )
}

export default Illnesses;