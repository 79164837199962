import "./AccountCustomization.css";

import React from "react";
import CustomizeUser from "./CustomizeUser";
import CustomizeRanch from "./CustomizeRanch";

const AccountCustomization = (props) => {
    
    
    return (
        <div className={"account-customization-container"}>
            <CustomizeUser user={props.user} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>
            <CustomizeRanch user={props.user} ranches={props.ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>
        </div>
    )
}

export default AccountCustomization