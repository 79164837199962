import React, {useEffect, useState} from 'react';

import Trait from "./Trait";

import "./Traits.css";
import Gaits from "./Gaits";
import axios from "axios";

const Traits = (props) => {
    
    const [horse, setHorse] = useState(null);

    useEffect(() => {
        axios.get("api/Horses/Performance", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setHorse(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.id]);
    
    return(
        <>
            {horse && <div className={"traits"}>
                <Trait title={"Agility"} currentStat={horse.agility?.currentValue}
                       potentialStat={horse.agility?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Balance"} currentStat={horse.balance?.currentValue}
                       potentialStat={horse.balance?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Intelligence"} currentStat={horse.intelligence?.finalValue}
                       potentialStat={horse.intelligence?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Jumping"} currentStat={horse.jumping?.currentValue}
                       potentialStat={horse.jumping?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Speed"} currentStat={horse.speed?.currentValue}
                       potentialStat={horse.speed?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Stamina"} currentStat={horse.stamina?.currentValue}
                       potentialStat={horse.stamina?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Strength"} currentStat={horse.strength?.currentValue}
                       potentialStat={horse.strength?.finalValue} darkMode={props.darkMode}/>
                <Trait title={"Surefooted"} currentStat={horse.surefootedness?.currentValue}
                       potentialStat={horse.surefootedness?.finalValue} darkMode={props.darkMode}/>
                <Gaits horse={horse} darkMode={props.darkMode} api={props.api} token={props.token}/>
            </div>}
        </>
    )
}

export default Traits;