import "./Approvals.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../../Theme";
import {
    Button,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import axios from "axios";

const Approvals = (props) => {
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const [horses, setHorses] = useState([]);

    useEffect(() => {
        axios.get("api/sales/pendingApproval", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setHorses(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [updated]);
    
    const approvePurchase = (id) => {
        axios.post('api/Sales/approve', id, props.config)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
                console.log(error);
        })
    }
    
    const denyPurchase = (id) => {
        axios.post('api/Sales/deny', id, props.config)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }
    
    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={"approvals-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"approvals-title-container"}>
                <Typography>Pending Approval</Typography>
            </div>
            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Horse</TableCell>
                            <TableCell align={"center"}>Buyer</TableCell>
                            <TableCell align={"center"} width={"20%"}>Approve/Deny</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {horses && horses.map(h => 
                            <TableRow key={h.horseId}>
                                <TableCell><Link to={`/horses/${h.horseId}`}>{h.regName}</Link></TableCell>
                                <TableCell align={"center"}><Link to={`/ranches/${h.sale.ranchId}`}>{h.sale.buyer.name}</Link></TableCell>
                                <TableCell align={"right"}>
                                    <Button variant={"contained"} size={"small"} onClick={approvePurchase.bind(this, h.horseId)}>Approve</Button>
                                    <Button variant={"contained"} size={"small"} color={"warning"} onClick={denyPurchase.bind(this, h.horseId)}>Deny</Button>
                                </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Approvals