import "./SemenCard.css";

import React, {useEffect, useState} from "react";
import Photo from "../horse/photo/Photo";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select, TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../enums/Breeds";

const SemenCard = (props) => {
    const displaySire = () => {
        if (props.horse.sire != null) {
            return props.horse.sire.regName;
        }
        return "Unknown";
    }

    const displayDam = () => {
        if (props.horse.dam != null) {
            return props.horse.dam.regName;
        }
        return "Unknown";
    }

    const [amount, setAmount] = useState(0);

    const handleSetAmount = (event) => {
        setAmount(event.target.value);
    }

    const [disabled, setDisabled] = useState(false);

    const purchaseStraws = async () => {
        setDisabled(true);
        let data = new FormData();
        data.append("buyer", props.ranchId);
        data.append("id", props.id);
        data.append("amount", amount);

        await axios.post('/api/Health/buyStraws', data, props.formConfig)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
                setDisabled(false);
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
                setDisabled(false);
            });
        setAmount(0);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [message, setMessage] = useState("");

    return (
        <>
            <div className={"semen-card-container"}>
                <Link xs={{display: "inline-block"}} to={`/horses/${props.horse.horseId}`}>
                    <Photo className={"horse-image"} id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/>
                </Link>
                <div className={"semen-info-container"}>
                    <div className={"semen-horse-info"}>
                        <div className={"semen-name-container"}>
                            <p className={"semen-prefix"}>{props.horse.prefix}</p>
                            <p className={"semen-name"}>{props.horse.regName}</p>
                        </div>
                        <p className={"semen-breed"}>{Breeds[props.horse.breed]}</p>
                        <p className={"semen-parents"}>{displaySire()} x {displayDam()}</p>
                    </div>
                    <div className={"semen-purchase-info"}>
                        <p className={"semen-price"}>{(props.price).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumSignificantDigits: Math.trunc(Math.abs(props.price)).toFixed().length
                        })}</p>
                        <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between", width: "100%"}}>
                            <FormControl>
                                <InputLabel>Straws</InputLabel>
                                <Select sx={{width: 75, margin: "0 10px 10px 10px", color: "black"}} size={"small"} onChange={handleSetAmount}
                                        value={amount}>
                                    {Array.apply(null, {length: (props.amount + 1)}).map(Number.call, Number).map(a =>
                                        <MenuItem key={a} value={a}>{a * 4}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <Button variant={"contained"} size={"small"} onClick={purchaseStraws}
                                    disabled={disabled}>Purchase</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> </DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SemenCard;