import "./CreditTransfers.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {
    Autocomplete, Checkbox,
    Dialog, DialogActions, DialogContentText,
    DialogTitle,
    Divider,
    FormControl, FormControlLabel,
    InputLabel,
    Select,
    TextField,
    Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Button from "@mui/material/Button";
import {CheckBox} from "@mui/icons-material";

const CreditTransfers = (props) => {
    
    const [recipient, setRecipient] = useState();

    const handleSetRecipient = (event, values) => {
        setRecipient(values.id);
    }

    const [creditType, setCreditType] = useState(null);

    const handleSetCreditType = (event) => {
        setCreditType(event.target.value);
    }

    const [creditsAmount, setCreditsAmount] = useState(null);

    const handleSetCreditsAmount = (event) => {
        setCreditsAmount(event.target.value);
    }
    
    const [isAnonymous, setIsAnonymous] = useState(false);
    
    const handleSetIsAnonymous = (event) => {
        setIsAnonymous(event.target.checked);
    }

    const [openTransferConfirmation, setOpenTransferConfirmation] = useState(false);

    const handleOpenTransferConfirmation = () => {
        setOpenTransferConfirmation(true);
    }

    const handleCloseTransferConfirmation = () => {
        setOpenTransferConfirmation(false);
    }

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleTransferCredits = () => {
        const data = new FormData();
        data.append("sender", props.user.email);
        data.append("recipient", recipient);
        data.append("type", creditType);
        data.append("total", creditsAmount);
        data.append("isAnonymous", isAnonymous);

        axios.post("/api/Account/transferCredits", data, props.formConfig)
            .then(response => {
                handleCloseTransferConfirmation();
                console.log(response);
                setMessage(response.data)
                handleOpen();
                props.handleUpdate();
            }).catch(error => {
            console.log(error);
            setMessage(error.response.data);
            handleOpen();
        })
    }

    const inputStyle = {
        margin: 1,
        width: "65%"
    }
    
    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={props.darkMode ? "credit-transfers-container credit-transfers-container-dark" : "credit-transfers-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"credit-transfers-header-container"}>
                    <Typography variant={"h3"}>
                        Transfer
                    </Typography>
                </div>
                <FormControl sx={inputStyle}>
                    <Autocomplete freeSolo
                                  required
                                  label={"Recipient"}
                                  options={props.users}
                                  getOptionLabel={user => user.displayName}
                                  onChange={handleSetRecipient}
                                  renderInput={(params) => (
                                      <TextField {...params} label={"Recipient"} variant={"outlined"}
                                                 size={"small"}/>)}/>
                </FormControl>
                <FormControl sx={inputStyle}>
                    <InputLabel id={"barn"}>Choose a credit type</InputLabel>
                    <Select size={"small"} value={creditType} onChange={handleSetCreditType}>
                        <MenuItem disabled value={""}>Credit type</MenuItem>
                        <MenuItem value={"import"}>Import</MenuItem>
                        <MenuItem value={"custom import"}>Custom Import</MenuItem>
                        <MenuItem value={"background"}>Background</MenuItem>
                        <MenuItem value={"tack"}>Tack</MenuItem>
                        {/*<MenuItem value={"mane tail"}>Main & Tail</MenuItem>*/}
                    </Select>
                </FormControl>
                {creditType == "import" ? <FormControl sx={inputStyle}>
                    <Select label={"Ranch"} onChange={handleSetCreditsAmount} size={"small"}>
                        {Array.apply(null, {length: (props.user.importCredits)}).map(Number.call, Number).map(amount =>
                            <MenuItem key={amount} value={amount + 1}>{amount + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl> : ""}
                {creditType == "custom import" ? <FormControl sx={inputStyle}>
                    <Select label={"Ranch"} onChange={handleSetCreditsAmount} size={"small"}>
                        {Array.apply(null, {length: (props.user.customImportCredits)}).map(Number.call, Number).map(amount =>
                            <MenuItem key={amount} value={amount + 1}>{amount + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl> : ""}
                {creditType == "background" ? <FormControl sx={inputStyle}>
                    <Select label={"Ranch"} onChange={handleSetCreditsAmount} size={"small"}>
                        {Array.apply(null, {length: (props.user.backgroundCredits)}).map(Number.call, Number).map(amount =>
                            <MenuItem key={amount} value={amount + 1}>{amount + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl> : ""}
                {creditType == "tack" ? <FormControl>
                    <Select label={"Ranch"} onChange={handleSetCreditsAmount} size={"small"}>
                        {Array.apply(null, {length: (props.user.tackCredits)}).map(Number.call, Number).map(amount =>
                            <MenuItem key={amount} value={amount + 1}>{amount + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl> : ""}
                <FormControl sx={inputStyle}>
                    <FormControlLabel control={<Checkbox checked={isAnonymous} onChange={handleSetIsAnonymous}/>} label={"Send anonymously"} />
                </FormControl>
                <Button sx={{alignSelf: "flex-end", marginRight: "50px"}} variant={"contained"}
                        onClick={handleOpenTransferConfirmation}>Transfer</Button>
            </div>
            <Dialog open={openTransferConfirmation} onClose={handleCloseTransferConfirmation} >
                <DialogTitle>Transfer Credits</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Are you sure you want to transfer your credits? This action is permanent.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseTransferConfirmation} variant={"contained"}>No</Button>
                    <Button onClick={handleTransferCredits} autoFocus color={"warning"} variant={"contained"}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog sx={{paddingLeft: 4, paddingRight: 4}} open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Confirmation</DialogTitle>
                <p style={{padding: 20}}>{message}</p>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreditTransfers