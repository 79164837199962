import "./Gaits.css";
import React, {useEffect, useState} from "react";
import axios from "axios";

const Gaits = (props) => {
    return (
        <div className={"gaits-container"}>
            {props.horse.gaited.finalValue == 3 ? <img className={"gaits-img"} src={props.darkMode ? "https://media.legacyhorsegame.com/misc/gaits/gaits_orange_3.png" : "https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/gaits/gaits_3.png"} alt={"Three gaits"}/> : ""}
            {props.horse.gaited.finalValue == 4 ? <img className={"gaits-img"} src={props.darkMode ? "https://media.legacyhorsegame.com/misc/gaits/gaits_orange_4.png" :"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/gaits/gaits_4.png"} alt={"Four gaits"}/> : ""}
            {props.horse.gaited.finalValue == 5 ? <img className={"gaits-img"} src={props.darkMode ? "https://media.legacyhorsegame.com/misc/gaits/gaits_orange_5.png" :"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/gaits/gaits_5.png"} alt={"Five gaits"}/> : ""}
        </div>
    )
}

export default Gaits;