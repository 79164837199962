import "./PublicStud.css";

import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select
} from "@mui/material";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

const Breed = (props) => {
    
    const [message, setMessage] = useState();
    
    const [dams, setDams] = useState([]);

    useEffect(() => {
        axios.get('/api/Horses/getMares', {
            params: {
                id: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
                setDams(response.data);
            }).catch(error => {
                console.log(error);
        });
    }, [props]);

    const [dam, setDam] = useState();

    const handleSetDam = (event) => {
        setDam(event.target.value);
    }

    const handleBreed = async () => {
        var formData = new FormData();
        formData.append("damId", dam.horseId);
        formData.append("sireId", props.id);
        formData.append("email", props.email)

        await axios.post('/api/Health/breed', formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                setMessage(response.data);
                props.handleSetUpdated();
                handleOpen();
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
            });
    }    
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <div className={"public-stud-container"}>
                <FormControl sx={{width: "60%", minWidth: "200px", margin: "5px"}}>
                    <InputLabel id={"stud"}>Stallion</InputLabel>
                    <Select labelId={"stud"} value={dam} onChange={handleSetDam} size={"small"} disabled={props.disabled}>
                        {dams.map(m =>
                            <MenuItem key={m.horseId} value={m}>{m.regName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Button variant={"contained"} onClick={handleBreed} disabled={props.disabled}>Breed</Button>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Message</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Breed;