import './Product.css';

import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {darkTheme, lightTheme} from "../../../Theme";

const Subscription = (props) => {
    
    const [price, setPrice] = useState(null)

    useEffect(() => {
        axios.get('api/Stripe/price', {
            params: {
                id: props.product.defaultPriceId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPrice(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, []);

    function insertDecimal(num) {
        return (Number((num/100).toFixed(2))).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    const [product, setProduct] = useState({});

    const handleSetProduct = (event) => {
        const newProduct = {
            name: props.name,
            priceId: price.id,
            price: insertDecimal(price.unitAmount),
            amount: price.transformQuantity?.divideBy
        }
        if(newProduct.amount == null) {
            newProduct.amount = 1;
        }
        setProduct(newProduct);
    }
    
    const [user, setUser] = useState(null);
    
    const handleSetUser = (event) => {
        setUser(event.target.value);
        handleSetProduct();
    }

    const handleSubscribe = (subscription) => {
        if(user == null){
            setMessage("Please select a user.");
            handleOpen();
        } else {
            axios.post('/api/Stripe/checkoutGiftSubscription', subscription, {
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                    "id": user.id
                }
            }).then(response => {
                window.location.replace(response.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const inputStyle = {
        margin: 1,
        width: "65%"
    }

    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "product-container product-container-dark" : "product-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"product-header-container"}>
                <Typography variant={"h3"}>
                    {props.product.name}
                </Typography>
            </div>
            <Typography sx={{margin: "10px 20px"}}>{props.product.description}</Typography>
            {price && <Typography sx={{
                margin: "10px 20px",
                fontWeight: "bolder",
                fontSize: "20px"
            }}>{insertDecimal(price.unitAmount)}</Typography>}
            <FormControl sx={inputStyle}>
                <Select size={"small"} onChange={handleSetUser}>
                    <MenuItem value={0}>Select User</MenuItem>
                    {props.users && props.users.filter(u => u.email != props.email).map((user, i) =>
                        <MenuItem key={i} value={user}>{user.displayName}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <Button sx={{alignSelf: "flex-end", marginRight: "15%"}} variant={"contained"} size={"small"} onClick={handleSubscribe.bind(this, product)}>Gift</Button>

            <Dialog  sx={{paddingLeft: 4, paddingRight: 4}} open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{message?.title}</DialogTitle>
                <p style={{padding: 20}}>{message}</p>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Subscription;