import "./Pedigree.css";

import React, {useEffect, useState} from "react";
import Parent from "./Parent";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import axios from "axios";

const Pedigree = (props) => {    
    const [horse, setHorse] = useState();
    
    useEffect(() => {
        axios.get("/api/Horses/pedigree", {params: {id: props.id}, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorse(response.data);
            });
    }, [props.id]);
    
    const returnHorse = (horse) => {
        if(horse != null){
            return(
                <Link to={`/horses/${horse.horseId}`}>
                    <Parent name={horse.regName} gender={horse.gender}/>
                </Link>
            )
        } else {
            return(
                <Parent name={null}/>
            )
        }
    }
    
    return (
        <div style={{overflow: "auto"}}>
            <div style={{width: "100%", display: "table", tableLayout: "fixed" }}>
                <div className={"pedigree-container"} >
                    <div className={"pedigree-column"}>
                        {horse && returnHorse(horse)}
                    </div>
                    <div className={"pedigree-column"}>
                        {horse && returnHorse(horse.sire)}
                        {horse && returnHorse(horse.dam)}
                    </div>
                    <div className={"pedigree-column"}>
                        {horse && returnHorse(horse.sire?.sire)}
                        {horse && returnHorse(horse.sire?.dam)}
                        {horse && returnHorse(horse.dam?.sire)}
                        {horse && returnHorse(horse.dam?.dam)}
                    </div>
                    <div className={"pedigree-column"}>
                        {horse && returnHorse(horse.sire?.sire?.sire)}
                        {horse && returnHorse(horse.sire?.sire?.dam)}
                        {horse && returnHorse(horse.sire?.dam?.sire)}
                        {horse && returnHorse(horse.sire?.dam?.dam)}
                        {horse && returnHorse(horse.dam?.sire?.sire)}
                        {horse && returnHorse(horse.dam?.sire?.dam)}
                        {horse && returnHorse(horse.dam?.dam?.sire)}
                        {horse && returnHorse(horse.dam?.dam?.dam)}
                    </div>
                    <div className={"pedigree-column"}>
                        {horse && returnHorse(horse.sire?.sire?.sire?.sire)}
                        {horse && returnHorse(horse.sire?.sire?.sire?.dam)}
                        {horse && returnHorse(horse.sire?.sire?.dam?.sire)}
                        {horse && returnHorse(horse.sire?.sire?.dam?.dam)}
                        {horse && returnHorse(horse.sire?.dam?.sire?.sire)}
                        {horse && returnHorse(horse.sire?.dam?.sire?.dam)}
                        {horse && returnHorse(horse.sire?.dam?.dam?.sire)}
                        {horse && returnHorse(horse.sire?.dam?.dam?.dam)}
                        {horse && returnHorse(horse.dam?.sire?.sire?.sire)}
                        {horse && returnHorse(horse.dam?.sire?.sire?.dam)}
                        {horse && returnHorse(horse.dam?.sire?.dam?.sire)}
                        {horse && returnHorse(horse.dam?.sire?.dam?.dam)}
                        {horse && returnHorse(horse.dam?.dam?.sire?.sire)}
                        {horse && returnHorse(horse.dam?.dam?.sire?.dam)}
                        {horse && returnHorse(horse.dam?.dam?.dam?.sire)}
                        {horse && returnHorse(horse.dam?.dam?.dam?.dam)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pedigree;