import "./Results.css";

import React, {useEffect, useState} from "react";
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from "@mui/material";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useCookies} from "react-cookie";

const Results = (props) => {
    const { id } = useParams();
    const [show, setShow] = useState();

    useEffect(() => {
        axios.get('/api/Shows/show/', {params: {id: id},
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setShow(response.data);
            });
    }, [id]);
    
    const [entrants, setEntrants] = useState([]);

    function compare(a, b) {
        if(a.placement < b.placement){
            return -1;
        }
        if(a.placement > b.placement){
            return 1;
        }
        return 0;
    }
    
    useEffect(() => {
        axios.get('/api/Shows/entrantsByShow', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setEntrants(response.data.sort(compare));
                var entered = [];
                for (let i = 0; i < response.data.length; i++) {
                    entered.push(response.data[i].horse);
                }
            });
    }, []);
    
    const placements = [
        "First",
        "Second",
        "Third",
        "Fourth",
        "Fifth",
        "Sixth",
        "Seventh",
        "Eighth",
        "Ninth",
        "Tenth",
        "Eleventh",
        "Twelfth",
        "Thirteenth",
        "Fourteenth",
        "Fifteenth",
        "Sixteenth",
        "Seventeenth",
        "Eighteenth",
        "Nineteenth",
        "Twentieth"
    ]
    
    return (
        <div className={"results-container"}>
            <p className={"show-results-title"}>Results</p>
            <Table sx={{width: "65%", alignSelf: "center", marginBottom: 5}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Placement</TableCell>
                        <TableCell>Horse</TableCell>
                        <TableCell>Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entrants && entrants.map(entrant =>
                        <TableRow key={entrant.entrantId}>
                            <TableCell>{placements[entrant.placement]}</TableCell>
                            <TableCell>{entrant.horse?.regName != undefined ? entrant.horse?.regName : "Unknown"}</TableCell>
                            <TableCell>{entrant.score}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default Results;