import "./RegisterForm.css";

import React, {useEffect} from "react";
import {useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel,
    TextField, Typography
} from "@mui/material";

const RegisterForm = (props) => {

    const [verified, setVerified] = useState();

    const [username, setUsername] = useState("");

    const handleSetUserName = (event) => {
        setUsername(event.target.value);
    }

    const [email, setEmail] = useState("");

    const handleSetEmail = (event) => {
        setEmail(event.target.value);
    }

    const [password, setPassword] = useState("");

    const [strength, setStrength] = useState('Weak');
    const [isLong, setIsLong] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasSpecial, setHasSpecial] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    // Function to check the strength of the password
    const evaluatePassword = (password) => {
        let strengthLevel = 0;

        // Criteria for the password strength
        const criteria = [
            (password.length >= 8), // Minimum length of 8 characters
            /[A-Z]/.test(password), // Includes an uppercase letter
            /[a-z]/.test(password), // Includes a lowercase letter
            /[0-9]/.test(password), // Includes a digit
            /[^A-Za-z0-9]/.test(password), // Includes a special character
        ];

        setIsLong(password.length >= 8);
        setHasUppercase(/[A-Z]/.test(password));
        setHasLowercase(/[a-z]/.test(password));
        setHasNumber(/[0-9]/.test(password));
        setHasSpecial(/[^A-Za-z0-9]/.test(password));

        // Check each criterion
        criteria.forEach((criterion) => {
            if (criterion) strengthLevel += 1;
        });

        // Update strength based on the number of criteria met
        switch (strengthLevel) {
            case 0:
            case 1:
            case 2:
                setStrength('Weak');
                break;
            case 3:
            case 4:
                setStrength('Moderate');
                break;
            case 5:
                setStrength('Strong');
                break;
            default:
                setStrength('');
        }
    };

    const handleSetPassword = (event) => {
        evaluatePassword(event.target.value);
        setPassword(event.target.value);
    }

    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const handleSetPasswordConfirmation = (event) => {
        setPasswordsMatch(event.target.value == password);
        setPasswordConfirmation(event.target.value);
    }

    const [messageOpen, setMessageOpen] = useState(false);
    const [message, setMessage] = useState("Registration successful. Please check your email for a confirmation. You must confirm your account before you will be able to login.")

    const handleOpenMessage = () => {
        setMessageOpen(true);
    }

    const handleCloseMessage = () => {
        setMessageOpen(false);
    }

    const [success, setSuccessOpen] = useState(false);

    const handleOpenSuccess = () => {
        setSuccessOpen(true);
    }

    const handleCloseSuccess = () => {
        setSuccessOpen(false);
    }


    const [ranch, setRanch] = useState("");

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }

    const handleSubmitForm = async (event) => {
        event.preventDefault();

        var registerDto = {
            Username: username,
            displayName: username,
            Email: email,
            Password: password,
            Over13: confirmed13,
            AcceptedPrivacy: confirmedPrivacy,
            AcceptedTos: confirmedTos,
            RanchName: ranch
        }

        var formData = new FormData();
        formData.append("email", email);
        formData.append("name", ranch);

        await axios.post('/api/Account/register', registerDto, props.config)
            .then(function (response) {
                console.log(response);
                handleOpenSuccess();
            }).catch(function (error) {
                console.log(error);
                if (error.response.status === 400) {
                    setMessage(error.response.data);
                } else {
                    setMessage(error.response.data);
                }
                handleOpenMessage();
            });
    }

    const [confirmed13, setConfirmed13] = useState(false);

    const handleSetConfirmed13 = () => {
        setConfirmed13(!confirmed13);
    }

    const [confirmedTos, setConfirmedTos] = useState(false);

    const handleSetConfirmedTos = () => {
        setConfirmedTos(!confirmedTos);
    }

    const [clickedTos, setClickedTos] = useState(false);

    const handleSetClickedTos = () => {
        setClickedTos(true);
        props.handleTosOpen();
    }

    const [confirmedPrivacy, setConfirmedPrivacy] = useState(false);

    const handleSetConfirmedPrivacy = () => {
        setConfirmedPrivacy(!confirmedPrivacy);
    }

    const [clickedPrivacy, setClickedPrivacy] = useState(false);

    const handleSetClickedPrivacy = () => {
        setClickedPrivacy(true);
        props.handlePrivacyOpen();
    }

    const privacy = (
        <span style={{display: "flex", flexFlow: "row nowrap", alignItems: "flex-end"}}>
            <span>I accept the </span>
            <span style={{marginLeft: 2, color: "teal", fontWeight: "bolder"}} onClick={handleSetClickedPrivacy}>Privacy Policy</span>
        </span>
    )

    const tos = (
        <span style={{display: "flex", flexFlow: "row nowrap", alignItems: "flex-end"}}>
            <span>I accept the </span>
            <span style={{marginLeft: 2, color: "teal", fontWeight: "bolder"}} onClick={handleSetClickedTos}>Terms of Service</span>
        </span>
    )

    const warningTextStyle = {
        margin: 1,
        color: "red"
    }
    
    const inputStyle = {
        margin: 1,
        width: "25%",
        minWidth: "300px"
    }

    const register = (
        <div className={"register"}>
            <FormControl sx={inputStyle}>
                <TextField value={username} onChange={handleSetUserName} type={"text"}
                           placeholder={"Username"} label={"Username"} size={"small"}/>
            </FormControl>
            <FormControl sx={inputStyle}>
                <TextField value={email} onChange={handleSetEmail} type={"email"}
                           placeholder={"E-mail"} label={"E-mail"} size={"small"}/>
            </FormControl>
            <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}>
                <FormControl sx={inputStyle}>
                    <TextField value={password} onChange={handleSetPassword}
                               type={"password"} placeholder={"Password"} label={"Password"} size={"small"}/>
                </FormControl>
                <Typography sx={{color: strength !== "Strong" ? "red" : "green"}}>{strength}</Typography>
            </div>
            <FormControl sx={inputStyle}>
                <TextField value={passwordConfirmation}
                           onChange={handleSetPasswordConfirmation} type={"password"} placeholder={"Password"}
                           label={"Password"} size={"small"}/>
            </FormControl>
            <FormControl sx={inputStyle}>
                <TextField value={ranch} onChange={handleSetRanch} type={"text"}
                           placeholder={"Ranch Name"} label={"Ranch Name"} size={"small"}/>
            </FormControl>
            <FormControlLabel control={<Checkbox onChange={handleSetConfirmed13}/>}
                              label={"I confirm I am over the age of 13."}/>
            <FormControlLabel control={<Checkbox onChange={handleSetConfirmedPrivacy} disabled={!clickedPrivacy}/>}
                              label={privacy}/>
            <FormControlLabel control={<Checkbox onChange={handleSetConfirmedTos} disabled={!clickedTos}/>}
                              label={tos}/>
            <Button sx={{margin: 1, maxWidth: 70}} type={"submit"} onClick={handleSubmitForm} variant={"contained"}
                    disabled={!confirmed13 || !confirmedTos || !confirmedPrivacy || !hasLowercase || !hasUppercase || !hasNumber || !hasSpecial || !isLong || !passwordsMatch}>Register</Button>
            {!isLong ? <Typography sx={warningTextStyle}>Password must be at least 8 characters long.</Typography> : ""}
            {!hasLowercase ?
                <Typography sx={warningTextStyle}>Please include a lowercase letter in your password.</Typography> : ""}
            {!hasUppercase ? <Typography sx={warningTextStyle}>Please include an uppercase letter in your
                password.</Typography> : ""}
            {!hasNumber ? <Typography sx={warningTextStyle}>Please include a number in your password.</Typography> : ""}
            {!hasSpecial ? <Typography sx={warningTextStyle}>Please include a special character in your
                password.</Typography> : ""}
            {!passwordsMatch ? <Typography sx={warningTextStyle}>Passwords don't match.</Typography> : ""}
            <Typography style={{maxWidth: 350}}>Password must contain a special character, an uppercase character, a
                lowercase character and be at least 8 characters long. Your username also cannot contain any special
                characters or spaces. In order to confirm the TOS and Privacy Policy, you need to click the green links
                and confirm them in the popup.</Typography>
            <Dialog open={messageOpen} onClose={handleCloseMessage} sx={{padding: '20px'}}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={success} onClose={handleCloseSuccess} sx={{padding: '20px'}}>
                <DialogTitle>Success</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Account successfully created. Please check and confirm your email address.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseSuccess} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

    const [key, setKey] = useState();

    const handleSetKey = (event) => {
        setKey(event.target.value);
    }

    const handleVerifyKey = async (event) => {
        event.preventDefault();

        var form = new FormData;
        form.append("key", key);

        await axios.post('/api/Application/verifyKey', form, props.formConfig)
            .then(function (response) {
                setVerified(response.data);
            }).catch(function (error) {
                console.log(error);
            });
    }

    const validation = (
        <div className={"validation-container"}>
            <FormControl sx={{paddingBottom: 2}}>
                <TextField size={"small"} onChange={handleSetKey} placeholder={"Access Key"}/>
            </FormControl>
            <Button variant={"contained"} onClick={handleVerifyKey}>Verify</Button>
        </div>
    )

    const [view, setView] = useState();


    useEffect(() => {
        if (!verified) {
            setView(validation);
        } else {
            setView(register);
        }
    }, [verified])

    return (
        <>
            {register}
            {/*{verified ? register : validation}*/}
        </>
    )
}

export default RegisterForm;