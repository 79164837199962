import React from "react";

const Rollover = () => {
    
    
    return(
        <div>
            <p>Currently in rollover. Please be patient.</p>
        </div>
    )
}

export default Rollover;