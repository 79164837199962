import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

const TermsOfService = () => {


    return (
        <div>
            <p>Welcome to Legacy horse game! Before you start playing, please read and agree to our Terms of Service.
                By accessing or using our website and game, you agree to be bound by these terms and conditions.</p>
            <List>
                <ListItem>
                    <ListItemText primary={"1. Account Registration"}
                                  secondary={"To use our game, you must register for an account. You must provide accurate " +
                                      "and complete information when creating your account. You are responsible for maintaining " +
                                      "the confidentiality of your account information and for all activities that occur under your " +
                                      "account. You must notify us immediately if you become aware of any unauthorized use of your account."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"2. User Conduct"}
                                  secondary={<React.Fragment>
                                      <p>You agree to use our game only for lawful purposes and in a manner consistent with these Terms of Service. You may not use our game to:</p>
                                      <List dense={"dense"}>
                                          <ListItem>
                                              <p>Harass, abuse, or harm other players</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Cheat or exploit bugs in the game</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Impersonate another person or entity</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Violate any intellectual property rights or other proprietary rights</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Engage in any illegal activity</p>
                                          </ListItem>
                                      </List>
                                      <p>We reserve the right to terminate your account and take legal action if you engage in any prohibited conduct.</p>
                                  </React.Fragment>}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"3. User Content"}
                                  secondary={"Our game allows you to create and submit content, such as horse names, descriptions, " +
                                      "and images. You retain ownership of your content, but you grant us a non-exclusive, royalty-free, " +
                                      "worldwide, perpetual, and irrevocable license to use, copy, modify, distribute, and display your " +
                                      "content in connection with our game. You represent and warrant that your content does not infringe " +
                                      "any third-party rights."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"4. In Game Items"}
                                  secondary={"Our game may include virtual items that can be purchased with real money. " +
                                      "These purchases are non-refundable and are for your personal use only. You may not transfer " +
                                      "or sell virtual currency or items to other players or third parties for real life currency."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"5. Indemnification"}
                                  secondary={"You agree to indemnify and hold us harmless from any claims, damages, liabilities," +
                                      " and expenses (including attorneys' fees) arising from your use of our game or your violation of these Terms of Service."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"6. Disclaimer of Warranties"}
                                  secondary={'Our game is provided "as is" without warranties of any kind, ' +
                                      'either express or implied, including, but not limited to, ' +
                                      "implied warranties of merchantability and fitness for a particular purpose. " +
                                      "We do not guarantee that our game will be uninterrupted or error-free, " +
                                      "and we are not responsible for any losses or damages arising from your use of our game."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"7. Limitation of Liability"}
                                  secondary={"In no event shall we be liable for any indirect, incidental, special, " +
                                      "or consequential damages arising from your use of our game, including, " +
                                      "but not limited to, lost profits, data loss, or business interruption."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Termination"}
                                  secondary={"We may terminate or suspend your account and access to our game at any " +
                                      "time and for any reason without notice. You may also terminate your " +
                                      "account at any time by contacting us."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"9. Changes to Terms of Service"}
                                  secondary={"We reserve the right to modify these Terms of Service at any time. " +
                                      "Your continued use of our game after any such modifications will constitute " +
                                      "your acceptance of the new terms."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"10. Governing Law and Jurisdiction"}
                                  secondary={"These Terms of Service shall be governed by and construed in accordance " +
                                      "with the laws of Montana, USA. Any disputes arising from these terms shall be " +
                                      "resolved in the courts located in Montana."}
                    />
                </ListItem>
            </List>
            <p>Thank you for reading our Terms of Service. We hope you enjoy our online horse game!</p>
        </div>
    )
}

export default TermsOfService;