import "./Stallion.css";

import React from "react";
import {HourglassEmpty} from "@mui/icons-material";
import {Divider, Typography} from "@mui/material";
import {Months} from "../../../../enums/Months";
import SemenCollection from "./SemenCollection";
import PublicStud from "./PublicStud";

const Stallion = (props) => {
    
    return (
        <div className={"stallion-container"}>
            <div className={"stallion-breedings-container"}>
                <HourglassEmpty sx={{fontSize: "2em"}} color={"primary"} />
                <Typography sx={{fontWeight: "bolder", fontSize: "1.2em", textTransform: "uppercase", paddingRight: "10px"}} >Breedings remaining</Typography>
                <Divider sx={{flexGrow: 1}} />
                <Typography sx={{paddingLeft: "10px", fontSize: "1.2em",}} >{props.available}</Typography>
            </div>
            {props.isOwner ? <SemenCollection id={props.horse.horseId}
                              disabled={props.disabled}
                              available={props.available}
                              formConfig={props.formConfig}
                              handleSetUpdated={props.handleSetUpdated}
            /> : ""}
            {props.horse.fertility.publicStud &&  <PublicStud id={props.horse.horseId} email={props.email} ranchId={props.ranchId}
                                                             disabled={props.disabled} api={props.api} token={props.token} formConfig={props.formConfig}
                                                             handleSetUpdated={props.handleSetUpdated} />} 
        </div>
    )
}

export default Stallion