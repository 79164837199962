import "./Trophy.css";

import React from "react";


const Trophy = (props) => {
    
    
    
    return (
        <div className={"trophy-container"}>
            <img className={"trophy-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/trophies/" + props.type + '/' + props.placement + ".png"} alt={"trophy"}/>
            <p className={"trophy-discipline"}>{props.discipline}</p>
            <p>{props.date}</p>
        </div>
    )
}

export default Trophy