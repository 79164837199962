import "./Search.css";

import React, {useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SearchHorses from "./horses/SearchHorses";
import SearchRanches from "./ranches/SearchRanches";
import SearchUsers from "./players/SearchUsers";

const Search = (props) => {
    const location = useLocation();
    const history = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };


    const [selectedTab, setSelectedTab] = useState(tab || "horses");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        updateUrlParams(newTab);
    }

    return (
        <div style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"search-container"}>
            <img className={"horsesBanner"} src={"https://media.legacyhorsegame.com/banners/search-banner.jpg"} alt={"Horse"}/>
            <TabContext value={selectedTab}>
                <Box width={"100%"}>
                    <TabList className={"horses-tabs"} onChange={handleChangeTab} centered  textColor={"white"}>
                        <Tab label={"Horses"} value={"horses"}/>
                        <Tab label={"Ranches"} value={"ranches"}/>
                        <Tab label={"Players"} value={"players"}/>
                    </TabList>
                </Box>
                <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"search-horses-tab"} value={"horses"}>
                    <SearchHorses config={props.config} api={props.api} token={props.token}/>
                </TabPanel>
                <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"search-tab"} value={"ranches"}>
                    <SearchRanches config={props.config} api={props.api} token={props.token}/>
                </TabPanel>
                <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"search-tab"} value={"players"}>
                    <SearchUsers config={props.config} api={props.api} token={props.token}/>
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default Search