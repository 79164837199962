import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import {useParams} from "react-router-dom";
import axios from "axios";

const Confirmation = (props) => {
    const { email } = useParams();
    const { token } = useParams();
    
    const handleSubmitForm = () => {
        const params = {
            token: token,
            email: email
        }
        axios.post('api/Account/verifyEmail', params, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            setMessage(error.response.data);
        })
        handleOpenMessage();
    }

    const [messageOpen, setMessageOpen] = useState(false);
    const [message, setMessage] = useState("Confirmation successful.")

    const handleOpenMessage = () => {
        setMessageOpen(true);
    }

    const handleCloseMessage = () => {
        setMessageOpen(false);
    }
    
    return (
        <div>
            <Button sx={{margin: 1, maxWidth: 70}} type={"submit"} onClick={handleSubmitForm} variant={"contained"}>Confirm Email</Button>
            <Dialog open={messageOpen} onClose={handleCloseMessage} sx={{padding: '20px'}}>
                <DialogTitle>Error</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Confirmation;