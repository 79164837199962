export const Title = [
    "Junior Champion",
    "Regional Champion",
    "Reserve Champion",
    "Grand Champion",
    "World Champion",
    "Futurity Champion",
    "Speciality Champion",
    "Breeders Cup Champion",
    "Veteran Champion"
]