import "./RanchCard.css";

import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {List, ListItem, Button} from "@mui/material";
import axios from "axios";
import {useCookies} from "react-cookie";
import {darkTheme, lightTheme} from "../../Theme";

const RanchCard = (props)=>  {
    
    const id = props.ranch.ranchId;

    const [source, setSource] = useState(`https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/ranchimages/${id}.png`);

    const handleMissingImage = () => {
        setSource("https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/defaultRanchImage.png")
    }
    
    useEffect(() => {
        setSource(`https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/ranchimages/${id}.png`)
    }, [id])
    
       
    return(
        <div className={"ranch-card"}>
            <div className={props.darkMode ? "ranch-card-image-cropper ranch-card-image-cropper-dark" : "ranch-card-image-cropper"}>
                <img className={"ranch-card-image"}
                     src={source}
                     alt={props.ranch.name}
                     onError={handleMissingImage}
                />
            </div>
            <div style={{backgroundColor: props.darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} className={props.darkMode ? "ranch-card-name-container ranch-card-name-container-dark" : "ranch-card-name-container"}>
                <Typography sx={{color: "white", fontWeight: "bold", fontSize: "16px"}}>{props.ranch.name}</Typography>
            </div>
        </div>
    )
}

export default RanchCard;