import "./LeaseCard.css";

import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {
    Dialog,
    DialogActions, DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../../../enums/Breeds";
import Photo from "../../../horse/photo/Photo";

const LeaseCard = (props) => {
    const saleId = props.horse.saleId;
    
    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {params: {id: props.ranchId},
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                console.log(response.data);
                setPastures(response.data);
            });
    }, []);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {params: {id: props.ranchId},
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data);
            });
    }, []);

    const [location, setLocation] = useState()

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [locations, setLocations] = useState([]);
    
    useEffect(() => {
        setLocations(pastures.concat(barns))
    }, [pastures, barns])
    
    const [errorOpen, setErrorOpen] = useState(false);
    
    const openError = () => {
        setErrorOpen(true);
    }
    
    const closeError = () => {
        setErrorOpen(false);
    }
    
    const [message, setMessage] = useState("");

    const acceptLease = async () => {
        closeAccept();

        var data = new FormData();
        data.append("horseId", props.horse.horseId);
        data.append("leaserId", props.horse.ranchId);
        data.append("leaseeId", props.ranchId);
        data.append("saleId", saleId);
        data.append("location", location);

        await axios.post('/api/sales/acceptLease', data, props.formConfig)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                openError();
            });
    }

    const [denyOpen, setDenyOpen] = useState(false);

    const openDeny = () => {
        setDenyOpen(true);
    }

    const closeDeny = () => {
        setDenyOpen(false);
    }

    const [acceptOpen, setAcceptOpen] = useState(false);

    const openAccept = () => {
        setAcceptOpen(true);
    }

    const closeAccept = () => {
        setAcceptOpen(false);
    }

    const cancelLease = async () => {
        await axios.post('/api/sales/denyLease', saleId, props.config)
            .then(function (response) {
                console.log(response);
                closeDeny();
                props.handleSetUpdated();
            }).catch(function (error) {
                console.log(error);
            });
    }

    const female = (<img className={"lease-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"} alt={"female"}/>)
    const male = (<img className={"lease-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"} alt={"male"}/>)
    const gelding = (<img className={"lease-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    return (
        <>
            <div className={"lease-card-container"}>
                <Link to={`/horses/${props.horse.horseId}`}>
                    {props.horse.gender == 0 ? female : props.horse.gelded ? gelding : male}
                    <Photo id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/>
                </Link>
                <div className={"lease-card-info-container"}>
                    <div className={"lease-card-name-container"}>
                        <Typography sx={{
                            mx: '10px',
                            textAlign: "right",
                            fontFamily: "Roboto Thin, sans-serif",
                            fontWeight: "bold",
                            fontStyle: "italic",
                            fontSize: "20px"
                        }}>{props.horse.prefix}</Typography>
                        <Typography sx={{
                            fontFamily: "Raleway, san-serif",
                            fontSize: "1.2em",
                            fontWeight: "bolder"
                        }}> {props.horse.regName}</Typography>
                    </div>
                    <Typography sx={{
                        fontFamily: "Roboto Light, san-serif",
                        fontSize: "1em"
                    }}>{(props.horse.sale.leasePrice).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumSignificantDigits: Math.trunc(Math.abs(props.horse.sale.price)).toFixed().length
                    })}</Typography>
                </div>
                <div className={"lease-card-btns-container"}>
                    <Button variant={"contained"} onClick={openDeny}>Deny</Button>
                    <Button variant={"contained"} onClick={openAccept}>Accept</Button>
                </div>
            </div>

            <Dialog open={acceptOpen} onClose={closeAccept}>
                <DialogTitle>Accept</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{pl: 4, pr: 4, display: "flex", flexFlow: "column nowrap", alignItems: "center"}}>
                    Please choose a barn or pasture.
                    <FormControl sx={{width: "85%", margin: 2}}>
                        <Select labelId={"location"} label={"Location"} size={"small"} value={location}
                                onChange={handleSetLocation}>
                            {locations && locations.map(location =>
                                <MenuItem key={location.name}
                                          value={location.pastureId ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContentText>
                <DialogActions>
                    <Button size={"small"} variant={"contained"} onClick={acceptLease}>Ok</Button>
                    <Button size={"small"} variant={"contained"} onClick={closeAccept}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{padding: 5}} open={denyOpen} onClose={closeDeny}>
                <DialogTitle>Cancel</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Would you like to cancel this lease?
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={cancelLease}>Ok</Button>
                    <Button variant={"contained"} onClick={closeDeny}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{padding: 5}} open={errorOpen} onClose={closeError}>
                <DialogTitle>Cancel</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={closeError}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LeaseCard;