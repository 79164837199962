import "./SearchHorses.css";

import {React, useEffect, useState} from "react";
import axios from "axios";
import {
    Checkbox, FormControl,
    FormControlLabel,
    Pagination, Radio, RadioGroup, TextField,
} from "@mui/material";
import HorseCard from "../../horses/HorseCard";
import {Link} from "react-router-dom";
import {Breeds} from "../../../enums/Breeds";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {Gender} from "../../../enums/Gender";

const SearchHorses = (props) => {

    const [ids, setIds] = useState([]);

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [breeds, setBreeds] = useState([]);

    const handleSetBreeds = (breed) => {
        if (breeds.includes(breed)) {
            setBreeds(breeds.filter(b => b != breed));
        } else {
            setBreeds([...breeds, breed]);
        }
    }

    const [genders, setGenders] = useState([]);

    const handleSetGenders = (gender) => {
        if (genders.includes(gender)) {
            setGenders(genders.filter(g => g !== gender));
        } else {
            setGenders([...genders, gender]);
        }
    }

    const [searchAges, setSearchAges] = useState([]);

    const handleSetSearchAges = (age) => {
        if (searchAges.includes(age)) {
            setSearchAges(searchAges.filter(a => a != age));
        } else {
            setSearchAges([...searchAges, age]);
        }
    }

    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }

    const [owner, setOwner] = useState(null);

    const handleSetOwner = (event) => {
        setOwner(event.target.value);
    }

    const [name, setName] = useState(null);

    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const [horses, setHorses] = useState([]);

    useEffect(() => {
        const searchDto = {
            name: name,
            ranch: ranch,
            owner: owner,
            genders: genders,
            breeds: breeds,
            ages: searchAges,
            pageNumber: pageNumber
        }

        const getHorses = () => {
            axios.post('/api/Searches/horses', searchDto, props.config)
                .then(response => {
                    setHorses(response.data);
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getHorses();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }

    }, [pageNumber, breeds, genders, searchAges, ranch, owner, name]);

    useEffect(() => {
        const searchDto = {
            name: name,
            ranch: ranch,
            owner: owner,
            genders: genders,
            breeds: breeds,
            ages: searchAges,
            pageNumber: pageNumber
        }

        const getHorsesIds = () => {
            axios.post('/api/Searches/horsesIds', searchDto, props.config)
                .then(response => {
                    console.log(response.data);
                    setIds(response.data);
                    localStorage.removeItem("ids");
                    localStorage.setItem("ids", response.data)
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getHorsesIds();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }

    }, [pageNumber, breeds, genders, searchAges, ranch, owner, name]);

    useEffect(() => {
        const searchDto = {
            name: name,
            ranch: ranch,
            owner: owner,
            genders: genders,
            breeds: breeds,
            ages: searchAges
        }

        const getHorses = () => {
            axios.post("api/searches/horsesTotalPages", searchDto, props.config)
                .then(response => {
                    setPagesTotal(response.data);
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getHorses();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [breeds, genders, searchAges, ranch, owner, name]);

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const ages = [
        "Suckling",
        "Weanling",
        "Mature",
        "Breeding Age"
    ]

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const filterInputStyle = {
        margin: 1,
    }

    const clearFilters = () => {
        setBreeds([]);
        setSearchAges([]);
        setGenders([]);
        setRanch("");
        setOwner("");
        setName("");
    }

    const filters = (
        <div className={"search-horses-filters-container"}>
            <div className={"search-horses-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Age</Typography>
                {ages.map((a, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={searchAges.includes(a.toLowerCase().replaceAll(/ /g, ""))}
                              onChange={() => handleSetSearchAges(a.toLowerCase().replaceAll(/ /g, ""))}
                    />} label={a}/>
                )}
            </div>
            <div className={"search-horses-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Gender</Typography>
                {Gender.map((g, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={genders.includes(g.toLowerCase())}
                              onChange={() => handleSetGenders(g.toLowerCase())}
                    />} label={g}/>
                )}
            </div>
            <div className={"search-horses-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Breed</Typography>
                <div className={"shows-filter-items-container"}>
                    {[...Breeds].sort().map((breed, i) =>
                        <FormControlLabel key={i} sx={filterItemStyle} control={
                            <Checkbox sx={filterCheckboxStyle} checked={breeds.includes(breed.replaceAll(/ /g, ""))}
                                      onChange={() => handleSetBreeds(breed.replaceAll(/ /g, ""))}
                            />} label={breed}/>
                    )}
                </div>
            </div>
            <div className={"search-horses-filter-sub-container"}>
                <FormControl sx={filterInputStyle}>
                    <TextField label={"Name"} size={"small"} value={name} onChange={handleSetName}/>
                </FormControl>
                <FormControl sx={filterInputStyle}>
                    <TextField label={"Ranch"} size={"small"} value={ranch} onChange={handleSetRanch}/>
                </FormControl>
                <FormControl sx={filterInputStyle}>
                    <TextField label={"Owner"} size={"small"} value={owner} onChange={handleSetOwner}/>
                </FormControl>
            </div>
            <div className={"search-horses-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <div className={"search-horses-container"}>
            {filtersOpen ? filters : ""}
            <div className={"search-horses-nav-container"}>
                <FilterList onClick={openFilters}/>
                <Pagination variant={"outlined"}
                            shape={"rounded"}
                            boundaryCount={2}
                            page={pageNumber}
                            count={pagesTotal}
                            onChange={handleSetPageNumber}
                />
            </div>
            <div className={"search-horses-horses-container"}>
                {horses.length == 0 ? <p>Search returned no results.</p> : ""}
                {horses.map(horse =>
                    <Link className={"horses-link"} to={`/horses/${horse.horseId}`}>
                        <HorseCard key={horse.horseId} horse={horse} id={horse.horseId} api={props.api}/>
                    </Link>
                )}
            </div>
            <Pagination
                sx={{alignSelf: "flex-end"}}
                variant={"outlined"}
                shape={"rounded"}
                boundaryCount={2}
                page={pageNumber}
                count={pagesTotal}
                onChange={handleSetPageNumber}
            />
        </div>
    )
}

export default SearchHorses;