import "./ConditionChange.css";

import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import axios from "axios";

const CurrentCondition = (props) => {
    
    const [change, setChange] = useState(0);

    useEffect(() => {
        axios.get("api/Health/ConditionChange", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setChange(response.data);
        }).catch(error => {
            console.log(error)
        })
    }, [props.id, props.updated]);
    
    return (
        <div className={props.darkMode ? "condition-change-bar-dark" : "condition-change-bar"}>
            <Typography sx={{
                fontFamily: "Roboto Light, sans-serif",
                fontSize: "14px",
                fontWeight: "bolder",
                marginLeft: "5px",
                color: "black"
            }}>Condition Change</Typography>
            <div style={{left: ((change * 10) + 50) + '%'}} className={"condition-change-indicator"}/>
            <div className={props.darkMode ? "middle-marker-dark" : "middle-marker"}></div>
            <div style={{right: "10%"}} className={"step-indicator"}/>
            <div style={{right: "20%"}} className={"step-indicator"}/>
            <div style={{right: "30%"}} className={"step-indicator"}/>
            <div style={{right: "40%"}} className={"step-indicator"}/>
            <div style={{right: "60%"}} className={"step-indicator"}/>
            <div style={{right: "70%"}} className={"step-indicator"}/>
            <div style={{right: "80%"}} className={"step-indicator"}/>
            <div style={{right: "90%"}} className={"step-indicator"}/>
        </div>
    )
}

export default CurrentCondition