import "./Banner.css";

import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {Link} from "react-router-dom";

const Banner = (props) => {

    const [bannerSource, setBannerSource] = useState(`https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/ranchbanner/${props.ranch.ranchId}.png`);

    const handleMissingBannerImage = () => {
        setBannerSource("https://media.legacyhorsegame.com/banners/ranch-banner.jpg")
    }

    useEffect(() => {
        setBannerSource(`https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/ranchbanner/${props.ranch.ranchId}.png`)
    }, [props.ranch])

    const [profileSource, setProfileSource] = useState(`https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/ranchimages/${props.ranch.ranchId}.png`);

    const handleMissingProfileImage = () => {
        setProfileSource("https://media.legacyhorsegame.com/misc/defaultRanchImage.png")
    }

    useEffect(() => {
        setProfileSource(`https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/ranchimages/${props.ranch.ranchId}.png`)
    }, [props.ranch])

    const infoTitleStyle = {
        fontWeight: "bold",
        marginRight: "5px"
    }

    const infoTextStyle = {}

    const [imageCropper, setImageCropper] = useState(document.getElementById('profile-image-cropper'));
    const [imageCropperHeight, setImageCropperHeight] = useState(0);

    const [profileInfoContainer, setProfileInfoContainer] = useState(document.getElementById('profile-info-container'));
    const [profileInfoContainerHeight, setProfileInfoContainerHeight] = useState(0);
    
    const [bannerHeight, setBannerHeight] = useState(0);

    useEffect(() => {
        setImageCropper(document.getElementById('profile-image-cropper'));
        setProfileInfoContainer(document.getElementById('profile-info-container'));
    }, []);

    useEffect(() => {
        if(imageCropper){
            setImageCropperHeight(imageCropper.offsetHeight)
        }
        if(profileInfoContainer){
            setProfileInfoContainerHeight(profileInfoContainer.offsetHeight)
        }
    }, [imageCropper, profileInfoContainer]);

    useEffect(() => {
        const newBannerHeight = profileInfoContainerHeight + imageCropperHeight + 120;
        setBannerHeight(newBannerHeight);
    }, [imageCropperHeight, profileInfoContainerHeight]);

    return (
        <div style={{height: `${bannerHeight}px`}} className={"banner-container"}>
            <div className={"banner-profile-image-cropper"} id={"profile-image-cropper"}>
                <img className={"banner-profile-image"}
                     src={profileSource}
                     alt={props.ranch.name}
                     onError={handleMissingProfileImage}
                />
            </div>
            <div className={"banner-ranch-info-container"} id={"profile-info-container"}>
                <Typography sx={{textAlign: "center"}} variant={"h3"}>{props.ranch.name}</Typography>
                <Typography sx={{
                    fontFamily: "Roboto Thin, san-serif",
                    fontStyle: "italic",
                    paddingBottom: "5px",
                    fontSize: "18px"
                }}>#{props.ranch.ranchId}</Typography>
                <div className={"banner-ranch-info-item-container"}>
                    <Typography sx={infoTitleStyle}>Created On:</Typography>
                    <Typography>{new Date(props.ranch.creationDate).toLocaleDateString('en-US', {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                    })}</Typography>
                </div>
                <div className={"banner-ranch-info-item-container"}>
                    <Typography sx={infoTitleStyle}>Owner:</Typography>
                    <Link to={`/users/${props.ranch.owner}`}>{props.ranch.owner}</Link>
                </div>
                <div className={"banner-ranch-info-item-container"}>
                    <Typography sx={infoTitleStyle}>Horses Owned:</Typography>
                    <Typography>{props.ranch.horsesOwned}</Typography>
                </div>
                <div className={"banner-ranch-info-item-container"}>
                    <Typography sx={infoTitleStyle}>Horses Bred:</Typography>
                    <Typography>{props.ranch.horsesBred}</Typography>
                </div>
            </div>
            <img className={"banner-background-image"}
                 src={bannerSource}
                 alt={props.ranch.name}
                 onError={handleMissingBannerImage}
            />
        </div>
    )
}

export default Banner