import "./Ranches.css";

import React from "react";
import RanchesInfo from "./RanchesInfo";
import RanchManagement from "./RanchManagement";

const Ranches = (props) => {
    
    return (
        <div className={"ranches-management-container"}>
            <RanchesInfo ranches={props.ranches} darkMode={props.darkMode}/>
            <RanchManagement ranches={props.ranches} darkMode={props.darkMode} config={props.config}/>
        </div>
    )
}

export default Ranches