import React, {useEffect, useState} from 'react';

import "./HorsePhoto.css";

import axios from "axios";

function HorsePhoto(props) {

    const id = props.id;

    const [horse, setHorse] = useState(null);

    useEffect(() => {
        axios.get('/api/Horses/artwork', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorse(response.data);
            });
    }, [id]);

    return (
        <>
            {horse && <div className={"photo-container"}>
                {horse.isLeased ?
                    <img className={"for-sale-img"} src={"https://media.legacyhorsegame.com/misc/onleasebanner.png"}
                         alt={"for lease"}/> : ""}
                {horse.isForSale ? <img className={"for-sale-img"}
                                        src={horse.sale.isAuctioned ? "https://media.legacyhorsegame.com/misc/forauctionbanner.png" :
                                            horse.sale.isForSale ? "https://media.legacyhorsegame.com/misc/forsalebanner.png" : ""}
                                        alt={"for sale"}/> : ""}
                {horse.pregnant && horse.pregChecked && !horse.isNursing ?
                    <img className={"pregnant"} src={"https://media.legacyhorsegame.com/misc/pregnancy.png"}
                         alt={"pregnant"}/> : ""}
                {horse.pregnant && horse.pregChecked && horse.isNursing ?
                    <img className={"nursing"} src={"https://media.legacyhorsegame.com/misc/pregnantnursing.png"}
                         alt={"pregnant and nursing"}/> : horse.isNursing ?
                        <img className={"nursing"} src={"https://media.legacyhorsegame.com/misc/nursing.png"}
                             alt={"pregnant and nursing"}/> : ""}
                {!horse.pregnant && horse.isNursing ?
                    <img className={"nursing"} src={"https://media.legacyhorsegame.com/misc/nursing.png"}
                         alt={"pregnant and nursing"}/> : ""}
                {horse.isFoal &&
                    <img className={"pregnant"} src={"https://media.legacyhorsegame.com/misc/foal.png"} alt={"foal"}/>}
                {horse.isWeanling && <img className={"pregnant"} src={"https://media.legacyhorsegame.com/misc/weanling.png"}
                                          alt={"weanling"}/>}
                {horse.head &&
                    <img className={"head"} src={`https://media.legacyhorsegame.com/${horse.head}`} alt={""}/>}
                {horse.backTop &&
                    <img className={"back-top"} src={`https://media.legacyhorsegame.com/${horse.backTop}`} alt={""}/>}
                {horse.backBottom &&
                    <img className={"back-bottom"} src={`https://media.legacyhorsegame.com/${horse.backBottom}`}
                         alt={""}/>}
                {horse.leopardMane && <img className={"appy-mane"}
                                           src={`https://media.legacyhorsegame.com/maneappy/${horse.maneTail}/${horse.leopardMane.toLowerCase()}`}
                                           alt={""}/>}
                {horse.leopardTail && <img className={"appy-tail"}
                                           src={`https://media.legacyhorsegame.com/tailappy/${horse.leopardTail.toLowerCase()}`}
                                           alt={""}/>}
                {horse.patternMane && <img className={"mane-white"}
                                           src={`https://media.legacyhorsegame.com/manepatterns/${horse.maneTail}/${horse.patternMane.substr(9).toLowerCase()}.png`}
                                           alt={""}/>}
                {horse.mane && <img className={"mane"}
                                    src={`https://media.legacyhorsegame.com/mane/${horse.maneTail}/${
                                        horse.mane.toString().toLowerCase() + ".png"}`}
                                    alt={""}/>}
                {horse.leopard && <img className={"leopard"}
                                       src={`https://media.legacyhorsegame.com/leopard/${horse.leopard.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.pattern && <img className={"pinto"}
                                       src={`https://media.legacyhorsegame.com/${horse.pattern.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.roaning && <img className={"roan"}
                                       src={`https://media.legacyhorsegame.com/${horse.roaning.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.leftFore && <img className={"left-fore"}
                                        src={`https://media.legacyhorsegame.com/markings/${horse.leftFore.toLowerCase()}.png`}
                                        alt={""}/>}
                {horse.rightFore && <img className={"right-fore"}
                                         src={`https://media.legacyhorsegame.com/markings/${horse.rightFore.toLowerCase()}.png`}
                                         alt={""}/>}
                {horse.leftHind && <img className={"left-hind"}
                                        src={`https://media.legacyhorsegame.com/markings/${horse.leftHind.toLowerCase()}.png`}
                                        alt={""}/>}
                {horse.rightHind && <img className={"right-hind"}
                                         src={`https://media.legacyhorsegame.com/markings/${horse.rightHind.toLowerCase()}.png`}
                                         alt={""}/>}
                {horse.nose && <img className={"nose"}
                                    src={`https://media.legacyhorsegame.com/markings/${horse.nose.toLowerCase()}.png`}
                                    alt={""}/>}
                {horse.forehead && <img className={"forehead"}
                                        src={`https://media.legacyhorsegame.com/markings/${horse.forehead.toLowerCase()}.png`}
                                        alt={""}/>}
                {horse.dapples && <img className={"dapples"}
                                       src={`https://media.legacyhorsegame.com/${horse.dapples.toLowerCase()}`}
                                       alt={""}/>}
                {horse.sooty && <img className={"sooty"}
                                     src={`https://media.legacyhorsegame.com/${horse.sooty.toLowerCase()}`}
                                     alt={""}/>}
                {horse.pangare && <img className={"pangare"}
                                       src={`https://media.legacyhorsegame.com/${horse.pangare.toLowerCase()}.png`}
                                       alt={""}/>}
                {horse.color && <img className={"base"}
                                     src={`https://media.legacyhorsegame.com/${horse.color.toLowerCase()}.png`}
                                     alt={""}/>}
                <img className={"background"} src={`https://media.legacyhorsegame.com/${horse.background}`}
                     alt={"background"}/>
            </div>}
        </>
    )
}

export default HorsePhoto;