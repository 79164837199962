import "./Injuries.css"

import React, {useState} from "react";
import axios from "axios";
import Injury from "./Injury";
import {Dialog, DialogActions, DialogContentText, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";

const Injuries = (props) => {
    
    const [message, setMessage] = useState("");
    
    const treatHorse = (injury) => {
        const data = new FormData();
        data.append("id", props.id);
        data.append("injury", injury)
        axios.post("/api/health/treat", data, props.formConfig)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
                setMessage(response.data);
                handleOpen();
            }).catch(function (error) {
            console.log(error);
            setMessage(error.response.data);
            handleOpen();
        });
    }

    const callVet = (injury) => {
        const data = new FormData();
        data.append("id", props.id);
        data.append("injury", injury)
        axios.post("/api/health/callVet", data, props.formConfig)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
                setMessage(response.data);
                handleOpen();
            }).catch(function (error) {
            console.log(error);
            setMessage(error.response.data);
            handleOpen();
        });
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div className={props.darkMode ? "injuries-container-dark" : "injuries-container"}>
            <div className={"injuries-title-container"}>
                <Typography variant={"h3"}>Injuries</Typography>
            </div>
            {props.injuries && props.injuries.cut ? <Injury id={props.id} title={"Cut"} treated={props.injuries.cutTreated || props.injuries.cutVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.cutTime}/> : ""}
            {props.injuries && props.injuries.biteWound ? <Injury id={props.id} title={"Bite Wound"} treated={props.injuries.biteWoundTreated || props.injuries.biteWoundVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.biteWoundTime}/> : ""}
            {props.injuries && props.injuries.soreness ? <Injury id={props.id} title={"Muscle Soreness"} treated={props.injuries.sorenessTreated || props.injuries.sorenessVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.sorenessTime}/> : ""}
            {props.injuries && props.injuries.muscleInflammation ? <Injury id={props.id} title={"Muscle Inflammation"} treated={props.injuries.muscleInflammationTreated || props.injuries.muscleInflammationVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.muscleInflammationTime}/> : ""}
            {props.injuries && props.injuries.ligamentInflammation ? <Injury id={props.id} title={"Ligament Inflammation"} treated={props.injuries.ligamentInflammationTreated || props.injuries.ligamentInflammationVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.ligamentInflammationTime}/> : ""}
            {props.injuries && props.injuries.hoofSoleBruise ? <Injury id={props.id} title={"Hoof Sole Bruise"} treated={props.injuries.hoofSoleBruiseTreated || props.injuries.hoofSoleBruiseVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.hoofSoleBruiseTime}/> : ""}
            {props.injuries && props.injuries.ligamentTear ? <Injury id={props.id} title={"Ligament Tear"} treated={props.injuries.ligamentTearTreated || props.injuries.ligamentTearVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.ligamentTearTime}/> : ""}
            {props.injuries && props.injuries.scapulaFracture ? <Injury id={props.id} title={"Scapula Hairline Fracture"} treated={props.injuries.scapularFractureTreated || props.injuries.scapulaFractureVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.scapulaFractureTime}/> : ""}
            {props.injuries && props.injuries.severeConfLaminitis ? <Injury id={props.id} title={"Severe Conformational Laminitis"} treated={props.injuries.severeConfLaminitisTreated || props.injuries.severeConfLaminitisVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.severeConfLaminitisTime}/> : props.injuries && props.injuries.modConfLaminitis ?
                <Injury id={props.id} title={"Moderate Conformational Laminitis"} treated={props.injuries.modConfLaminitisTreated || props.injuries.modConfLaminitisVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.modConfLaminitisTimes}/> : ""}
            {props.injuries && props.injuries.modBackPain ? <Injury id={props.id} title={"Moderate Back Pain"} treated={props.injuries.modBackPainTreated || props.injuries.modBackPainVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.modBackPainTime}/> : props.injuries && props.injuries.minorBackPain ?
                <Injury id={props.id} title={"Minor Back Pain"} treated={props.injuries.minorBackPainTreated || props.injuries.minorBackPainVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.minorBackPainTime}/> : ""}
            {props.injuries && props.injuries.canonFracture ? <Injury id={props.id} title={"Canon Bone Fracture"} treated={props.injuries.canonFractureTreated || props.injuries.canonFractureVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.canonFractureTime}/> : ""}
            {props.injuries && props.injuries.modKneeInflammation ? <Injury id={props.id} title={"Moderate Knee Inflammation"} treated={props.injuries.modKneeInflammationTreated || props.injuries.modKneeInflammationVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.modKneeInflammationTime}/> : props.injuries &&  props.injuries.minorKneeInflammation ?
                <Injury id={props.id} title={"Minor Knee Inflammation"} treated={props.injuries.minorKneeInflammationTreated || props.injuries.minorKneeInflammationVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.minorKneeInflammationTime}/> : ""}
            {props.injuries && props.injuries.modHockInflammation ? <Injury id={props.id} title={"Moderate Hock Inflammation"} treated={props.injuries.modHockInflammationTreated || props.injuries.modHockInflammationVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.modHockInflammationTime}/> : props.injuries &&  props.injuries.minorHockInflammation ?
                <Injury id={props.id} title={"Minor Hock Inflammation"} treated={props.injuries.minorHockInflammationTreated || props.injuries.minorHockInflammationVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.minorHockInflammationTime}/> : ""}
            {props.injuries && props.injuries.heelBruise ? <Injury id={props.id} title={"Heel Cut or Bruise"} treated={props.injuries.heelBruiseTreated || props.injuries.heelBruiseVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.heelBruiseTime}/> : ""}
            {props.injuries && props.injuries.stressFracture ? <Injury id={props.id} title={"Stress Fracture"} treated={props.injuries.stressFractureTreated || props.injuries.stressFractureVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.stressFractureTime}/> : ""}
            {props.injuries && props.injuries.bowedTendon ? <Injury id={props.id} title={"Bowed Tendon"} treated={props.injuries.bowedTendonTreated || props.injuries.bowedTendonVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} severe={true} currentDate={props.date} timePassed={props.injuries.bowedTendonTime}/> : ""}
            {props.injuries && props.injuries.modProxSuspDesmitis ? <Injury id={props.id} title={"Moderate Proximal Suspensory Desmitis"} treated={props.injuries.modProxSuspDesmitisTreated || props.injuries.modProxSuspDesmitisVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.modProxSuspDesmitisTime}/> : props.injuries &&  props.injuries.minorProxSuspDesmitis ?
                <Injury id={props.id} title={"Minor Proximal Suspensory Desmitis"} treated={props.injuries.minorProxSuspDesmitisTreated || props.injuries.minorProxSuspDesmitisVetCalled} treatHorse={treatHorse} callVet={callVet} isOwner={props.isOwner} currentDate={props.date} timePassed={props.injuries.minorProxSuspDesmitis}/> : ""}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Injury Treated</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Injuries;