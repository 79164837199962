import "./SemenCollection.css";

import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    Slider, Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";

const SemenCollection = (props) => {

    const [message, setMessage] = useState();
    const [collections, setCollections] = useState(0);
    
    const handleSetCollections = (event, value) => {
        setCollections(value);
    }

    const handleCollectSemen = async () => {
        var formData = new FormData();
        formData.append("collections", collections);
        formData.append("studId", props.id);

        await axios.post('/api/Health/collectSemen', formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                setMessage(response.data);
                props.handleSetUpdated();
                handleOpen();
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
            });
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    
    return (
       <>
           <div className={"semen-collection-container"}>
               <div className={"semen-collection-slider-container"}>
                   <Typography sx={{marginBottom: "-10px"}}>Semen Collections</Typography>
                   <FormControl sx={{width: "100%"}}>
                       <Slider
                           valueLabelDisplay={"auto"}
                           onChange={handleSetCollections}
                           marks={true}
                           defaultValue={0}
                           min={0}
                           max={props.available}
                           disabled={props.disabled}
                       />
                   </FormControl>
               </div>
               <Button onClick={handleCollectSemen} variant={"contained"}
                       disabled={props.disabled}>Collect</Button>
           </div>
           <Dialog open={open} onClose={handleClose}>
               <DialogTitle>Message</DialogTitle>
               <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                   {message}
               </DialogContentText>
               <DialogActions>
                   <Button onClick={handleClose} variant={"contained"}>Ok</Button>
               </DialogActions>
           </Dialog>
       </>
    )
}

export default SemenCollection;