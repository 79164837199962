import "./SearchUsers.css";

import {React, useEffect, useState} from "react";
import axios from "axios";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    TextField
} from "@mui/material";
import User from "./User";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Breeds} from "../../../enums/Breeds";
import Typography from "@mui/material/Typography";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";

const SearchUsers = (props) => {

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [breeds, setBreeds] = useState([]);

    const handleSetBreeds = (breed) => {
        if (breeds.includes(breed)) {
            setBreeds(breeds.filter(b => b != breed));
        } else {
            setBreeds([...breeds, breed]);
        }
    }

    const [owner, setOwner] = useState(null);

    const handleSetOwner = (event) => {
        setOwner(event.target.value);
    }
    
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const searchDto = {
            name: owner,
            breeds: breeds,
            pageNumber: pageNumber
        }
        const getUsers = () => {
            axios.post("api/searches/players", searchDto, props.config)
                .then(response => {
                    setUsers(response.data);
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getUsers();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [pageNumber, breeds, owner]);

    useEffect(() => {
        const searchDto = {
            name: owner,
            breeds: breeds,
            pageNumber: pageNumber
        }
        const getPages = () => {
            axios.post("api/searches/playersTotalPages", searchDto, props.config)
                .then(response => {
                    setPagesTotal(response.data);
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getPages();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [pageNumber, breeds, owner]);

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const filterInputStyle = {
        margin: 1,
    }

    const clearFilters = () => {
        setBreeds([]);
        setOwner("");
    }

    const filters = (
        <div className={"search-horses-filters-container"}>
            <div className={"search-horses-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Breed</Typography>
                <div className={"shows-filter-items-container"}>
                    {[...Breeds].sort().map((breed, i) =>
                        <FormControlLabel key={i} sx={filterItemStyle} control={
                            <Checkbox sx={filterCheckboxStyle} checked={breeds.includes(breed.replaceAll(/ /g, ""))}
                                      onChange={() => handleSetBreeds(breed.replaceAll(/ /g, ""))}
                            />} label={breed}/>
                    )}
                </div>
            </div>
            <FormControl sx={filterInputStyle}>
                <TextField label={"Name"} size={"small"} value={owner} onChange={handleSetOwner}/>
            </FormControl>
            <div className={"search-horses-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )
    
    return(
        <div>
            {filtersOpen ? filters : ""}
            <div className={"ranch-search-controls-container"}>
                <FilterList onClick={openFilters}/>
                <Pagination variant={"outlined"}
                            shape={"rounded"}
                            boundaryCount={2}
                            page={pageNumber}
                            count={pagesTotal}
                            onChange={handleSetPageNumber}
                />
            </div>
            <div className={"users-container"}>
                {users.length == 0 ? <p>Search returned no results.</p> : ""}
                {users && users.map(user =>
                    <Link className={"link"} to={`/users/${user.displayName}`}>
                        <User key={user.userName} user={user}/>
                    </Link>
                )}
            </div>
            <Pagination variant={"outlined"}
                        shape={"rounded"}
                        boundaryCount={2}
                        page={pageNumber}
                        count={pagesTotal}
                        onChange={handleSetPageNumber}
            />
        </div>
    )
}

export default SearchUsers;