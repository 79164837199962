import './DividerTitle.css';

import React from "react";
import {Typography} from "@mui/material";

const DividerTitle = (props) => {
    
    return (
        <div style={props.darkMode ? {backgroundColor: "#0C5757"} : {}} className={"divider"}>
            <Typography sx={{fontSize: "1.2em"}} variant={"h3"}>{props.title}</Typography>
        </div>
    )
}

export default DividerTitle;