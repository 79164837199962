import "./TrainingSection.css";
import {React, useEffect, useState} from "react";
import Task from "./Task";
import {Button, Switch} from "@mui/material";
import axios from "axios";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const TrainingSection = (props) => {
    const id = props.id;
    
    const[toggle, setToggle] = useState(false);
    
    useEffect(() => {
        setToggle(props.toggle);
    }, [props])

    const handleSetToggle = async () => {
        setToggle(!toggle);
        await axios.post('/api/Training/toggle' + props.discipline, id, props.config)
            .then(function (response) {
                console.log(response);
            }).catch(function (error) {
                console.log(error);
            });
    };
    
    const handleTrain = (task) => {
        props.handleTrain(task);
    }
    
    const handleRest = () => {
        props.handleRest(props.id);
    }

    const [open, setOpen] = useState(false);
    const handleSetOpen = () => {
        setOpen(!open);
    }
    
    return (
        <div style={{width: "100%"}}>
            <div className={"title-section"} style={!open ? {justifyContent: "flex-start"} : {}}>
                <div style={open ? {display: "flex", flexFlow: "row wrap", alignItems: "flex-start"} : {display: "flex", flexFlow: "row wrap", alignItems: "center"}}>
                    {!open && <ExpandMore onClick={handleSetOpen}/>}
                    {open && <ExpandLess onClick={handleSetOpen}/>}
                    <p className={"training-title"} onClick={handleSetOpen}>{props.title}</p>
                    {props.isOwner && <Switch checked={toggle} onChange={handleSetToggle} disabled={props.mini || (props.horse.sale.isLeased && !props.horse.sale.allowTraining)}/>}
                </div>
                {props.isOwner && open && <div className={"rest-section"}>
                    <p className={"total-sessions"}>Sessions remaining: {props.totalSessions}</p>
                </div>}
            </div>
            {open && <div className={"training-section"}>
                {props.tasks.map(t =>
                    <div key={t.id} className={"training-task"}>
                        <Task key={t.id} title={t.title} stat={t.stat} darkMode={props.darkMode}/>
                        {props.isOwner && <Button variant={"contained"} sx={{height: 30, minWidth: 75}}
                                            onClick={handleTrain.bind(this, t.title.replaceAll(" ", "").replaceAll("'", ""))}
                                            disabled={t.underAge || props.isInjured || t.mini || t.stat === 100 || props.pace(t.title) || props.amble(t.title) || (props.horse.sale.isLeased && !props.horse.sale.allowTraining)}>Train</Button>}
                    </div>
                )}
            </div>}
        </div>
    )
}

export default TrainingSection;