import "./Training.css";
import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import TrainingSection from "./TrainingSection";
import {useCookies} from "react-cookie";
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import Exercising from "./Exercising";

const Training = (props) => {
    const id = props.id;

    let underTwo;
    let mini;
    const [horse, setHorse] = useState(null);
    const [groundwork, setGroundwork] = useState([]);
    const [underSaddle, setUnderSaddle] = useState([]);
    const [barrelRacing, setBarrelRacing] = useState([]);
    const [cutting, setCutting] = useState([]);
    const [crossCountry, setCrossCountry] = useState([]);
    const [dressage, setDressage] = useState([]);
    const [endurance, setEndurance] = useState([]);
    const [flatRacing, setFlatRacing] = useState([]);
    const [gaits, setGaits] = useState([]);
    const [inHandJumping, setInHandJumping] = useState([]);
    const [pulling, setPulling] = useState([]);
    const [reining, setReining] = useState([]);
    const [saddleJumping, setSaddleJumping] = useState([]);
    const [trotRacing, setTrotRacing] = useState([]);

    const [trained, setTrained] = useState(0);

    const handleTrain = async (task) => {
        await axios.post('/api/Training/train' + task, id, props.config)
            .then(function (response) {
                console.log(response);
                setTrained(trained + 1);
            }).catch(function (error) {
                console.log(error);
            });
    }

    const handleExercise = async (endpoint) => {
        await axios.post('/api/Training/' + endpoint, props.id, props.config)
            .then(function (response) {
                console.log(response);
                setTrained(trained + 1);
            }).catch(function (error) {
                console.log(error);
            });
    };

    const [isInjured, setIsInjured] = useState();

    useEffect(() => {
        axios.get('api/health/isInjured', {
            params: {
                id: id
            }, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                if (response.data) {
                    setIsInjured(true);
                    if (horse.ranchId == props.ranchId) {
                        handleOpen();
                    }
                } else {
                    setIsInjured(false);
                }
            })
    }, [id, trained, horse])


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [gaited, setGaited] = useState(3);

    const pace = (task) => {
        if (task == "Pace") {
            console.log(gaited)
            return gaited < 4;
        }
        return false;
    }

    const amble = (task) => {
        if (task == "Amble") {
            return gaited < 5;
        }
        return false;
    }

    useEffect(() => {
        axios.get('/api/Training', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorse(response.data);
                underTwo = response.data.age < 24;
                //underTwo = false;
                setGaited(response.data.gaited.finalValue);
                mini = response.data.breed == 1 || response.data.breed == 2 || response.data.breed == 3;
                setGroundwork([
                    {
                        id: 1,
                        title: "Desensitize",
                        stat: response.data.training.groundWork.desensitize,
                        underAge: false,
                        mini: false
                    },
                    {
                        id: 2,
                        title: "Halter",
                        stat: response.data.training.groundWork.halter,
                        underAge: false,
                        mini: false
                    },
                    {
                        id: 3,
                        title: "Lead",
                        stat: response.data.training.groundWork.lead,
                        underAge: false,
                        mini: false
                    },
                    {
                        id: 4,
                        title: "Stand",
                        stat: response.data.training.groundWork.stand,
                        underAge: false,
                        mini: false
                    },
                    {
                        id: 5,
                        title: "Tack",
                        stat: response.data.training.groundWork.tack,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Ground Drive",
                        stat: response.data.training.groundWork.groundDrive,
                        underAge: underTwo,
                        mini: mini
                    }
                ])
                setUnderSaddle([
                    {
                        id: 1,
                        title: "Walk Out",
                        stat: response.data.training.underSaddle.walkOut,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Trot",
                        stat: response.data.training.underSaddle.trot,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Canter",
                        stat: response.data.training.underSaddle.canter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Stop",
                        stat: response.data.training.underSaddle.stop,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Back Up",
                        stat: response.data.training.underSaddle.backUp,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Transitions",
                        stat: response.data.training.underSaddle.transitions,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 7,
                        title: "Leg Yield",
                        stat: response.data.training.underSaddle.legYield,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 8,
                        title: "Turn Right",
                        stat: response.data.training.underSaddle.turnRight,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 9,
                        title: "Turn Left",
                        stat: response.data.training.underSaddle.turnLeft,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 10,
                        title: "Vertical Flex",
                        stat: response.data.training.underSaddle.vertFlex,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 11,
                        title: "Lateral Flex",
                        stat: response.data.training.underSaddle.latFlex,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 12,
                        title: "Collected Walk",
                        stat: response.data.training.underSaddle.collectedWalk,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 13,
                        title: "Collected Trot",
                        stat: response.data.training.underSaddle.collectedTrot,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 14,
                        title: "Lead Changes",
                        stat: response.data.training.underSaddle.leadChanges,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 15,
                        title: "Neck Reining",
                        stat: response.data.training.underSaddle.neckReining,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 16,
                        title: "Side Passing",
                        stat: response.data.training.underSaddle.sidePassing,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setBarrelRacing([
                    {
                        id: 1,
                        title: "Rate Barrels",
                        stat: response.data.training.barrelRacing.rateBarrels,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Walk Pattern",
                        stat: response.data.training.barrelRacing.walkPattern,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Trot Pattern",
                        stat: response.data.training.barrelRacing.trotPattern,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Lope Pattern",
                        stat: response.data.training.barrelRacing.lopePattern,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Gallop Pattern",
                        stat: response.data.training.barrelRacing.gallopPattern,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Pocket",
                        stat: response.data.training.barrelRacing.pocket,
                        underAge: underTwo,
                        mini: mini
                    }
                ])
                setCrossCountry([
                    {
                        id: 1,
                        title: "Key Hole",
                        stat: response.data.training.crossCountry.keyHole,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Weldon's Wall",
                        stat: response.data.training.crossCountry.weldonsWall,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Coffin",
                        stat: response.data.training.crossCountry.coffin,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Banks",
                        stat: response.data.training.crossCountry.banks,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Sunken Road",
                        stat: response.data.training.crossCountry.sunkenRoad,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Normandy Bank",
                        stat: response.data.training.crossCountry.normandyBank,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 7,
                        title: "Tiger Trap",
                        stat: response.data.training.crossCountry.tigerTrap,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 8,
                        title: "Chevron",
                        stat: response.data.training.crossCountry.chevron,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 9,
                        title: "Corner",
                        stat: response.data.training.crossCountry.corner,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 10,
                        title: "Trakehner",
                        stat: response.data.training.crossCountry.trakehner,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setCutting([
                    {
                        id: 1,
                        title: "Flag Work",
                        stat: response.data.training.cutting.flagWork,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Introduce Cows",
                        stat: response.data.training.cutting.introduceCows,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Trailing Cow",
                        stat: response.data.training.cutting.trailingCow,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Tracking Cow",
                        stat: response.data.training.cutting.trackingCow,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Work Single Cow",
                        stat: response.data.training.cutting.singleCow,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Work Herd",
                        stat: response.data.training.cutting.workHerd,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setDressage([
                    {
                        id: 1,
                        title: "Free Walk",
                        stat: response.data.training.dressage.freeWalk,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Halt",
                        stat: response.data.training.dressage.halt,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Working Canter",
                        stat: response.data.training.dressage.workingCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Lengthened Trot",
                        stat: response.data.training.dressage.lengthenedTrot,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Lengthened Canter",
                        stat: response.data.training.dressage.lengthenedCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Medium Trot",
                        stat: response.data.training.dressage.mediumTrot,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 7,
                        title: "Medium Canter",
                        stat: response.data.training.dressage.mediumCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 8,
                        title: "Shoulder In",
                        stat: response.data.training.dressage.shoulderIn,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 9,
                        title: "Rein Back",
                        stat: response.data.training.dressage.reinBack,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 10,
                        title: "Extended Walk",
                        stat: response.data.training.dressage.extendedWalk,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 11,
                        title: "Extended Trot",
                        stat: response.data.training.dressage.extendedTrot,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 12,
                        title: "Extended Canter",
                        stat: response.data.training.dressage.extendedCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 13,
                        title: "Turn On Haunches",
                        stat: response.data.training.dressage.turnOnHaunches,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 14,
                        title: "Travers",
                        stat: response.data.training.dressage.travers,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 15,
                        title: "Counter Canter",
                        stat: response.data.training.dressage.counterCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 16,
                        title: "Renvers",
                        stat: response.data.training.dressage.renvers,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 17,
                        title: "Flying Lead Changes",
                        stat: response.data.training.dressage.flyingLeadChanges,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 18,
                        title: "Half Passes",
                        stat: response.data.training.dressage.halfPasses,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 19,
                        title: "Collected Canter",
                        stat: response.data.training.dressage.collectedCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 20,
                        title: "Pirouettes",
                        stat: response.data.training.dressage.pirouettes,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 21,
                        title: "Tempi Changes",
                        stat: response.data.training.dressage.tempiChanges,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 22,
                        title: "Passage",
                        stat: response.data.training.dressage.passage,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 23,
                        title: "Piaffe",
                        stat: response.data.training.dressage.piaffe,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setEndurance([
                    {
                        id: 1,
                        title: "Endurance Pacing",
                        stat: response.data.training.endurance.pacing,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Walk Trot Short Distances",
                        stat: response.data.training.endurance.walkTrotShortDist,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Walk Trot Medium Distances",
                        stat: response.data.training.endurance.walkTrotMedDist,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Walk Trot Long Distances",
                        stat: response.data.training.endurance.walkTrotLongDist,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Hill Work",
                        stat: response.data.training.endurance.hillWork,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Rough Terrain",
                        stat: response.data.training.endurance.roughTerrain,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setFlatRacing([
                    {
                        id: 1,
                        title: "Introduce Gate",
                        stat: response.data.training.flatRacing.introFlatGate,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Breaking From Gate",
                        stat: response.data.training.flatRacing.flatBreaking,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Work With Other Horses",
                        stat: response.data.training.flatRacing.workWithHorses,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setGaits([
                    {
                        id: 1,
                        title: "Improve Walk Form",
                        stat: response.data.training.gaits.improveWalk,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 1,
                        title: "Improve Trot Form",
                        stat: response.data.training.gaits.improveTrot,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 1,
                        title: "Work in stretches",
                        stat: response.data.training.gaits.workStretches,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 1,
                        title: "Improve Canter Form",
                        stat: response.data.training.gaits.improveCanter,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 1,
                        title: "Pace",
                        stat: response.data.training.gaits.pace,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 1,
                        title: "Amble",
                        stat: response.data.training.gaits.amble,
                        underAge: underTwo,
                        mini: mini
                    },
                ]);
                setSaddleJumping([
                    {
                        id: 1,
                        title: "Walk Poles",
                        stat: response.data.training.saddleJumping.walkPoles,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Trot Poles",
                        stat: response.data.training.saddleJumping.trotPoles,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Trot Cross Rails",
                        stat: response.data.training.saddleJumping.trotCrossRail,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Canter Cross Rails",
                        stat: response.data.training.saddleJumping.canterCrossRail,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 5,
                        title: "Canter Low Jumps",
                        stat: response.data.training.saddleJumping.canterLowJumps,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 6,
                        title: "Canter Medium Jumps",
                        stat: response.data.training.saddleJumping.canterMedJumps,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 7,
                        title: "Canter High Jumps",
                        stat: response.data.training.saddleJumping.canterHighJumps,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 8,
                        title: "Introduce Course",
                        stat: response.data.training.saddleJumping.introduceCourse,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 9,
                        title: "Pacing",
                        stat: response.data.training.saddleJumping.pacing,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setPulling([
                    {
                        id: 1,
                        title: "Introduce Pulling Harness",
                        stat: response.data.training.pulling.introPullingHarness,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 2,
                        title: "Introduce Hitch",
                        stat: response.data.training.pulling.hitch,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 3,
                        title: "Train Cues",
                        stat: response.data.training.pulling.trainCues,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 4,
                        title: "Pull Light Weight",
                        stat: response.data.training.pulling.pullLightWeight,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 5,
                        title: "Pull Medium Weight",
                        stat: response.data.training.pulling.pullMedWeight,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 6,
                        title: "Pull Heavy Weight",
                        stat: response.data.training.pulling.pullHeavyWeight,
                        underAge: underTwo,
                        mini: false
                    },
                ])
                setReining([
                    {
                        id: 1,
                        title: "Sliding Stop",
                        stat: response.data.training.reining.slidingStop,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 2,
                        title: "Spin",
                        stat: response.data.training.reining.spin,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 3,
                        title: "Large Circles",
                        stat: response.data.training.reining.largeCircles,
                        underAge: underTwo,
                        mini: mini
                    },
                    {
                        id: 4,
                        title: "Small Circles",
                        stat: response.data.training.reining.smallCircles,
                        underAge: underTwo,
                        mini: mini
                    },
                ])
                setInHandJumping([
                    {
                        id: 1,
                        title: "In Hand Walk Poles",
                        stat: response.data.training.inHandJumping.walkPoles,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 2,
                        title: "In Hand Trot Poles",
                        stat: response.data.training.inHandJumping.trotPoles,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 3,
                        title: "In Hand Trot Cross Rail",
                        stat: response.data.training.inHandJumping.trotCrossRail,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 4,
                        title: "In Hand Canter Cross Rail",
                        stat: response.data.training.inHandJumping.canterCrossRail,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 5,
                        title: "In Hand Canter Low Jumps",
                        stat: response.data.training.inHandJumping.canterLowJumps,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 6,
                        title: "In Hand Canter Medium Jumps",
                        stat: response.data.training.inHandJumping.canterMedJumps,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 7,
                        title: "In Hand Canter High Jumps",
                        stat: response.data.training.inHandJumping.canterHighJumps,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 8,
                        title: "In Hand Introduce Course",
                        stat: response.data.training.inHandJumping.introduceCourse,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 9,
                        title: "In Hand Pacing",
                        stat: response.data.training.inHandJumping.pacing,
                        underAge: underTwo,
                        mini: false
                    },
                ])
                setTrotRacing([
                    {
                        id: 1,
                        title: "Introduce Harness",
                        stat: response.data.training.trotRacing.introTrotHarness,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 2,
                        title: "Introduce Hobbles",
                        stat: response.data.training.trotRacing.introHobbles,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 3,
                        title: "Introduce Cart",
                        stat: response.data.training.trotRacing.introCart,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 4,
                        title: "Introduce Trotting Gate",
                        stat: response.data.training.trotRacing.introTrotGate,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 5,
                        title: "Break From Trotting Gate",
                        stat: response.data.training.trotRacing.breakTrotGate,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 6,
                        title: "Introduce Other Horses",
                        stat: response.data.training.trotRacing.introHorses,
                        underAge: underTwo,
                        mini: false
                    },
                    {
                        id: 7,
                        title: "Practice Passing",
                        stat: response.data.training.trotRacing.practicePassing,
                        underAge: underTwo,
                        mini: false
                    },
                ])
            });
    }, [id, trained]);

    return (
        <div style={{width: "100%"}}>
            {/*<div>
                {horse && <p className={"total-sessions"}>Sessions remaining: {horse.training?.totalSessions}</p>}
            </div>*/}
            {horse && props.ranchId == horse.ranchId.toString() ? <Exercising id={horse.horseId}
                                  horse={horse}
                                  underage={horse.age < 24}
                                  isInjured={isInjured}
                                  handleExercise={handleExercise}
                                  toggle={horse.training?.exerciser}
                                  config={props.config}
                                  totalSessions={horse.training?.totalSessions}
                                  darkMode={props.darkMode}
            /> : ""}
            {horse && <TrainingSection
                toggle={horse.training?.groundWork?.active}
                tasks={groundwork}
                title={"Groundwork"}
                id={horse.horseId}
                isOwner={props.ranchId === horse.ranchId.toString()}
                horse={horse}
                discipline={"Groundwork"}
                handleTrain={handleTrain}
                isInjured={isInjured}
                totalSessions={horse.training?.totalSessions}
                config={props.config}
                mini={false}
                amble={amble}
                pace={pace}
                underage={horse.age < 24}
                darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.underSaddle?.active}
                                       tasks={underSaddle}
                                       title={"Undersaddle"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"UnderSaddle"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.barrelRacing?.active}
                                       tasks={barrelRacing}
                                       title={"Barrel Racing"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"BarrelRacing"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.cutting?.active}
                                       tasks={cutting}
                                       title={"Cutting"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"Cutting"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
            />}
            {horse && <TrainingSection toggle={horse.training?.dressage?.active}
                                       tasks={dressage}
                                       title={"Dressage"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"Dressage"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.endurance?.active}
                                       tasks={endurance}
                                       title={"Endurance"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"Endurance"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.flatRacing?.active}
                                       tasks={flatRacing}
                                       title={"Flat Racing"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"FlatRacing"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
            />}
            {horse && <TrainingSection toggle={horse.training?.gaits?.active}
                                       tasks={gaits}
                                       title={"Gaited/Saddleseat"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"Gaits"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.saddleJumping?.active}
                                       tasks={saddleJumping}
                                       title={"Saddle Jumping"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"SaddleJumping"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.crossCountry?.active}
                                       tasks={crossCountry}
                                       title={"Cross Country"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"CrossCountry"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.pulling?.active}
                                       tasks={pulling}
                                       title={"Pulling"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"Pulling"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={mini}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.reining?.active}
                                       tasks={reining}
                                       title={"Reining"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"Reining"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={horse.breed == 1 || horse.breed == 2 || horse.breed == 3}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.inHandJumping?.active}
                                       tasks={inHandJumping}
                                       title={"In-Hand Jumping"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"InHandJumping"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={false}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            {horse && <TrainingSection toggle={horse.training?.trotRacing?.active}
                                       tasks={trotRacing}
                                       title={"Trot Racing/Pace Racing"}
                                       id={horse.horseId}
                                       isOwner={props.ranchId === horse.ranchId.toString()}
                                       horse={horse}
                                       discipline={"TrotRacing"}
                                       handleTrain={handleTrain}
                                       isInjured={isInjured}
                                       totalSessions={horse.training?.totalSessions}
                                       config={props.config}
                                       mini={false}
                                       amble={amble}
                                       pace={pace}
                                       underage={horse.age < 24}
                                       darkMode={props.darkMode}
            />}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Horse is injured</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    You're horse is injured or ill and requires rest!
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Training;