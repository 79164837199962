import "./Other.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl, FormLabel,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {ColorCodes} from "../../../../../enums/ColorCodes";

const Other = (props) => {    
    const inputStyle = {
        width: "90%", 
        margin: "10px 5px"
    }
    
    const buttonStyle = {
        margin: "0 20px",
        alignSelf: "flex-end"
    }
    
    const [barnName, setBarnName] = useState();

    const handleSetBarnName = (event => {
        setBarnName(event.target.value);
    });

    const handleBarnName = () => {
        const formData = new FormData;

        formData.append("id", props.id);
        formData.append("name", barnName);

        axios.post("/api/Horses/barnName", formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                setMessage(response.data);
                handleOpenConfirmation();
                props.handleSetUpdated();
            }).catch(function (error) {
            console.log(error);
            setMessage(error.response.data);
            handleOpenConfirmation();
        });
    }

    const handleGeldHorse = () => {
        handleClose();
        const data = new FormData();
        data.append("id", props.id);
        data.append("ranch", props.ranch);
        axios.post("/api/health/geld", data, props.formConfig)
            .then(function (response){
                console.log(response);
                setMessage(response.data);
                handleOpenConfirmation();
                props.handleSetUpdated();
            }).catch(function (error){
            console.log(error);
            setMessage(error.response.data);
            handleOpenConfirmation();
        })
    }

    const [price, setPrice] = useState();

    const handleSetPrice = (event) => {
        setPrice(event.target.value);
    }

    const handlePublicStud = () => {
        const data = new FormData;
        data.append("id", props.horse.horseId);
        data.append("price", price);
        axios.post("/api/Health/publicStud", data, props.formConfig)
            .then(function (response){
                console.log(response);
                setOpen(false);
                props.handleSetUpdated();
            }).catch(function (error){
            console.log(error);
        })
    }

    const handleRemoveFromStud = () => {
        axios.post("/api/Health/removePublicStud", props.horse.horseId, props.config)
            .then(function (response){
                console.log(response);
                props.handleSetUpdated();
            }).catch(function (error){
            console.log(error);
        })

    }

    const [negative, setNegative] = useState(false);

    useEffect(() => {
        if(price < 0){
            setNegative(true);
        } else {
            setNegative(false);
        }
    }, [price])

    const [colorCode, setColorCode] = useState("");
    
    const handleSetColorCode = (event) => {
        setColorCode(colorCode);
        
        const data = new FormData();
        
        data.append("id", props.id);
        data.append("color", event.target.value);
        
        axios.post("api/horses/colorCode", data, props.formConfig)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
                setColorCode("");
            }).catch(error => {
                console.log(error);
        })
    }
    
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    return (
        <>
            <div className={props.darkMode ? "other-container-dark" :"other-container"}>
                <div className={"other-title-container"}>
                    <Typography variant={"h3"} >Other</Typography>
                </div>
                <FormControl sx={inputStyle}>
                    <TextField label={"Barn Name"} size={"small"} onChange={handleSetBarnName}/>
                </FormControl>
                <Button sx={buttonStyle} onClick={handleBarnName} variant={"contained"}>Change</Button>
                <FormControl sx={inputStyle}>
                    <FormLabel>Color Code</FormLabel>
                    <Select onChange={handleSetColorCode} value={colorCode} size={"small"}>
                        <MenuItem value={"none"}>None</MenuItem>
                        {ColorCodes.map((color, i) =>
                            <MenuItem key={i} value={color}>{color.split(/(?=[A-Z])/).join(' ')}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {props.horse.gender == 1 && !props.horse.gelded && !props.horse.fertility.publicStud && <>
                    <FormControl sx={inputStyle}>
                        <TextField required label={"Price"} onChange={handleSetPrice} type={"number"} size={"small"}
                                   error={negative}/>
                    </FormControl>
                    <div className={"other-btns-container"}>
                        <Button sx={buttonStyle} variant={"contained"} color={"warning"} onClick={handleOpen}>Geld</Button>
                        <Button sx={buttonStyle} variant={"contained"} onClick={handlePublicStud}>public stud</Button>
                    </div>
                </>}
                {props.horse.fertility.publicStud &&
                    <Button sx={buttonStyle} variant={"contained"} onClick={handleRemoveFromStud}>remove from stud</Button>}
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Geld Horse</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    Gelding a horse is final and cannot be undone.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                    <Button onClick={handleGeldHorse} color={"warning"} variant={"contained"}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button sx={buttonStyle} onClick={handleCloseConfirmation} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Other