import "./Activity.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {ReactNode} from "react";
import axios from "axios";
import {Link} from "react-router-dom";

function TableHeader(props: { children: ReactNode }) {
    return null;
}

const Activity = (props) => {
    
    const [subcats, setSubCats] = useState([]);

    useEffect(() => {
        axios.get("api/forum/subcategories", {
            params: {
                userId: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setSubCats(response.data)
        }).catch(error => {
            console.log(error);
        })
    }, []);
    
    const tableCellStyle={
        borderBottom: "none"
    }
    
    return (
        <div
            style={props.darkMode ? {
                backgroundColor: darkTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px #262626"
            } : {
                backgroundColor: lightTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px darkgray"
            }}
            className={'latest-container'}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"latest-title-container"}>
                <Typography variant={"h3"}>Activity</Typography>
            </div>
            <Table sx={{padding: "5px"}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={tableCellStyle}>Category</TableCell>
                        <TableCell sx={tableCellStyle} align={"center"}>Unread</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subcats.map(subcat => 
                        <TableRow key={subcat.subCategoryId} sx={{}}>
                            <TableCell sx={tableCellStyle}>
                                <Link to={`/forum/${subcat.subCategoryId}`}>
                                    <Typography sx={{fontWeight: 700}}>{subcat.title}</Typography>
                                </Link>
                            </TableCell>
                            <TableCell sx={tableCellStyle} align={"center"}>
                                {subcat.unreadCount}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default Activity