import React, {useEffect, useState} from 'react';

import "./Information.css";


import axios from "axios";
import {Link} from "react-router-dom";
import {Breeds} from "../../../../enums/Breeds";
import {Body} from "../../../../enums/Body";
import {Colors} from "../../../../enums/Colors";
import {Patterns} from "../../../../enums/Patterns";
import {Months} from "../../../../enums/Months";
import {Gender} from "../../../../enums/Gender";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select, Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import HorseInfoItem from "./HorseInfoItem";
import {Condition} from "../../../../enums/Condition";

function Information(props){
          
    const [horse, setHorse] = useState(null);
    const [breeder, setBreeder] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [statusString, setStatusString] = useState("");

    useEffect(() => {
        axios.get('/api/Horses/Information', {
            params: {
                id: props.id
            }, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorse(response.data);
            });
    }, [props.id, props.updated])

    useEffect(() => {
        axios.get('/api/Health/statuses', {
            params: {
                id: props.id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setStatuses(response.data);
                setStatusString(response.data.join(" | "));
            });
    }, [props.id, props.updated])
    
    useEffect(() => {
        if (horse?.breederId != null){
            axios.get('/api/Account/owner', {
                params: {
                    id: horse.breederId
                }, 
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }})
                .then(response => {
                    setBreeder(response.data);
                });
        }
    }, [horse, props.updated]);
    
    const getHands = (height) => {
        const h = Math.trunc(height);
        const hands = height.toString().split(".")[1];
        let hh;
        switch (hands){
            case "25":
                hh = ".1";
                break;
            case "5":
                hh = ".2";
                break;
            case "75":
                hh = ".3";
                break;
            default:
                hh = "";
                break;
        }
        
        return h + hh;
    }

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }

    const calculateAge = (age) => {
        const months = age % 12;
        const years = Math.floor(age / 12);

        return years + " years, " + months + " months";
    }

    const formatDollars = (amount) => {
        return (amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumSignificantDigits: Math.trunc(Math.abs(amount)).toFixed().length
        })
    }
    
    return(
        <>
            {horse &&
                <div className={props.darkMode ? "info-container-dark" : "info-container"}>
                    <div className={"info-header-container"}>
                        <Typography sx={{
                            mx: '10px', 
                            textAlign: "right",
                            fontFamily: "Roboto Thin, sans-serif",
                            fontWeight: "bold",
                            fontStyle: "italic",
                            fontSize: "20px",
                        }} >{horse.prefix}</Typography>
                        <Typography sx={{fontSize: "22px", textAlign: "center"}} variant={"h3"} >{horse.regName}</Typography>
                    </div>
                    <Typography sx={{
                        fontFamily: "Roboto, sans-serif", 
                        fontSize: "24px",
                        padding: "20px 10px 5px 10px",
                    }} 
                    >{horse.barnName != null ? horse.barnName : " "}</Typography>
                    <div className={"info-items-container"}>
                        <HorseInfoItem title={"Status"} info={!horse.alive ? "Deceased" : statuses.length > 0 ? statusString : "Healthy"}/>
                        <HorseInfoItem title={"Condition"} info={Condition[horse.condition.state]}/>
                        <HorseInfoItem title={"Registered name"} info={horse.regName}/> 
                        <HorseInfoItem title={"Date of birth"} info={formatDate(horse.dob)}/>
                        <HorseInfoItem title={"Age"} info={calculateAge(horse.age)}/>
                        <HorseInfoItem title={"Gender"} info={horse.gelded ? "Gelding" : Gender[horse.gender]}/>
                        <HorseInfoItem title={"Breed"} info={Breeds[horse.breed]}/>
                        <HorseInfoItem title={"Body Type"} info={Body[horse.bodyType]}/>
                        <HorseInfoItem title={"Registered Color"} info={horse.registeredColor != null ? horse.registeredPattern != null ? Colors[horse.registeredColor] + " " + Patterns[horse.registeredPattern] : Colors[horse.registeredColor] : "unregistered"}/>
                        <HorseInfoItem title={"Height"} info={getHands(horse.height?.finalValue) +  "hh"}/>
                        {props.owner && <Link className={"info-link"} to={`/users/${props.owner.displayName}`}><HorseInfoItem title={"Owned by"} info={props.owner.displayName}/></Link>}
                        {breeder && breeder ? <Link className={"info-link"} to={`/users/${breeder.displayName}`}><HorseInfoItem title={"Bred by"} info={breeder.displayName}/></Link> : <HorseInfoItem title={"Bred by:"} info={"Unknown"}/>}
                        <Link className={"info-link"} to={`/ranches/${horse.ranchId}`}><HorseInfoItem title={"Location"} info={horse.ranch?.name}/></Link>
                        <HorseInfoItem title={"Total Earnings"} info={formatDollars(horse.scores.totalEarnings)}/>
                        <HorseInfoItem title={"Total Points Earned"} info={horse.scores.totalPoints}/>
                    </div>
                    {/*<Ad dataadslot={"6638948669"}/>*/}
                </div>
            }
        </>
    )
}

export default Information;