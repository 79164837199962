 import "./Landing.css";
import React, {useEffect, useState} from "react";
import axios from "axios";
import RanchCard from "./RanchCard";
import PlayerInfo from "./PlayerInfo";
import {Box, Dialog, DialogActions, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Link, useParams, withRouter} from "react-router-dom";
import Button from "@mui/material/Button";
import { Base64 } from 'js-base64';
import Announcements from "./Announcements";
 import DividerTitle from "../DividerTitle";
 import ForumActivity from "./forum/ForumActivity";
 import RecentMail from "./RecentMail";
 import NewMessage from "../account/messages/NewMessage";


const Landing = (props) => {
    const { displayName } = useParams();
    
    const handleSetRanch = (ranchId) => {
        localStorage.setItem("ranch", Base64.encode(ranchId.toString()));
        localStorage.removeItem("ids");
    }
    
    const [refresh, setRefresh] = useState(false);
    
    const [user, setUser] = useState({});

    useEffect(() => {
        axios.get('/api/Account/userByName', {
            params: {
                userName: displayName
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setUser(response.data);
            }).catch(error => {
                props.history("/notfound")
                console.log(error);
        });
    }, [refresh, displayName]);

    const [openRanchCreation, setOpenRanchCreation] = useState(false);

    const handleOpenRanchCreation = () => {
        setOpenRanchCreation(true);
    }

    const handleCloseRanchCreation = () => {
        setOpenRanchCreation(false);
    }
    
    const [ranches, setRanches] = useState([]);

    useEffect(() => {
        if(user){
            axios.get('/api/Ranches/getByUser', {
                params: {
                    id: user.id
                }, headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }})
                .then(response => {
                    setRanches(response.data);
                })
        }
    }, [user, refresh]);

    const [name, setName] = useState();

    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const handleCreateRanch = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("email", props.email);
        formData.append("name", name);

        await axios.post('/api/Ranches/createRanch', formData, props.formConfig)
            .then(function (response) {
                setResponse(response.data);
                handleCloseRanchCreation();
                handleOpenResponse();
                setRefresh(!refresh);
            }).catch(function (error) {
                console.log(error);
            });
    }

    const [response, setResponse] = useState("");
    
    const [openResponse, setOpenResponse] = useState(false);
    
    const handleOpenResponse = () => {
        setOpenResponse(true);
    }

    const handleCloseResponse = () => {
        setOpenResponse(false);
    }
    
    const addRanch = (
        <div style={{padding: 20}}>
            <Button variant={"contained"} onClick={handleOpenRanchCreation}>Add Ranch</Button>
            <Dialog open={openRanchCreation} onClose={handleCloseRanchCreation} fullWidth>
                <DialogTitle>Create New Ranch</DialogTitle>
                <TextField onChange={handleSetName} type={"text"} placeholder={"Ranch Name"}/>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleCreateRanch}>Create Ranch</Button>
                    <Button variant={"contained"} onClick={handleCloseRanchCreation}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openResponse} onClose={handleOpenResponse}>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    {response}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseResponse} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
    
    const owner = (
        ranches.map(ranch =>
            <Link key={ranch.ranchId} className={"landing-link"} to={`/ranches/${ranch.ranchId}`} onClick={handleSetRanch.bind(this, ranch.ranchId)}>
                <RanchCard ranch={ranch} darkMode={props.darkMode}/>
            </Link>
        )
    )
    
    const visitor = (
        ranches.map(ranch =>
                <Link key={ranch.ranchId} className={"landing-link"} to={`/ranches/${ranch.ranchId}`}>
                    <RanchCard ranch={ranch} darkMode={props.darkMode}/>
                </Link>
            )
    )

    const [isNewMessageOpen, setIsNewMessageOpen] = useState(false);

    const openNewMessage = () => {
        setIsNewMessageOpen(true);
    }

    const closeNewMessage = () => {
        setIsNewMessageOpen(false);
    }
    
    return(
        <div style={{width: "100%"}}>
            <img className={"landing-img"}
                 src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/home-banner.jpg"}
                 alt={"Horse ranch"}/>
            <div className={"landing-container"}>
                <div className={"landing-profile"}>
                    {user && <PlayerInfo user={user}/>}
                    {user && props.user?.displayName != displayName ?
                        <Button variant={"contained"} onClick={openNewMessage}>Message</Button> : ""}
                </div>
                <Box sx={{flexGrow: 2, width: '58%'}}>
                    {/*{user && props.user?.displayName == displayName ? addRanch : ""}*/}
                    {user && props.user?.displayName == displayName ?
                        <Announcements admin={props.user?.admin} email={props.user.email} config={props.config}
                                       formConfig={props.formConfig} api={props.api} token={props.token}
                                       darkMode={props.darkMode}/> : ""}
                    <div className={"landing-ranch-cards-container"}>
                        {props.user.id !== user.id ? visitor : owner}
                    </div>
                </Box>
            </div>
            {user && props.user?.displayName == displayName ?
                <>
                    <DividerTitle title={"Recent Messages"} darkMode={props.darkMode}/>
                    <RecentMail user={props.user} api={props.api} token={props.token} darkMode={props.darkMode}/>
                    <DividerTitle title={"Forum Activity"} darkMode={props.darkMode}/>
                    <ForumActivity user={props.user} api={props.api} token={props.token} darkMode={props.darkMode}/>
                </> : ""
            }
            {isNewMessageOpen ? <div className={"landing-new-message-container"}>
                <NewMessage id={props.user.id} recipient={user} config={props.config} closeNewMessage={closeNewMessage}
                            darkMode={props.darkMode}/>
            </div> : ""}
        </div>
    )
}

 export default Landing;