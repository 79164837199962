import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import axios from "axios";

const ForgotPassword = (props) => {
    
    const [email, setEmail] = useState("");

    const handleSetEmail = (event) => {
        setEmail(event.target.value);
    }
    
    const handleSubmitForm = () => {
        axios.post("api/Account/forgotPassword", JSON.stringify(email), props.config)
            .then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
        })
    }
    
    return (
        <div>
            <TextField sx={{margin: 1, maxWidth: 350}} onChange={handleSetEmail} type={"email"} placeholder={"E-mail"} label={"E-mail"} size={"small"}/>
            <Button sx={{margin: 1, maxWidth: 70}} type={"submit"} onClick={handleSubmitForm} variant={"contained"}>Submit</Button>
        </div>
    )
}

export default ForgotPassword;