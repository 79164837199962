import "./RanchSearchCard.css";

import React, {useState} from 'react';
import {Typography} from "@mui/material";

const RanchSearchCard = (props) => {
    
    console.log(props.ranch)

    const [source, setSource] = useState(`https://media.legacyhorsegame.com/ranchimages/${props.ranch.ranchId}.png`);

    const handleMissingImage = () => {
        setSource("https://media.legacyhorsegame.com/misc/defaultRanchImage.png")
    }

    return(
        <div className={"ranch-search-container"}>
            <div className={"ranch-search-title-container"}>
                <Typography sx={{fontSize: "18px"}} variant={"h3"}>{props.ranch.name}</Typography>
            </div>
            <div className={"ranch-search-background"}>
                <div className={"ranch-search-image-cropper"}>
                    <img className={"ranch-search-image"}
                         src={source}
                         alt={props.ranch.name}
                         onError={handleMissingImage}
                    />
                </div>
            </div>
            <div>
                
            </div>
        </div>
    )
}

export default RanchSearchCard;