import "./Mare.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Event, Favorite, Schedule} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {Months} from "../../../../enums/Months";
import Breed from "./Breed";
import InstantBreed from "./InstantBreed";
import {Link} from "react-router-dom";

const Mare = (props) => {
    
    const infoTitleStyle = {
        fontWeight: "bolder",
        fontSize: "1.2em",
        paddingRight: "10px",
        textTransform: "uppercase"
    }

    const infoTextStyle = {
        fontSize: "1.2em",
        paddingLeft: "10px",
    }

    const [stud, setStud] = useState(null);

    useEffect(() => {
        axios.get("api/Horses/JustHorse", {
            params: {
                id: props.horse.fertility.studId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setStud(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.horse])

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    const getTrimester = () => {
        const monthsPassed = props.date - props.horse.fertility.dateBred;
        
        console.log(monthsPassed);
        
        if(monthsPassed <= 4){
            return "First";
        }
        if(monthsPassed <= 8){
            return "Second";
        }
        if(monthsPassed <= 13){
            return "Third";
        }
    }
    
    return (
        <div className={"mare-container"}>
            {props.horse.fertility.bred || props.horse.fertility.pregnant ?
                <>
                    <div className={"mare-info-item"}>
                        <Favorite sx={{fontSize: "2em", marginRight: "5px"}}/>
                        <Typography sx={infoTitleStyle}>Bred to:</Typography>
                        <Divider sx={{flexGrow: 1}}/>
                        {stud && <Link to={`/horses/${stud.horseId}`}>
                            <Typography sx={infoTextStyle}>{stud.regName}</Typography>
                        </Link>}
                    </div>
                    <div className={"mare-info-item"}>
                        <Event sx={{fontSize: "2em", marginRight: "5px"}}/>
                        <Typography sx={infoTitleStyle}>Bred on:</Typography>
                        <Divider sx={{flexGrow: 1}}/>
                        <Typography sx={infoTextStyle}>{formatDate(props.horse.fertility.dateBred)}</Typography>
                    </div>
                    {props.horse.fertility.pregnant && <div className={"mare-info-item"}>
                        <Schedule sx={{fontSize: "2em", marginRight: "5px"}}/>
                        <Typography sx={infoTitleStyle}>Trimester:</Typography>
                        <Divider sx={{flexGrow: 1}}/>
                        <Typography sx={infoTextStyle}>{getTrimester()}</Typography>
                    </div>}
                </> : ""
            }
            {props.isOwner && <>
                {!props.disabled && !props.horse.sale.isLeased ? <Breed id={props.horse.horseId} email={props.email} ranchId={props.ranchId}
                                           api={props.api} token={props.token} formConfig={props.formConfig}
                                           handleSetUpdated={props.handleSetUpdated}/> : ""}
                {!props.disabled && props.horse.sale.isLeased && props.horse.sale.allowBreeding ? <Breed id={props.horse.horseId} email={props.email} ranchId={props.ranchId}
                    api={props.api} token={props.token} formConfig={props.formConfig}
                    handleSetUpdated={props.handleSetUpdated}/> : ""}
                {props.isTest && props.admin ?
                    <InstantBreed id={props.horse.horseId} email={props.email} ranchId={props.ranchId}
                                  api={props.api} token={props.token} formConfig={props.formConfig}
                                  handleSetUpdated={props.handleSetUpdated}/> : ""}
            </>}
        </div>
    )
}

export default Mare