import "./Forum.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import CategoryCard from "./CategoryCard";
import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl, Pagination, Tab,
    TextField, Typography
} from "@mui/material";
import Categories from "./Categories";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import RecentPosts from "./RecentPosts";
import FollowedPosts from "./FollowedPosts";
import PopularPosts from "./PopularPosts";
import ArchivedPosts from "./ArchivedPosts";
import Post from "./Post";
import PostCard from "./PostCard";

const Forum = (props) => {

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [title, setTitle] = useState("");

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleAddCategory = () => {
        const data = new FormData();
        data.append("email", props.email);
        data.append("title", title);
        axios.post("/api/Forum/newCategory", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleClose();
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [selectedTab, setSelectedTab] = useState("categories");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }
    
    const [searchTerm, setSearchTerm] = useState("");
    
    const handleSetSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const [isLoading, setIsLoading] = useState(false);
    
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        if(searchTerm != null && searchTerm != ""){
            axios.get("api/forum/searchPosts", {
                params: {
                    query: searchTerm,
                    userId: props.user.id,
                    page: pageNumber
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api
                }
            }).then(response => {
                setPosts(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 5000)
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })
        }
    }, [searchTerm, pageNumber]);

    const [totalPosts, setTotalPosts] = useState(1);

    useEffect(() => {
        if(searchTerm != null && searchTerm != ""){
            axios.get("/api/Forum/searchPostsTotal", {
                params: {
                    query: searchTerm
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api
                }
            })
                .then(response => {
                    setTotalPosts(response.data);
                }).catch(error => {
                console.log(error);
            })
        }
    });
    
    const searchResults = (
        <div>
            {isLoading ? <CircularProgress/> :
                posts.length > 0 ? <div>
                        <Pagination sx={{alignSelf: "flex-end"}}
                                    variant={"outlined"}
                                    shape={"rounded"}
                                    boundaryCount={2}
                                    page={pageNumber}
                                    count={Math.ceil(totalPosts / 15)}
                                    onChange={handleSetPageNumber}
                        />
                        {posts.map(post => <PostCard post={post} user={props.user}
                                                                        formConfig={props.formConfig}/>)}
                        <Pagination sx={{alignSelf: "flex-end"}}
                                    variant={"outlined"}
                                    shape={"rounded"}
                                    boundaryCount={2}
                                    page={pageNumber}
                                    count={Math.ceil(totalPosts / 15)}
                                    onChange={handleSetPageNumber}
                        />
                    </div>
            : <Typography>Search returned no results.</Typography>}
        </div>
    )

    return (
        <div>
            <img className={"horsesBanner"}
                 src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/forum-banner.jpg"}
                 alt={"horses"}/>
            <div className={"forum-container"}>
                <TabContext value={selectedTab}>
                    <Box width={"100%"} sx={{color: "white"}}>
                        <TabList variant={"scrollable"} className={"horse-tabs"} onChange={handleChangeTab}
                                 scrollButtons={"auto"} textColor={"inherit"}>
                            <Tab label="Categories" value={"categories"}/>
                            <Tab label="Recent Posts" value={"recent"}/>
                            <Tab label="Following" value={"following"}/>
                            <Tab label="Popular" value={"popular"}/>
                            {props.user.admin ? <Tab label="Archived" value={"archived"}/> : ""}
                        </TabList>
                    </Box>
                    <TabPanel className={"forum-tab"} value={"categories"}>
                        <FormControl sx={{alignSelf: "flex-end"}}>
                            <TextField sx={{margin: 1}} label={"Search"} variant={"outlined"} size={"small"} onChange={handleSetSearchTerm}/>
                        </FormControl>
                        {searchTerm !== "" ? searchResults : <Categories user={props.user} api={props.api} token={props.token}/>}
                    </TabPanel>
                    <TabPanel className={"forum-tab"} value={"recent"}>
                        <RecentPosts user={props.user} api={props.api} token={props.token}/>
                    </TabPanel>
                    <TabPanel className={"forum-tab"} value={"following"}>
                        <FollowedPosts user={props.user} api={props.api} token={props.token}/>
                    </TabPanel>
                    <TabPanel className={"forum-tab"} value={"popular"}>
                        <PopularPosts user={props.user} api={props.api} token={props.token}/>
                    </TabPanel>
                    <TabPanel className={"forum-tab"} value={"archived"}>
                        {props.user.admin ? <ArchivedPosts user={props.user} api={props.api} token={props.token}/> : ""}
                    </TabPanel>
                </TabContext>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add Category</DialogTitle>
                    <FormControl sx={{display: "flex", flexFlow: "row nowrap"}}>
                        <TextField sx={{margin: 1, maxWidth: 250}} label={"Category title"} size={"small"}
                                   onChange={handleSetTitle}/>
                    </FormControl>
                    <DialogActions>
                        <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                        <Button onClick={handleAddCategory} autoFocus color={"warning"} variant={"contained"}>
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Forum;