import "./Notifications.css";

import {
    List,
    ListItem, Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Notification from "./Notification";
import ReadNotifications from "./ReadNotifications";
import {BeenhereRounded, MarkChatRead, MarkChatReadRounded, NotificationsOff} from "@mui/icons-material";

const Notifications = (props) => {
    const id = props.id;
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        axios.get('/api/Application/notifications', {
            params: {
                id: id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setNotifications(response.data);
            }).catch(error => {
            console.log(error)
        });
    }, [id, updated]);
    
    const handleReadAllNotifications = () => {
        axios.post('api/Application/markAllNotificationsRead', props.id, props.config)
            .then(response =>{
                console.log(response);
                setUpdated(updated + 1);
            }).catch(error => {
            console.log(error);
        })
    }
    
    const [openRead, setOpenRead] = useState(false);
    
    const handleOpenRead = () => {
        setOpenRead(true);
    }
    
    const unreadNotifications = (
        <Box className={"notifications-box"} sx={{flexGrow: 1}}>
            <div className={"notifications-btns-container"}>
                {notifications.length > 0 ? <NotificationsOff onClick={handleReadAllNotifications}/> : ""}
                <BeenhereRounded sx={{margin: "0 10px"}} onClick={handleOpenRead}/>
            </div>
            <List sx={{width: "100%"}}>
                {notifications && notifications.map(notification =>
                    <ListItem key={notification.notificationId} 
                              sx={{width: "100%", 
                                  display: "flex", 
                                  flexFlow: "column noWrap", 
                                  alignItems: "center"}}>
                        <Notification notification={notification}
                                      config={props.config}
                                      handleSetUpdated={handleSetUpdated}
                                      read={notification.read}
                                      darkMode={props.darkMode}
                        />
                    </ListItem>
                )}
            </List>
            {notifications.length == 0 ? <Typography  sx={{alignSelf: "center", marginBottom: "15px"}}>No new notifications.</Typography> : ""}
        </Box>
    )

    return (
        <div className={"all-notifications-container"}>
            {unreadNotifications}
            {openRead && <ReadNotifications id={props.id}
                                            handleSetUpdated={handleSetUpdated}
                                            setOpenRead={setOpenRead}
                                            config={props.config}
                                            darkMode={props.darkMode}
            />}
        </div>
    )
}

export default Notifications