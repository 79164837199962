import "./DeleteLocation.css";

import {React, useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContentText, DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import axios from "axios";
import {darkTheme, lightTheme} from "../../../../Theme";

const DeleteLocation = (props) => {

    const [pastures, setPastures] = useState([]);
    const [barns, setBarns] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        axios.get("/api/Ranches/getPasturesNotFull", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [props.updated])

    useEffect(() => {
        axios.get("/api/Ranches/getBarnsNotFull", {
            params: {
                id: props.id
            }, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [props.updated])

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures, props.updated]);

    const [location, setLocation] = useState(null);

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const handleDeleteLocation = () => {
        closeConfirmation();
        
        const type = location.split(" ")[0];
        const id = location.split(" ")[1];
        
        if(type.toLowerCase() == "barn"){
            axios.post("/api/Ranches/deleteBarn",  id, props.config)
                .then(response => {
                    console.log(response.data);
                    setMessage(response.data);
                    setLocation("")
                    props.handleSetUpdated();
                    handleOpenMessage();
                }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpenMessage();
            })
        }
        
        if(type.toLowerCase() == "pasture"){
            axios.post("/api/Ranches/deletePasture", id, props.config)
                .then(response => {
                    console.log(response.data);
                    setMessage(response.data);
                    setLocation("")
                    props.handleSetUpdated();
                    handleOpenMessage();
                }).catch(error => {
                    console.log(error);
                    setMessage(error.response.data);
                    handleOpenMessage();
            })
        }
    }
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const openConfirmation = () => {
        setConfirmationOpen(true);
    }

    const closeConfirmation = () => {
        setConfirmationOpen(false);
    }
    
    const [message, setMessage] = useState("");
    const [openMessage, setOpenMessage] = useState(false);

    const handleOpenMessage = () => {
        setOpenMessage(true);
    }

    const handleCloseMessage = () => {
        setOpenMessage(false);
    }

    const inputStyle = {
        margin: "0 5px 10px 5px",
        width: "85%",
    }

    return(
        <>
            <div style={props.darkMode ? {
                backgroundColor: darkTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px #262626"
            } : {
                backgroundColor: lightTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px darkgray"
            }} className={"delete-location-container"}>
                <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"delete-location-title-container"}>
                    <Typography variant={"h3"}>Sell</Typography>
                </div>
                <FormControl sx={inputStyle}>
                    <InputLabel id={"location"}>Choose a location</InputLabel>
                    <Select labelId={"location"}
                            label={"Choose a barn or pasture"}
                            size={"small"}
                            onChange={handleSetLocation}>
                        <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or barn</MenuItem>
                        {locations && locations.map((location, i) =>
                            <MenuItem key={i}
                                      value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Button sx={{alignSelf: "flex-end", margin: "10px"}} variant={"contained"} onClick={openConfirmation}>Sell</Button>
            </div>
            <Dialog open={confirmationOpen} onClose={closeConfirmation} sx={{paddingTop: 5}}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Are you sure you want to sell barn/pasture? Deleting a barn/pasture is permanent.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleDeleteLocation} variant={"contained"}>Confirm</Button>
                    <Button onClick={closeConfirmation} variant={"contained"}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openMessage} onClose={handleCloseMessage} sx={{paddingTop: 5}}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteLocation;