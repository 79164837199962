import "./Management.css";

import React from "react";
import Register from "./register/Register";
import Manage from "./manage/Manage";
import Sales from "./sales/Sales";
import Other from "./other/Other";
import Disciplines from "./disciplines/Disciplines";
import Customization from "./customization/Customization";
import EnterShow from "./shows/EnterShow";

const Management = (props) => {
    
    return (
        <div className={"management-container"}>
            {!props.horse.registered && props.isOwner ? <Register id={props.id} horse={props.horse} 
                                                                  formConfig={props.formConfig} token={props.token} 
                                                                  handleSetUpdated={props.handleSetUpdated} 
                                                                  darkMode={props.darkMode}/> : ""}
            <Manage id={props.id} ranchId={props.ranchId} horse={props.horse} api={props.api} token={props.token} 
                    formConfig={props.formConfig} handleSetUpdated={props.handleSetUpdated} darkMode={props.darkMode}/>
            {!props.horse.sale.isLeased && props.horse.age > 5 ? <Sales id={props.id} ranchId={props.ranchId} horse={props.horse} api={props.api} token={props.token}
                    config={props.config} formConfig={props.formConfig} handleSetUpdated={props.handleSetUpdated}
                    darkMode={props.darkMode}/> : ""}
            {!props.horse.sale.isLeased ? <Other id={props.id} ranchId={props.ranchId} horse={props.horse} api={props.api} token={props.token}
                    config={props.config} formConfig={props.formConfig} handleSetUpdated={props.handleSetUpdated}
                    darkMode={props.darkMode}/> : ""}
            {!props.horse.sale.isLeased ? <Disciplines id={props.id} ranchId={props.ranchId} api={props.api} token={props.token}
                          config={props.config}
                          formConfig={props.formConfig} handleSetUpdated={props.handleSetUpdated}
                          darkMode={props.darkMode}/> : ""}
            {props.horse.sale.isLeased && props.horse.sale.allowShowing ? <Disciplines id={props.id} ranchId={props.ranchId} api={props.api} token={props.token}
                                                       config={props.config}
                                                       formConfig={props.formConfig} handleSetUpdated={props.handleSetUpdated}
                                                       darkMode={props.darkMode}/> : ""}
            <EnterShow horse={props.horse} ranchId={props.ranchId} api={props.api} token={props.token} formConfig={props.formConfig} />
            {!props.horse.sale.isLeased ? <Customization id={props.id} ranchId={props.ranchId} api={props.api} token={props.token}
                            config={props.config}
                            formConfig={props.formConfig} handleSetUpdated={props.handleSetUpdated}
                            darkMode={props.darkMode}/> : ""}
        </div>
    )
}

export default Management