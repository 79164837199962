import "./Finances.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Checkbox,
    FormControl, 
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField, Typography
} from "@mui/material";
import Transactions from "./Transactions";
import {Months} from "../../../enums/Months";
import {darkTheme, lightTheme} from "../../../Theme";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";

const Finances = (props) => {
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [months, setMonths] = useState([]);

    const handleSetMonths = (month) => {
        if (months.includes(month)) {
            setMonths(months.filter((m) => m !== month));
        } else {
            setMonths([...months, month]);
        }
    }
    
    const [year, setYear] = useState(null);

    const handleSetYear = (event) => {
        const newValue = event.target.value === '' ? null : parseInt(event.target.value, 10);
        setYear(newValue);
    }
    
    const [description, setDescription] = useState("");
    
    const handleSetDescription = (event) => {
        setDescription(event.target.value);
    }

    const [transactions, setTransactions] = useState([]);
    const [income, setIncome] = useState(0);
    const [expenses, setExpenses] = useState(0);

    useEffect(() => {
        const search = {
            ranchId: props.id,
            months: months,
            year: year === "" ? null : year,
            description: description
        }
        const getTransactions = async () => {
            await axios.post("api/Application/transactions", search, props.config)
                .then(response => {
                    setTransactions(response.data);
                    setIncome(response.data.reduce((accumulator, currentValue) => {
                        if (currentValue["amount"] > 0) {
                            return accumulator + currentValue["amount"];
                        }
                        return accumulator;
                    }, 0))
                setExpenses(response.data.reduce((accumulator, currentValue) => {
                    if (currentValue["amount"] < 0) {
                        return accumulator + currentValue["amount"];
                    }
                    return accumulator;
                }, 0))
            }).catch(error => {
                console.log(error);
            })
        }
        
        const timer = setTimeout(() => {
            getTransactions();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [months, year, description]);



    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const clearFilters = () => {
        setMonths([]);
        setYear("");
        setDescription('');
    }

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }
    
    const tableHeaderTextStyle = {
        fontWeight: "bold"
    }

    const filters = (
        <div className={"read-notifications-filters-container"}>
            <div className={"finances-filters-sub-container"}>
                <div className={"finances-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Date</Typography>
                    <div className={"finances-filter-items-container"}>
                        {Months.map((month, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={months.includes(month)}
                                          onChange={() => handleSetMonths(month)}
                                />} label={month}/>
                        )}
                        <FormControl>
                            <TextField sx={{width: "100px", marginTop: "10px"}} label={"Year"} size={"small"} value={year} onChange={handleSetYear}/>
                        </FormControl>
                    </div>
                </div>
                <FormControl sx={{minWidth: "300px", width: "35%"}}>
                    <TextField label={"Description"} size={"small"} value={description} onChange={handleSetDescription}/>
                </FormControl>
            </div>
            <div className={"finances-close-clear-container"}>
                <Close onClick={closeFilters} />
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )
    
    return (
        <div className={"finances-container"}>
            {transactions &&
                <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}} className={"finances-table-container"}>
                    {filtersOpen ? filters : ""}
                    <Toolbar sx={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main :  lightTheme.palette.secondary.main,
                        width: "100%",
                        padding: 0,
                        margin: 0
                    }}>
                        <FilterList onClick={openFilters} />
                        <TablePagination
                            sx={{alignSelf: "flex-end"}}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            component="div"
                            count={transactions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Toolbar>
                    <TableContainer sx={{padding: "20px", display: "flex", flexFlow: "column nowrap", alignItems: "center"}}>
                        <Table sx={{width: "35%", alignSelf: "flex-end", marginBottom: "30px"}} size={"small"}>
                            <TableHead sx={{marginBottom: "20px"}}>
                                <TableRow>
                                    <TableCell align={"left"}><Typography sx={tableHeaderTextStyle}>Income</Typography></TableCell>
                                    <TableCell align={"right"}>{income}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align={"left"}><Typography sx={tableHeaderTextStyle}>Expenses</Typography></TableCell>
                                    <TableCell align={"right"}>{expenses}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align={"left"}><Typography sx={tableHeaderTextStyle}>Difference</Typography></TableCell>
                                    <TableCell align={"right"}>{income + expenses}</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Table size={"small"}>
                            <TableHead sx={{marginBottom: "20px"}}>
                                <TableCell/>
                                <TableCell><Typography sx={tableHeaderTextStyle}>Date</Typography></TableCell>
                                <TableCell><Typography sx={tableHeaderTextStyle}>Amount</Typography></TableCell>
                            </TableHead>
                            <TableBody>
                                {transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((t, index) =>
                                    <Transactions transaction={t} description={description}/>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    )
}

export default Finances;