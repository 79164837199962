import "./SalesHistory.css";

import React, {useEffect, useState} from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {lightTheme} from "../../../../Theme";
import axios from "axios";
import {Months} from "../../../../enums/Months";

const SalesHistory = (props) => {
    
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        axios.get("api/Sales/history", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setTransactions(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    return (
        <TableContainer sx={{margin: "50px 0", width: "95%"}} component={Paper}>
            <Table size="small">
                <TableHead sx={{backgroundColor: lightTheme.palette.secondary.main, height: "30px"}}>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Game Date</TableCell>
                        <TableCell>Seller</TableCell>
                        <TableCell>Buyer</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.length == 0 ? <TableRow>
                        <TableCell colSpan={5} align={"center"}>No purchase history available.</TableCell>
                    </TableRow> : ""}
                    {transactions.map((transaction) => (
                        <TableRow key={transaction.id}>
                            <TableCell>{new Date(transaction.transactionDate).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            })}</TableCell>
                            <TableCell>{formatDate(transaction.gameDate)}</TableCell>
                            <TableCell>{transaction.sellerRanch}</TableCell>
                            <TableCell>{transaction.buyerRanch}</TableCell>
                            <TableCell>{transaction.price.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SalesHistory