import "../horses/Horses.css";
import "./PublicSales.css";

import React, {useEffect, useState} from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import {useCookies} from "react-cookie";
import HorseCard from "../horses/HorseCard";

const Owned = (props) => {
    const [horses, setHorses] = useState([]);
    const [ids, setIds] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getHorsesForSale', {
            params: {
                id: props.ranch
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                const horseIds = [];
                setHorses(response.data);
                for (let i = 0; i < response.data.length; i++) {
                    horseIds.push(response.data[i].horseId);
                }
                setIds(horseIds);
            });

    }, []);

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const moveForward = (event) => {
        setIndexStart(indexStart + 30);
        setIndexEnd(indexEnd + 30);
    }

    const moveBackward = (event) => {
        setIndexStart(indexStart - 30);
        setIndexEnd(indexEnd - 30);
    }

    const [tab, setTab] = useState("0");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setTab(newTab);
    }

    return (
        <div className={"horsesPage"} style={{paddingTop: 20}}>
            <div className={"page-nav-section"}>
                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                {indexEnd > horses.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
            </div>
            <div className={"horsesSection"}>
                {localStorage.setItem("ids", ids)}
                {horses.slice(indexStart, indexEnd).map(horse =>
                    <Link className={"public-sales-link"} to={`/horses/${horse.horseId}`}>
                        <HorseCard key={horse.horseId} horse={horse} id={horse.horseId}/>
                    </Link>
                )}
            </div>
            <div className={"page-nav-section"}>
                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                {indexEnd > horses.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
            </div>
        </div>
    )
}

export default Owned;