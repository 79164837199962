import "./Manage.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {Feeds} from "../../../../../enums/Feeds";
import {Jobs} from "../../../../../enums/Jobs";

const Manage = (props) => {
    const textStyle = {
        fontFamily: "Roboto Light, sans-serif", 
        margin: "5px 5px 5px 0", 
        alignSelf: "flex-start"
    }
    
    const selectStyle = {
        margin: "0 5px 10px 5px", 
        width: "100%",
    }
    
    const [updatedFeed, setUpdatedFeed] = useState(0);

    const handleSetUpdatedFeed = () => {
        setUpdatedFeed(updatedFeed + 1);
        props.handleSetUpdated();
    }

    const [updatedJob, setUpdatedJob] = useState(0);

    const handleSetUpdatedJob = () => {
        setUpdatedJob(updatedJob + 1);
    }

    const [updatedLocation, setUpdatedLocation] = useState(0);
    
    const handleSetUpdatedLocation = () => {
        setUpdatedLocation(updatedLocation + 1);
        props.handleSetUpdated();
    }
    
    const [currentFeed, setCurrentFeed] = useState(null);

    useEffect(() => {
        axios.get("/api/Condition", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setCurrentFeed(response.data.feed)
            })
    }, [props.id, updatedFeed]);
    
    const handleSetFeed = (event) => {
        const data = new FormData();
        data.append("id", props.id);
        data.append("feed", event.target.value);

        axios.post("/api/Condition/setFeed", data, props.formConfig)
            .then(function (response){
                console.log(response);
                handleSetUpdatedFeed();
            }).catch(function (error){
            console.log(error);
        });
    }

    const [assignedJob, setAssignedJob] = useState(0);

    useEffect(() => {
        axios.get("/api/Horses/job", {
            params: {
                id: props.id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                if(response.data != null){
                    setAssignedJob(response.data);
                }
            })
    }, [props.id, updatedJob]);

    const handleSetJob = (event) => {
        const data = new FormData();
        
        data.append("id", props.id);
        data.append("job", event.target.value);
        
        axios.post("/api/horses/setJob", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdatedJob();
            }).catch(error => {
            console.log(error);
        })
    }

    const [pastures, setPastures] = useState([]);
    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get("/api/Ranches/getPasturesNotFull", {
            params: {
                id: props.ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [updatedLocation])

    useEffect(() => {
        axios.get("/api/Ranches/getBarnsNotFull", {
            params: {
                id: props.ranchId
            }, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [updatedLocation])

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures, updatedLocation]);

    const handleSetLocation = (event) => {
        const data = new FormData();
        
        data.append("id", props.id);
        data.append("location", event.target.value);
        
        axios.post("/api/Ranches/setLocation", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdatedLocation();
            }).catch(error => {
                console.log(error);
        })
    }

    return (
        <div className={props.darkMode ? "manage-container-dark" : "manage-container"}>
            <div className={"manage-title-container"}>
                <Typography variant={"h3"}>Manage</Typography>
            </div>
            <div className={"manage-items"}>
                <div className={"manage-item"}>
                    <Typography sx={textStyle}><b>Current feed: </b>{Feeds[currentFeed]}</Typography>
                    <FormControl sx={selectStyle}>
                        <InputLabel id={"feed-select"}>Choose feed</InputLabel>
                        <Select id={"feed-select"} size={"small"} onChange={handleSetFeed}>
                            {Feeds.map((feed, i) =>
                                <MenuItem key={i} value={feed}>{feed}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className={"manage-item"}>
                    <Typography sx={textStyle}><b>Current Job: </b>{Jobs[assignedJob]} </Typography>
                    <FormControl sx={selectStyle}>
                        <InputLabel id={"job"}>Choose a job</InputLabel>
                        <Select id={"job"} labelId={"job"} size={"small"} disabled={props.horse.sale.isLeased && !props.horse.sale.allowChangeJob} onChange={handleSetJob}>
                            <MenuItem value={"Remove"}>Remove from job</MenuItem>
                            {Jobs.map((job, i) =>
                                <MenuItem key={i} value={job.replace(/ /g,'')}>{job}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className={"manage-item"}>
                    <Typography sx={textStyle}><b>Current
                        location: </b>{props.horse.pasture != null ? props.horse.pasture.name : props.horse.barn.name}
                    </Typography>
                    <FormControl sx={selectStyle}>
                        <InputLabel id={"location"}>Choose a barn or pasture</InputLabel>
                        <Select labelId={"location"}
                                label={"Choose a barn or pasture"}
                                size={"small"}
                                disabled={props.horse.age < 6}
                                onChange={handleSetLocation}>
                            <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or barn</MenuItem>
                            {locations && locations.map((location, i) =>
                                <MenuItem key={i}
                                          value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default Manage