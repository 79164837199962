import "./ShowList.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Box, Checkbox,
    FormControl,
    FormControlLabel, Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow, TextField, Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Months} from "../../../../enums/Months";
import {Discipline} from "../../../../enums/Discipline";
import {Placements} from "../../../../enums/Placements";
import {darkTheme, lightTheme} from "../../../../Theme";
import {Levels} from "../../../../enums/Levels";
import Toolbar from "@mui/material/Toolbar";
import {Close, Filter, FilterList, FilterListOff} from "@mui/icons-material";

const ShowList = (props) => {

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const [months, setMonths] = useState([]);

    const handleSetMonths = (month) => {
        if (months.includes(month)) {
            setMonths(months.filter((m) => m !== month));
        } else {
            setMonths([...months, month]);
        }
    }

    const [year, setYear] = useState(null);

    const handleSetYear = (event) => {
        const newValue = event.target.value === '' ? null : parseInt(event.target.value, 10);
        setYear(newValue);
    }

    const [placements, setPlacements] = useState([]);

    const handleSetPlacements = (placement) => {
        if (placements.includes(placement)) {
            setPlacements(months.filter((d) => d !== placement));
        } else {
            setPlacements([...placements, placement]);
        }
    }

    const [disciplines, setDisciplines] = useState([]);

    const handleSetDisciplines = (discipline) => {
        if (disciplines.includes(discipline)) {
            setDisciplines(months.filter((d) => d !== discipline));
        } else {
            setDisciplines([...disciplines, discipline]);
        }
    }

    const [levels, setLevels] = useState([]);

    const handleSetLevels = (level) => {
        if (levels.includes(level)) {
            setLevels(levels.filter((l) => l !== level));
        } else {
            setLevels([...levels, level]);
        }
    }

    const [sortBy, setSortBy] = useState("date");

    const handleSortBy = (event) => {
        setSortBy(event.target.value);
    }

    const [shows, setShows] = useState([]);
    
    const [time, setTime] = useState("");
    
    const handleSetTime = (event) => {
        setTime(event.target.value);
    }
    

    useEffect(() => {
        const searchDto = {
            horseId: props.id,
            months: months,
            year: year === "" ? null : year,
            time: time,
            placements: placements,
            disciplines: disciplines,
            levels: levels,
            includePastShows: true,
            sortBy: sortBy
        }
        const getShows = () => {
            axios.post('/api/Shows/ShowsByHorse', searchDto, props.config)
                .then(response => {
                    setShows(response.data);
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getShows();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [props.id, months, year, time, placements, disciplines, sortBy]);

    const formatDate = (date) => {
        const month = date % 12 == 0 ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    const [filtersOpen, setFiltersOpen] = useState(false);
    
    const openFilters = () => {
        setFiltersOpen(true);
    }
    
    const closeFilters = () => {
        setFiltersOpen(false);
    }
    
    const clearFilters = () => {
        setMonths([]);
        setYear("");
        setTime("");
        setPlacements([]);
        setDisciplines([]);
    }

    const filters = (
        <div className={"read-notifications-filters-container"}>
            <div className={"past-shows-filters-sub-container"}>
                <div className={"past-shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Discipline</Typography>
                    <div className={"past-shows-filter-items-container"}>
                        {Discipline.map((discipline, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={disciplines.includes(discipline.replaceAll(/ /g, ""))}
                                          onChange={() => handleSetDisciplines(discipline.replaceAll(/ /g, ""))}
                                />} label={discipline}/>
                        )}
                    </div>
                </div>
                <div className={"past-shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Level</Typography>
                    <div className={"past-shows-filter-items-container"}>
                        {Levels.map((level, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={levels.includes(level)}
                                          onChange={() => handleSetLevels(level)}
                                />} label={level}/>
                        )}
                    </div>
                </div>
                <div className={"past-shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Time</Typography>
                    <FormControl>
                        <RadioGroup onChange={handleSetTime} value={time}>
                            <FormControlLabel value={""} defaultChecked control={<Radio sx={filterCheckboxStyle}/>} label={"All"}/>
                            <FormControlLabel value={"AM"} control={<Radio sx={filterCheckboxStyle}/>} label={"AM"}/>
                            <FormControlLabel value={"PM"} control={<Radio sx={filterCheckboxStyle}/>} label={"PM"}/>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={"past-shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Date</Typography>
                    <div className={"past-shows-filter-items-container"}>
                        {Months.map((month, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={months.includes(month)}
                                          onChange={() => handleSetMonths(month)}
                                />} label={month}/>
                        )}
                        <FormControl>
                            <TextField sx={{width: "100px", marginTop: "10px"}} label={"Year"} size={"small"} value={year} onChange={handleSetYear}/>
                        </FormControl>
                    </div>
                </div>
                <div className={"past-shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Placement</Typography>
                    <div className={"past-shows-filter-items-container"}>
                        {Placements.map((placement, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={placements.includes(placement)}
                                          onChange={() => handleSetPlacements(placement)}
                                />} label={placement}/>
                        )}
                    </div>
                </div>
                <div className={"past-shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Sort By</Typography>
                    <FormControl>
                        <RadioGroup value={sortBy} onChange={handleSortBy}>
                            <FormControlLabel value={"date"} control={<Radio sx={filterCheckboxStyle}/>} label={"Date"}/>
                            <FormControlLabel value={"title"} control={<Radio sx={filterCheckboxStyle}/>} label={"Title"}/>
                            <FormControlLabel value={"score"} control={<Radio sx={filterCheckboxStyle}/>} label={"Score"}/>
                            <FormControlLabel value={"placement"} control={<Radio sx={filterCheckboxStyle}/>} label={"Placement"}/>
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className={"past-shows-close-clear-container"}>
                <Close onClick={closeFilters} />
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}} className={props.darkMode ? "past-shows-container-dark" : "past-shows-container"}>
            {filtersOpen && filters}
            <Box sx={{overflow: "auto"}}>
                <Box sx={{width: "100%", display: "table", tableLayout: "fixed"}}>
                    <Toolbar sx={{
                        display: "flex", 
                        flexFlow: "row nowrap", 
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main :  lightTheme.palette.secondary.main,
                        width: "100%",
                        padding: 0,
                        margin: 0
                    }}>
                        <FilterList onClick={openFilters} />
                        <TablePagination
                            sx={{alignSelf: "flex-end"}}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            component="div"
                            count={shows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Toolbar>
                    {shows && <Table style={{padding: 20}}>
                        <TableHead sx={{backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}>
                            <TableRow>
                                <TableCell className={"show-date"}>Date</TableCell>
                                <TableCell className={"show-title"}>Title</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Placement</TableCell>
                                <TableCell>View</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? shows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : shows).map((show, i) =>
                                <TableRow key={i}>
                                    <TableCell
                                        className={"show-date"}>{formatDate(show.eventDate)}</TableCell>
                                    <TableCell className={"show-title"}>{show.title}</TableCell>
                                    <TableCell>{Math.trunc(show.score * 100) / 100}</TableCell>
                                    <TableCell>{Placements[show.placement]}</TableCell>
                                    <TableCell>
                                        <Link to={`/shows/${show.id}`}>
                                            <Button variant={"contained"}>View</Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>}
                </Box>
            </Box>
        </div>
    )
}

export default ShowList;