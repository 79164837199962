import "./FoundationImport.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select, Typography
} from "@mui/material";
import {useCookies} from "react-cookie";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../../../enums/Breeds";
import {darkTheme, lightTheme} from "../../../../Theme";
import {ImportBreeds} from "../../../../enums/ImportBreeds";

const FoundationImport = (props) => {
    const ranchId = props.ranchId;
    
    const [clicked, setClicked] = useState(false);
    
    const [breed, setBreed] = useState("");
    
    const handleBreedChange = (event) => {
        setBreed(event.target.value);
    }
    
    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {
            params: {
                id: ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data);
            });
    }, [ranchId, props.locationsUpdated, clicked]);
    
    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {
            params: {
                id: ranchId
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data);
            });
    }, [ranchId, props.locationsUpdated, clicked]);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);
    
    const [locked, setLocked] = useState(false);

    useEffect(() => {
        if(locations.length < 1) {
            setLocked(true);
        } else {
            setLocked(false);
        }
    }, [locations]);
    
    const [location, setLocation] = useState(0);

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }
    
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [message, setMessage] = useState("");
    
    const importFoundation = async () => {
        setClicked(true);
        var formData = new FormData();
        formData.append("breed", breed);
        formData.append("user", props.user.id);
        formData.append("ranch", ranchId);
        formData.append("location", location);

        if(location != undefined){
            await axios.post('/api/Horses/createFoundation', formData, props.formConfig)
                .then(function (response) {
                    console.log(response);
                    setMessage(response.data);
                    props.handleRefresh();
                }).catch(function (error) {
                    console.log(error);
                    setMessage(error.response.data);
                });
            handleOpen();
        } else {
            setMessage("Please choose a pasture or barn. If you do not have an option, please create an additional pasture or barn.");
            handleOpen();
        }
        setClicked(false);
    }

    const selectStyle = {
        minWidth: "85%",
        margin: 1
    }
    
    return(
        <>
            <div style={props.darkMode ? {
                backgroundColor: darkTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px #262626"
            } : {
                backgroundColor: lightTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px darkgray"
            }} className={"foundation-import-container"}>
                <div style={{backgroundColor: lightTheme.palette.secondary.main}} className={"foundation-import-title-container"}>
                    <Typography sx={{flexGrow: 2, textAlign: "center", paddingLeft: "30px"}} variant={"h3"}>Import</Typography>
                    <Typography>{props.credits}</Typography>
                </div>
                <FormControl sx={selectStyle}>
                    <InputLabel>Choose a breed</InputLabel>
                    <Select labelId={"breed"} label={"Choose a breed"} size={"small"} value={breed}
                            onChange={handleBreedChange} defaultValue={0}>
                        <MenuItem disabled value={0}>Select a Breed</MenuItem>
                        {[...ImportBreeds].sort().map(breed =>
                            <MenuItem value={breed.replace(/ /g, '')}>{breed}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={selectStyle}>
                    <InputLabel>Choose a pasture or barn</InputLabel>
                    <Select labelId={"pasture"} label={"Choose a location"} size={"small"}
                            onChange={handleSetLocation} defaultValue={0}>
                        <MenuItem disabled key={0} value={0}>Select a location</MenuItem>
                        {locations && locations.map(location =>
                            <MenuItem key={location.name}
                                      value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Button sx={{alignSelf: "flex-end", margin: "15px 30px 0 30px"}}
                        size={"small"}
                        variant={"contained"}
                        onClick={importFoundation}
                        disabled={clicked || locked || location === 0}>Import</Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FoundationImport