import "./Excersize.css";

import { React } from "react";
import Button from "@mui/material/Button";
const Exercise = (props) => {
    
    const handleExercise = (endpoint) => {
        props.handleExercise(endpoint);
    }

    return (
        <div className={"exercise"}>
            <p className={"exercise-title"}>{props.title}</p>
            {props.isOwner && <Button variant={"contained"} sx={{height: 30, width: 80}}
                     onClick={handleExercise.bind(this, props.endpoint)}
                     disabled={props.underage || props.isInjured || (props.horse.sale.isLeased && !props.horse.sale.allowTraining)}>Exercise</Button>}
        </div>
    )
}

export default Exercise;