import "./Sales.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Box, FormControl, InputLabel, Select, Tab, TextField} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import SalesCard from "./SaleCard";
import AuctionCard from "./AuctionCard";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../enums/Breeds";
import {useLocation, useNavigate} from "react-router-dom";

const Sales = (props) => {
    const ranchId = props.ranchId;

    const location = useLocation();
    const history = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };


    const [selectedTab, setSelectedTab] = useState(tab || "classifieds");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        updateUrlParams(newTab);
    }

    const [changed, setChanged] = useState(0);
    
    const handleSetChanged = () => {
        setChanged(changed + 1);
        props.handleSetRefreshed();
    }

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const moveForward = (event) => {
        setIndexStart(indexStart + 30);
        setIndexEnd(indexEnd + 30);
    }

    const moveBackward = (event) => {
        setIndexStart(indexStart - 30);
        setIndexEnd(indexEnd - 30);
    }
    
    const [breed, setBreed] = useState(null);
    
    const handleSetBreed = (event) => {
        setBreed(event.target.value);
    }
    
    const [age, setAge] = useState(-1);
    
    const handleSetAge = (event) => {
        setAge(event.target.value);
    }
    
    const [gender, setGender] = useState(null);
    
    const handleSetGender = (event) => {
        setGender(event.target.value);
    }

    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }

    const [owner, setOwner] = useState(null);

    const handleSetOwner = (event) => {
        setOwner(event.target.value);
    }

    const [name, setName] = useState(null);

    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const [saleHorses, setSaleHorses] = useState([]);
    const [auctionHorses, setAuctionHorses] = useState([]);

    useEffect(() => {
        const getSales = () => {
            axios.get('/api/sales/sales', {
                params: {
                    breedFilter: breed,
                    age: age,
                    genderFilter: gender,
                    name: name,
                    ranch: ranch,
                    owner: owner
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
                .then(response => {
                    setSaleHorses(response.data.sort(function(a, b){
                        return parseInt(a.horseId) - parseInt(b.horseId);
                    }));
                })
        }

        const timer = setTimeout(() => {
            getSales();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [changed, gender, age, breed, name, ranch, owner]);

    useEffect(() => {
        const getAuctions = () => {
            axios.get('/api/sales/auctions', {
                params: {
                    breedFilter: breed,
                    age: age,
                    genderFilter: gender,
                    name: name,
                    ranch: ranch,
                    owner: owner
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
                .then(response => {
                    setAuctionHorses(response.data.sort(function(a, b){
                        return parseInt(a.horseId) - parseInt(b.horseId);
                    }));
                })
        }

        const timer = setTimeout(() => {
            getAuctions();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [changed, gender, age, breed, name, ranch, owner]);
    
    const filters = (
        <div>
            <FormControl sx={{margin: 1}}>
                <InputLabel id={"Choose a breed"}>Breed</InputLabel>
                <Select value={breed} labelId={"Choose a breed"} size={"small"} variant={"filled"} sx={{width: 200}} onChange={handleSetBreed}>
                    <MenuItem key={-1} value={"All"}>All</MenuItem>
                    <MenuItem key={0} value={"Grade"}>Open</MenuItem>
                    {[...Breeds].sort().map(b =>
                        <MenuItem key={Breeds.indexOf(b)} value={b.replaceAll(" ", "")}>{b}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{margin: 1}}>
                <InputLabel id={"Choose a gender"}>Gender</InputLabel>
                <Select value={gender} labelId={"Gender"} size={"small"} variant={"filled"}  sx={{width: 200}} onChange={handleSetGender}>
                    <MenuItem key={0} value={"All"}>All</MenuItem>
                    <MenuItem key={1} value={"Mare"}>Mares</MenuItem>
                    <MenuItem key={2} value={"Stallion"}>Stallions</MenuItem>
                    <MenuItem key={3} value={"Gelding"}>Geldings</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{margin: 1}}>
                <InputLabel id={"Choose an age"}>Age</InputLabel>
                <Select value={age} labelId={"Age"} size={"small"} variant={"filled"}  sx={{width: 200}} onChange={handleSetAge}>
                    <MenuItem key={-1} value={-1}>All</MenuItem>
                    <MenuItem key={0} value={0}>Weanling</MenuItem>
                    <MenuItem key={1} value={1}>1</MenuItem>
                    <MenuItem key={2} value={2}>2</MenuItem>
                    <MenuItem key={3} value={3}>3</MenuItem>
                    <MenuItem key={4} value={4}>4</MenuItem>
                    <MenuItem key={5} value={5}>5</MenuItem>
                    <MenuItem key={6} value={6}>6</MenuItem>
                    <MenuItem key={7} value={7}>7</MenuItem>
                    <MenuItem key={8} value={8}>8</MenuItem>
                    <MenuItem key={9} value={9}>9</MenuItem>
                    <MenuItem key={10} value={10}>10+</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <TextField label={"Ranch"} variant={"outlined"} size={"small"} sx={{margin: "10px"}} value={ranch} onChange={handleSetRanch}/>
            </FormControl>
            <FormControl>
                <TextField label={"Owner"} variant={"outlined"} size={"small"} sx={{margin: "10px"}} value={owner} onChange={handleSetOwner}/>
            </FormControl>
            <FormControl>
                <TextField label={"Name"} variant={"outlined"} size={"small"} sx={{margin: "10px"}} onChange={handleSetName}/>
            </FormControl>
        </div>
    )
    
    return (
        <div className={"sales-horses-page"}>
            <img className={"horsesBanner"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/sales-banner.jpg"} alt={"auction"}/>
            <TabContext value={selectedTab} >
                <Box width={"100%"}>
                    <TabList className={"horses-tabs"} onChange={handleChangeTab} centered  textColor={"white"}>
                        <Tab label={"Classifieds"} value={"classifieds"}/>
                        <Tab label={"Auctions"} value={"auctions"}/>
                    </TabList>
                </Box>
                {saleHorses && <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"horses-tab"} value={"classifieds"}>
                    <div>
                        {filters}
                        <div className={"page-nav-section"}>
                            {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                            {indexEnd > saleHorses.length ? "" :
                                <p className={"page-nav"} onClick={moveForward}>Next</p>}
                        </div>
                        <div className={"sales-horses-section"}>
                            {saleHorses.slice(indexStart, indexEnd).map(horse =>
                                <SalesCard key={horse.horseId} horse={horse} id={horse.horseId} ranchId={ranchId} email={props.email} formConfig={props.formConfig} api={props.api} token={props.token} handleSetChanged={handleSetChanged}/>
                            )}
                        </div>
                        <div className={"page-nav-section"}>
                            {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                            {indexEnd > saleHorses.length ? "" :
                                <p className={"page-nav"} onClick={moveForward}>Next</p>}
                        </div>
                    </div>
                </TabPanel>}
                {auctionHorses && <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"horses-tab"} value={"auctions"}>
                    <div>
                        {filters}
                        <div className={"page-nav-section"}>
                            {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                            {indexEnd > auctionHorses.length ? "" :
                                <p className={"page-nav"} onClick={moveForward}>Next</p>}
                        </div>
                        <div className={"sales-horses-section"}>
                            {auctionHorses.slice(indexStart, indexEnd).map(horse =>
                                <AuctionCard key={horse.horseId} horse={horse} id={horse.horseId} ranchId={ranchId} email={props.email} formConfig={props.formConfig} api={props.api} token={props.token} handleSetChanged={handleSetChanged}/>
                            )}
                        </div>
                        <div className={"page-nav-section"}>
                            {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                            {indexEnd > auctionHorses.length ? "" :
                                <p className={"page-nav"} onClick={moveForward}>Next</p>}
                        </div>
                    </div>
                </TabPanel>}
            </TabContext>
        </div>
    )
}

export default Sales;