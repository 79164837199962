import React, {useEffect, useState} from 'react';

import "./Conformation.css";

import Stat from "./Stat";
import axios from "axios";

function Conformation(props){

    const id = props.id;
    
    const [backCurve, setBackCurve] = useState(0);
    const [backLength, setBackLength] = useState(0);
    const [bone, setBone] = useState(0);
    const [chestWidth, setChestWidth] = useState(0);
    const [crest, setCrest] = useState(0);
    const [foreleg, setForeleg] = useState(0);
    const [headProfile,  setHeadProfile] = useState(0);
    const [headSize, setHeadSize] = useState(0);
    const [hipAngle, setHipAngle] = useState(0);
    const [hipLength, setHipLength] = useState(0);
    const [hipWidth, setHipWidth] = useState(0);
    const [hockAngle, setHockAngle] = useState(0);
    const [hoofSize, setHoofSize] = useState(0);
    const [humerusAngle, setHumerusAngle] = useState(0);
    const [humerusLength, setHumerusLength] = useState(0);
    const [kneeFront, setKneeFront] = useState(0);
    const [kneeSide, setKneeSide] = useState(0);
    const [legProportion, setLegProportion] = useState(0);
    const [loin, setLoin] = useState(0);
    const [muscleMass, setMuscleMass] = useState(0);
    const [neckCurve, setNeckCurve] = useState(0);
    const [neckLength, setNeckLength] = useState(0);
    const [neckPosition, setNeckPosition] = useState(0);
    const [nostrilSize, setNostrilSize] = useState(0);
    const [pasternAngle, setPasternAngle] = useState(0);
    const [pasternLength, setPasternLength] = useState(0);
    const [ribcage, setRibcage] = useState(0);
    const [shoulderLength, setShoulderLength] = useState(0);
    const [shoulderSlope, setShoulderSlope] = useState(0);
    const [topline, setTopline] = useState(0);
    const [tracking, setTracking] = useState(0);
    const [kneeAction, setKneeAction] = useState(0);
    const [hockAction, setHockAction] = useState(0);
    const [stride, setStride] = useState(0);
    const [feathering, setFeathering] = useState(0);
    const [tailSet, setTailSet] = useState(0)

    useEffect(() => {
        axios.get('/api/Horses/Conformation', {params: {id: id}, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBackCurve(response.data.allConformation.backCurve?.finalValue);
                setBackLength(response.data.allConformation.backLength?.finalValue);
                setBone(response.data.allConformation.bone?.finalValue * 10);
                setChestWidth(response.data.allConformation.chestWidth?.finalValue);
                if(response.data.allConformation.crest?.finalValue == 2){
                    setCrest(100);
                } else if(response.data.allConformation.crest?.finalValue == 1){
                    setCrest(50);
                } else {
                    setCrest(0);
                }
                setForeleg(response.data.allConformation.foreleg?.finalValue);
                setHeadProfile(response.data.allConformation.headProfile?.finalValue);
                setHeadSize(response.data.allConformation.headSize?.finalValue);
                setHipAngle(response.data.allConformation.hipAngle?.finalValue);
                setHipLength(response.data.allConformation.hipLength?.finalValue);
                setHipWidth(response.data.allConformation.hipWidth?.finalValue);
                setHockAngle(response.data.allConformation.hockAngle?.finalValue);
                setHoofSize(response.data.allConformation.hoofSize?.finalValue);
                setHumerusAngle(response.data.allConformation.humerusAngle?.finalValue);
                setHumerusLength(response.data.allConformation.humerusLength?.finalValue);
                setKneeFront(response.data.allConformation.kneeFront?.finalValue);
                setKneeSide(response.data.allConformation.kneeSide?.finalValue);
                setLegProportion(response.data.allConformation.legProportion?.finalValue);
                setLoin(response.data.allConformation.loin?.finalValue);
                setMuscleMass((response.data.allConformation.muscleMass?.finalValue / 30)* 100)
                setNeckCurve(response.data.allConformation.neckCurve?.finalValue);
                setNeckLength(response.data.allConformation.neckLength?.finalValue);
                setNeckPosition(response.data.allConformation.neckPosition?.finalValue);
                setNostrilSize(response.data.allConformation.nostrilSize?.finalValue);
                setPasternAngle(response.data.allConformation.pasternAngle?.finalValue);
                setPasternLength(response.data.allConformation.pasternLength?.finalValue);
                setRibcage(response.data.allConformation.ribcage?.finalValue);
                setShoulderLength(response.data.allConformation.shoulderLength?.finalValue);
                setShoulderSlope(response.data.allConformation.shoulderSlope?.finalValue);
                setTopline(response.data.allConformation.topline?.finalValue);
                setTracking(response.data.allConformation.tracking?.finalValue);
                setKneeAction(response.data.kneeAction?.finalValue);
                setHockAction(response.data.hockAction?.finalValue);
                setStride(response.data.stride.finalValue);
                if(response.data.allConformation.feathering.hasFeathering){
                    setFeathering(100);
                }
                setTailSet(response.data.allConformation.tailSet?.finalValue)
            });
    }, [id]);
    
    return(
        <div className={"conformation"}>
            <Stat title={"Knee Action"} low={"Low"} mid={"Moderate"} high={"High"} stat={kneeAction} darkMode={props.darkMode} />
            <Stat title={"Hock Action"} low={"Low"} mid={"Moderate"} high={"High"} stat={hockAction} darkMode={props.darkMode} />
            <Stat title={"Stride"} low={"Short"} midLeft={"Average"} midRight={"Long"} high={"Forges"} stat={stride} darkMode={props.darkMode} />
            <Stat title={"Head Profile"} low={"Dished"} mid={"Straight"} high={"Roman"} stat={headProfile} darkMode={props.darkMode} />
            <Stat title={"Head Size"} low={"Small"} mid={"Ideal"} high={"Large"} stat={headSize} darkMode={props.darkMode}/>
            <Stat title={"Nostril Size"} low={"Small"} mid={"Average"} high={"Large"} stat={nostrilSize} darkMode={props.darkMode}/>
            <Stat title={"Neck Length"} low={"Short"} mid={"Average"} high={"Long"} stat={neckLength} darkMode={props.darkMode} />
            <Stat title={"Neck Position"} low={"Low"} mid={"Middle"} high={"High"} stat={neckPosition} darkMode={props.darkMode}/>
            <Stat title={"Neck Curve"} low={"Ewed"} midLeft={"Straight"} midRight={"Arched"} high={"Swanned"} stat={neckCurve} darkMode={props.darkMode} />
            <Stat title={"Crest"} low={"None"} mid={"Small"} high={"Large"} stat={crest} darkMode={props.darkMode}/>
            <Stat title={"Shoulder Length"} low={"Short"} mid={"Ideal"} high={"Long"} stat={shoulderLength} darkMode={props.darkMode}/>
            <Stat title={"Shoulder Slope"} low={"Upright"} mid={"Middle"} high={"Laid Back"} stat={shoulderSlope} darkMode={props.darkMode} />
            <Stat title={"Chest Width"} low={"Narrow"} mid={"Ideal"} high={"Wide"} stat={chestWidth} darkMode={props.darkMode}/>
            <Stat title={"Humerus Length"} low={"Short"} mid={"Average"} high={"Long"} stat={humerusLength} darkMode={props.darkMode}/>
            <Stat title={"Humerus Angle"} low={"Flat"} mid={"Moderate"} high={"Steep"} stat={humerusAngle} darkMode={props.darkMode}/>
            <Stat title={"Foreleg Proportion"} low={"Short"} mid={"Equal"} high={"Long"} stat={foreleg} darkMode={props.darkMode}/>
            <Stat title={"Knee Front"} low={"Knock"} mid={"Straight"} high={"Bowed"} stat={kneeFront} darkMode={props.darkMode}/>
            <Stat title={"Knee Side"} low={"Over"} mid={"Straight"} high={"Back"} stat={kneeSide} darkMode={props.darkMode}/>
            <Stat title={"Pastern Angle"} low={"Upright"} mid={"Ideal"} high={"Sloped"} stat={pasternAngle} darkMode={props.darkMode}/>
            <Stat title={"Pastern Length"} low={"Short"} mid={"Ideal"} high={"Long"} stat={pasternLength} darkMode={props.darkMode}/>
            <Stat title={"Topline"} low={"Downhill"} mid={"Level"} high={"Uphill"} stat={topline} darkMode={props.darkMode}/>
            <Stat title={"Back Length"} low={"Short"} mid={"Average"} high={"Long"} stat={backLength} darkMode={props.darkMode}/>
            <Stat title={"Back Curve"} low={"Roached"} mid={"Ideal"} high={"Swayed"} stat={backCurve} darkMode={props.darkMode}/>
            <Stat title={"Ribcage"} low={"Narrow"} mid={"Average"} high={"Wide"} stat={ribcage} darkMode={props.darkMode}/>
            <Stat title={"Hip Angle"} low={"Flat"} mid={"Ideal"} high={"Steep"} stat={hipAngle} darkMode={props.darkMode}/>
            <Stat title={"Hip Length"} low={"Short"} mid={"Adequate"} high={"Long"} stat={hipLength} darkMode={props.darkMode}/>
            <Stat title={"Hip Width"} low={"Narrow"} mid={"Ideal"} high={"Wide"} stat={hipWidth} darkMode={props.darkMode}/>
            <Stat title={"Loin"} low={"Short"} mid={"Average"} high={"Long"} stat={loin} darkMode={props.darkMode}/>
            <Stat title={"Leg Proportion"} low={"Short"} mid={"Ideal"} high={"Long"} stat={legProportion} darkMode={props.darkMode}/>
            <Stat title={"Hock Angle"} low={"Post-legged"} mid={"Ideal"} high={"Sickle"} stat={hockAngle} darkMode={props.darkMode}/>
            <Stat title={"Hoof Size"} low={"Small"} mid={"Ideal"} high={"Large"} stat={hoofSize} darkMode={props.darkMode}/>
            <Stat title={"Muscle Mass"} low={"Light"} mid={"Average"} high={"Heavy"} stat={muscleMass} darkMode={props.darkMode}/>
            <Stat title={"Bone"} low={"Fine Boned"} mid={"Average"} high={"Big Boned"} stat={bone} darkMode={props.darkMode}/>
            <Stat title={"Tracking"} low={"Toed In"} mid={"Straight"} high={"Toed Out"} stat={tracking} darkMode={props.darkMode}/>
            <Stat title={"Tail Set"} low={"Low"} mid={"Middle"} high={"High"} stat={tailSet} darkMode={props.darkMode}/>
            <Stat title={"Feathering"} low={"None"} high={"Feathered"} stat={feathering} darkMode={props.darkMode}/>
        </div>
    )
}

export default Conformation;