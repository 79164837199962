import "./PrivateSalesLeases.css";

import React from "react";
import PrivateSales from "./private/PrivateSales";
import DividerTitle from "../../DividerTitle";
import PendingLeases from "./leases/PendingLeases";
import ActiveLeases from "./leases/ActiveLeases";
import Leased from "./leases/Leased";
import Approvals from "./approvals/Approvals";

const PrivateSalesLeases = (props) => {
    
    
    return (
        <div className={"private-sales-leases-container"}>
            <div className={"private-sales-leases-sub-container"}>
                <PrivateSales ranchId={props.ranchId}
                              email={props.email}
                              api={props.api}
                              formConfig={props.formConfig}
                              config={props.config}
                              handleSetRefreshed={props.handleSetRefreshed}
                              darkMode={props.darkMode}/>
                <Approvals id={props.ranchId}
                           api={props.api}
                           token={props.token}
                           config={props.config}/> 
            </div>
            <DividerTitle title={"Leases"} darkMode={props.darkMode} />
            <div className={"private-sales-leases-sub-container"}>
                <PendingLeases ranchId={props.ranchId}
                               email={props.email}
                               api={props.api}
                               token={props.token}
                               formConfig={props.formConfig}
                               config={props.config}
                               handleSetRefreshed={props.handleSetRefreshed}
                               darkMode={props.darkMode}/>
                <ActiveLeases ranchId={props.ranchId}
                              email={props.email}
                              api={props.api}
                              token={props.token}
                              formConfig={props.formConfig}
                              config={props.config}
                              handleSetRefreshed={props.handleSetRefreshed}
                              darkMode={props.darkMode}/>
                <Leased ranchId={props.ranchId}
                        email={props.email}
                        api={props.api}
                        token={props.token}
                        formConfig={props.formConfig}
                        config={props.config}
                        handleSetRefreshed={props.handleSetRefreshed}
                        darkMode={props.darkMode}/>
            </div>
        </div>
    )
}

export default PrivateSalesLeases