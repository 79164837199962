import "./PendingLeases.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Pagination, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../../../../Theme";
import ActiveLeaseCard from "./ActiveLeaseCard";

const PendingLeases = (props) => {

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }

    const [leaseHorses, setLeaseHorses] = useState([]);

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        axios.get('/api/Sales/leaseeActiveLeases',
            {
                params:
                    {
                        ranchId: props.ranchId,
                        page: pageNumber
                    }, 
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
            .then(response => {
                setLeaseHorses(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [pageNumber, updated]);

    useEffect(() => {
        axios.get('/api/Sales/leaseeActiveLeasesCount',
            {
                params:
                    {
                        ranchId: props.ranchId,
                        page: pageNumber
                    }, 
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
            .then(response => {
                setPagesTotal(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [pageNumber, updated]);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    return (
        <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}} className={"pending-leases-container"}>
            <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"pending-leases-title-container"}>
                <Typography sx={{flexGrow: 2, textAlign: "center", paddingLeft: 15}} variant={"h3"}>Active Leases</Typography>
                <Pagination variant={"outlined"}
                            shape={"rounded"}
                            boundaryCount={2}
                            page={pageNumber}
                            count={pagesTotal}
                            onChange={handleSetPageNumber}
                            itemRef={"pagination"}
                />
            </div>
            <div className={"pending-leases-sub-container"}>
                <div className={"horsesSection"}>
                    {leaseHorses.length === 0 ? <Typography sx={{margin: 10}}>No active leases.</Typography> : ""}
                    {leaseHorses.map(horse =>
                        <ActiveLeaseCard key={horse.horseId} horse={horse} id={horse.horseId} ranchId={props.ranchId}
                                   email={props.email} formConfig={props.formConfig} api={props.api} token={props.token}
                                   handleSetUpdated={handleSetUpdated} config={props.config}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PendingLeases;