import "./Clubs.css";

import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import DividerTitle from "../DividerTitle";
import axios from "axios";
import ActiveClubCard from "./ActiveClubCard";
import {Link} from "react-router-dom";

const Clubs = (props) => {

    const [activeClubs, setActiveClubs] = useState([]);

    useEffect(() => {
        axios.get("api/Clubs/activeClubs", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setActiveClubs(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const [disciplineClubs, setDisciplineClubs] = useState([]);

    useEffect(() => {
        axios.get("api/Clubs/disciplineClubs", {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setDisciplineClubs(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const [breedClubs, setBreedClubs] = useState([]);

    useEffect(() => {
        axios.get("api/Clubs/breedClubs", {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setBreedClubs(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    return (
        <div>
            <img src={"https://media.legacyhorsegame.com/banners/clubs-banner.jpg"} alt={"Horses lined up on fence"}/>
            <div className={"active-clubs-container"}>
                {activeClubs.length > 0 ? activeClubs.map(club =>
                    <ActiveClubCard club={club}/>
                ) : <Typography sx={{alignSelf: "center"}}>You are not an active member of any clubs.</Typography>}
            </div>
            <DividerTitle title={"Discipline Clubs"} darkMode={props.darkMode}/>
            <div className={"discipline-clubs-container"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Club Name</TableCell>
                            <TableCell>Lifetime Horses</TableCell>
                            <TableCell>Active Horses</TableCell>
                            <TableCell align={"center"}>Total Members</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {disciplineClubs && disciplineClubs.map(club =>
                            <TableRow key={club.clubId}>
                                <TableCell>
                                    <Link to={`/clubs/${club.clubId}`}>{club.name}</Link>
                                </TableCell>                                
                                <TableCell>{club.lifetimeHorses}</TableCell>
                                <TableCell>{club.activeHorses}</TableCell>
                                <TableCell>{club.members.length}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <DividerTitle title={"Breed Clubs"} darkMode={props.darkMode}/>
            <div className={"breed-clubs-container"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Club Name</TableCell>
                            <TableCell align={"center"}>Total Members</TableCell>
                            <TableCell>Active Horses</TableCell>
                            <TableCell align={"center"}>Total Members</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breedClubs.map(club =>
                            <TableRow key={club.clubId}>
                                <TableCell>
                                    <Link to={`/clubs/${club.clubId}`}>{club.name}</Link>
                                </TableCell>
                                <TableCell>{club.lifetimeHorses}</TableCell>
                                <TableCell>{club.activeHorses}</TableCell>
                                <TableCell>{club.members.length}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default Clubs