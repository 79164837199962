import "./RanchManagement.css";

import React, {useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {
    Dialog, DialogActions,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";

const RanchManagement = (props) => {
    
    const [selectedRanch, setSelectedRanch] = useState(null);

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = (ranch) => {
        setSelectedRanch(ranch);
        console.log(ranch)
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [message, setMessage] = useState();
    
    const resetRanch = (event) => {
        handleCloseConfirmation();
        axios.post("api/Ranches/reset", selectedRanch.ranchId, props.config)
            .then(response => {
                console.log(response);
                setMessage(response.data);
                handleOpen();
            }).catch(error => {
                console.log(error);
            setMessage(error.response.data);
            handleOpen();
        })
    }

    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={props.darkMode ? "ranch-management-container ranch-management-container-dark" : "ranch-management-container"}>
                <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"ranch-management-header-container"}>
                    <Typography variant={"h3"}>Ranch Management</Typography>
                </div>
                <Table>
                    <TableHead>
                        <TableRow sx={{fontWeight: "bolder"}}>
                            <TableCell>Ranch Name</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Reset</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.ranches.map(ranch =>
                            <TableRow key={ranch.ranchId}>
                                <TableCell>{ranch.name}</TableCell>
                                <TableCell>{(ranch.balance).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumSignificantDigits: Math.trunc(Math.abs(ranch.balance)).toFixed().length
                                })}</TableCell>
                                <TableCell>
                                    <Button variant={"contained"} onClick={handleOpenConfirmation.bind(this, ranch)}>Reset</Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>

            <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Reset Confirmation</DialogTitle>
                {selectedRanch ? <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Are you sure you want to reset {selectedRanch.name}? This action is permanent.
                </DialogContentText> : ""}
                <DialogActions>
                    <Button onClick={handleCloseConfirmation} variant={"contained"}>Cancel</Button>
                    <Button onClick={resetRanch} variant={"contained"}>Reset</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> </DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RanchManagement