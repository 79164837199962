import "./PrivateNote.css";

import React, {useState} from "react";
import {Close, Delete, Edit, Save} from "@mui/icons-material";
import {FormControl, TextField, Typography} from "@mui/material";
import axios from "axios";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import Button from "@mui/material/Button";

const PrivateNote = (props) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    
    const handleClose = () => {
        setOpen(false);
    }

    const [editing, setEditing] = useState(false);
    
    const handleSetEditing = () => {
        setEditing(true);
    }

    const [title, setTitle] = useState(props.note.title || "");

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const [content, setContent] = useState(props.note.content || "");

    const handleSetContent = (content, delta, source, editor) => {
        setContent(DOMPurify.sanitize(content, {
            USE_PROFILES: {html: true}
        }))
    }

    const [color, setColor] = useState(props.note.color || "");

    const handleSetColor = (event) => {
        setColor(event.target.value);
    }

    const handleEditNote = () => {
        const data = new FormData();
        data.append("id", props.note.noteId);
        data.append("email", props.email);
        data.append("title", title);
        data.append("content", content);
        data.append("color", color);

        axios.post('api/Application/editNote', data, props.formConfig)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
        
        setEditing(false);
    }

    const handleDeleteNote = () => {
        axios.post('api/Application/deleteNote', props.note.noteId, props.config)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })

        setEditing(false);
    }

    const getTextColor = (backgroundColor) => {
        // Convert the hexadecimal color to RGB
        const hexToRgb = (hex) =>
            hex
                .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1)
                .match(/.{2}/g)
                .map((x) => parseInt(x, 16));

        const [r, g, b] = hexToRgb(backgroundColor);

        // Calculate the relative luminance
        const luminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

        // Choose white or black text based on the luminance
        return luminance > 0.5 ? 'black' : 'white';
    };

    const textColor = getTextColor(props.note.color);
    
    return (
        <>
            <div style={{backgroundColor: props.note.color}} className={props.darkMode ? "private-note-container private-note-container-dark" : "private-note-container"} onClick={handleOpen}>
                <Typography sx={{paddingBottom: "15px", color: textColor}} variant={"h3"}>{props.note.title}</Typography>
                <div className={"private-note-content"}>
                    <Typography sx={{color: textColor}} dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(props.note.content, {
                            USE_PROFILES: {html: true}
                        })
                    }}/>
                </div>
            </div>
            {open && <div  style={{backgroundColor: props.note.color}} className={props.darkMode ? "open-private-note-container open-private-note-container-dark" : "open-private-note-container"}>
                <Typography sx={{paddingBottom: "15px", color: textColor}} variant={"h3"}>{props.note.title}</Typography>
                <Close sx={{position: "absolute", right: "10px", top: "10px", color: textColor}} onClick={handleClose}/>
                <div className={"private-note-btns"}>
                    <Edit sx={{color: textColor}} className={"private-note-btn"} onClick={handleSetEditing}/>
                    <Delete sx={{color: textColor}} className={"private-note-btn"} onClick={handleDeleteNote}/>
                </div>
                <Typography sx={{color: textColor, alignSelf: "flex-start"}} dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.note.content, {
                        USE_PROFILES: {html: true}
                    })
                }}/>
                {editing &&
                    <div className={"edit-note-container"}>
                        <div className={"edit-note-title-container"}>
                            <FormControl sx={{width: "85%", paddingBottom: "20px"}} >
                                <TextField label={"Title"} sx={{borderRadius: "4px"}} inputProps={{style: { color: textColor}}} 
                                           variant={"outlined"} size={"small"} value={title} onChange={handleSetTitle}/>
                            </FormControl>
                            <FormControl sx={{width: "50px", paddingBottom: "20px"}} >
                                <TextField type={"color"} sx={{borderRadius: "4px"}} variant={"outlined"} size={"small"} value={color} onChange={handleSetColor}/>
                            </FormControl>
                        </div>
                        <ReactQuill style={props.darkMode ? {backgroundColor: "transparent", color: textColor} : {}} value={content} dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(props.note.content, {
                                USE_PROFILES: {html: true}
                            })
                        }} onChange={handleSetContent}/>
                        <Button sx={{alignSelf: "flex-end"}} variant={"contained"} onClick={handleEditNote}>Save</Button>
                    </div>
                }
            </div>}
            
        </>
    )
}

export default PrivateNote;