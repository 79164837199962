import './UploadImage.css';

import React, {useState} from "react";
import aws from "aws-sdk";
import {PutObjectCommand, S3Client} from "@aws-sdk/client-s3";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContentText, DialogTitle, styled} from "@mui/material";
import {UploadFile} from "@mui/icons-material";
import emptyCache from "../../../ClearCache";


const UploadImage = (props) => {
    const endpoint = new aws.Endpoint("nyc3.digitaloceanspaces.com");
    const s3 = new S3Client({
        forcePathStyle: false, // Configures to use subdomain/virtual calling format.
        endpoint: endpoint,
        region: "us-east-1",
        credentials: {
            accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
        }
    });
    
    const [file, setFile] = useState();
    
    const handleFileChange = async (e) => {
        if(e.target.files.length > 0){
            setFile(e.target.files[0]);
        }
    }
    
    const handleUpload = async (event) => {
        const blob = event.target.files[0];
        
        const params = {
            Body: blob,
            Bucket: 'legacyhorsegame',
            Key: `${props.bucket}/${props.fileName}.png`,
            ContentType: 'image/png',
            ContentLength: blob.size,
            ACL: "public-read",
            CacheControl: "no-cache"
        };

        s3.send(new PutObjectCommand(params)).then(result => {
            setOpen(true);
            setTimeout(function () {
                emptyCache();
                window.location.reload(true);
            }, 1000)
        }).catch(error => {
            console.log(error);
        });
    }
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    
    return (
        <div className={"upload-image-container"}>
            <Button component="label" variant="contained" startIcon={<UploadFile />} onChange={handleUpload}>
                Upload
                <VisuallyHiddenInput type="file" />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    Image successfully changed.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UploadImage;