import "./ActiveLeaseCard.css";

import React, {useState} from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {
    Dialog,
    DialogActions, 
    DialogContentText,
    DialogTitle,
    Typography
} from "@mui/material";
import Photo from "../../../horse/photo/Photo";
import {Months} from "../../../../enums/Months";

const ActiveLeaseCard = (props) => {
    
    const [messageOpen, setMessageOpen] = useState(false);
    
    const openMessage = () => {
        setMessageOpen(true);
    }
    
    const closeError = () => {
        setMessageOpen(false);
    }
    
    const [message, setMessage] = useState("");

    const [returnOpen, setReturnOpen] = useState(false);

    const openReturn = () => {
        setReturnOpen(true);
    }

    const closeReturn = () => {
        setReturnOpen(false);
    }

    const returnLease = async () => {
        closeReturn();
        await axios.post('/api/sales/LeaseeReturnLease', props.id, props.config)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
                setMessage(response.data);
                openMessage();
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                openMessage();
            });
    }
    
    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }

    const female = (<img className={"active-lease-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"} alt={"female"}/>)
    const male = (<img className={"active-lease-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"} alt={"male"}/>)
    const gelding = (<img className={"active-lease-card-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    return (
        <>
            <div className={"active-lease-card-container"}>
                <Link to={`horses/${props.horse.horseId}`}>
                    {props.horse.gender == 0 ? female : props.horse.gelded ? gelding : male}
                    <Photo id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/>
                </Link>
                <div className={"active-lease-card-info-container"}>
                    <div className={"active-lease-card-name-container"}>
                        <Typography sx={{
                            mx: '10px',
                            textAlign: "right",
                            fontFamily: "Roboto Thin, sans-serif",
                            fontWeight: "bold",
                            fontStyle: "italic",
                            fontSize: "20px"
                        }}>{props.horse.prefix}</Typography>
                        <Typography sx={{
                            fontFamily: "Raleway, san-serif",
                            fontSize: "1.2em",
                            fontWeight: "bolder"
                        }}> {props.horse.regName}</Typography>
                    </div>
                    <Typography sx={{
                        fontFamily: "Roboto Light, san-serif",
                        fontSize: "1em"
                    }}>{props.horse.sale.leaseTimer} Months Remaining</Typography>
                </div>
                <Button variant={"contained"} onClick={openReturn}>End Lease Early</Button>
            </div>

            <Dialog sx={{padding: 5}} open={returnOpen} onClose={closeReturn}>
                <DialogTitle>Cancel</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Would you like to end this lease early? Lease price will be refunded to the leaser.
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={returnLease}>Ok</Button>
                    <Button variant={"contained"} onClick={closeReturn}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{padding: 5}} open={messageOpen} onClose={closeError}>
                <DialogTitle></DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={closeError}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ActiveLeaseCard;