import "./RanchSemens.css"
import {React, useEffect, useState} from "react";
import axios from "axios";
import SemenCard from "../../studs/SemenCard";

const RanchStuds = (props) => {
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [semens, setSemens] = useState([]);

    useEffect(() => {
        axios.get('api/Health/publicRanchSemens',{
            params: {
                id: props.ranch
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        })
            .then(response => {
                setSemens(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [updated])

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const moveForward = (event) => {
        setIndexStart(indexStart + 30);
        setIndexEnd(indexEnd + 30);
    }

    const moveBackward = (event) => {
        setIndexStart(indexStart - 30);
        setIndexEnd(indexEnd - 30);
    }
    
    return (
        <>
            {semens &&
                <div className={"horsesPage"}>
                    <div className={"page-nav-section"}>
                        {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                        {indexEnd > semens.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                    </div>
                    <div className={"horsesSection"}>
                        {semens.slice(indexStart, indexEnd).map(semen =>
                            <SemenCard key={semen.semenId} id={semen.semenId} horse={semen.stud} ranchId={props.ranchId} formConfig={props.formConfig}
                                       price={semen.price} api={props.api} token={props.token} amount={semen.amountForSale} handleSetUpdated={handleSetUpdated}/>
                        )}
                    </div>
                    <div className={"page-nav-section"}>
                        {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                        {indexEnd > semens.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                    </div>
                </div>
            }
        </>
    )
}

export default RanchStuds