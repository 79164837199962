import "./Disciplines.css";

import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import axios from "axios";
import CurrentLevel from "./CurrentLevel";
import {Discipline} from "../../../../../enums/Discipline";

const Disciplines = (props) => {
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const levels = [
        "None",
        "L1",
        "L2",
        "L3",
        "L4"
    ]
    
    const [availableLevels, setAvailableLevels] = useState(null);
    const [discipline, setDiscipline] = useState("");
    const [currentLevels, setCurrentLevels] = useState([]);

    useEffect(() => {
        axios.get("api/Horses/availableLevels", {
            params: {
                id: props.id,
                discipline: discipline
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setAvailableLevels(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.id, discipline]);
    
    const handleSetLevel = (event) => {
        const data = new FormData();

        data.append("id", props.id);
        data.append("level", event.target.value);
        data.append("discipline", discipline);

        axios.post("api/Horses/SetShowLevel", data, props.formConfig)
            .then(response => {
                console.log(response);
                setDiscipline("");
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }
    
    const handleSetDiscipline = (event) => {
        setDiscipline(event.target.value);
    }

    useEffect(() => {
        axios.get("api/Horses/ShowLevels", {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setCurrentLevels(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.id, updated]);
    
    return (
        <div className={props.darkMode ? "disciplines-container-dark" : "disciplines-container"}>
            <div className={"disciplines-title-container"}>
                <Typography variant={"h3"}>Disciplines</Typography>
            </div>
            <div className={"disciplines-form-container"}>
                <FormControl>
                    <InputLabel id={"discipline-select"}>Choose discipline</InputLabel>
                    <Select sx={{margin: 1, width: 250}} id={"discipline-select"} size={"small"} value={discipline} onChange={handleSetDiscipline} >
                        <MenuItem value={undefined} disabled>Choose discipline</MenuItem>
                        {Discipline.map((d, i) =>
                            <MenuItem key={i} value={d}>{d}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {discipline && <FormControl>
                    <InputLabel id={"level-select"}>Choose level</InputLabel>
                    <Select sx={{margin: 1, width: 250}} id={"level-select"} size={"small"} onChange={handleSetLevel}
                            defaultValue={null}>
                        <MenuItem value={"None"}>None</MenuItem>
                        {availableLevels && availableLevels.sort((a, b) => a - b).map((l, i) =>
                            <MenuItem key={i} value={l}>{l}</MenuItem>
                        )}
                    </Select>
                </FormControl>}
            </div>
            {currentLevels && <div className={"current-disciplines-container"}>
                {currentLevels.map((l, i) =>
                    <CurrentLevel key={i} discipline={Discipline[l.discipline]} level={levels[l.level]} darkMode={props.darkMode} />
                )}
            </div>}
        </div>
    )
}

export default Disciplines