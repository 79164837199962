import "./Ranch.css";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Owned from "./horses/Owned";
import PublicSales from "./PublicSales";
import RanchStuds from "./studs/RanchStuds";
import RanchSemens from "./studs/RanchSemens";
import RanchManager from "./manager/RanchManager";
import Bred from "./horses/Bred";
import General from "./general/General";
import Finances from "./finances/Finances";
import SemenStorage from "./semen/SemenStorage";
import PrivateSalesLeases from "./sales/PrivateSalesLeases";
import LocationManager from "./locations/LocationManager";
import FeedRoom from "./feed/FeedRoom";
import Banner from "./profile/Banner";
import Profile from "./profile/Profile";

const Ranch = (props) => {
    let { id } = useParams();

    const location = useLocation();
    const history = useNavigate();

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }

    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };
    
    const [ranch, setRanch] = useState(0);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        if(id === props.ranchId){
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }
    }, [location]);

    useEffect(() => {
        setSelectedTab(isOwner ? "general" : "profile");
    }, [isOwner]);
    
    useEffect(() => {
        axios.get('/api/Ranches/info', {
            params: {
                id: id
            }, headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setRanch(response.data);
            });
    }, [id, updated]);

    const [selectedTab, setSelectedTab] = useState(tab || "profile");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        updateUrlParams(newTab);
    }

    return(
        <div>
            {ranch && <Banner ranch={ranch}/>}
            <TabContext value={selectedTab}>
                <Box width={"100%"}>
                    <TabList variant={"scrollable"} className={"ranch-tabs"} onChange={handleChangeTab} scrollButtons={"auto"}  textColor={"white"}>
                        {isOwner ? <Tab label="General" value={"general"}/> : ""}
                        {isOwner ? <Tab label="Finances" value={"finances"}/> : ""}
                        {isOwner ? <Tab label="Semen Storage" value={"semen"}/> : ""}
                        {isOwner ? <Tab label="Sales & Leases" value={"salesAndLeases"}/> : ""}
                        {isOwner ? <Tab label="Manage Locations" value={"manageLocations"}/> : ""}
                        {isOwner ? <Tab label="Feed Room" value={"feedRoom"}/> : ""}
                        {isOwner && ranch.active ? <Tab label="Manager" value={"manager"}/> : ""}
                        {!isOwner ? <Tab label="Horses" value={"horses"}/> : ""}
                        {!isOwner ? <Tab label="Sales" value={"sales"}/> : ""}
                        {!isOwner ? <Tab label="Public Studs" value={"studs"}/> : ""}
                        {!isOwner ? <Tab label="Public Semen" value={"semens"}/> : ""}
                        {isOwner ? <Tab label="Profile" value={"profile"}/> : ""}
                        <Tab label="Horses Bred" value={"horsesBred"}/>
                    </TabList>
                </Box>
                <TabPanel className={"ranch-tab"} value={"general"}>
                    {isOwner && <General id={id} 
                                         ranch={ranch} 
                                         user={props.user} 
                                         api={props.api} 
                                         token={props.token} 
                                         config={props.config}
                                         formConfig={props.formConfig}
                                         darkMode={props.darkMode}
                                         handleRefresh={handleSetUpdated}
                    />}
                </TabPanel>
                {isOwner ? <TabPanel className={"ranch-tab"} value={"finances"}>
                    <Finances id={id} config={props.config} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {isOwner ? <TabPanel className={"ranch-tab"} value={"semen"} sx={{padding: 0}}>
                    <SemenStorage ranchId={id} api={props.api} formConfig={props.formConfig} token={props.token} email={props.email} config={props.config} handleSetRefreshed={props.handleSetRefreshed} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {isOwner ? <TabPanel className={"ranch-tab"} value={"salesAndLeases"} sx={{padding: 0}}>
                    <PrivateSalesLeases ranchId={id} email={props.email} api={props.api} token={props.token} formConfig={props.formConfig} config={props.config} handleSetRefreshed={props.handleSetRefreshed} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {isOwner && ranch.active ? <TabPanel className={"ranch-tab"} value={"manager"} sx={{padding: 0}}>
                    <RanchManager ranchId={id} email={props.email} api={props.api} formConfig={props.formConfig} token={props.token} config={props.config} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {!isOwner ? <TabPanel className={"ranch-tab"} value={"horses"} sx={{padding: 0}}>
                    <Owned ranch={id} api={props.api} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {!isOwner ? <TabPanel className={"ranch-tab"} value={"sales"} sx={{padding: 0}}>
                    <PublicSales ranch={id} api={props.api} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {isOwner ? <TabPanel className={"ranch-tab"} value={"manageLocations"} sx={{padding: 0}}>
                    <LocationManager ranchId={props.ranchId} date={props.date} api={props.api} token={props.token} config={props.config} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {isOwner ? <TabPanel className={"ranch-tab"} value={"feedRoom"} sx={{padding: 0}}>
                    <FeedRoom id={id} api={props.api} token={props.token} ranchId={props.ranchId} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {!isOwner ? <TabPanel className={"ranch-tab"} value={"studs"} sx={{padding: 0}}>
                    <RanchStuds ranch={id} api={props.api} token={props.token} ranchId={props.ranchId} config={props.config} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                {!isOwner ? <TabPanel className={"ranch-tab"} value={"semens"} sx={{padding: 0}}>
                    <RanchSemens ranch={id} api={props.api} token={props.token} ranchId={props.ranchId} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>
                </TabPanel> : ""}
                <TabPanel className={"ranch-tab"} value={"horsesBred"} sx={{padding: 0}}>
                    <Bred ranch={id} api={props.api} darkMode={props.darkMode}/>
                </TabPanel>
                <TabPanel className={"ranch-tab"} value={"profile"} sx={{padding: 0}}>
                    <Profile ranch={ranch} darkMode={props.darkMode}/>
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default Ranch;