export const Body = [
    "Mini",
    "Pony",
    "Light",
    "Stock",
    "Cob",
    "Saddle",
    "Warmblood",
    "Baroque",
    "Draft"
]