import "./CustomImport.css"

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../../../enums/Breeds";
import Typography from "@mui/material/Typography";
import {darkTheme, lightTheme} from "../../../../Theme";
import {ImportBreeds} from "../../../../enums/ImportBreeds";

const CustomImport = (props) => {
    const [clicked, setClicked] = useState(false);

    const [breed, setBreed] = useState("");

    const handleBreedChange = (event) => {
        setBreed(event.target.value);
    }

    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {
            params: {
                id: props.ranchId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setPastures(response.data);
            });
    }, [props.ranchId, props.locationsUpdated]);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {
            params: {
                id: props.ranchId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setBarns(response.data);
            });
    }, [props.ranchId, props.locationsUpdated]);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);

    const [location, setLocation] = useState(0);

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [baseColor, setBaseColor] = useState("");

    const handleSetBaseColor = (event) => {
        setBaseColor(event.target.value);
    }

    const [dilutionA, setDilutionA] = useState("");

    const handleSetDilutionA = (event) => {
        setDilutionA(event.target.value);
    }

    const [dilutionB, setDilutionB] = useState("");

    const handleSetDilutionB = (event) => {
        setDilutionB(event.target.value);
    }

    const [modifier, setModifier] = useState("");

    const handleSetModifier = (event) => {
        setModifier(event.target.value);
    }

    const [pattern, setPattern] = useState("");

    const handleSetPattern = (event) => {
        setPattern(event.target.value);
    }

    const [gender, setGender] = useState("");

    const handleSetGender = (event) => {
        setGender(event.target.value);
        console.log(gender)
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [message, setMessage] = useState();

    const importFoundation = async () => {
        setClicked(true);
        const formData = new FormData();
        formData.append("breed", breed);
        formData.append("user", props.user.id);
        formData.append("ranch", props.ranchId);
        formData.append("location", location);
        formData.append("baseColor", baseColor);
        formData.append("dilutionA", dilutionA);
        formData.append("dilutionB", dilutionB);
        formData.append("modifier", modifier);
        formData.append("pattern", pattern);
        formData.append("gender", gender);

        await axios.post('/api/Horses/createCustomFoundation', formData, props.formConfig)
            .then(response => {
                console.log(response);
                setMessage(response.data);
                props.handleRefresh();
            }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
            });
        setClicked(false);
        handleOpen();
    }

    const baseColors = [
        "Bay",
        "Black",
        "Chestnut",
        "Seal Brown",
        "Wild Bay"
    ]

    const dilutions = [
        "Cream",
        "Pearl",
        "Champagne",
        "Dun",
        "Silver",
        "Gray"
    ]

    const modifiers = [
        "Sooty",
        "Dapples",
        "Pangare",
        "Rabicano"
    ]

    const patterns = [
        {
            name: "Splash White",
            value: "Splash"
        },
        {
            name: "Tobiano",
            value: "Tobiano"
        },
        {
            name: "Frame",
            value: "Frame"
        },
        {
            name: "Leopard",
            value: "Leopard"
        },
        {
            name: "Roan",
            value: "Roan"
        },
        {
            name: "Sabino 1",
            value: "Sab1"
        },
        {
            name: "W1",
            value: "W1"
        },
        {
            name: "W10",
            value: "W10"
        },
        {
            name: "W14",
            value: "W14"
        },
        {
            name: "W20",
            value: "W20"
        },
    ]

    const selectStyle = {
        minWidth: "46%",
        margin: 1
    }

    return (
        <>
            <div
                style={props.darkMode ? {
                    backgroundColor: darkTheme.palette.containerBackground.main,
                    boxShadow: "5px 5px 5px #262626"
                } : {
                    backgroundColor: lightTheme.palette.containerBackground.main,
                    boxShadow: "5px 5px 5px darkgray"
                }}
                className={"custom-import-container"}>
                <div style={{backgroundColor: lightTheme.palette.secondary.main}}
                     className={"custom-import-title-container"}>
                    <Typography sx={{flexGrow: 2, textAlign: "center", paddingLeft: "30px"}} variant={"h3"}>Custom
                        Import</Typography>
                    <Typography>{props.credits}</Typography>
                </div>
                <div className={"custom-import-sub-container"}>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a breed</InputLabel>
                        <Select labelId={"breed"} label={"Choose a breed"} size={"small"} value={breed}
                                onChange={handleBreedChange} defaultValue={0}>
                            <MenuItem disabled key={0} value={0}>Select a Breed</MenuItem>
                            {[...ImportBreeds].sort().map(breed =>
                                <MenuItem value={breed.replace(/ /g, '')}>{breed}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a pasture or barn</InputLabel>
                        <Select labelId={"pasture"} label={"Choose a location"} size={"small"}
                                onChange={handleSetLocation} defaultValue={0}>
                            <MenuItem disabled key={0} value={0}>Select a location</MenuItem>
                            {locations && locations.map(location =>
                                <MenuItem key={location.name}
                                          value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className={"custom-import-sub-container"}>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a gender</InputLabel>
                        <Select labelId={"gender"} label={"Choose a gender"} size={"small"} value={gender}
                                onChange={handleSetGender} defaultValue={0}>
                            <MenuItem value={0} disabled>Select a gender</MenuItem>
                            <MenuItem value={"Stallion"}>Stallion</MenuItem>
                            <MenuItem value={"Mare"}>Mare</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a base color</InputLabel>
                        <Select labelId={"baseColor"} label={"Choose a base color"} size={"small"} value={baseColor}
                                onChange={handleSetBaseColor} defaultValue={0}>
                            <MenuItem value={""} disabled>Select a color</MenuItem>
                            {baseColors.map((color, i) =>
                                <MenuItem value={color.replace(/ /g, '')}>{color}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className={"custom-import-sub-container"}>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a dilution</InputLabel>
                        <Select labelId={"dilutionA"} label={"Choose a dilution"} size={"small"} value={dilutionA}
                                onChange={handleSetDilutionA} defaultValue={"None"}>
                            <MenuItem value={"None"}>None</MenuItem>
                            {dilutions.map((dilution, i) =>
                                <MenuItem key={i} value={dilution.replace(/ /g, "")}>{dilution}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a dilution</InputLabel>
                        <Select labelId={"dilutionB"} label={"Choose a dilution"} size={"small"} value={dilutionB}
                                onChange={handleSetDilutionB} defaultValue={"None"}>
                            <MenuItem value={"None"}>None</MenuItem>
                            {dilutions.map((dilution, i) =>
                                <MenuItem key={i} value={dilution.replace(/ /g, "")}>{dilution}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className={"custom-import-sub-container"}>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a modifier</InputLabel>
                        <Select labelId={"modifier"} label={"Choose a modifier"} size={"small"} value={modifier}
                                onChange={handleSetModifier} defaultValue={"None"}>
                            <MenuItem value={"None"}>None</MenuItem>
                            {modifiers.map((modifier, i) =>
                                <MenuItem value={modifier.replace(/ /g, '')}>{modifier}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={selectStyle}>
                        <InputLabel>Choose a white pattern</InputLabel>
                        <Select labelId={"pattern"} label={"Choose a pattern"} size={"small"} value={pattern}
                                onChange={handleSetPattern} defaultValue={"None"}>
                            <MenuItem value={"None"}>None</MenuItem>
                            {patterns.map((pattern, i) =>
                                <MenuItem value={pattern.value}>{pattern.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <Button sx={{alignSelf: "flex-end", margin: "15px 30px 30px 30px"}} variant={"contained"}
                        onClick={importFoundation}
                        disabled={clicked || location === 0 || baseColor == "" || dilutionA == "" || dilutionB == "" || 
                            modifier == "" || pattern == "" || gender == ""}>Import</Button>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> </DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomImport;