import "./AccountManagement.css";

import React, {useEffect, useState} from "react";
import DividerTitle from "../DividerTitle";
import Store from "./store/Store";
import {
    Dialog,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import AccountCustomization from "./customize/AccountCustomization";
import HorseManagement from "./horses/HorseManagement";
import CreditManagement from "./credits/CreditManagement";
import Ranches from "./ranches/Ranches";

const AccountManagement = (props) => {

    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetUpdated();
    }

    const [ranches, setRanches] = useState([]);

    useEffect(() => {
        axios.get('api/Ranches/getAllByUser', {
            params: {
                id: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setRanches(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.email])

    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('api/Account/otherUsers', {
            params : {
                email: props.email
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setUsers(response.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])
    
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
            handleOpen();
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
            handleOpen();
        }
    }, []);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    
    return(
        <div>
            <img className={"ranch-banner"} src={"https://media.legacyhorsegame.com/misc/ranch-background.jpg"} alt={"ranch"}/>
            <DividerTitle title={"Customize Profiles"}/>
            <AccountCustomization user={props.user} ranches={ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>
            
            <DividerTitle title={"Customize Horses"}/>
            <HorseManagement ranches={ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleSetUpdated}/>
            
            <DividerTitle title={"Ranch Management"}/>
            <Ranches ranches={ranches} api={props.api} token={props.token} config={props.config} darkMode={props.darkMode} />
            
            <DividerTitle title={"Credit Management"}/>
            <CreditManagement user={props.user} ranches={ranches} users={users} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleSetUpdated}/>

            
            <DividerTitle title={"Store"}/>
            <Store users={users} ranches={ranches} config={props.config} token={props.token} api={props.api} email={props.email}/>
            
            <Dialog  sx={{paddingLeft: 4, paddingRight: 4}} open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{message?.title}</DialogTitle>
                <p style={{padding: 20}}>{message}</p>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AccountManagement