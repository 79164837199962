import React from 'react';

import "./Footer.css";

const Footer = (props) => {
    return (
        <div className={"footer-container"}>
            <a className={"footer-link"} onClick={props.handleContactUsOpen}>Contact Us</a>
            <p className={"footer-link"} onClick={props.handleTosOpen}>Terms of Service</p>
            <p className={"footer-link"} onClick={props.handlePrivacyOpen}>Privacy Policy</p>
            <p className={"footer-link"} onClick={props.handleRulesOpen}>Game Rules</p>
        </div>
    )
}

export default Footer;