import "./Album.css";

import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import aws from "aws-sdk";
import {Body} from "../../../enums/Body";
import {DeleteObjectCommand, ListObjectsV2Command, PutObjectCommand, S3Client} from "@aws-sdk/client-s3";
import {Close, DeleteRounded} from "@mui/icons-material";
import emptyCache from "../../../ClearCache";

const Album = (props) => {
    const inputStyle = {
        margin: "0 5px 10px 5px",
        width: "90%",
    }
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const endpoint = new aws.Endpoint("nyc3.digitaloceanspaces.com");
    const s3 = new S3Client({
        forcePathStyle: false, // Configures to use subdomain/virtual calling format.
        endpoint: endpoint,
        region: "us-east-1",
        credentials: {
            accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
        }
    });

    const [backgrounds, setBackgrounds] = useState([]);

    useEffect(async () => {
        const params = {
            Bucket: 'legacyhorsegame',
            Prefix: `background/`
        };

        const { Contents, IsTruncated, NextContinuationToken } = await s3.send(new ListObjectsV2Command(params));
        setBackgrounds(Contents);
        backgrounds.shift();
    }, []);

    const [background, setBackground] = useState("background/default.jpg");

    const handleSetBackground = (event) => {
        setBackground(event.target.value);
    }

    const [backTops, setBackTops] = useState([]);
    const [backBottoms, setBackBottoms] = useState([]);
    const [heads, setHeads] = useState([]);

    useEffect(async () => {
        const params = {
            Bucket: 'legacyhorsegame',
            Prefix: `tack/${Body[props.horse.bodyType].toLowerCase()}/`
        };

        const { Contents, IsTruncated, NextContinuationToken } = await s3.send(new ListObjectsV2Command(params));
        setBackTops(Contents.filter(c => c.Key.includes("saddles/")));
        setBackBottoms(Contents.filter(c => c.Key.includes("saddlepad/")));
        setHeads(Contents.filter(c => c.Key.includes("bridle/") || c.Key.includes("halter/")));
    }, []);

    const [horse, setHorse] = useState(null);
    
    const [maneTail, setManeTail] = useState("back");

    const handleSetManeTail = (event) => {
        setManeTail(event.target.value);
    }

    const [backTop, setBackTop] = useState(null);

    const handleSetBackTop = (event) => {
        setBackTop(event.target.value);
    }

    const [backBottom, setBackBottom] = useState(null);

    const handleSetBackBottom = (event) => {
        setBackBottom(event.target.value);
    }

    const [head, setHead] = useState(null);

    const handleSetHead = (event) => {
        setHead(event.target.value);
    }

    const [legs, setLegs] = useState(null);

    const handleSetLegs = (event) => {
        setLegs(event.target.value);
    }

    useEffect(() => {
        axios.get('/api/Horses/artwork', {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorse(response.data);
            });
    }, [props.id, updated]);

    const canvasRef = useRef();
    const containerRef = useRef();
    
    const [photos, setPhotos] = useState([]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    function checkImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = function() {
                resolve(image);
            };

            image.onerror = function() {
                reject(new Error(`Error loading image: ${url}`));
            };

            image.src = url;
            image.setAttribute('crossorigin', 'anonymous');
        });
    }

    useEffect(async () => {
        const canvas = canvasRef.current;
        
        if(horse != null && canvas != null){
            canvas.width = containerRef.current.offsetWidth * .65;
            canvas.height = canvas.width * .56;
            
            const imageURLs = [];
            const images = [];

            imageURLs.push(`https://media.legacyhorsegame.com/${background}`);
            imageURLs.push(`https://media.legacyhorsegame.com/${horse.color.toLowerCase()}.png`);
            horse.sooty && horse.sooty != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.sooty.toLowerCase()}`);
            horse.pangare && imageURLs.push(`https://media.legacyhorsegame.com/${horse.pangare.toLowerCase()}.png`);
            horse.dappling != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.dappling.toLowerCase()}`);
            horse.forehead && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.forehead.toLowerCase()}.png`);
            horse.nose != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.nose.toLowerCase()}.png`);
            horse.leftFore != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.leftFore.toLowerCase()}.png`);
            horse.rightFore != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.rightFore.toLowerCase()}.png`);
            horse.leftHind != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.leftHind.toLowerCase()}.png`);
            horse.rightFore != null && imageURLs.push(`https://media.legacyhorsegame.com/markings/${horse.rightFore.toLowerCase()}.png`);
            horse.pattern != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.pattern.toLowerCase()}.png`);
            horse.leopard != null && imageURLs.push(`https://media.legacyhorsegame.com/leopard/${horse.leopard.toLowerCase()}.png`);
            horse.roaning != null && imageURLs.push(`https://media.legacyhorsegame.com/${horse.roaning.toLowerCase()}.png`);
            imageURLs.push(`https://media.legacyhorsegame.com/mane/${maneTail}/${horse.mane.toLowerCase()}.png`);
            horse.pattern != null && imageURLs.push(`https://media.legacyhorsegame.com/manepatterns/${maneTail}/${horse.pattern.substr(9).toLowerCase()}.png`);
            horse.leopardMane != null && imageURLs.push(`https://media.legacyhorsegame.com/maneappy/${maneTail}/${horse.leopardMane.toLowerCase()}`);
            horse.leopardTail != null && imageURLs.push(`https://media.legacyhorsegame.com/tailappy/${maneTail}/${horse.leopardTail.toLowerCase()}`);
            backBottom != null && imageURLs.push(`https://media.legacyhorsegame.com/${backBottom}`);
            backTop != null && imageURLs.push(`https://media.legacyhorsegame.com/${backTop}`);
            head != null && imageURLs.push(`https://media.legacyhorsegame.com/${head}`);
            
            async function loadImages() {
                const canvas = canvasRef.current;
                if (horse != null && canvas != null) {
                    const context = canvas.getContext('2d');

                    for (const url of imageURLs) {
                        try {
                            const loadedImage = await checkImage(url);
                            context.drawImage(loadedImage, 0, 0, canvas.width, canvas.height);
                        } catch (error) {
                            console.error(error.message);
                        }
                    }
                    
                    for (let i = 0; i < images.length; i++) {
                        console.log(images[i]);
                        context.drawImage(images[i], 0, 0, canvas.width, canvas.height);
                    }
                }
            }
            
            await loadImages();
        }
    }, [horse, maneTail, backTop, backBottom, head, background, open]);

    useEffect(() => {
        setReplacedPhoto(null);
        if(horse != null){
            setPhotos([]);
            for (const key in horse.album) {
                if (horse.album.hasOwnProperty(key) && horse.album[key] !== null && key != "albumId") {
                    setPhotos(prevPhotos => [...prevPhotos, horse.album[key]]);
                }
            }
        }
    }, [horse, updated]);
    
    const [openMessage, setOpenMessage] = useState(false);
    
    const handleOpenMessage = () => {
        setOpenMessage(true);
    }
    
    const handleCloseMessage = () => {
        setOpenMessage(false);
    }
    
    const handleCheckExistingPhotos = () => {
        const images = [];

        for (const key in horse.album) {
            if (horse.album.hasOwnProperty(key) && horse.album[key] === null) {
                images.push(horse.album[key]);
            }
        }
        
        if(images.length === 0 && horse.album != null){
            handleOpenMessage()
        } else {
            handleAddPhoto();
        }
    }
    
    const handleAddPhoto = async () => {
        const canvas = canvasRef.current;

        //const numbers = array.slice(-1)[array[array.length - 1] !== null].replace(/\D/g, "");

        let firstNullProperty = null;

        for (const key in horse.album) {
            if (horse.album.hasOwnProperty(key) && horse.album[key] === null) {
                firstNullProperty = key;
                break;
            }
        }
        
        let number;
        if(firstNullProperty != null){
            number = firstNullProperty.replace(/\D/g, "");
        } else {
            number = 1;
        }

        if(replacedPhoto != null){
            number = replacedPhoto.substring(replacedPhoto.lastIndexOf('/'))
            number = number.replace(/\D/g, "");
        }
        
        number = String(number).padStart(2, '0');
        
        if(number != null){
            canvas.toBlob(blob => {
                const params = {
                    Body: blob,
                    Bucket: 'legacyhorsegame',
                    Key: `photos/${props.owner.displayName.toLowerCase()}/${props.horse.ranch.name.replace(/\s/g, '').toLowerCase()}/${props.horse.horseId}/${number}.png`,
                    ContentType: 'image/png',
                    ContentLength: blob.size,
                    ACL: "public-read",
                    CacheControl: "no-cache"
                };

                s3.send(new PutObjectCommand(params));
            });
        }

        const data = new FormData();
        data.append("id", props.id);
        data.append("filename", number);
        data.append("photo", `photos/${props.owner.displayName.toLowerCase()}/${props.horse.ranch.name.replace(/\s/g, '').toLowerCase()}/${props.horse.horseId}/${number}.png`);
        
        axios.post("api/horses/addPhoto", data, props.formConfig)
            .then(response => {
                console.log(response);
                setTimeout(function () {
                    emptyCache();
                    handleSetUpdated();
                    window.location.reload(true);
                }, 1000)
            }).catch(error => {
                console.log(error);
        })
        handleClose();
        handleCloseMessage();
    }

    const [clicked, setClicked] = useState(false);

    const [openedPhoto, setOpenedPhoto] = useState(null);

    const handleSetOpenedPhoto = (photo) => {
        setOpenedPhoto(photo);
        setClicked(true);
    }

    const [replacedPhoto, setReplacedPhoto] = useState(null);

    const handleSetReplacedPhoto = async (photo) => {
        setReplacedPhoto(photo);
    }
    
    const deletePhoto = async (photo) => {        
        const deleteFilePath = () => {
            const data = new FormData();
            data.append("id", props.id);
            data.append("photo", photo);
            
            axios.post("api/Horses/deletePhoto", data, props.formConfig)
                .then(response => {
                    console.log(response);
                    emptyCache();
                    handleSetUpdated();
                }).catch(error => {
                    console.log(error);
            })
        }
        
        const command = new DeleteObjectCommand({
            Bucket: 'legacyhorsegame',
            Prefix: `background/`,
            Key: photo,
        });

        try {
            const response = await s3.send(command);
            console.log(response);
            deleteFilePath();
        } catch (err) {
            console.error(err);
        }
    }

    const replacePhoto = (
        <div>
            <p>This horse already has 10 images. Please choose an image to replace:</p>
            {photos.map((p, i) =>
                <img key={i} className={p != replacedPhoto ? "photo-thumbnail" : "selected-photo-thumbnail"} src={`https://media.legacyhorsegame.com/${p}`} alt={""} onClick={handleSetReplacedPhoto.bind(this, p)}/>
            )}
            <Button variant={"contained"} onClick={handleAddPhoto}>Continue</Button>
        </div>
    )
    
    const addPhotoInputs = (
        <div className={"save-photo-container"} ref={containerRef}>
            <div className={"save-photo-form-container"}>
                {props.horse.isBackgroundActive && <FormControl sx={inputStyle}>
                    <InputLabel id={"feed-select"}>Choose background</InputLabel>
                    <Select id={"feed-select"}
                            size={"small"}
                            onChange={handleSetBackground}
                            defaultValue={"default"}>
                        <MenuItem value={"default"} disabled>Choose background</MenuItem>
                        {backgrounds && backgrounds.map((background, i) =>
                            <MenuItem key={i}
                                      value={background.Key}>{background.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                        )}
                    </Select>
                </FormControl>}
                {props.horse.isTackActive && <>
                    <FormControl sx={inputStyle}>
                        <InputLabel id={"feed-select"}>Choose Saddle</InputLabel>
                        <Select id={"feed-select"}
                                size={"small"}
                                onChange={handleSetBackTop}
                                defaultValue={"default"}>
                            <MenuItem value={"default"} disabled>Choose Saddle</MenuItem>
                            <MenuItem value={null} >None</MenuItem>
                            {backTops && backTops.map(bt =>
                                <MenuItem key={bt.ETag}
                                          value={bt.Key}>{bt.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={inputStyle}>
                        <InputLabel id={"feed-select"}>Choose Saddle Blanket</InputLabel>
                        <Select id={"feed-select"}
                                size={"small"}
                                onChange={handleSetBackBottom}
                                defaultValue={"default"}>
                            <MenuItem value={"default"} disabled>Choose Saddle Blanket</MenuItem>
                            <MenuItem value={null} >None</MenuItem>
                            {backBottoms && backBottoms.map(bb =>
                                <MenuItem key={bb.ETag}
                                          value={bb.Key}>{bb.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={inputStyle}>
                        <InputLabel id={"feed-select"}>Choose Bridle</InputLabel>
                        <Select id={"feed-select"} size={"small"} onChange={handleSetHead}
                                defaultValue={"default"}>
                            <MenuItem value={"default"} disabled>Choose Saddle</MenuItem>
                            <MenuItem value={null} >None</MenuItem>
                            {heads && heads.map(h =>
                                <MenuItem key={h.ETag} value={h.Key}>{h.Key.split(/[\\\/]/).pop().split('.')[0]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </>}
                <Button sx={{alignSelf: "flex-end", marginRight: "10px"}} variant={"contained"} onClick={handleCheckExistingPhotos}>Save Photo</Button>
            </div>

            {openMessage && replacePhoto}
            
            {containerRef && <canvas ref={canvasRef} width={300} height={300 * .56}></canvas>}
        </div>
    )
    
    return (
        <div className={"album-container"}>
            {openedPhoto && clicked &&
                <div className={"enlarged-photo-container"}>
                    <Close className={"close-button"} onClick={setClicked.bind(this, false)}/>
                    <img className={"enlarged-photo"} src={openedPhoto} alt={""}/>
                </div>
            }
            <div className={"photos-container"}>
                {photos.map((p, i) =>
                    <div className={"photo-card-container"}>
                        <DeleteRounded sx={{position: "absolute", top: "15px", right: "15px", zIndex: 1}} color={"secondary"} onClick={deletePhoto.bind(this, p)} />
                        <img key={i} className={"photo-card"} src={`https://media.legacyhorsegame.com/${p}`} alt={""} onClick={handleSetOpenedPhoto.bind(this, `https://media.legacyhorsegame.com/${p}`)}/>
                    </div>
            )}
            </div>
            {props.isOwner && addPhotoInputs}
        </div>
    )
}

export default Album