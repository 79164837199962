import "./Categories.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import CategoryCard from "./CategoryCard";

const Categories = (props) => {

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios.get("/api/Forum/categories", {
            params: {
                id: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setCategories(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [])
    
    return (
        <div>
            {categories.map(cat =>
                <>
                    <CategoryCard category={cat}/>
                </>
            )}
        </div>
    )
}

export default Categories