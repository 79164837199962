import "./RanchStuds.css"
import {React, useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import StudCard from "../../studs/StudCard";

const RanchStuds = (props) => {
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const [horses, setHorses] = useState([]);
    
    useEffect(() => {
        axios.get("/api/Horses/publicRanchStuds", {
            params: {
                id: props.ranch
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        })
            .then(response =>
                setHorses(response.data)
            ).catch( error =>
                console.log(error)
            )
    }, []);

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const moveForward = (event) => {
        setIndexStart(indexStart + 30);
        setIndexEnd(indexEnd + 30);
    }

    const moveBackward = (event) => {
        setIndexStart(indexStart - 30);
        setIndexEnd(indexEnd - 30);
    }
    
    return (
        <>
            {horses &&
                <div className={"horsesPage"}>
                    <div className={"page-nav-section"}>
                        {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                        {indexEnd > horses.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                    </div>
                    <div className={"horsesSection"}>
                        {horses.slice(indexStart, indexEnd).map(horse =>
                            <Link className={"studs-link"} to={`/horses/${horse.horseId}`}>
                                <StudCard key={horse.horseId} horse={horse} id={horse.horseId} api={props.api}/>
                            </Link>
                        )}
                    </div>
                    <div className={"page-nav-section"}>
                        {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                        {indexEnd > horses.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                    </div>
                </div>}
        </>
    )
}

export default RanchStuds