import "./Health.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import Illnesses from "./illnesses/Illnesses";
import Injuries from "./injuries/Injuries";

import GeneticTests from "./tests/GeneticTests";
import DividerTitle from "../../DividerTitle";

const Health = (props) => {

    const[date, setDate] = useState(0);

    useEffect(() => {
        axios.get("api/Application/month", props.config)
            .then(response => {
                setDate(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, []);

    const [injuries, setInjuries] = useState();
    const [illnesses, setIllnesses] = useState();
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    useEffect(() => {
        axios.get('/api/health/health', {
            params: {
                id: props.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setInjuries(response.data.injuries);
                setIllnesses(response.data.illnesses);
            })
    }, [props.id, updated]);
    
    return(
        <div className={"health-container"}>
            <div className={"conditions-container"}>
                <Illnesses id={props.id} illnesses={illnesses} injuries={injuries} isOwner={props.isOwner} date={date} darkMode={props.darkMode} config={props.config} handleSetUpdated={handleSetUpdated}/>
                <Injuries id={props.id} injuries={injuries} isOwner={props.isOwner} date={date}  darkMode={props.darkMode} config={props.config} formConfig={props.formConfig} handleSetUpdated={handleSetUpdated}/>
            </div>
            <DividerTitle title={"Genetic Tests"} darkMode={props.darkMode} />
            <GeneticTests id={props.id} isOwner={props.isOwner} darkMode={props.darkMode} api={props.api} formConfig={props.formConfig} token={props.token} handleSetRefreshed={props.handleSetRefreshed}/>
        </div>
    )
}

export default Health