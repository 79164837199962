import './Product.css';

import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {Button, FormControl, Input, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {darkTheme, lightTheme} from "../../../Theme";

const Subscription = (props) => {

    const [prices, setPrices] = useState([])
    const [price, setPrice] = useState(null)

    useEffect(() => {
        axios.get('api/Stripe/pricesByProduct', {
            params: {
                id: props.product.id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPrices(response.data);
                setPrice(response.data.find(p => p.nickname != "default ranch"));
            }).catch(error => {
            console.log(error);
        })
    }, []);

    function insertDecimal(num) {
        return (Number((num/100).toFixed(2))).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    const [product, setProduct] = useState({});

    const handleSetProduct = (event) => {
        const price = event.target.value;
        const newProduct = {
            name: props.product.name,
            priceId: price.id,
            price: insertDecimal(price.unitAmount),
            amount: price.transformQuantity?.divideBy
        }
        if(newProduct.amount == null) {
            newProduct.amount = 1;
        }
        setProduct(newProduct);
    }
    
    const [ranch, setRanch] = useState({});
    
    const handleSetRanch = (event) => {
        if(event.target.value == 0){
            setRanch(0);
        } else {
            setRanch(event.target.value);
        }
        
        const price = ranch.isPrimary ? prices.find(p => p.nickname == "default ranch") : prices.find(p => p.nickname != "default ranch");
        setPrice(price);

        const newProduct = {
            name: props.product.name,
            priceId: price.id,
            price: insertDecimal(price.unitAmount),
            amount: 1
        }
        setProduct(newProduct);
    }

    const handleSubscribe = (subscription) => {
        axios.post('/api/Stripe/checkoutSubscription', subscription, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
                "email": props.email,
                "ranch": ranch == 0 ? null : ranch.ranchId,
                "name": name
            }
        }).then(response => {
            window.location.replace(response.data);
        }).catch(error => {
            console.log(error);
        })
    }
    
    const [name, setName] = useState("");
    
    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const inputStyle = {
        margin: 1,
        width: "65%"
    }

    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "product-container product-container-dark" : "product-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"product-header-container"}>
                <Typography variant={"h3"}>
                    {props.product.name}
                </Typography>
            </div>
            <Typography sx={{margin: "10px 20px"}}>{props.product.description}</Typography>
            {price && <Typography sx={{
                margin: "10px 20px",
                fontWeight: "bolder",
                fontSize: "20px"
            }}>{insertDecimal(price.unitAmount)}</Typography>}
            <FormControl sx={inputStyle}>
                <Select size={"small"} onChange={handleSetRanch}>
                    <MenuItem value={0}>New Ranch</MenuItem>
                    {props.ranches && props.ranches.map(ranch =>
                        <MenuItem key={ranch.ranchId} value={ranch}>{ranch.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {ranch == 0 ? <FormControl sx={inputStyle}>
                <InputLabel id={"ranch-name"}>Ranch Name</InputLabel>
                <Input variant={"outlined"} size={"small"} onChange={handleSetName}
                       value={name}/>
            </FormControl> : ""}
            <Button sx={{alignSelf: "flex-end", marginRight: "15%"}} variant={"contained"} size={"small"}
                    onClick={handleSubscribe.bind(this, product)}>Subscribe</Button>
        </div>
    )
}

export default Subscription;