import "./Rankings.css";

import React, {useEffect, useState} from "react";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import axios from "axios";
import {Breeds} from "../../enums/Breeds";

const BreedsRanked = (props) => {
    
    const [rankedBreeds, setRankedBreeds] = useState([]);

    useEffect(() => {
        axios.get("api/Rankings/popularBreeds", props.config)
            .then(response => {
                setRankedBreeds(response.data);
            }).catch(error => {
                console.log(error);
        })
    }, []);
    
    return(
        <div className={"breed-rankings-container"}>
            <Box sx={{ display: 'table', tableLayout: 'fixed'}}>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: 100, textAlign: "center"}}>Rank</TableCell>
                            <TableCell sx={{width: 350, textAlign: "center"}}>Breed</TableCell>
                            <TableCell sx={{width: 150, textAlign: "center"}}>Total Horses</TableCell>
                            <TableCell sx={{width: 150, textAlign: "center"}}>Total Owners</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rankedBreeds.map((record, i) =>
                            <TableRow key={i}>
                                <TableCell sx={{width: 100, textAlign: "center"}}>
                                    {i + 1}
                                </TableCell>
                                <TableCell sx={{width: 350, textAlign: "center"}}>
                                    {Breeds[record.breed]}
                                </TableCell>
                                <TableCell sx={{width: 150, textAlign: "center"}}>
                                    {record.totalHorses}
                                </TableCell>
                                <TableCell sx={{width: 150, textAlign: "center"}}>
                                    {record.totalPlayers}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </div>
    )
}

export default BreedsRanked