import "./SubCategory.css";

import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel, Pagination,
    TextField, Typography
} from "@mui/material";
import {AddBox, ArrowForwardIos, Close} from "@mui/icons-material";
import {darkTheme, lightTheme} from "../../Theme";
import PostCard from "./PostCard";
import aws from "aws-sdk";
import {PutObjectCommand, S3Client} from "@aws-sdk/client-s3";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import { ImageDrop } from 'quill-image-drop-module'
import DOMPurify from "dompurify";

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop)

const endpoint = new aws.Endpoint("nyc3.digitaloceanspaces.com");
const s3 = new S3Client({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: endpoint,
    region: "us-east-1",
    credentials: {
        accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
    }
});

const handleUpload = async (filename, blob) => {
    const params = {
        Body: blob,
        Bucket: 'legacyhorsegame',
        Key: `forum/${filename}`,
        ContentType: 'image/png',
        ContentLength: blob.size,
        ACL: "public-read",
        CacheControl: "no-cache"
    };

    s3.send(new PutObjectCommand(params)).then(result => {
        console.log(result)
    }).catch(error => {
        console.log(error);
    });
}

var quillObj;

const  uploadFiles = (uploadFileObj, filename, quillObj) => {

    var currentdate = new Date();
    var fileNamePredecessor = currentdate.getDate().toString() + currentdate.getMonth().toString() + currentdate.getFullYear().toString() + currentdate.getTime().toString();

    filename = fileNamePredecessor + "_" + filename;

    if (uploadFileObj != '') {
        handleUpload(filename, uploadFileObj).then((response) => {
            const range = quillObj.current.getEditor().getSelection();

            const res = `https://media.legacyhorsegame.com/forum/${filename}`;

            setTimeout(() => {
                quillObj.current.getEditor().insertEmbed(range.index, 'image', res);
            }, 1000)
        }).catch((error) =>
            console.log(error)
        );
    }
}

const imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file: any = input.files[0];

        const res = await uploadFiles(file, file.name, quillObj);
    };
}

const quillModules = {
    toolbar: {
        container: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean'],
            [{ 'color': [] }]
        ],
        handlers: {
            image: imageHandler
        },
        imageDrop: true,
        imageResize: {
            displaySize: true,
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
        }
    }
}

const SubCategory = (props) => {
    
    const {id} = useParams();

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    quillObj = useRef(null);
    
    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [subCat, setSubCat] = useState(null);

    useEffect(() => {
        axios.get("/api/Forum/subCategory", {
            params: {
                id: id,
                userId: props.user.id,
                page: pageNumber
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        })
            .then(response => {
                setSubCat(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, [id, updated, pageNumber])

    useEffect(() => {
        let newPostIds = [];

        if(subCat){
            for (let i = 0; i < subCat.posts.length; i++) {
                newPostIds.push(subCat.posts[i].postId.toString());
            }
        }

        localStorage.setItem("postIds", newPostIds);
    }, [subCat]);

    const [title, setTitle] = useState("");
    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const [content, setContent] = useState("");
    const handleSetContent = useCallback((text, delta, source, editor) => {
        setContent(text);
    }, []);

    const [adminOnly, setAdminOnly] = useState(false);
    const handleSetAdminOnly = () => {
        setAdminOnly(!adminOnly);
    }

    const [isPinned, setIsPinned] = useState(false);
    const handleSetIsPinned = () => {
        setIsPinned(!isPinned);
    }

    const createNewPost = () => {
        const data = new FormData();
        data.append("userId", props.user.id);
        data.append("subCatId", id);
        data.append("content", DOMPurify.sanitize(content, {
            USE_PROFILES: {html: true}
        }));
        data.append("title", title);
        data.append("isAdmin", adminOnly);
        data.append("isPinned", isPinned);

        axios.post("/api/Forum/newPost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
                handleClose();
            }).catch(error => {
            console.log(error);
        })
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    
    const checkBoxStyle = {
        margin: "2px"
    }
    
    const addPostIconStyle = {
        fontSize: "36px",
        cursor: "pointer",
        color: props.darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main,
        margin: 1
    }

    const breadcrumbStyle = {
        fontWeight: 500,
        fontSize: "16px",
        margin: "2px"
    }

    const editor = (
        <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}} className={"sub-category-post-editor"}>
            <Close sx={{alignSelf: "flex-end", marginBottom: 2}} onClick={handleClose}/>
            <TextField sx={{marginBottom: 2, width: '100%'}} label={"Post title"} size={"small"}
                       onChange={handleSetTitle}/>
            {props.user.admin && <div style={{alignSelf: "flex-start"}}>
                <FormControl sx={checkBoxStyle}>
                    <FormControlLabel control={<Checkbox size={"small"} checked={adminOnly} onChange={handleSetAdminOnly}/>} label={"Admin Only"}/>
                </FormControl>
                <FormControl sx={checkBoxStyle}>
                    <FormControlLabel control={<Checkbox size={"small"} checked={isPinned} onChange={handleSetIsPinned}/>} label={"Pinned"}/>
                </FormControl>
            </div>}
            <ReactQuill style={props.darkMode ? {backgroundColor: "#414042", color: "white", width: '100%', height: "300px"} : {width: '100%', height: "300px"}} ref={quillObj} modules={quillModules} value={content} onChange={handleSetContent} />
            <Button sx={{marginRight: 2, marginTop: 2, alignSelf: "flex-end"}} onClick={createNewPost} autoFocus color={"primary"}
                    variant={"contained"}>
                Post
            </Button>
        </div>
    )

    return (
        <div>
            <img className={"horsesBanner"}
                 src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/forum-banner.jpg"}
                 alt={"horses"}/>
            {subCat && <>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}
                    className={"sub-category-title"}>
                    <Typography sx={{flexGrow: 1, marginLeft: "20px"}} variant={"h3"}>{subCat.title}</Typography>
                </div>
                <div className={"sub-category-container"}>
                    {open ? editor : ""}
                    <div className={"sub-category-navigation-container"}>
                        <div className={"sub-category-breadcrumbs-container"}>
                            <Link to={`/forum`}>
                                <Typography sx={breadcrumbStyle}>{subCat.category}</Typography>
                            </Link>
                            <ArrowForwardIos sx={breadcrumbStyle}/>
                            <Typography sx={breadcrumbStyle}>{subCat.title}</Typography>
                        </div>
                        <div className={"sub-category-page-nav-container"}>
                            <Pagination variant={"outlined"}
                                        shape={"rounded"}
                                        boundaryCount={2}
                                        page={pageNumber}
                                        count={Math.ceil(subCat.postCount / 15)}
                                        onChange={handleSetPageNumber}
                            />
                            {(subCat.adminOnly && props.user.admin) ?
                                <AddBox sx={addPostIconStyle} onClick={handleOpen}/> : !subCat.adminOnly ?
                                    <AddBox sx={addPostIconStyle} onClick={handleOpen}/> : ""}
                        </div>
                    </div>
                    {subCat.posts.map(post =>
                        <PostCard post={post} user={props.user} formConfig={props.formConfig} darkMode={props.darkMode}/>
                    )}
                    <Pagination sx={{alignSelf: "flex-end"}}
                                variant={"outlined"}
                                shape={"rounded"}
                                boundaryCount={2}
                                page={pageNumber}
                                count={Math.ceil(subCat.postCount / 15)}
                                onChange={handleSetPageNumber}
                    />
                </div>
            </>}
        </div>
    )
}

export default SubCategory;