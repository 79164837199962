import "./Breeding.css";

import React, {useEffect, useState} from "react";
import DividerTitle from "../../DividerTitle";
import Pedigree from "./pedigree/Pedigree";
import axios from "axios";
import Stallion from "./stallion/Stallion";
import Offspring from "./offspring/Offspring";
import Mare from "./mare/Mare";

const Breeding = (props) => {

    const[date, setDate] = useState(0);

    useEffect(() => {
        axios.get("api/Application/month", props.config)
            .then(response => {
                setDate(response.data);
            }).catch(error => {
            console.log(error);
        })
    }, []);
    
    return (
        <div>
            {props.horse.gender === 1 ? 
                <Stallion horse={props.horse} date={date} disabled={props.breedingDisabled} ranchId={props.ranchId}
                          available={props.available}
                          isOwner={props.isOwner} api={props.api} token={props.token}
                          formConfig={props.formConfig} handleSetUpdated={props.setUpdated}/> 
                : <Mare horse={props.horse} date={date} disabled={props.breedingDisabled} email={props.email}
                        ranchId={props.ranchId} isOwner={props.isOwner}
                        admin={props.admin} isTest={props.isTest} api={props.api} token={props.token}
                        formConfig={props.formConfig} handleSetUpdated={props.setUpdated}/>}
            <DividerTitle title={"Pedigree"}/>
            <Pedigree id={props.id} config={props.config} token={props.token} api={props.api}/>
            <DividerTitle title={"Offspring"} config={props.config} api={props.api} token={props.token}/>
            <Offspring id={props.id} token={props.token} api={props.api}/>
        </div>
    )
}

export default Breeding;