import "./FeedRoom.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Checkbox,
    FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TablePagination,
    TableRow, TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Feeds} from "../../../enums/Feeds";
import {Condition} from "../../../enums/Condition";
import Typography from "@mui/material/Typography";
import {Gender} from "../../../enums/Gender";
import {CheckCircle, Close, FilterList, FilterListOff} from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import {darkTheme, lightTheme} from "../../../Theme";


const FeedRoom = (props) => {

    const [updated, setUpdated] = useState(0);
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [searchAges, setSearchAges] = useState([]);

    const handleSetSearchAges = (age) => {
        if (searchAges.includes(age)) {
            setSearchAges(searchAges.filter(a => a != age));
        } else {
            setSearchAges([...searchAges, age]);
        }
    }

    const [genders, setGenders] = useState([]);

    const handleSetGenders = (gender) => {
        if (genders.includes(gender)) {
            setGenders(genders.filter(g => g !== gender));
        } else {
            setGenders([...genders, gender]);
        }
    }

    const [conditions, setConditions] = useState([]);

    const handleSetConditions = (condition) => {
        if (conditions.includes(condition)) {
            setConditions(conditions.filter(g => g !== condition));
        } else {
            setConditions([...conditions, condition]);
        }
    }

    const [jobs, setJobs] = useState([]);

    const handleSetJobs = (job) => {
        if (jobs.includes(job)) {
            setJobs(jobs.filter(g => g !== job));
        } else {
            setJobs([...jobs, job]);
        }
    }

    const [isPregnant, setIsPregnant] = useState("all");

    const handleSetIsPregnant = (event) => {
        setIsPregnant(event.target.value);
    }

    const [isTrained, setIsTrained] = useState("all");

    const handleSetIsTrained = (event) => {
        setIsTrained(event.target.value);
    }

    const [name, setName] = useState(null);

    const handleSetName = (event) => {
        setName(event.target.value)
    }

    const [sortBy, setSortBy] = useState(null);

    const handleSetSortBy = (event) => {
        setSortBy(event.target.value);
    }

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const clearFilters = () => {
        setSearchAges([]);
        setGenders([]);
        setSortBy(null);
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [feedRoom, setFeedRoom] = useState([]);

    useEffect(() => {
        const getHorses = () => {
            const searchDto = {
                id: props.id,
                name: name,
                ages: searchAges,
                genders: genders,
                conditions: conditions,
                jobs: jobs,
                pregnancy: isPregnant,
                training: isTrained,
                sortBy: sortBy
            }

            axios.post("api/Condition/feedRoom", searchDto, props.config)
                .then(response => {
                    setFeedRoom(response.data);
                }).catch(error => {
                    console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getHorses();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [props.id, conditions, jobs, searchAges, genders, isPregnant, isTrained, name, sortBy, updated]);

    const handleSubmitFeed = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("feed", event.target.value);

        axios.post("/api/condition/setFeed", data, props.formConfig)
            .then(function (response) {
                console.log(response);
                handleSetUpdated();
            }).catch(function (error) {
            console.log(error);
        });
    }

    const jobList = [
        "None",
        "Pack Horse",
        "Rodeo Pick Up",
        "Hunting Pack Horse",
        "Police",
        "Logging",
        "Ranch",
        "Parade",
        "Actor",
        "Circus",
        "Pony",
        "Carriage",
        "Trail",
        "Therapy",
        "Lesson",
        "Petting",
        "Bronc"
    ]

    const ages = [
        "Suckling",
        "Weanling",
        "Mature",
        "Breeding Age"
    ]

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const filters = (
        <div className={"feed-manager-filters-container"}>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Age</Typography>
                {ages.map((a, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={searchAges.includes(a.toLowerCase().replaceAll(/ /g, ""))}
                              onChange={() => handleSetSearchAges(a.toLowerCase().replaceAll(/ /g, ""))}
                    />} label={a}/>
                )}
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Gender</Typography>
                {Gender.map((g, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={genders.includes(g.toLowerCase())}
                              onChange={() => handleSetGenders(g.toLowerCase())}
                    />} label={g}/>
                )}
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Condition</Typography>
                {Condition.map((c, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={conditions.includes(c.replaceAll(/ /g, "").toLowerCase())}
                              onChange={() => handleSetConditions(c.replaceAll(/ /g, "").toLowerCase())}
                    />} label={c}/>
                )}
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Job</Typography>
                <FormControlLabel sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={jobs.includes("none")}
                              onChange={() => handleSetConditions("none")}
                    />} label={"None"}/>
                <FormControlLabel sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={jobs.includes("high")}
                              onChange={() => handleSetConditions("high")}
                    />} label={"High Energy"}/>
                <FormControlLabel sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={jobs.includes("low")}
                              onChange={() => handleSetConditions("low")}
                    />} label={"Low Energy"}/>
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Pregnant</Typography>
                <RadioGroup value={isPregnant} onChange={handleSetIsPregnant}>
                    <FormControlLabel sx={filterItemStyle} value={"all"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"All"}/>
                    <FormControlLabel sx={filterItemStyle} value={"pregnant"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Pregnant"}/>
                    <FormControlLabel sx={filterItemStyle} value={"not pregnant"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Not Pregnant"}/>
                </RadioGroup>
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Trained</Typography>
                <RadioGroup value={isTrained} onChange={handleSetIsTrained}>
                    <FormControlLabel sx={filterItemStyle} value={"all"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"All"}/>
                    <FormControlLabel sx={filterItemStyle} value={"trained"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Trained"}/>
                    <FormControlLabel sx={filterItemStyle} value={"untrained"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Untrained"}/>
                </RadioGroup>
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Sort By</Typography>
                <RadioGroup value={sortBy} onChange={handleSetSortBy}>
                    <FormControlLabel sx={filterItemStyle} value={"name"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Horse Name"}/>
                    <FormControlLabel sx={filterItemStyle} value={"next event"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Next Event"}/>
                    <FormControlLabel sx={filterItemStyle} value={"condition"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Condition"}/>
                    <FormControlLabel sx={filterItemStyle} value={"upcoming events"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Upcoming Events"}/>
                </RadioGroup>
            </div>
            <div className={"feed-manager-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <div className={"feed-manager-container"}>
            {filtersOpen ? filters : ""}
            <Toolbar sx={{
                position: "relative",
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main,
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                <FilterList onClick={openFilters}/>
                <TablePagination
                    sx={{alignSelf: "flex-end"}}
                    rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                    component="div"
                    count={feedRoom.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Toolbar>
            {feedRoom &&
                <TableContainer sx={props.darkMode ? {
                    backgroundColor: darkTheme.palette.containerBackground.main,
                    boxShadow: "5px 5px 5px #262626"
                } : {
                    backgroundColor: lightTheme.palette.containerBackground.main,
                    boxShadow: "5px 5px 5px darkgray"
                }}>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Horse Name</TableCell>
                                <TableCell align={"center"}>Current Condition</TableCell>
                                <TableCell align={"center"}>Current Feed</TableCell>
                                <TableCell align={"center"}>Job</TableCell>
                                <TableCell align={"center"}>Pregnant</TableCell>
                                <TableCell align={"center"}>Trained</TableCell>
                                <TableCell align={"center"}>Autotrainer</TableCell>
                                <TableCell align={"center"}>Next Show Date</TableCell>
                                <TableCell align={"center"}>Shows Next Month</TableCell>
                                <TableCell align={"center"}>Set Feed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {feedRoom.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((f, index) =>
                                <TableRow key={f.horseId}>
                                    <TableCell>{f.regName}</TableCell>
                                    <TableCell align={"center"}>{Condition[f.condition]}</TableCell>
                                    <TableCell align={"center"}>{Feeds[f.currentFeed]}</TableCell>
                                    <TableCell align={"center"}>{jobList[f.job] || ""}</TableCell>
                                    <TableCell align={"center"}>{f.pregnant ? <CheckCircle/> : ""}</TableCell>
                                    <TableCell align={"center"}>{f.trained ? <CheckCircle/> : ""}</TableCell>
                                    <TableCell align={"center"}>{f.autotrainer ? <CheckCircle/> : ""}</TableCell>
                                    <TableCell align={"center"}>{f.nextShowDate}</TableCell>
                                    <TableCell align={"center"}>{f.showsEntered}</TableCell>
                                    <TableCell align={"center"}>
                                        <FormControl sx={{minWidth: 200, margin: 1}}>
                                            <Select labelId={"feed"}
                                                    label={"Feed"}
                                                    size={"small"}
                                                    onChange={handleSubmitFeed.bind(this, f.horseId)}>
                                                {Feeds.map(f =>
                                                    <MenuItem key={f} value={Feeds.indexOf(f)}>{f}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        sx={{alignSelf: "flex-end"}}
                        rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                        component="div"
                        count={feedRoom.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            }
        </div>
    )
}

export default FeedRoom