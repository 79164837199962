import "./Task.css";
import React from "react";
import {darkTheme, lightTheme} from "../../../Theme";

function Task(props){

    return(
        <div className={"task"}>
            <div className={"task-title"}>{props.title}</div>
            <div style={{backgroundColor: props.darkMode ? darkTheme.palette.primary.main: lightTheme.palette.primary.main}} className={"task-bar"}>
                <div className={"task-indicator"} style={{left: props.stat === 100 ? 'calc(' + props.stat + '% - 10px)' : props.stat + '%'}} />
            </div>
            <div className={"task-labels"}>
                <div className={"task-label-low"}>0</div>
                <div className={"task-label-high"}>100</div>
            </div>
        </div>
    )

}

export default Task;