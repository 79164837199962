import "./Post.css";

import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {
    ArrowBackIos,
    ArrowForwardIos,
    Close,
    EditNote,
    ModeComment,
    ThumbDown,
    ThumbUp
} from "@mui/icons-material";
import DOMPurify from 'dompurify';
import {Button, Checkbox, FormControl, FormControlLabel, Icon, TextField, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {darkTheme, lightTheme} from "../../Theme";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Comment from "./Comment";
import DividerTitle from "../DividerTitle";
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize';
import {ImageDrop} from 'quill-image-drop-module'
import aws from "aws-sdk";
import {PutObjectCommand, S3Client} from "@aws-sdk/client-s3";

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop)

const endpoint = new aws.Endpoint("nyc3.digitaloceanspaces.com");
const s3 = new S3Client({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: endpoint,
    region: "us-east-1",
    credentials: {
        accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
    }
});

const handleUpload = async (filename, blob) => {
    const params = {
        Body: blob,
        Bucket: 'legacyhorsegame',
        Key: `forum/${filename}`,
        ContentType: 'image/png',
        ContentLength: blob.size,
        ACL: "public-read",
        CacheControl: "no-cache"
    };

    s3.send(new PutObjectCommand(params)).then(result => {
        console.log(result)
    }).catch(error => {
        console.log(error);
    });
}

var quillObj;
var quillPostObj;

const uploadFiles = (uploadFileObj, filename, quillObj) => {

    var currentdate = new Date();
    var fileNamePredecessor = currentdate.getDate().toString() + currentdate.getMonth().toString() + currentdate.getFullYear().toString() + currentdate.getTime().toString();

    filename = fileNamePredecessor + "_" + filename;

    if (uploadFileObj != '') {
        handleUpload(filename, uploadFileObj).then((response) => {
            const range = quillObj.current.getEditor().getSelection();

            const res = `https://media.legacyhorsegame.com/forum/${filename}`;

            setTimeout(() => {
                quillObj.current.getEditor().insertEmbed(range.index, 'image', res);
            }, 1000)
        }).catch((error) =>
            console.log(error)
        );
    }
}

const imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file: any = input.files[0];

        const res = await uploadFiles(file, file.name, quillObj);
    };
}

const postImageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file: any = input.files[0];

        const res = await uploadFiles(file, file.name, quillPostObj);
    };
}

const quillModules = {
    toolbar: {
        container: [
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['link', 'image'],
            ['clean'],
            [{'color': []}]
        ],
        handlers: {
            image: imageHandler
        },
        imageDrop: true,
        imageResize: {
            displaySize: true,
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        }
    }
}

const quillPostModules = {
    toolbar: {
        container: [
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['link', 'image'],
            ['clean'],
            [{'color': []}]
        ],
        handlers: {
            image: postImageHandler
        },
        imageDrop: true,
        imageResize: {
            displaySize: true,
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        }
    }
}


const Post = (props) => {

    const {id} = useParams();

    const [updated, setUpdated] = useState(0);

    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    quillObj = useRef(null);
    quillPostObj = useRef(null);

    const ids = localStorage.getItem("postIds") != null ? localStorage.getItem("postIds").split(",") : null;

    const [prev, setPrev] = useState(null);
    const [next, setNext] = useState(null);

    useEffect(() => {
        if (ids) {
            const current = ids.indexOf(id.toString());
            if (current === ids.length - 1) {
                setPrev(ids[current - 1]);
                setNext(ids[0]);
            } else if (current === 0) {
                setPrev(ids.length - 1);
                setNext(ids[current + 1]);
            } else {
                setPrev(ids[current - 1]);
                setNext(ids[current + 1]);
            }
        }
    }, [id]);

    const [post, setPost] = useState(null);

    useEffect(() => {
        axios.get("api/Forum/post", {
            params: {
                id: id,
                userId: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setPost(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [id, updated]);

    const [postEditorOpen, setPostEditorOpen] = useState(false);

    const openPostEditor = () => {
        setPostEditorOpen(true);
    }

    const closePostEditor = () => {
        setPostEditorOpen(false);
    }

    const [title, setTitle] = useState("");
    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const [postContent, setPostContent] = useState("");

    const handleSetPostContent = (content, delta, source, editor) => {
        setPostContent(content);
    }

    useEffect(() => {
        if(post != null){
            setPostContent(DOMPurify.sanitize(post.content, {
                USE_PROFILES: {html: true}
            }))
            setTitle(post.title);
        }
    }, [post]);

    const [adminOnly, setAdminOnly] = useState(false);
    const handleSetAdminOnly = () => {
        setAdminOnly(!adminOnly);
    }

    const editPost = (event) => {
        console.log(post.author.id, props.user.id);
        const data = new FormData();
        data.append("postId", post.postId);
        data.append("userId", props.user.id);
        data.append("authorId", post.author.id);
        data.append("title", title);
        data.append("content", DOMPurify.sanitize(postContent, {
            USE_PROFILES: {html: true}
        }));

        axios.post("api/Forum/editPost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
                closePostEditor();
            }).catch(error => {
            console.log(error);
        })
    }

    const pinPost = (event) => {
        const data = new FormData();
        data.append("id", post.postId);
        data.append("isPinned", event.target.checked);

        axios.post("api/Forum/pinPost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const lockPost = (event) => {
        const data = new FormData();
        data.append("id", post.postId);
        data.append("isLocked", event.target.checked);

        axios.post("api/Forum/lockPost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const archivePost = (event) => {
        const data = new FormData();
        data.append("id", post.postId);
        data.append("isArchived", !event.target.checked);

        axios.post("api/Forum/archivePost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const upVote = () => {
        const data = new FormData();
        data.append("postId", post.postId);
        data.append("userId", props.user.id);

        axios.post("api/Forum/upVotePost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const downVote = () => {
        const data = new FormData();
        data.append("postId", post.postId);
        data.append("userId", props.user.id);

        axios.post("api/Forum/downVotePost", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [content, setContent] = useState("");
    const handleSetContent = (text, delta, source, editor) => {
        setContent(text);
    }

    const createNewComment = () => {
        const data = new FormData();
        data.append("postId", post.postId);
        data.append("userId", props.user.id);
        data.append("content", DOMPurify.sanitize(content, {
            USE_PROFILES: {html: true}
        }));

        axios.post("/api/Forum/newComment", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const checkBoxStyle = {
        margin: "2px 0 2px 2px",
        alignSelf: "flex-end"
    }

    const breadcrumbStyle = {
        fontWeight: 500,
        fontSize: "16px",
        margin: "2px"
    }

    const postEditor = (
        <>
            {post &&
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                    className={"sub-category-post-editor"}>
                    <Close sx={{alignSelf: "flex-end", marginBottom: 2}} onClick={closePostEditor}/>
                    <TextField sx={{marginBottom: 2, width: '100%'}} label={"Post title"} size={"small"} value={title}
                               onChange={handleSetTitle}/>
                    <ReactQuill style={props.darkMode ? {
                        backgroundColor: "#414042",
                        color: "white",
                        width: '100%',
                        height: "300px"
                    } : {width: '100%', height: "300px"}} ref={quillPostObj} modules={quillPostModules}
                                value={postContent} onChange={handleSetPostContent}/>
                    <Button sx={{marginRight: 2, marginTop: 2, alignSelf: "flex-end"}} onClick={editPost} autoFocus
                            color={"primary"}
                            variant={"contained"}>
                        Post
                    </Button>
                </div>
            }
        </>
    )

    const editor = (
        <div className={"post-comment-editor"}>
            <ReactQuill style={props.darkMode ? {
                backgroundColor: "#414042",
                color: "white",
                width: '100%',
                height: "300px"
            } : {width: '100%', height: "300px"}} ref={quillObj} modules={quillModules} value={content} onChange={handleSetContent}/>
            <Button sx={{marginRight: 2, marginTop: 2, alignSelf: "flex-end"}} onClick={createNewComment} autoFocus
                    color={"primary"}
                    variant={"contained"}>
                Post
            </Button>
        </div>
    )

    const activePost = (
        <div className={"post-page-container"}>
            <img className={"horsesBanner"}
                 src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/forum-banner.jpg"}
                 alt={"horses"}/>
            <DividerTitle darkMode={props.darkMode}/>
            {post && <div className={"post-page-subcontainer"}>
                <div className={"post-navigation-container"}>
                    <div className={"post-breadcrumbs-container"}>
                        <Link to={`/forum`}>
                            <Typography sx={breadcrumbStyle}>{post.category}</Typography>
                        </Link>
                        <ArrowForwardIos sx={breadcrumbStyle}/>
                        <Link to={`/forum/${post.subCategoryId}`}>
                            <Typography sx={breadcrumbStyle}>{post.subCategory}</Typography>
                        </Link>
                        <ArrowForwardIos sx={breadcrumbStyle}/>
                        <Typography sx={breadcrumbStyle}>{post.title}</Typography>
                    </div>
                    <div className={"post-navigation-btns-container"}>
                        <Link to={`/forum/posts/${prev}`}>
                            <ArrowBackIos/>
                        </Link>
                        <Link to={`/forum/posts/${next}`}>
                            <ArrowForwardIos/>
                        </Link>
                    </div>
                </div>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                    className={'post-container'}>
                    <div
                        style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                        className={"post-title-container"}>
                        <Typography sx={{flexGrow: 1, marginLeft: "20px"}} variant={"h3"}>{post.title}</Typography>
                        <div className={"post-title-info-container"}>
                            <div className={"post-title-info-text-container"}>
                                <Typography>{moment(post.createDate).format('MMMM DD, YYYY, h:mm a')}</Typography>
                                <Link to={`/users/${post.author.displayName}`}>
                                    <Typography sx={{fontWeight: "bold"}}>{post.author.displayName}</Typography>
                                </Link>
                                {post.edited ? <Typography sx={{fontSize: "12px"}}>Edited</Typography> : ""}
                            </div>
                            <IconButton sx={{p: 0}}>
                                <Avatar alt="Remy Sharp"
                                        src={`https://media.legacyhorsegame.com/profileimages/${post.author.displayName}.png`}
                                        sx={{height: {md: 70}, width: {md: 70}}}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={"post-content-container"}>
                        {postEditorOpen ? postEditor : <Typography sx={{alignSelf: "flex-start"}} dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(post.content, {
                                USE_PROFILES: {html: true}
                            })
                        }}/>}
                        <div className={"post-bottom-container"}>
                            <div className={"post-icons-container"}>
                                <div className={"post-comment-count-container"}>
                                    <Typography sx={{
                                        color: "white",
                                        fontWeight: 700,
                                        position: "absolute",
                                        top: "45%",
                                        left: "40%",
                                        transform: "translate(-45%, -50%)",
                                        zIndex: 1,
                                    }}>{post.commentCount}</Typography>
                                    <ModeComment sx={{
                                        fontSize: "40px",
                                        marginRight: 1
                                    }}/>
                                </div>
                                <ThumbUp onClick={upVote}/>
                                <Typography sx={{margin: 1, fontWeight: 700}}>{post.voteCount}</Typography>
                                <ThumbDown onClick={downVote}/>
                            </div>
                            {props.user.admin ? <div className={"post-edit-btns-container"}>
                                    <FormControl sx={{alignSelf: "flex-end"}}>
                                        <FormControlLabel control={<Checkbox checked={post.isPinned} onChange={pinPost}/>}
                                                          label={"Pin Post"}/>
                                    </FormControl>
                                    <FormControl sx={{alignSelf: "flex-end"}}>
                                        <FormControlLabel control={<Checkbox checked={post.isLocked} onChange={lockPost}/>}
                                                          label={"Lock Post"}/>
                                    </FormControl>
                                    <FormControl sx={{alignSelf: "flex-end"}}>
                                        <FormControlLabel
                                            control={<Checkbox checked={!post.isActive} onChange={archivePost}/>}
                                            label={"Archive Post"}/>
                                    </FormControl>
                                    <FormControl sx={checkBoxStyle}>
                                        <FormControlLabel
                                            control={<Checkbox size={"small"} checked={adminOnly}
                                                               onChange={handleSetAdminOnly}/>}
                                            label={"Admin Only"}/>
                                    </FormControl>
                                    {props.user.id == post.author.id && !post.isLocked ? <IconButton onClick={openPostEditor}>
                                        <EditNote sx={{margin: 1, fontSize: "28px", color: props.darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}/>
                                    </IconButton> : ""}
                                </div> :
                                props.user.id == post.author.id && !post.isLocked ? <IconButton sx={{alignSelf: "flex-end"}} onClick={openPostEditor}>
                                    <EditNote sx={{margin: 1}}/>
                                </IconButton> : ""}
                        </div>
                        {!post.isLocked ? editor : ""}
                    </div>
                </div>
                {post.comments.map(comment =>
                    <Comment comment={comment} post={post} user={props.user} api={props.api} token={props.token}
                             formConfig={props.formConfig} config={props.config} handleSetUpdated={handleSetUpdated}
                             darkMode={props.darkMode}/>
                )}
            </div>}
        </div>
    )


    const inActivePost = (
        <div className={"post-page-container"}>
            <img className={"horsesBanner"}
                 src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/forum-banner.jpg"}
                 alt={"horses"}/>
            <DividerTitle darkMode={props.darkMode}/>
            {post && props.user.admin ? <div className={"post-page-subcontainer"}>
                <div className={"post-navigation-container"}>
                    <div className={"post-breadcrumbs-container"}>
                        <Link to={`/forum`}>
                            <Typography sx={breadcrumbStyle}>{post.category}</Typography>
                        </Link>
                        <ArrowForwardIos sx={breadcrumbStyle}/>
                        <Link to={`/forum/${post.subCategoryId}`}>
                            <Typography sx={breadcrumbStyle}>{post.subCategory}</Typography>
                        </Link>
                        <ArrowForwardIos sx={breadcrumbStyle}/>
                        <Typography sx={breadcrumbStyle}>{post.title}</Typography>
                    </div>
                    <div className={"post-navigation-btns-container"}>
                        <Link to={`/forum/posts/${prev}`}>
                            <ArrowBackIos/>
                        </Link>
                        <Link to={`/forum/posts/${next}`}>
                            <ArrowForwardIos/>
                        </Link>
                    </div>
                </div>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                    className={'post-container'}>
                    <div
                        style={{backgroundColor: post.isActive ? (props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main) : "lightgray"}}
                        className={"post-title-container"}>
                        <Typography sx={{flexGrow: 1, marginLeft: "20px"}} variant={"h3"}>{post.title}</Typography>
                        <div className={"post-title-info-container"}>
                            <div className={"post-title-info-text-container"}>
                                <Typography>{moment(post.createDate).format('MMMM DD, YYYY, h:mm a')}</Typography>
                                <Link to={`/users/${post.author.displayName}`}>
                                    <Typography sx={{fontWeight: "bold"}}>{post.author.displayName}</Typography>
                                </Link>
                            </div>
                            <IconButton sx={{p: 0}}>
                                <Avatar alt="Remy Sharp"
                                        src={`https://media.legacyhorsegame.com/profileimages/${post.author.displayName}.png`}
                                        sx={{height: {md: 50}, width: {md: 50}}}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={"post-content-container"}>
                        <Typography dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(post.content, {
                                USE_PROFILES: {html: true}
                            })
                        }}/>
                        <div className={"post-bottom-container"}>
                            <div className={"post-icons-container"}>
                                <div className={"post-comment-count-container"}>
                                    <Typography sx={{
                                        color: "white",
                                        fontWeight: 700,
                                        position: "absolute",
                                        top: "45%",
                                        left: "40%",
                                        transform: "translate(-45%, -50%)",
                                        zIndex: 1,
                                    }}>{post.commentCount}</Typography>
                                    <ModeComment sx={{
                                        fontSize: "40px",
                                        marginRight: 1
                                    }}/>
                                </div>
                                <ThumbUp onClick={upVote}/>
                                <Typography sx={{margin: 1, fontWeight: 700}}>{post.voteCount}</Typography>
                                <ThumbDown onClick={downVote}/>
                            </div>
                            {props.user.admin ? <div>
                                <FormControl sx={{alignSelf: "flex-end"}}>
                                    <FormControlLabel control={<Checkbox checked={post.isPinned} onChange={pinPost}/>}
                                                      label={"Pin Post"}/>
                                </FormControl>
                                <FormControl sx={{alignSelf: "flex-end"}}>
                                    <FormControlLabel control={<Checkbox checked={post.isLocked} onChange={lockPost}/>}
                                                      label={"Lock Post"}/>
                                </FormControl>
                                <FormControl sx={{alignSelf: "flex-end"}}>
                                    <FormControlLabel
                                        control={<Checkbox checked={!post.isActive} onChange={archivePost}/>}
                                        label={"Archive Post"}/>
                                </FormControl>
                            </div> : ""}
                        </div>
                        {!post.isLocked ? editor : ""}
                    </div>
                </div>
                {post.comments.map(comment =>
                    <Comment comment={comment} post={post} user={props.user} api={props.api} token={props.token}
                             formConfig={props.formConfig} config={props.config} handleSetUpdated={handleSetUpdated}
                             darkMode={props.darkMode}/>
                )}
            </div> : <Typography>Post cannot be found.</Typography>}
        </div>
    )

    return (<>{post && post.isActive ? activePost : inActivePost}</>)
}

export default Post