export const Colors = [
    "Chestnut",
    "Liver Chestnut",
    "Black",
    "Bay",
    "Blood Bay",
    "Wild Bay",
    "Seal Brown",
    "Gray",
    "White",
    "Palomino",
    "Cremello",//10
    "Red Dun",
    "Gold Champagne",
    "Dunalino",
    "Ivory Champagne",
    "Gold Dun",
    "Smoky Black",
    "Smoky Cream",
    "Grulla",
    "Silver Grulla",
    "Silver Dapple Black",//20
    "Classic Champagne",
    "Smoky Cream Grulla",
    "Silver Smoky Black",
    "Classic Ivory Champagne",
    "Classic Dun",
    "Classic Silver Champagne",
    "Buckskin",
    "Bay Dun",
    "Perlino",
    "Silver Bay",//30
    "Amber Champagne",
    "Dunskin",
    "Dun Perlino",
    "Silver Buckskin",
    "Buckskin Champagne",
    "Amber Dun",
    "Amber Silver",
    "Brown Buckskin",
    "Brown Dun",
    "Silver Brown",
    "Brown Dunskin",
    "Sable Champagne",
    "Sable Dun",
    "Blue",
    "Pearl Bay",
    "Pearl Chestnut",
    "Pearl Black",
    "Pearl Dun",
    "Pearl Red Dun",
    "False Perlino",
    "False Cremello",
    "False Smoky Cream",
    "Sorrel",
    "Strawberry",
    "Smoky Grulla",
    "Silver Bay Dun",
    "Silver Brown Dun",
    "Silver Smoky Cream",
    "Silver Amber Dun",
    "Silver Dunskin",
    "Silver Classic Dun",
    "Silver Amber Pearl",
    "Silver Classic Pearl",
    "Silver Bay Pearl",
    "Silver Black Pearl",
    "Pearl Grulla",
    "Silver Amber Dun Pearl",
    "Silver Classic Dun Pearl",
    "Silver Blue"
]