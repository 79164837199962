import "./PrivateNotes.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import PrivateNote from "./PrivateNote";
import NewPrivateNote from "./NewPrivateNote";

const PrivateNotes = (props) => {
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }
    
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        axios.get('api/Application/horsePrivateNotes', {
            params: {
                id: props.id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setNotes(response.data);
            }).catch(error => {
                console.log(error);
        })
    }, [props.id, updated]);
    
    return (
        <div>
            <div className={"private-notes-container"}>
                {notes && notes.map((note, i) =>
                    <PrivateNote key={i} note={note} owner={props.owner} isOwner={props.isOwner} 
                                 handleSetUpdated={handleSetUpdated} config={props.config} formConfig={props.formConfig}
                                 darkMode={props.darkMode}/>
                )}
            </div>
            <NewPrivateNote id={props.id} email={props.owner.email} handleSetUpdated={handleSetUpdated} 
                            config={props.config} formConfig={props.formConfig} darkMode={props.darkMode}/>
        </div>
    )
}

export default PrivateNotes;