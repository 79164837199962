import "./LoginForm.css";

import React, {useState} from 'react';
import "../testing/Testing.css";
import axios from "axios"
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle, FormControl, TextField} from "@mui/material";
import {useCookies} from "react-cookie";
import {Link, Navigate} from 'react-router-dom';

const LoginForm = (props) => {
    const handleSetCookie = (token) => {
        props.setCookie("jwt", token, {
            expires: new Date(Date.now() + 3 * 60 * 60 * 1000) //three hours later
        });
    }
        
    const [email, setEmail] = useState("");

    const handleSetEmail = (event) => {
        event.preventDefault();
        setEmail(event.target.value);
    }

    const [password, setPassword] = useState("");

    const handleSetPassword = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
    }

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        var loginDto = {
            Email: email,
            Password: password
        }
        axios.post('/api/Account/login', loginDto, props.config)
            .then(function (response) {
                let user = response.data;
                if(user.token != null) {
                    handleSetCookie(user.token);
                    props.setIsLogged(true);
                    props.history(`/users/${user.displayName}`);
                }
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpenMessage();
            });
    }

    const [messageOpen, setMessageOpen] = useState(false);
    const [message, setMessage] = useState("")

    const handleOpenMessage = () => {
        setMessageOpen(true);
    }

    const handleCloseMessage = () => {
        setMessageOpen(false);
    }


    return (
        <div className={"login-form-container"}>
            <FormControl sx={{padding: 2}}>
                <TextField label={"Email"} variant={"outlined"} size={"small"} required onChange={handleSetEmail}/>
                <TextField label={"Password"} variant={"outlined"} size={"small"} margin={"normal"} required type={"password"} onChange={handleSetPassword}/>
                <Link to={"/forgotPassword"}>Forgot password</Link>
                <Button variant={"contained"} onClick={handleSubmitForm}>Login</Button>
            </FormControl>

            <Dialog open={messageOpen} onClose={handleCloseMessage} sx={{padding: '20px'}}>
                <DialogTitle>Error</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseMessage} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default LoginForm
