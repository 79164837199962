import React, {useEffect, useState} from 'react';

import "./Title.css";

import {Breeds} from "../../../enums/Breeds";
import {Gender} from "../../../enums/Gender";
import axios from "axios";

function Title(props){
    
    const [titles, setTitles] = useState([]);

    useEffect(() => {
        axios.get("/api/horses/titles", {
            params: {
                id: props.horse.horseId
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setTitles(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.horse.horseId]);
    
    const female = (<img className={"title-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"} alt={"female"}/>)
    const male = (<img className={"title-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"} alt={"male"}/>)
    const gelding = (<img className={"title-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)
    
    return(
        <div className={"horse-title"}>
            <div className={"information"}>
                <div className={"name"}>
                    <p className={"prefix"}>{props.horse.prefix}</p>
                    <p className={"name-text"} style={{color: props.textColor}}>{props.horse.regName}</p>
                    {Gender[props.horse.gender] == "Mare" ? female : props.horse.gelded ? gelding : male}
                </div>
                <div className={"breed"} style={{color: props.textColor}}>{Breeds[props.horse.breed]}</div>
                <div className={"parents"} style={{color: props.textColor}}>{props.horse.dam !== null ? props.horse.dam.regName : "Unknown"} x {props.horse.sire !== null  ? props.horse.sire.regName : "Unknown"}</div>
            </div>
        </div>
    )
}

export default Title;