import "./Shows.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Box,
    Checkbox,
    FormControl, FormControlLabel, FormGroup, Input,
    InputLabel, makeStyles, Radio, RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead, TablePagination,
    TableRow,
    TextField, Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {Cancel, Close, FilterList, FilterListOff} from "@mui/icons-material";
import {Discipline} from "../../enums/Discipline";
import {Months} from "../../enums/Months";
import {Breeds} from "../../enums/Breeds";
import {Levels} from "../../enums/Levels";
import {Placements} from "../../enums/Placements";
import {ShowTypes} from "../../enums/ShowTypes";
import Toolbar from "@mui/material/Toolbar";
import {darkTheme, lightTheme} from "../../Theme";
import DividerTitle from "../DividerTitle";

const Shows = (props) => {

    const controller = new AbortController();
    
    const levels = [
        "First",
        "Second",
        "Third",
        "Fourth"
    ]

    const [unfiltered, setUnfiltered] = useState(false);

    const handleSetFiltered = (event) => {
        setUnfiltered(event.target.checked);
    }
    
    const [disciplines, setDisciplines] = useState([]);

    const handleSetDisciplines = (discipline) => {
        if (disciplines.includes(discipline)) {
            setDisciplines(disciplines.filter(d => d != discipline));
        } else {
            setDisciplines([...disciplines, discipline]);
        }
    }

    const [searchLevels, setSearchLevels] = useState([]);

    const handleSetSearchLevels = (level) => {
        if (searchLevels.includes(level)) {
            setSearchLevels(searchLevels.filter(l => l != level));
        } else {
            setSearchLevels([...searchLevels, level]);
        }
    }

    const [time, setTime] = useState("all");

    const handleSetTime = (event) => {
        controller.abort();
        setTime(event.target.value);
    }

    const [months, setMonths] = useState([]);

    const handleSetMonths = (month) => {
        if (months.includes(month)) {
            setMonths(months.filter((m) => m !== month));
        } else {
            setMonths([...months, month]);
        }
    }

    const [year, setYear] = useState(null);

    const handleSetYear = (event) => {
        const newValue = event.target.value === '' ? 0 : parseInt(event.target.value, 10);
        setYear(newValue);
    }

    const [breeds, setBreeds] = useState(["Grade"]);

    const handleSetBreeds = (breed) => {
        if (breeds.includes(breed)) {
            setBreeds(breeds.filter(b => b != breed));
        } else {
            setBreeds([...breeds, breed]);
        }
    }

    const [types, setTypes] = useState([]);

    const handleSetTypes = (type) => {
        if (types.includes(type)) {
            setTypes(types.filter(t => t != type));
        } else {
            setTypes([...types, type]);
        }
    }
    
    const [shows, setShows] = useState([]);
    
    useEffect(() => {
        const searchDto = {
            months: months,
            year: year,
            disciplines: disciplines,
            breeds: breeds,
            levels: searchLevels,
            time: time,
            types: types,
            includePastShows: unfiltered
        }
        
       const getShows = () => {
           axios.post('/api/Shows/search', searchDto, props.config)
               .then(response => {
                setShows(response.data);
               }).catch(error => {
                   console.log(error);
               })
       }

        const timer = setTimeout(() => {
            getShows();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [disciplines, types, searchLevels, breeds, time, months, year, unfiltered]);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: "0 5px 0 0",
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const clearFilters = () => {
        setMonths([]);
        setYear("");
        setTime("");
        setDisciplines([]);
        setSearchLevels([]);
        setBreeds(["Grade"]);
        setTypes([]);
    }
    
    const filters = (
        <div className={"shows-filters-container"}>
            <div className={"shows-filters-sub-container"}>
                <div className={"shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Discipline</Typography>
                    <div className={"shows-filter-items-container"}>
                        {Discipline.map((discipline, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle}
                                          checked={disciplines.includes(discipline.replaceAll(/ /g, ""))}
                                          onChange={() => handleSetDisciplines(discipline.replaceAll(/ /g, ""))}
                                />} label={discipline}/>
                        )}
                    </div>
                </div>
                <div className={"shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Level</Typography>
                    <div className={"shows-filter-items-container"}>
                        {levels.map((level, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={searchLevels.includes(level)}
                                          onChange={() => handleSetSearchLevels(level)}
                                />} label={level}/>
                        )}
                    </div>
                </div>
                <div className={"shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Time</Typography>
                    <FormControl>
                        <RadioGroup onChange={handleSetTime} value={time}>
                            <FormControlLabel value={"all"} defaultChecked control={<Radio sx={filterCheckboxStyle}/>}
                                              label={"All"}/>
                            <FormControlLabel value={"AM"} control={<Radio sx={filterCheckboxStyle}/>} label={"AM"}/>
                            <FormControlLabel value={"PM"} control={<Radio sx={filterCheckboxStyle}/>} label={"PM"}/>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={"shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Date</Typography>
                    <div className={"shows-filter-items-container"}>
                        {Months.map((month, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={months.includes(month)}
                                          onChange={() => handleSetMonths(month)}
                                />} label={month}/>
                        )}
                        <FormControl sx={{width: "100px", marginTop: "10px"}}>
                            <TextField label={"Year"} size={"small"}
                                       value={year} onChange={handleSetYear}/>
                        </FormControl>
                    </div>
                </div>
                <div className={"shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Breed</Typography>
                    <div className={"shows-filter-items-container"}>
                        {[...Breeds].sort().map((breed, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={breeds.includes(breed.replaceAll(/ /g, ""))}
                                          onChange={() => handleSetBreeds(breed.replaceAll(/ /g, ""))}
                                />} label={breed == "Grade" ? "Open" : breed}/>
                        )}
                    </div>
                </div>
                <div className={"shows-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Show Type</Typography>
                    <div className={"shows-filter-items-container"}>
                        {ShowTypes.map((type, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={types.includes(type.Value)}
                                          onChange={() => handleSetTypes(type.Value)}
                                />} label={type.Title}/>
                        )}
                    </div>
                </div>
                <div className={"shows-filter-items-container"}>
                    <FormControlLabel sx={filterItemStyle} control={
                        <Checkbox sx={filterCheckboxStyle} checked={unfiltered}
                                  onChange={handleSetFiltered}
                        />} label={"Include Past Shows"}/>
                </div>
            </div>
            <div className={"shows-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <div>
            <img className={"shows-banner"} src={"https://media.legacyhorsegame.com/banners/shows_banner.jpg"}
                 alt={"dressage horse"}/>
            <DividerTitle darkMode={props.darkMode}/>
            <div
                style={{background: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={"shows-container"}>
                {filtersOpen ? filters : ""}
                <Toolbar sx={{
                    position: "relative",
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main,
                    width: "100%",
                    padding: 0,
                    margin: 0
                }}>
                    <FilterList onClick={openFilters}/>
                    <TablePagination
                        sx={{alignSelf: "flex-end"}}
                        rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                        component="div"
                        count={shows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Toolbar>
                <TableContainer sx={{width: "98%", overFlowX: 'auto'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={"20%"}>Title</TableCell>
                                <TableCell width={"15%"}>Discipline</TableCell>
                                <TableCell width={"10%"}>Level</TableCell>
                                <TableCell width={"15%"}>Breed</TableCell>
                                <TableCell width={"10%"}>Entries Close</TableCell>
                                <TableCell width={"5%"}>Date</TableCell>
                                <TableCell width={"5%"}>Time</TableCell>
                                <TableCell width={"5%"}>Fee</TableCell>
                                <TableCell width={"5%"}>Purse</TableCell>
                                <TableCell width={"10%"}>View</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shows && shows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(show =>
                                <TableRow key={show.id}>
                                    <TableCell>
                                        {show.title}
                                    </TableCell>
                                    <TableCell>
                                        {Discipline[show.discipline]}
                                    </TableCell>
                                    <TableCell>
                                        {levels[show.level]}
                                    </TableCell>
                                    <TableCell>
                                        {Breeds[show.breed] == "Grade" ? "Open" : Breeds[show.breed]}
                                    </TableCell>
                                    <TableCell>
                                        {formatDate(show.eventDate - 1)}
                                    </TableCell>
                                    <TableCell>
                                        {formatDate(show.eventDate)}
                                    </TableCell>
                                    <TableCell>
                                        {show.eventTime}
                                    </TableCell>
                                    <TableCell>
                                        {show.entryFee}
                                    </TableCell>
                                    <TableCell>
                                        {show.purse}
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/shows/${show.showId}`}>
                                            <Button variant={"contained"} sx={{minWidth: "50px", padding: "5px"}}>View</Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{alignSelf: "flex-end"}}
                    rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                    component="div"
                    count={shows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )
}

export default Shows;