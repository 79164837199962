import "./ClubChat.css";

import React, {useEffect, useRef, useState} from "react";
import {TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Message from "./Message";
import * as signalR from "@microsoft/signalr";
import {useCookies} from "react-cookie";
import {darkTheme, lightTheme} from "../../Theme";
import {Close} from "@mui/icons-material";
import axios from "axios";
import {Breeds} from "../../enums/Breeds";
import {Discipline} from "../../enums/Discipline";

const GlobalChat = (props) => {
    const [cookies] = useCookies(["jwt"]);
    //const baseUrl = window.location.origin;
    const baseUrl = process.env.REACT_APP_IS_TEST == "true" ? process.env.REACT_APP_CHAT_URL_TEST : process.env.REACT_APP_CHAT_URL;

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState({body: ""});

    const connectionRef = useRef(null);
    const messagesRef = useRef(null);
    
    const [chatThread, setChatThread] = useState(null);

    useEffect(() => {
        axios.get("api/Messages/chatThread", {
            params: {
                discipline: props.club.discipline != null ? Discipline[props.club.discipline].replace(/ /g, "") : "",
                breed:  props.club.breed != null ? Breeds[props.club.breed].replace(/ /g, "") : ""
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setChatThread(response.data)
        }).catch(error => {
            console.log(error);
        })
    }, [props.club]);

    const start = () => {

        connectionRef.current = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/chat?chatThreadId=${chatThread.chatThreadId}`, {
                accessTokenFactory: () => cookies.jwt
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        connectionRef.current.start()
            .then(() => {
                console.log("Connected!");

                connectionRef.current.on('LoadMessages', (response) => {
                    setMessages(response.data); // Adjust this based on the actual shape of your 'response'
                });

                connectionRef.current.on("ReceiveChatMessage", (message) => {
                    setMessages(prevMessages => [...prevMessages, message]);
                });
            })
            .catch(err => console.error("SignalR Connection Error: ", err));
    }

    useEffect(() => {
        if((chatThread != null || chatThread != undefined)){
            start();

            connectionRef.current.onclose(async () => {
                await start(); // Function that starts the SignalR connection
            });

            return () => {
                connectionRef.current.stop().then(() => console.log('Connection stopped'));
            };
        }
    }, [chatThread]);

    useEffect(() => {
        if(messagesRef.current){
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSetMessage = (event) => {
        const newMessage = {
            body: event.target.value,
            authorId: props.user.id,
            chatThreadId: chatThread.chatThreadId,
            author: props.user.displayName
        };
        setMessage(newMessage);
    };

    const sendMessage = async () => {
        if (connectionRef.current && connectionRef.current.state === signalR.HubConnectionState.Connected) {
            connectionRef.current.invoke("SendChatMessage", message)
                .catch(err => console.error("SignalR Error: ", err));
        } else {
            console.error("Cannot send message. Connection is not in the 'Connected' state.");
        }
        setMessage({body: ""});
    };
    return (
        <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}} className={"club-chat-container"}>
            <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main, alignSelf: "flex-start"}} className={"club-chat-header-container"}>
                <Typography sx={{flexGrow: 1, textAlign: "center", marginLeft: "15px"}} variant={"h3"}>Club Chat</Typography>
                <Close onClick={props.closeChat} />
            </div>
            <div className={"club-chat-messages-container"} ref={messagesRef}>
                <div className={"club-chat-messages-subcontainer"}>
                    {messages.map((m, index) =>
                        <Message key={index} message={m} user={props.user}/> // Ensure 'Message' component can handle the 'message' prop
                    )}
                </div>
            </div>
            <div className={'club-chat-new-message-container'}>
                <TextField sx={{flexGrow: 1, margin: 1}} multiline minRows={1} maxRows={3} inputProps={{maxLength: 255}}
                           value={message.body} onChange={handleSetMessage}/>
                <Button sx={{alignSelf: "center"}} variant={"contained"} onClick={sendMessage}>Send</Button>
            </div>
        </div>
    );
}

export default GlobalChat