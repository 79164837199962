import "./EnterShow.css";

import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Select,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import {Discipline} from "../../../../../enums/Discipline";
import MenuItem from "@mui/material/MenuItem";
import {Levels} from "../../../../../enums/Levels";
import {ShowTypes} from "../../../../../enums/ShowTypes";
import {Months} from "../../../../../enums/Months";
import axios from "axios";
import {Breeds} from "../../../../../enums/Breeds";

const EnterShow = (props) => {
    
    const [discipline, setDiscipline] = useState("");
    
    const handleSetDiscipline = (event) => {
        setDiscipline(event.target.value);
    }
    
    const [level, setLevel] = useState("");
    
    const handleSetLevel = (event) => {
        setLevel(event.target.value);
    }
    
    const [type, setType] = useState("");
    
    const handleSetType = (event) => {
        setType(event.target.value);
    }
    
    const [month, setMonth] = useState("");
    
    const handleSetMonth = (event) => {
        setMonth(event.target.value);
    }
    
    const [time, setTime] = useState("");
    
    const handleSetTime = (event) => {
        setTime(event.target.value);
    }
    
    const [isBreedSpecific, setIsBreedSpecific] = useState(false);
    
    const handleSetBreedSpecific = (event) => {
        setIsBreedSpecific(event.target.checked);
    }
    
    const [shows, setShows] = useState([]);

    useEffect(() => {
        if(discipline != null && discipline != "" &&
            ((level != null && level != "") || (type != null && type != ""))
        ){
            axios.get("api/shows/horsePageSearch", {
                params: {
                    discipline: discipline,
                    level: level,
                    type: type,
                    month: month,
                    time: time,
                    isBreedSpecific: isBreedSpecific,
                    breed: Breeds[props.horse.breed].replace(/ /g, ''),
                    horseId: props.horse.horseId
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            }). then(response =>{
                setShows(response.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [discipline, level, type, month, time, isBreedSpecific])
    
    const enterShow = (showId) => {
        const data = new FormData();
        data.append("showId", showId);
        data.append("horseId", props.horse.horseId);
        data.append("ranchId", props.ranchId);
        
        axios.post("api/Shows/EnterShow", data, props.formConfig)
            .then(response => {
                console.log(response)
            }).catch(error => {
                console.log(error);
        })
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    const formInputStyle = {
        margin: 1,
        width: '20%'
    }
    
    return (
        <div className={props.darkMode ? "enter-show-container-dark" : "enter-show-container"}>
            <div className={"enter-show-header-container"}>
                <Typography variant={"h3"}>Enter Shows</Typography>
            </div>
            <div className={"enter-show-filters-container"}>
                <FormControl sx={formInputStyle}>
                    <Select size={"small"} value={discipline} onChange={handleSetDiscipline}>
                        {Discipline.map((discipline, i) =>
                            <MenuItem key={i} value={discipline.replace(/ /g, '')}>{discipline}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={formInputStyle}>
                    <Select size={"small"} value={level} onChange={handleSetLevel} disabled={type != null && type.length > 0}>
                        <MenuItem value={""}>All</MenuItem>
                        {Levels.map((level, i) =>
                            <MenuItem key={i} value={level}>{level}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={formInputStyle}>
                    <Select size={"small"} value={type} onChange={handleSetType} disabled={level != null && level.length > 0}>
                        <MenuItem value={""}>None</MenuItem>
                        {ShowTypes.map((type, i) =>
                            <MenuItem key={i} value={type.Value}>{type.Title}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={formInputStyle}>
                    <Select size={"small"} value={month} onChange={handleSetMonth}>
                        {Months.map((month, i) =>
                            <MenuItem key={i} value={month}>{month}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl size={"small"} sx={formInputStyle}>
                    <Select value={time} onChange={handleSetTime}>
                        <MenuItem value={"PM"}>PM</MenuItem>
                        <MenuItem value={"AM"}>AM</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormControlLabel control={<Checkbox checked={isBreedSpecific} onChange={handleSetBreedSpecific}/>}
                                      label={"Breed Specific"}/>
                </FormControl>
            </div>
            <div className={"enter-show-table-container"}>

                <TablePagination
                    sx={{alignSelf: "flex-end"}}
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={shows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer sx={{width: "98%", overFlowX: 'auto'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Discipline</TableCell>
                                <TableCell>Level</TableCell>
                                <TableCell>Breed</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Fee</TableCell>
                                <TableCell>Purse</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(show =>
                                <TableRow key={show.showId}>
                                    <TableCell>{show.title}</TableCell>
                                    <TableCell>{Discipline[show.discipline]}</TableCell>
                                    <TableCell>{Levels[show.level]}</TableCell>
                                    <TableCell>{Breeds[show.breed] == "Grade" ? "Open" : Breeds[show.breed]}</TableCell>
                                    <TableCell>{formatDate(show.eventDate - 1)}</TableCell>
                                    <TableCell>{show.eventTime}</TableCell>
                                    <TableCell>{show.entryFee}</TableCell>
                                    <TableCell>{show.purse}</TableCell>
                                    <TableCell>
                                        <Button variant={"contained"} onClick={enterShow.bind(this, show.showId)}>Enter</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        sx={{alignSelf: "flex-end"}}
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={shows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </div>
        </div>
    )
}

export default EnterShow