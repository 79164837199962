import "./Studs.css"
import {React, useEffect, useState} from "react";
import axios from "axios";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, FormControl, InputLabel, Select, Tab, TextField} from "@mui/material";
import SemenCard from "./SemenCard";
import StudCard from "./StudCard";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../enums/Breeds";

const Studs = (props) => {
    const ranchId = props.ranchId;

    const location = useLocation();
    const history = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    const updateUrlParams = (newParamValue) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newParamValue);

        // Replace the current URL with the updated parameters
        history(`${location.pathname}?${searchParams.toString()}`);
    };


    const [selectedTab, setSelectedTab] = useState(tab || "publicstud");

    const handleChangeTab = (event: React.SyntheticEvent, newTab) => {
        setSelectedTab(newTab);
        updateUrlParams(newTab);
    }
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }
    
    const [breed, setBreed] = useState("All");

    const handleSetBreed = (event) => {
        setBreed(event.target.value);
    }

    const [minPrice, setMinPrice] = useState(0);

    const handleSetMinPrice = (event) => {
        setMinPrice(event.target.value);
    }

    const [maxPrice, setMaxPrice] = useState(0);

    const handleSetMaxPrice = (event) => {
        if(event.target.value == ''){
            setMaxPrice(0);
        } else {
            setMaxPrice(event.target.value);
        }
    }

    const [sortBy, setSortBy] = useState(null);

    const handleSetSortBy = (event) => {
        setSortBy(event.target.value);
    }
    
    const [horses, setHorses] = useState([]);
    
    useEffect(() => {
        const getStuds = async () => {
            await axios.get("/api/Horses/publicStuds", {
                params:{
                    breed: breed,
                    min: minPrice,
                    max: maxPrice,
                    sortBy: sortBy
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
                .then(response =>
                    setHorses(response.data)
                ).catch( error =>
                console.log(error)
            )
        }

        const timer = setTimeout(() => {
            getStuds();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [updated, breed, minPrice, maxPrice, sortBy]);

    const [semens, setSemens] = useState([]);

    useEffect(() => {
        const getSemens = async () => {
            await axios.get("/api/Health/publicSemens", {
                params:{
                    breed: breed,
                    min: minPrice,
                    max: maxPrice,
                    sortBy: sortBy
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
                .then(response =>
                    setSemens(response.data)
                ).catch( error =>
                    console.log(error)
                )
        }

        const timer = setTimeout(() => {
            getSemens();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [updated, breed, minPrice, maxPrice, sortBy])

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const moveForward = (event) => {
        setIndexStart(indexStart + 30);
        setIndexEnd(indexEnd + 30);
    }

    const moveBackward = (event) => {
        setIndexStart(indexStart - 30);
        setIndexEnd(indexEnd - 30);
    }
    
    const filters = (
        <>
            <FormControl sx={{margin: 1}}>
                <InputLabel id={"Choose a breed"}>Breed</InputLabel>
                <Select value={breed} labelId={"Choose a breed"} size={"small"} variant={"filled"} sx={{width: 200}} onChange={handleSetBreed}>
                    <MenuItem key={-1} value={"All"}>All</MenuItem>
                    {[...Breeds].sort().map(b =>
                        <MenuItem key={Breeds.indexOf(b)} value={b.replaceAll(" ", "")}>{b}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl>
                <TextField label={"Min Price"} sx={{margin: "5px"}} variant={"outlined"} size={"small"} type={"number"} onChange={handleSetMinPrice}/>
            </FormControl>
            <FormControl>
                <TextField label={"Max Price"} sx={{margin: "5px"}} variant={"outlined"} size={"small"} type={"number"} onChange={handleSetMaxPrice}/>
            </FormControl>
            <FormControl sx={{margin: 1}}>
                <InputLabel id={"Choose a breed"}>Order By</InputLabel>
                <Select value={breed} labelId={"Choose a breed"} size={"small"} variant={"filled"} sx={{width: 200}} onChange={handleSetSortBy}>
                    <MenuItem key={0} value={"breed"}>Breed</MenuItem>
                    <MenuItem key={1} value={"priceAscending"}>Price Ascending</MenuItem>
                    <MenuItem key={2} value={"priceDescending"}>Price Descending</MenuItem>
                </Select>
            </FormControl>
        </>
    )
    
    return (
        <div>
            <img className={"horsesBanner"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/banners/barn-banner.jpg"} alt={"Barn"}/>
            <TabContext value={selectedTab}>
                <Box width={"100%"}>
                    <TabList className={"horses-tabs"} onChange={handleChangeTab} centered  textColor={"white"}>
                        <Tab label={"Public Studs"} value={"publicstud"}/>
                        <Tab label={"Public Semen"} value={"publicsemen"}/>
                    </TabList>
                </Box>
                <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"horses-tab"} value={"publicstud"}>
                    {horses &&
                        <div>
                            {filters}
                            <div className={"page-nav-section"}>
                                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                                {indexEnd > horses.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                            </div>
                            <div className={"horsesSection"}>
                                {horses.slice(indexStart, indexEnd).map(horse =>
                                    <Link className={"studs-link"} to={`/horses/${horse.horseId}`}>
                                        <StudCard key={horse.horseId} horse={horse} id={horse.horseId} api={props.api}/>
                                    </Link>
                                )}
                            </div>
                            <div className={"page-nav-section"}>
                                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                                {indexEnd > horses.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                            </div>
                        </div>}
                </TabPanel>
                <TabPanel style={props.darkMode ? {backgroundColor: "transparent"} : {}} className={"horses-tab"} value={"publicsemen"}>
                    {semens && 
                        <div>
                            {filters}
                            <div className={"page-nav-section"}>
                                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                                {indexEnd > semens.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                            </div>
                            <div className={"horsesSection"}>
                                {semens.slice(indexStart, indexEnd).map(semen =>
                                    <SemenCard key={semen.semenId} id={semen.semenId} horse={semen.stud} ranchId={props.ranchId} formConfig={props.formConfig}
                                               price={semen.price} api={props.api} token={props.token} amount={semen.amountForSale} handleSetUpdated={handleSetUpdated}/>
                                )}
                            </div>
                            <div className={"page-nav-section"}>
                                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                                {indexEnd > semens.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
                            </div>
                        </div>
                    }
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default Studs;