import "./SaleCard.css";

import React, {useEffect, useState} from "react";
import Photo from "../horse/photo/Photo";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {Dialog, DialogActions, DialogContentText, DialogTitle, FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Breeds} from "../../enums/Breeds";

const SalesCard = (props) => {
    const displaySire = () => {
        if(props.horse.sire != null){
            return props.horse.sire.regName;
        }
        return "Unknown";
    }

    const displayDam = () => {
        if(props.horse.dam != null){
            return props.horse.dam.regName;
        }
        return "Unknown";
    }
    
    const [disabled, setDisabled] = useState(false);
    
    const purchaseHorse = async () => {    
        setDisabled(true);
        var data = new FormData();
        data.append("horseId", props.horse.horseId);
        data.append("buyerEmail", props.email);
        data.append("sellerRanchId", props.horse.ranchId);
        data.append("buyerRanchId", props.ranchId);
        data.append("salesId", props.horse.saleId);
        data.append("location", location);

        if(location != undefined){
            await axios.post('/api/Sales/purchase', data, props.formConfig)
                .then(function (response) {
                    console.log(response);
                    setMessage(response.data);
                    handleClose();
                    handleOpenConfirmation();
                }).catch(function (error) {
                    console.log(error);
                    setMessage(error.response.data);
                    handleOpenConfirmation();
                });
        } else {
            handleOpenConfirmation();
            setMessage("Please choose a pasture or barn. If you do not have an option, please create an additional pasture or barn.");
        }
        props.handleSetChanged();
        setDisabled(false);
    }

    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {
            params: {
                id: props.ranchId
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data);
            });
    }, []);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {
            params: {
                id: props.ranchId
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data);
            });
    }, []);

    const [locations, setLocations] = useState();

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);
    
    const [location, setLocation] = useState();
    
    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    const [message, setMessage] = useState();

    const female = (<img className={"private-sale-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"}/>)
    const male = (<img className={"private-sale-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"}/>)
    const gelding = (<img className={"private-sale-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)
    
    return (
        <div className={"sales-card-container"} >
            <Link to={`/horses/${props.horse.horseId}`}>
                {props.horse.gender == 0 ? female : props.horse.gelded ? gelding : male}
                <Photo className={"horse-image"} id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api} setTextColor={null}/>
            </Link>
            <div className={"sales-info-container"}>
                <div className={"sales-name-container"}>
                    <p className={"sales-prefix"}>{props.horse.prefix}</p>
                    <p className={"sales-name"}>{props.horse.regName}</p>
                </div>
                <p className={"sales-breed"}>{Breeds[props.horse.breed]}</p>
                <p className={"sales-parents"}>{displaySire()} x {displayDam()}</p>
            </div>
            <div className={"sales-purchase-info"}>
                <p className={"sales-price"}>{(props.horse.sale.price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(props.horse.sale.price)).toFixed().length
                })}</p>
                <Button variant={"contained"} onClick={handleOpen} size={"small"}>Purchase</Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Choose a location</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    <FormControl>
                        <InputLabel>Choose a barn/pasture</InputLabel>
                        <Select labelId={"pasture"} label={"Choose a pasture"} size={"small"} value={location} sx={{minWidth: 200, margin: 1}} onChange={handleSetLocation}>
                            <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or barn</MenuItem>
                            {locations && locations.map(location =>
                                <MenuItem key={location.name} value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId} >{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} disabled={disabled} onClick={purchaseHorse.bind(this, props.horse)}>Purchase</Button>
                    <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Message</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCloseConfirmation} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SalesCard;