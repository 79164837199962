export const Jobs = [
    "None",
    "Pack Horse",
    "Rodeo Pick Up",
    "Hunting Pack Horse",
    "Police",
    "Logging",
    "Ranch",
    "Parade",
    "Actor",
    "Circus",
    "Pony",
    "Carriage",
    "Trail",
    "Therapy",
    "Lesson",
    "Petting",
    "Bronc"
]