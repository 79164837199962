import "./Parent.css";

import React from "react";

const Parent = (props) => {
    
    const mare = (
        <div className={"mare"}>
            {props.name != null ? <p style={{margin: 0}}>{props.name}</p> : <p style={{margin: 0}}>Unknown</p>}
        </div>
    )

    const stallion = (
        <div className={"stallion"}>
            {props.name != null ? <p style={{margin: 0}}>{props.name}</p> : <p style={{margin: 0}}>Unknown</p>}
        </div>
    )

    const unknown = (
        <div className={"parent"}>
            {props.name != null ? <p style={{margin: 0}}>{props.name}</p> : <p style={{margin: 0}}>Unknown</p>}
        </div>
    )
    
    return(
        <>
            {/*{props.name != null ? props.gender == 0 ? mare : stallion : unknown}*/}
            {props.name != null ? <p className={"parent"} style={{margin: 0}}>{props.name}</p> : <p className={"parent"} style={{margin: 0}}>Unknown</p>}
        </>
    )
}

export default Parent;