import "./SearchRanches.css";

import {React, useEffect, useState} from "react";
import axios from "axios";
import {Autocomplete, Checkbox, FormControl, FormControlLabel, Pagination, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import RanchSearchCard from "./RanchSearchCard";
import {Link} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {Gender} from "../../../enums/Gender";
import {Breeds} from "../../../enums/Breeds";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";

const SearchRanches = (props) => {

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [breeds, setBreeds] = useState([]);

    const handleSetBreeds = (breed) => {
        if (breeds.includes(breed)) {
            setBreeds(breeds.filter(b => b != breed));
        } else {
            setBreeds([...breeds, breed]);
        }
    }

    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }

    const [owner, setOwner] = useState(null);

    const handleSetOwner = (event) => {
        setOwner(event.target.value);
    }

    const [ranches, setRanches] = useState([]);

    useEffect(() => {
        const searchDto = {
            name: ranch,
            owner: owner,
            breeds: breeds,
            pageNumber: pageNumber
        }
        const getRanches = () => {
            axios.post("api/searches/ranches", searchDto, props.config)
                .then(response => {
                setRanches(response.data);
            }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getRanches();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [pageNumber, breeds, ranch, owner]);

    useEffect(() => {
        const searchDto = {
            name: ranch,
            owner: owner,
            breeds: breeds,
            pageNumber: pageNumber
        }
        const getPages = () => {
            axios.post("api/searches/ranchesTotalPages", searchDto, props.config)
                .then(response => {
                    setPagesTotal(response.data);
                }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getPages();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [pageNumber, breeds, ranch, owner]);

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const filterInputStyle = {
        margin: 1,
    }

    const clearFilters = () => {
        setBreeds([]);
        setRanch("");
        setOwner("");
    }

    const filters = (
        <div className={"search-horses-filters-container"}>
            <div className={"search-horses-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Breed</Typography>
                <div className={"shows-filter-items-container"}>
                    {[...Breeds].sort().map((breed, i) =>
                        <FormControlLabel key={i} sx={filterItemStyle} control={
                            <Checkbox sx={filterCheckboxStyle} checked={breeds.includes(breed.replaceAll(/ /g, ""))}
                                      onChange={() => handleSetBreeds(breed.replaceAll(/ /g, ""))}
                            />} label={breed}/>
                    )}
                </div>
            </div>
            <FormControl sx={filterInputStyle}>
                <TextField label={"Name"} size={"small"} value={ranch} onChange={handleSetRanch}/>
            </FormControl>
            <FormControl sx={filterInputStyle}>
                <TextField label={"Owner"} size={"small"} value={owner} onChange={handleSetOwner}/>
            </FormControl>
            <div className={"search-horses-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <div className={"search"}>
            {filtersOpen ? filters : ""}
            <div className={"ranch-search-controls-container"}>
                <FilterList onClick={openFilters}/>
                <Pagination variant={"outlined"}
                            shape={"rounded"}
                            boundaryCount={2}
                            page={pageNumber}
                            count={pagesTotal}
                            onChange={handleSetPageNumber}
                />
            </div>
            {ranches && <div className={"ranches-container"}
                             style={ranches.length === 0 ? {justifyContent: "center", alignContent: "center"} : {}}>
                {ranches.length === 0 ? <p style={{alignSelf: "center"}}>Search returned no results.</p> :
                    ranches.map(ranch =>
                        <Link className={"link"} to={`/ranches/${ranch.ranchId}`}>
                            <RanchSearchCard key={ranch.ranchId} ranch={ranch}/>
                        </Link>
                    )}
            </div>}
            <Pagination sx={{alignSelf: "flex-end"}}
                        variant={"outlined"}
                        shape={"rounded"}
                        boundaryCount={2}
                        page={pageNumber}
                        count={pagesTotal}
                        onChange={handleSetPageNumber}
            />
        </div>
    )
}

export default SearchRanches;