export const ShowTypes = [
    {
        Title: "Futurity",
        Value: "Futurity"
    },
    {
        Title: "Veteran",
        Value: "Veteran"
    },
    {
        Title: "Breeder's Cup",
        Value: "Breeders"
    },
    {
        Title: "World",
        Value: "World"
    },
    {
        Title: "Grand Prix",
        Value: "GrandPrix"
    },
    {
        Title: "Kickin' It Old School",
        Value: "OldSchool"
    },
    {
        Title: "Grand Slam",
        Value: "GrandSlam"
    },
    {
        Title: "Puissance",
        Value: "Puissance"
    },
    {
        Title: "Longest Race",
        Value: "LongestRace"
    },
    {
        Title: "Derby",
        Value: "Derby"
    },
    {
        Title: "Stampede",
        Value: "Stampede"
    },
    {
        Title: "Hollywood",
        Value: "Hollywood"
    },
    {
        Title: "Gentle Giants",
        Value: "GentleGiants"
    },
    {
        Title: "None",
        Value: ""
    }
]