import "./Club.css";

import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {
    Autocomplete,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl, FormControlLabel, FormLabel, Pagination, Radio, RadioGroup,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField,
    Typography
} from "@mui/material";
import ClubChat from "../chat/ClubChat";
import DividerTitle from "../DividerTitle";
import Button from "@mui/material/Button";
import {darkTheme, lightTheme} from "../../Theme";
import MenuItem from "@mui/material/MenuItem";
import HorseCard from "../horses/HorseCard";
import {Discipline} from "../../enums/Discipline";
import {Breeds} from "../../enums/Breeds";
import Toolbar from "@mui/material/Toolbar";
import {Close, FilterList} from "@mui/icons-material";

const Club = (props) => {

    const {id} = useParams();
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [club, setClub] = useState(null);

    useEffect(() => {
        axios.get("api/Clubs/club", {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setClub(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [id, updated]);

    const [titlesPagesTotal, setTitlesPagesTotal] = useState(1);
    const [titlesPageNumber, setTitlesPageNumber] = useState(1);

    const handleSetTitlesPageNumber = (event, value) => {
        setTitlesPageNumber(value);
    }

    const [foalsPagesTotal, setFoalsPagesTotal] = useState(1);
    const [foalsPageNumber, setFoalsPageNumber] = useState(1);

    const handleSetFoalsPageNumber = (event, value) => {
        setFoalsPageNumber(value);
    }
    const [rankingType, setRankingType] = useState("points");
    
    const handleSetRankingType = (event) => {
        setRankingType(event.target.value)
    }
    
    const [allTime, setAllTime] = useState(false);

    const [recentTitles, setRecentTitles] = useState([]);
    const [recentFoals, setRecentFoals] = useState([]);
    const [rankings, setRankings] = useState([]);

    useEffect(() => {
        if (club) {
            axios.get("api/Rankings/recentTitles", {
                params: {
                    discipline: club.discipline ? Discipline[club.discipline].replace(/ /g, "") : "",
                    breed: club.breed ? Breeds[club.breed].replace(/ /g, "") : ""
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            }).then(response => {
                setRecentTitles(response.data);
                setTitlesPageNumber(Math.ceil(response.data.length / 3));
            }).catch(error => {
                console.log(error);
            })
        }
    }, [club]);

    const [breed, setBreed] = useState("");

    const handleSetBreed = (event, values) => {
        setBreed(values);
    }
 
    const [discipline, setDiscipline] = useState("");

    const handleSetDiscipline = (event, values) => {
        setDiscipline(values);
    }

    useEffect(() => {
        if (club && rankingType == "points") {
            axios.get("api/Rankings/byPoints", {
                params: {
                    disc: club.discipline != null ? Discipline[club.discipline].replace(/ /g, "") : discipline.replace(/ /g, ""),
                    breed: club.breed != null ? Breeds[club.breed].replace(/ /g, "") : breed.replace(/ /g, ""),
                    allTime: allTime
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            }).then(response => {
                setRankings(response.data);
            }).catch(error => {
                console.log(error);
            })
        }
        if (club && rankingType == "earnings") {
            axios.get("api/Rankings/byEarnings", {
                params: {
                    disc: club.discipline != null ? Discipline[club.discipline].replace(/ /g, "") : discipline.replace(/ /g, ""),
                    breed: club.breed != null ? Breeds[club.breed].replace(/ /g, "") : breed.replace(/ /g, ""),
                    allTime: allTime
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            }).then(response => {
                setRankings(response.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [club, rankingType, discipline, breed]);

    const [ranches, setRanches] = useState([]);

    useEffect(() => {
        axios.get('api/Ranches/getByUser', {
            params: {
                id: props.user.id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setRanches(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [props.email])

    const [open, setOpen] = useState(false);
    const [functionType, setFunctionType] = useState("Join");

    const handleOpen = (type) => {
        setFunctionType(type);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [ranchId, setRanchId] = useState(null);

    const handleSetRanchId = (event) => {
        setRanchId(event.target.value);
    }

    const joinClub = () => {
        const data = new FormData();
        data.append("clubId", club.clubId);
        data.append("id", ranchId);

        axios.post("api/Clubs/joinClub", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
                handleClose();
            }).catch(error => {
            console.log(error);
        })
    }

    const leaveClub = () => {
        const data = new FormData();
        data.append("clubId", club.clubId);
        data.append("id", ranchId);

        axios.post("api/Clubs/leaveClub", data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
                handleClose();
            }).catch(error => {
            console.log(error);
        })
    }

    const [rankingsPage, setRankingsPage] = useState(0);
    const [rowsPerRankingsPage, setRowsPerRankingsPage] = useState(50);

    const handleChangeRankingsPage = (event, newRankingsPage) => {
        setRankingsPage(newRankingsPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerRankingsPage(parseInt(event.target.value, 10));
        setRankingsPage(0);
    };

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const infoTextHeaderStyle = {
        fontWeight: "bold",
        marginRight: 1
    }

    const infoTextStyle = {
        marginLeft: 1
    }

    const joinButtonStyle = {
        margin: "5px 20px 30px 20px",
        width: "fit-content"
    }

    const filterInputStyle = {
        margin: 1,
        width: "200px"
    }

    const filters = (
        <>
            {club && <div className={"club-rankings-filters-container"}>
                <div>
                    <FormControl sx={filterInputStyle}>
                        <FormLabel>Ranking Type</FormLabel>
                        <RadioGroup
                            defaultValue="points"
                            onChange={handleSetRankingType}
                        >
                            <FormControlLabel value="points" control={<Radio />} label="By Points" />
                            <FormControlLabel value="earnings" control={<Radio />} label="By Earnings" />
                        </RadioGroup>
                    </FormControl>
                    {club.discipline == null ?
                        <FormControl sx={filterInputStyle}>
                            <Autocomplete required
                                          label={"Discipline"}
                                          options={Discipline}
                                          onChange={handleSetDiscipline}
                                          renderInput={(params) => (
                                              <TextField {...params} label={"Discipline"} variant={"outlined"}
                                                         size={"small"}/>)}/>
                        </FormControl> : ""}
                    {club.breed == null ?
                        <FormControl sx={filterInputStyle}>
                            <Autocomplete required
                                          label={"Breed"}
                                          options={Breeds}
                                          onChange={handleSetBreed}
                                          renderInput={(params) => (
                                              <TextField {...params} label={"Breed"} variant={"outlined"}
                                                         size={"small"}/>)}/>
                        </FormControl> : ""}
                </div>
                <Close onClick={closeFilters}/>
            </div>}
        </>
    )

    return (
        <>
            {club && <div className={"club-container"}>
                <img className={"club-banner-img"} src={`https://media.legacyhorsegame.com/banners/clubs/${club.image}.jpg`}
                     alt={club.name}/>
                <div className={"club-info-container"}>
                    <div className={"club-info-subcontainer"}>
                        <Button sx={joinButtonStyle} variant={"contained"} size={"small"}
                                onClick={handleOpen.bind(this, "Join")}>Join</Button>
                        {club && club.members.some(m => m.id == props.user.id) ?
                            <Button sx={joinButtonStyle} variant={"contained"} size={"small"}
                                    onClick={handleOpen.bind(this, "Leave")}>Leave</Button> : ""}
                        <div className={"club-info-item-container"}>
                            <Typography sx={infoTextHeaderStyle}>Active Horses</Typography>
                            <Divider sx={{flexGrow: 1}}/>
                            <Typography sx={infoTextStyle}>{club.activeHorses}</Typography>
                        </div>
                        <div className={"club-info-item-container"}>
                            <Typography sx={infoTextHeaderStyle}>Lifetime Horses</Typography>
                            <Divider sx={{flexGrow: 1}}/>
                            <Typography sx={infoTextStyle}>{club.lifetimeHorses}</Typography>
                        </div>
                        <div
                            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                            className={"club-members-list"}>
                            <div
                                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                                className={"club-members-list-header-container"}>
                                <Typography variant={"h3"}>Members</Typography>
                            </div>
                            <div className={"club-list-container"}>
                                {club.members.map(member =>
                                    <Link to={`/users/${member.displayName}`}>
                                        <Typography sx={{margin: 1}}>{member.displayName}</Typography>
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div
                            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                            className={"club-ranches-list"}>
                            <div
                                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                                className={"club-ranches-list-header-container"}>
                                <Typography variant={"h3"}>Ranches</Typography>
                            </div>
                            <div className={"club-list-container"}>
                                {club.ranches.map(ranch =>
                                    <Link to={`/users/${ranch.ranchId}`}>
                                        <Typography sx={{margin: 1}}>{ranch.name}</Typography>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                    <ClubChat user={props.user} club={club} api={props.api} token={props.token}
                              darkMode={props.darkMode}/>
                </div>
                <DividerTitle title={"Recently Titled"} darkMode={props.darkMode}/>
                <div className={"club-recent-titles-container"}>
                    <div className={"club-recent-titles-subcontainer"}>
                        {recentTitles.map(horse =>
                            <Link key={horse.horseId} className={"horses-link"} to={`/horses/${horse.horseId}`}>
                                <HorseCard horse={horse} id={horse.horseId} api={props.api}
                                           gelded={horse.gelded}/>
                            </Link>
                        )}
                    </div>
                    {recentTitles.length > 0 ? <Pagination variant={"outlined"}
                                                           shape={"rounded"}
                                                           boundaryCount={2}
                                                           page={titlesPageNumber}
                                                           count={titlesPagesTotal}
                                                           onChange={handleSetTitlesPageNumber}
                    /> : <Typography sx={{alignSelf: "center"}}>No recent titles</Typography>}
                </div>
                {club.breed != null ? <>
                    <DividerTitle title={"Recent Foals"} darkMode={props.darkMode}/>
                    <div className={"club-recent-foals-container"}>
                        <div className={"club-recent-foals-subcontainer"}>
                            {recentFoals.map(horse =>
                                <Link key={horse.horseId} className={"horses-link"} to={`/horses/${horse.horseId}`}>
                                    <HorseCard horse={horse} id={horse.horseId} api={props.api}
                                               gelded={horse.gelded}/>
                                </Link>
                            )}
                        </div>
                        {recentFoals.length > 0 ? <Pagination variant={"outlined"}
                                                              shape={"rounded"}
                                                              boundaryCount={2}
                                                              page={titlesPageNumber}
                                                              count={titlesPagesTotal}
                                                              onChange={handleSetTitlesPageNumber}
                        /> : <Typography sx={{alignSelf: "center"}}>No recent foals</Typography>}
                    </div>
                </> : ""}
                <DividerTitle title={"Rankings"} darkMode={props.darkMode}/>
                <div className={"club-rankings-container"}>
                    <div
                        style={{background: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                        className={"club-rankings-table-container"}>
                        {filtersOpen ? filters : ""}
                        <Toolbar sx={{
                            position: "relative",
                            display: "flex",
                            flexFlow: "row nowrap",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main,
                            width: "100%",
                            padding: 0,
                            margin: 0
                        }}>
                            <FilterList onClick={openFilters}/>
                            <TablePagination
                                sx={{alignSelf: "flex-end"}}
                                rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                                component="div"
                                count={rankings.length}
                                rowsPerPage={rowsPerRankingsPage}
                                page={rankingsPage}
                                onPageChange={handleChangeRankingsPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Toolbar>
                        <TableContainer sx={{width: "100%", overFlowX: 'auto'}}>
                            <Table size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{width: 100, textAlign: "center"}}>Rank</TableCell>
                                        <TableCell sx={{width: 350, textAlign: "center"}}>Horse</TableCell>
                                        <TableCell sx={{width: 350, textAlign: "center"}}>Breeder</TableCell>
                                        <TableCell sx={{width: 350, textAlign: "center"}}>Owner</TableCell>
                                        <TableCell sx={{width: 350, textAlign: "center"}}>Ranch</TableCell>
                                        <TableCell sx={{width: 200, textAlign: "center"}}>Points</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rankings.map((record, i) =>
                                        <TableRow>
                                            <TableCell sx={{width: 10, textAlign: "center"}}>
                                                {i + 1}
                                            </TableCell>
                                            <TableCell sx={{width: 75, textAlign: "center"}}>
                                                <Link to={`/horses/${record.id}`}>{record.name}</Link>
                                            </TableCell>
                                            <TableCell sx={{width: 75, textAlign: "center"}}>
                                                {record.breeder}
                                            </TableCell>
                                            <TableCell sx={{width: 75, textAlign: "center"}}>
                                                {record.owner}
                                            </TableCell>
                                            <TableCell sx={{width: 75, textAlign: "center"}}>
                                                {record.ranch}
                                            </TableCell>
                                            <TableCell sx={{width: 20, textAlign: "center"}}>
                                                {rankingType == "points" ? record.points : record.earnings}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            sx={{alignSelf: "flex-end"}}
                            rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                            component="div"
                            count={rankings.length}
                            rowsPerPage={rowsPerRankingsPage}
                            page={rankingsPage}
                            onPageChange={handleChangeRankingsPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Choose a Ranch</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    <FormControl sx={{width: "200px"}}>
                        <Select onChange={handleSetRanchId}>
                            {ranches.map(ranch =>
                                <MenuItem key={ranch.ranchId} value={ranch.ranchId}>{ranch.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                    {functionType == "Join" ? <Button onClick={joinClub} color={"warning"} variant={"contained"}>
                        Confirm
                    </Button> : <Button onClick={leaveClub} color={"warning"} variant={"contained"}>
                        Confirm
                    </Button>}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Club