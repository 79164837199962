import "./Rankings.css";

import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {Discipline} from "../../enums/Discipline";
import {Link} from "react-router-dom";

const ParentEarnings = (props) => {

    const [allTime, setAllTime] = useState(true);

    const handleSetAllTime = (event) => {
        setAllTime(event.target.checked);
    }

    const [discipline, setDiscipline] = useState("all");

    const handleSetDiscipline = (event) => {
        setDiscipline(event.target.value)
    }

    const [records, setRecords] = useState([]);

    useEffect(() => {
        axios.get("/api/Rankings/parentByEarnings", {
            params: {
                allTime: allTime,
                disc: discipline,
                gender: props.gender
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api
            }
        }).then(response => {
            setRecords(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [allTime, discipline]);

    return (
        <div className={"horse-points-container"}>
            <div className={"horse-points-filters"}>
                <FormControl>
                    <FormLabel>Discipline</FormLabel>
                    <Select value={discipline} labelId={"Choose a breed"} size={"small"} variant={"outlined"} sx={{width: 200}} onChange={handleSetDiscipline}>
                        <MenuItem key={-1} value={"All"}>All</MenuItem>
                        {[...Discipline].sort().map((b, index) =>
                            <MenuItem key={index} value={b.replace(/ /g, "")}>{b}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl sx={{display: "flex", flexFlow: "row wrap", alignItems: "center"}}>
                    <p>All time</p>
                    <Checkbox size={"small"} checked={allTime} onChange={handleSetAllTime}/>
                </FormControl>
            </div>
            <Box sx={{ display: 'table', tableLayout: 'fixed'}}>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: 100, textAlign: "center"}}>Rank</TableCell>
                            <TableCell sx={{width: 350, textAlign: "center"}}>Horse</TableCell>
                            <TableCell sx={{width: 350, textAlign: "center"}}>Breeder</TableCell>
                            <TableCell sx={{width: 350, textAlign: "center"}}>Owner</TableCell>
                            <TableCell sx={{width: 350, textAlign: "center"}}>Ranch</TableCell>
                            <TableCell sx={{width: 200, textAlign: "center"}}>Earnings</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((record, i) =>
                            <TableRow>
                                <TableCell sx={{width: 10, textAlign: "center"}}>
                                    {i + 1}
                                </TableCell>
                                <TableCell sx={{width: 75, textAlign: "center"}}>
                                    <Link to={`/horses/${record.id}`}>{record.name}</Link>
                                </TableCell>
                                <TableCell sx={{width: 75, textAlign: "center"}}>
                                    {record.breeder}
                                </TableCell>
                                <TableCell sx={{width: 75, textAlign: "center"}}>
                                    {record.owner}
                                </TableCell>
                                <TableCell sx={{width: 75, textAlign: "center"}}>
                                    {record.ranch}
                                </TableCell>
                                <TableCell sx={{width: 20, textAlign: "center"}}>
                                    {record.earnings.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumSignificantDigits: Math.trunc(Math.abs(record.earnings)).toFixed().length
                                    })}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </div>
    )
}

export default ParentEarnings