import "./GlobalChat.css";

import React, {useEffect, useRef, useState} from "react";
import {TextareaAutosize, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Message from "./Message";
import * as signalR from "@microsoft/signalr";
import {useCookies} from "react-cookie";
import {darkTheme, lightTheme} from "../../Theme";
import {Close} from "@mui/icons-material";

const GlobalChat = (props) => {
    const [cookies] = useCookies(["jwt"]);
    //const baseUrl = window.location.origin;
    const baseUrl = process.env.REACT_APP_IS_TEST == "true" ? process.env.REACT_APP_CHAT_URL_TEST : process.env.REACT_APP_CHAT_URL;

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState({body: ""});
    
    const connectionRef = useRef(null);
    const messagesRef = useRef(null);
    
    const start = () => {
        console.log(process.env.REACT_APP_IS_TEST);

        connectionRef.current = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/chat/?chatThreadId=1`, {
                accessTokenFactory: () => cookies.jwt
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        connectionRef.current.start({withCredentials: false})
            .then(() => {
                console.log("Connected!");

                connectionRef.current.on('LoadMessages', (response) => {
                    setMessages(response.data); // Adjust this based on the actual shape of your 'response'
                });

                connectionRef.current.on("ReceiveChatMessage", (message) => {
                    setMessages(prevMessages => [...prevMessages, message]);
                });                
            })
            .catch(err => console.error("SignalR Connection Error: ", err));
    }

    useEffect(() => {
        start();

        connectionRef.current.onclose(async () => {
            await start(); // Function that starts the SignalR connection
        });

        return () => {
            connectionRef.current.stop().then(() => console.log('Connection stopped'));
        };
    }, []);

    useEffect(() => {
        if(messagesRef.current){
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSetMessage = (event) => {
        const newMessage = {
            body: event.target.value,
            authorId: props.user.id,
            chatThreadId: 1,
            author: props.user.displayName
        };
        setMessage(newMessage);
    };

    const sendMessage = async () => {
        if (connectionRef.current && connectionRef.current.state === signalR.HubConnectionState.Connected) {
            connectionRef.current.invoke("SendChatMessage", message)
                .catch(err => console.error("SignalR Error: ", err));
        } else {
            console.error("Cannot send message. Connection is not in the 'Connected' state.");
        }
        setMessage({body: ""});
    };
    return (
        <div className={"global-chat-container"}>
            <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"global-chat-header-container"}>
                <Typography sx={{flexGrow: 1, textAlign: "center", marginLeft: "15px"}} variant={"h3"}>Global Chat</Typography>
                <Close onClick={props.closeChat} />
            </div>
            <div style={{overflowY: "auto", maxHeight: "50vh", flexGrow: 1, width: "100%"}} ref={messagesRef}>
                <div className={"global-chat-messages-container"}>
                    {messages.map((m, index) =>
                        <Message key={index} message={m} user={props.user}/> // Ensure 'Message' component can handle the 'message' prop
                    )}
                </div>
            </div>
            <div className={'global-chat-new-message-container'}>
                <TextField multiline minRows={1} maxRows={3} inputProps={{maxLength: 255}} value={message.body} onChange={handleSetMessage}/>
                <Button variant={"contained"} onClick={sendMessage}>Send</Button>
            </div>
        </div>
    );
}

export default GlobalChat