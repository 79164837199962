import "./Show.css";

import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    Select,
} from "@mui/material";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import HorseCard from "../horses/HorseCard";
import Results from "./Results";
import {Months} from "../../enums/Months";
import moment from 'moment';
import "moment-timezone"

const Show = (props) => {
    const { id } = useParams();
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }
    
    const [date, setDate] = useState(0);

    useEffect(() => {
        axios.get('/api/Application/month', props.config)
            .then(response => {
                setDate(response.data);
            });
    }, []);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [show, setShow] = useState();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        axios.get('/api/Shows/show', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setShow(response.data);
            }).catch(error => {
                console.log(error);
        });
    }, [id]);
    
    const [entrants, setEntrants] = useState([]);
    
    const [horses, setHorses] = useState([]);

    useEffect(() => {
        axios.get('/api/shows/getHorsesForShow', {
            params: {
                ranchId: props.ranchId,
                showId: id
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setHorses(response.data);
            });
        axios.get('/api/Shows/entrantsByShow', {params: {id: id},
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setEntrants(response.data);
                setHorse("");
            });
        setDisabled(false);
    }, [id, updated]);
    
    const [horse, setHorse] = useState({});
    
    const handleSelectHorse = (event) => {
        setHorse(event.target.value);
    }
    
    const [message, setMessage] = useState("")
    
    const handleEnterShow = async () => {
        setDisabled(true);
        const data = new FormData();
        
        data.append("showId", id);
        data.append("horseId", horse);
        data.append("ranchId", props.ranchId);

        await axios.post('/api/Shows/EnterShow', data, props.formConfig)
            .then(function (response) {
                console.log(response);
                handleSetUpdated();
            }).catch(function (error) {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
            });
    }
    
    const getIfShowRan = (value) => {
        if(date === value && show.eventTime === "AM" && moment.tz("America/New York").hour() > 6){
            return true;
        }
        return date === value && show.eventTime === "PM" && moment.tz("America/New York").hour() > 18;
    }
    
    const showEntryForm = (eventDate) => {
        if((entrants.length < 20 && eventDate > date) || (show.type !== null && eventDate > date)){
            return(
                <div>
                    <FormControl sx={{display: "flex", flexFlow: "row wrap", justifyContent: "center"}}>
                        <Select value={horse} label={"Choose a horse"} variant={"filled"} size={"small"} sx={{minWidth: 200, m: 2}} onChange={handleSelectHorse} defaultValue={"None"}>
                            <MenuItem disabled value={"None"}>Select a horse</MenuItem>
                            {horses && horses.map(horse =>
                                <MenuItem key={horse.horseId} value={horse.horseId}>{horse.regName}</MenuItem>
                            )}
                        </Select>
                        <Button variant={"contained"} sx={{m: 2}} onClick={handleEnterShow} disabled={disabled}>Enter Show</Button>
                    </FormControl>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Error</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                            {message}
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleClose} variant={"contained"}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
        return <p>Entries closed.</p>
    }
    
    return (
        <>
            {show && <div className={"show"}>
                <p className={"showTitle"}>{show.title}</p>
                <p className={"showEventDate"}>Show
                    date: {show.eventDate % 12 === 0 ? Months[11] : Months[(show.eventDate % 12) - 1]},
                    Y{show.eventDate % 12 === 0 ? Math.floor(show.eventDate / 12) - 1 : Math.floor(show.eventDate / 12)}</p>
                <p className={"showEntryFee"}><b>Entry fee:</b> {(show.entryFee).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(show.entryFee)).toFixed().length
                })}</p>
                <p className={"showPurse"}><b>First place:</b> {(show.purse * .3).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(show.purse)).toFixed().length
                })}</p>
                <p className={"showPurse"}><b>Second place:</b> {(show.purse * .25).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(show.purse)).toFixed().length
                })}</p>
                <p className={"showPurse"}><b>Third place:</b> {(show.purse * .2).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(show.purse)).toFixed().length
                })}</p>
                <p className={"showPurse"}><b>Fourth place:</b> {(show.purse * .15).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(show.purse)).toFixed().length
                })}</p>
                <p className={"showPurse"}><b>Fifth place:</b> {(show.purse * .1).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(show.purse)).toFixed().length
                })}</p>
                {date > show.eventDate ? <Results config={props.config} api={props.api}
                                                          token={props.token}/> : getIfShowRan(show.eventDate) ?
                    <Results config={props.config} api={props.api}
                             token={props.token}/> : showEntryForm(show.eventDate)}
                {<p className={"showEntrantsTitle"}>Entrants: </p>}
                <div className={"showEntrants"}>
                    {entrants && entrants.map(entrant =>
                        entrant.horse !== null ?
                            <>
                                <Link key={entrant.horse.horseId} className={"show-link"}
                                      to={`/horses/${entrant.horse.horseId}`}>
                                    <HorseCard className={"showEntrant"} horse={entrant.horse} config={props.config}
                                               api={props.api} token={props.token}/>
                                </Link>
                            </> : ""
                    )}
                </div>
            </div>}
        </>
    )
}

export default Show;