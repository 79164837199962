import "./NotFound.css";

import React from "react";

const NotFound = (props) => {
    
    
    return (
        <div className={"not-found-container"}>
            <h1>404 Not Found</h1>
        </div>
    )
}

export default NotFound