import "./RanchManager.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    FormControl, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox, FormControlLabel, RadioGroup, Radio, TextField
} from "@mui/material";
import {Condition} from "../../../enums/Condition";
import MenuItem from "@mui/material/MenuItem";
import {Discipline} from "../../../enums/Discipline";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {Gender} from "../../../enums/Gender";
import {Close, FilterList, FilterListOff} from "@mui/icons-material";
import {Title} from "../../../enums/Title";
import Toolbar from "@mui/material/Toolbar";
import {darkTheme, lightTheme} from "../../../Theme";

const RanchManager = (props) => {
    const [updated, setUpdated] = useState(0);
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [genders, setGenders] = useState([]);

    const handleSetGenders = (gender) => {
        if (genders.includes(gender)) {
            setGenders(genders.filter(g => g !== gender));
        } else {
            setGenders([...genders, gender]);
        }
    }

    const [searchAges, setSearchAges] = useState([]);

    const handleSetSearchAges = (age) => {
        if (searchAges.includes(age)) {
            setSearchAges(searchAges.filter(a => a != age));
        } else {
            setSearchAges([...searchAges, age]);
        }
    }

    const [name, setName] = useState(null);

    const handleSetName = (event) => {
        setName(event.target.value);
    }

    const [job, setJob] = useState("all");

    const handleSetJob = (event) => {
        setJob(event.target.value);
    }

    const [pasture, setPasture] = useState("all");

    const handleSetPasture = (event) => {
        setPasture(event.target.value);
    }

    const [disciplines, setDisciplines] = useState([]);

    const handleSetDisciplines = (discipline) => {
        if (disciplines.includes(discipline)) {
            setDisciplines(searchAges.filter(d => d != discipline));
        } else {
            setDisciplines([...disciplines, discipline]);
        }
    }
    
    const [searchLevels, setSearchLevels] = useState([]);
    
    const handleSetSearchLevels = (level) => {
        if (searchLevels.includes(level)) {
            setSearchLevels(searchLevels.filter(l => l != level));
        } else {
            setSearchLevels([...searchLevels, level]);
        }
    }
    
    const [showType, setShowType] = useState(null);
    
    const handleSetShowType = (event) => {
        setShowType(event.target.value);
    }
    
    const [titles, setTitles] = useState([]);

    const handleSetTitles = (title) => {
        if (titles.includes(title)) {
            setTitles(titles.filter(t => t != title));
        } else {
            setTitles([...titles, title]);
        }
    }
    
    const [sortBy, setSortBy] = useState(null);
    
    const handleSetSortBy = (event) => {
        setSortBy(event.target.value);
    }

    const [ranchManager, setRanchManager] = useState([]);

    useEffect(() => {
        const searchDto = {
            ranchId: props.ranchId,
            ages: searchAges,
            genders: genders,
            job: job,
            pasture: pasture,
            disciplines: disciplines,
            levels: searchLevels,
            showType: showType,
            titles: titles,
            sortBy: sortBy,
            name: name
        }

        const getManager = () => {
            axios.post("api/Horses/manager", searchDto, props.config).then(response => {
                setRanchManager(response.data);
            }).catch(error => {
                console.log(error);
            })
        }

        const timer = setTimeout(() => {
            getManager();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [name, searchAges, genders, job, pasture, disciplines, searchLevels, showType, titles, sortBy, updated]);

    const handleChangeTargetCondition = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("state", event.target.value);

        axios.post('api/Horses/targetCondition', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeAutoJobs = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("auto", event.target.checked);

        axios.post('api/Horses/autoJob', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeAutoShows = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("auto", event.target.checked);

        axios.post('api/Horses/autoShows', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeDiscipline = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("discipline", event.target.value);

        axios.post('api/Horses/showDiscipline', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeLevel = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("level", event.target.value);

        axios.post('api/Horses/autoShowLevel', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeTotalShows = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("totalShows", event.target.value);

        axios.post('api/Horses/numberOfShows', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeAutoPasture = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("auto", event.target.checked);

        axios.post('api/Horses/autoPasture', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeBreedSpecific = (id, event) => {
        const data = new FormData();
        data.append("id", id);
        data.append("isSpecific", event.target.checked);

        axios.post('api/Horses/isBreedSpecific', data, props.formConfig)
            .then(response => {
                console.log(response);
                handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const clearFilters = () => {
        setSearchAges([]);
        setGenders([]);
        setDisciplines([]);
        setSearchLevels([]);
        setTitles([]);
        setSortBy(null);
    }

    const selectStyle = {
        minWidth: "150px",
    }
    
    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: 0,
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const filterInputStyle = {
        margin: 1,
    }

    const ages = [
        "Suckling",
        "Weanling",
        "Mature",
        "Breeding Age"
    ]

    const levels = [
        "First",
        "Second",
        "Third",
        "Fourth"
    ]

    const filters = (
        <div className={"ranch-manager-filters-container"}>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Age</Typography>
                {ages.map((a, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={searchAges.includes(a.toLowerCase().replace(/ /g, ""))}
                              onChange={() => handleSetSearchAges(a.toLowerCase().replace(/ /g, ""))}
                    />} label={a}/>
                )}
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Gender</Typography>
                {Gender.map((g, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle} checked={genders.includes(g.toLowerCase())}
                              onChange={() => handleSetGenders(g.toLowerCase())}
                    />} label={g}/>
                )}
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Job</Typography>
                <RadioGroup value={job} onChange={handleSetJob}>
                    <FormControlLabel sx={filterItemStyle} value={"all"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"All"}/>
                    <FormControlLabel sx={filterItemStyle} value={"managed"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Managed"}/>
                    <FormControlLabel sx={filterItemStyle} value={"unmanaged"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Unmanaged"}/>
                </RadioGroup>
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Pasture</Typography>
                <RadioGroup value={pasture} onChange={handleSetPasture}>
                    <FormControlLabel sx={filterItemStyle} value={"all"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"All"}/>
                    <FormControlLabel sx={filterItemStyle} value={"managed"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Managed"}/>
                    <FormControlLabel sx={filterItemStyle} value={"unmanaged"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Unmanaged"}/>
                </RadioGroup>
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Discipline</Typography>
                {Discipline.map((d, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={disciplines.includes(d.replaceAll(/ /g, "").toLowerCase())}
                              onChange={() => handleSetDisciplines(d.replaceAll(/ /g, "").toLowerCase())}
                    />} label={d}/>
                )}
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Level</Typography>
                {levels.map((l, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={searchLevels.includes(l.replaceAll(/ /g, "").toLowerCase())}
                              onChange={() => handleSetSearchLevels(l.replaceAll(/ /g, "").toLowerCase())}
                    />} label={l}/>
                )}
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Show Type</Typography>
                <RadioGroup value={showType} onChange={handleSetShowType}>
                    <FormControlLabel sx={filterItemStyle} value={"all"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"All"}/>
                    <FormControlLabel sx={filterItemStyle} value={"breed"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Breed"}/>
                    <FormControlLabel sx={filterItemStyle} value={"open"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Open"}/>
                </RadioGroup>
            </div>
            <div className={"ranch-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Title</Typography>
                {Title.map((t, i) => <FormControlLabel key={i} sx={filterItemStyle} control={
                    <Checkbox sx={filterCheckboxStyle}
                              checked={titles.includes(t.split(" ")[0].toLowerCase())}
                              onChange={() => handleSetTitles(t.split(" ")[0].toLowerCase())}
                    />} label={t.split(" ")[0]}/>
                )}
            </div>
            <div className={"search-my-horses-filter-sub-container"}>
                <FormControl sx={filterInputStyle}>
                    <TextField label={"Name"} size={"small"} value={name} onChange={handleSetName}/>
                </FormControl>
            </div>
            <div className={"feed-manager-filter-sub-container"}>
                <Typography sx={filterTitleStyle}>Sort By</Typography>
                <RadioGroup value={sortBy} onChange={handleSetSortBy}>
                    <FormControlLabel sx={filterItemStyle} value={"name"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Horse Name"}/>
                    <FormControlLabel sx={filterItemStyle} value={"pasture"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Pasture Managed"}/>
                    <FormControlLabel sx={filterItemStyle} value={"job"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Auto Job"}/>
                    <FormControlLabel sx={filterItemStyle} value={"discipline"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Discipline"}/>
                    <FormControlLabel sx={filterItemStyle} value={"level"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Level"}/>
                    <FormControlLabel sx={filterItemStyle} value={"type"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Show Type"}/>
                    <FormControlLabel sx={filterItemStyle} value={"title"} control={
                        <Radio sx={filterCheckboxStyle}/>} label={"Title"}/>
                </RadioGroup>
            </div>
            <div className={"ranch-manager-close-clear-container"}>
                <Close onClick={closeFilters}/>
                <FilterListOff onClick={clearFilters}/>
            </div>
        </div>
    )

    return (
        <div className={"ranch-manager-container"}>
            {filtersOpen ? filters : ""}
            <Toolbar sx={{
                position: "relative",
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: props.darkTheme ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main,
                width: "100%",
                padding: 0,
                margin: 0
            }}>
                <FilterList onClick={openFilters}/>
                <TablePagination
                    sx={{alignSelf: "flex-end"}}
                    rowsPerPageOptions={[10, 25, 50, {value: -1, label: 'All'}]}
                    component="div"
                    count={ranchManager.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Toolbar>
            {ranchManager &&
                <TableContainer>
                    <Table size={"small"}>
                        <TableHead>
                        <TableRow>
                                <TableCell>Horse Name</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Manage Pasture</TableCell>
                                <TableCell>Target Condition</TableCell>
                                <TableCell>Auto Job</TableCell>
                                <TableCell>Auto Show</TableCell>
                                <TableCell>Discipline</TableCell>
                                <TableCell>Level</TableCell>
                                <TableCell>Breed Specific</TableCell>
                                <TableCell>Total Shows</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ranchManager.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((m, index) =>
                                <TableRow key={m.horseId}>
                                    <TableCell><Link to={`/horses/${m.horseId}`}>{m.regName}</Link></TableCell>
                                    <TableCell>{Title[m.title]}</TableCell>
                                    <TableCell>
                                        <Checkbox checked={m.pastureIsAuto}
                                                  onChange={handleChangeAutoPasture.bind(this, m.horseId)}/>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={selectStyle}>
                                            <Select labelId={"Condition"}
                                                    label={"Choose a target condition"}
                                                    size={"small"}
                                                    value={m.targetState != null ? Condition[m.targetState].replace(/ /g, "") : "None"}
                                                    onChange={handleChangeTargetCondition.bind(this, m.horseId)}>
                                                <MenuItem value={"None"}>None</MenuItem>
                                                <MenuItem value={"ModeratelyThin"}>Moderately Thin</MenuItem>
                                                <MenuItem value={"Moderate"}>Moderate</MenuItem>
                                                <MenuItem value={"ModeratelyFat"}>Moderately Fat</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox checked={m.jobIsAuto} disabled={m.age < 24}
                                                  onChange={handleChangeAutoJobs.bind(this, m.horseId)}/>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox checked={m.showIsAuto} disabled={m.age < 24}
                                                  onChange={handleChangeAutoShows.bind(this, m.horseId)}/>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={selectStyle}>
                                            <Select labelId={"Discipline"}
                                                    label={"Choose a discipline"}
                                                    disabled={m.age < 24}
                                                    size={"small"}
                                                    value={m.discipline != null ? Discipline[m.discipline].replace(/ /g, "") : null}
                                                    onChange={handleChangeDiscipline.bind(this, m.horseId)}>
                                                {Discipline.map(d =>
                                                    <MenuItem key={d.indexOf()}
                                                              value={d.replace(/ /g, "")}>{d}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl  sx={{
                                            width: "80px"
                                        }}>
                                            <Select labelId={"Level"}
                                                    label={"Choose a level"}
                                                    disabled={m.age < 24}
                                                    size={"small"}
                                                    value={levels[m.level]}
                                                    onChange={handleChangeLevel.bind(this, m.horseId)}>
                                                <MenuItem value={"First"}>L1</MenuItem>
                                                <MenuItem value={"Second"}>L2</MenuItem>
                                                <MenuItem value={"Third"}>L3</MenuItem>
                                                <MenuItem value={"Fourth"}>L4</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox checked={m.isBreedSpecific} disabled={m.age < 24}
                                                  onChange={handleChangeBreedSpecific.bind(this, m.horseId)}/>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{
                                            width: "80px"
                                        }}>
                                            <Select labelId={"Shows"}
                                                    label={"# of shows"}
                                                    disabled={m.age < 24}
                                                    size={"small"}
                                                    value={m.totalShows}
                                                    onChange={handleChangeTotalShows.bind(this, m.horseId)}>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <TablePagination
                rowsPerPageOptions={[50, 100, 500]}
                component="div"
                count={ranchManager.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default RanchManager