import "./ReadNotifications.css";

import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControl, FormControlLabel, List, ListItem, Pagination, TextField} from "@mui/material";
import {Close, Filter1, FilterList, FilterListOff} from "@mui/icons-material";
import Box from "@mui/material/Box";
import axios from "axios";
import {Months} from "../../../../enums/Months";
import Notification from "./Notification";
import {darkTheme, lightTheme} from "../../../../Theme";

const ReadNotifications = (props) => {

    const filterTitleStyle = {
        fontWeight: "bold",
        fontSize: "1.2em"
    }

    const filterItemStyle = {
        padding: 0,
        margin: "0 5px 0 0",
        fontWeight: "light"
    }

    const filterCheckboxStyle = {
        padding: 0,
        margin: "0 5px 0 0",
    }

    const [months, setMonths] = useState([]);

    const handleSetMonths = (month) => {
        if (months.includes(month)) {
            setMonths(months.filter((m) => m !== month));
        } else {
            setMonths([...months, month]);
        }
    }

    const [year, setYear] = useState("");

    const handleSetYear = (event) => {
        setYear(event.target.value)
    }

    const [description, setDescription] = useState("");

    const handleSetDescription = (event) => {
        setDescription(event.target.value);
    }

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    
    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    const [pastNotifications, setPastNotifications] = useState([]);

    useEffect(() => {
        const searchDto = {
            ranchId: props.id,
            months: months,
            year: year,
            description: description,
            page: pageNumber
        }
        
        const getNotifications = () => {
            axios.post("api/Application/pastNotifications", searchDto, props.config)
                .then(response => {
                    setPastNotifications(response.data);
                }).catch(error => {
                    console.log(error);
            })
        }

        const getTotalNotifications = () => {
            axios.post("api/Application/totalPastNotifications", searchDto, props.config)
                .then(response => {
                    setPagesTotal(response.data);
                }).catch(error => {
                    console.log(error);
                })
        }

        const timer = setTimeout(() => {
            getNotifications();
            getTotalNotifications();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [props.id, pageNumber, months, year, description]);

    const [filtersOpen, setFiltersOpen] = useState(false);

    const openFilters = () => {
        setFiltersOpen(true);
    }

    const closeFilters = () => {
        setFiltersOpen(false);
    }

    const clearFilters = () => {
        setMonths([]);
        setYear("");
        setDescription("");
    }

    const filters = (
        <div className={"read-notifications-filters-container"}>
            <div className={"read-notifications-filter-sub-container"}>
                <div className={"read-notifications-filter-sub-container"}>
                    <Typography sx={filterTitleStyle}>Date</Typography>
                    <div className={"read-notifications-filter-items-container"}>
                        {Months.map((month, i) =>
                            <FormControlLabel key={i} sx={filterItemStyle} control={
                                <Checkbox sx={filterCheckboxStyle} checked={months.includes(month)}
                                          onChange={() => handleSetMonths(month)}
                                />} label={month}/>
                        )}
                        <FormControl>
                            <TextField sx={{width: "100px", marginTop: "10px"}} label={"Year"} size={"small"} value={year}
                                       onChange={handleSetYear}/>
                        </FormControl>
                    </div>
                </div>
            </div>
            <FormControl>
                <TextField sx={{width: "100px", marginTop: "10px"}} label={"Description"} size={"small"} value={description}
                           onChange={handleSetDescription}/>
            </FormControl>
            <div>
                <FilterListOff sx={{marginRight: "20px"}} onClick={clearFilters} />
                <Close onClick={closeFilters}/>
            </div>
        </div>
    )
    
    return (
        <div style={{backgroundColor: props.darkMode ? 
                darkTheme.palette.containerBackground.main : 
                lightTheme.palette.containerBackground.main}} 
             className={"read-notifications-container"}>
            <div style={{backgroundColor: props.darkMode ?
                    darkTheme.palette.secondary.main :
                    lightTheme.palette.secondary.main}}
                 className={"read-notifications-title-container"}
            >
                {filtersOpen ? filters : ""}
                <FilterList sx={{marginLeft: "20px"}} onClick={openFilters}/>
                <Pagination variant={"outlined"}
                            shape={"rounded"}
                            boundaryCount={2}
                            page={pageNumber}
                            count={pagesTotal}
                            onChange={handleSetPageNumber}
                />
                <Close sx={{marginRight: "20px"}} onClick={props.setOpenRead.bind(this, false)}/>
            </div>
            <List sx={{width: "100%"}}>
                {pastNotifications && pastNotifications.map(notification =>
                    <ListItem key={notification.notificationId} sx={{flexFlow: "column", alignItems: "center"}}>
                        <Notification notification={notification}
                                      config={props.config}
                                      handleSetUpdated={props.handleSetUpdated}
                                      darkMode={props.darkMode}/>
                    </ListItem>
                )}
            </List>
        </div>
    )
}

export default ReadNotifications