import "./PrivateSaleCard.css";

import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import {Link} from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Photo from "../../../horse/photo/Photo";

const PrivateSaleCard = (props) => {
    const saleId = props.horse.saleId;

    const purchaseHorse = async () => {
        
        var data = new FormData();
        data.append("horseId", props.horse.horseId);
        data.append("buyerEmail", props.email);
        data.append("sellerRanchId", props.horse.ranchId);
        data.append("buyerRanchId", props.ranchId);
        data.append("saleId", props.horse.saleId);
        data.append("location", location);

        await axios.post('/api/sales/acceptPrivateSale', data, props.formConfig)
            .then(function (response) {
                console.log(response);
                handleClose();
                props.handleSetUpdated();
            }).catch(function (error) {
                console.log(error);
            });
    }

    const cancelPurchase = async () => {
        await axios.post('/api/sales/denyPrivateSale', saleId, props.config)
            .then(function (response) {
                console.log(response);
                handleCloseDeny();
                props.handleSetUpdated();
            }).catch(function (error) {
                console.log(error);
            });
    }

    const [pastures, setPastures] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getPasturesNotFull', {params: {id: props.ranchId},
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setPastures(response.data);
            });
    }, []);

    const [barns, setBarns] = useState([]);

    useEffect(() => {
        axios.get('/api/Ranches/getBarnsNotFull', {params: {id: props.ranchId},
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setBarns(response.data);
            });
    }, []);

    const [locations, setLocations] = useState();

    useEffect(() => {
        setLocations(barns.concat(pastures))
    }, [barns, pastures]);

    const [location, setLocation] = useState();

    const handleSetLocation = (event) => {
        setLocation(event.target.value);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openDeny, setOpenDeny] = useState(false);

    const handleOpenDeny = () => {
        setOpenDeny(true);
    }

    const handleCloseDeny = () => {
        setOpenDeny(false);
    }

    const female = (<img className={"private-sale-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"} alt={"female"}/>)
    const male = (<img className={"private-sale-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"} alt={"male"}/>)
    const gelding = (<img className={"private-sale-gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    return (
        <>
            <div className={"private-sales-card-container"}>
                <Link to={`horses/${props.horse.horseId}`}>
                    {props.horse.gender == 0 ? female : props.horse.gelded ? gelding : male}
                    <Photo id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api}/>
                </Link>
                <div className={"private-sale-info-container"}>
                    <div>
                        <Typography sx={{
                            mx: '10px',
                            textAlign: "right",
                            fontFamily: "Roboto Thin, sans-serif",
                            fontWeight: "bold",
                            fontStyle: "italic",
                            fontSize: "20px"
                        }}>{props.horse.prefix}</Typography>
                        <Typography sx={{
                            fontFamily: "Raleway, san-serif",
                            fontSize: "1.2em",
                            fontWeight: "bolder"
                        }}> {props.horse.regName}</Typography>
                    </div>
                    <Typography sx={{
                        fontFamily: "Roboto Light, san-serif",
                        fontSize: "1em"
                    }}>{(props.horse.sale.price).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumSignificantDigits: Math.trunc(Math.abs(props.horse.sale.price)).toFixed().length
                    })}</Typography>
                </div>
                <div className={"private-sales-card-btns-container"}>
                    <Button variant={"contained"} onClick={handleOpenDeny}>Deny</Button>
                    <Button variant={"contained"} onClick={handleOpen}>Accept</Button>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Choose a location</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    <FormControl>
                        <InputLabel>Choose a barn/pasture</InputLabel>
                        <Select labelId={"pasture"} label={"Choose a pasture"} size={"small"} value={location}
                                sx={{minWidth: 200, margin: 1}} onChange={handleSetLocation}>
                            <MenuItem key={0} defaultValue={undefined} value={""}>Select a pasture or
                                barn</MenuItem>
                            {locations && locations.map(location =>
                                <MenuItem key={location.name}
                                          value={location.pastureId != null ? "Pasture " + location.pastureId : "Barn " + location.barnId}>{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={purchaseHorse.bind(this, props.horse)}>Purchase</Button>
                    <Button onClick={handleClose} variant={"contained"}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeny} onClose={handleCloseDeny}>
                <DialogTitle>Cancel</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Would you like to cancel this private sale?
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={cancelPurchase}>Ok</Button>
                    <Button onClick={handleCloseDeny} variant={"contained"}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PrivateSaleCard;