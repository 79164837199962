import "./ExistingLoan.css";

import React, {useState} from "react";
import {darkTheme, lightTheme} from "../../../../Theme";
import {Dialog, DialogActions, DialogContentText, DialogTitle, Divider, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";

const ExistingLoan = (props) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [message, setMessage] = useState();

    const payoffLoan = () => {

        axios.post("api/Ranches/payoffLoan", props.ranch.ranchId, props.config)
            .then(response => {
                console.log(response);
                setMessage(response.data);
                props.handleRefresh();
                handleOpen();
            }).catch(error => {
            console.log(error);
            setMessage(error.response.data);
            handleOpen();
        })
    }

    const titleTextStyle = {
        fontWeight: "bold",
        marginRight: "10px"
    }

    const textStyle = {
        marginLeft: "10px"
    }
    
    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={props.darkMode ? "existing-loan-container-dark existing-loan-container" : "existing-loan-container"}>
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"existing-loan-title-container"}>
                    <Typography variant={"h3"}>
                        Existing Loan
                    </Typography>
                </div>
                {props.ranch.loanAmount > 0 ? <>
                    <div className={"existing-loan-info-container"}>
                        <Typography sx={titleTextStyle}>Balance</Typography>
                        <Divider sx={{flexGrow: 1}}/>
                        <Typography sx={textStyle}>{(props.ranch.loanAmount).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumSignificantDigits: Math.trunc(Math.abs(props.ranch.loanAmount)).toFixed().length
                        })}</Typography>
                    </div>
                    <div className={"existing-loan-info-container"}>
                        <Typography sx={titleTextStyle}>Payments Remaining</Typography>
                        <Divider sx={{flexGrow: 1}}/>
                        <Typography sx={textStyle}>{props.ranch.loanPaymentsRemaining}</Typography>
                    </div>
                    <div className={"existing-loan-info-container"}>
                        <Typography sx={titleTextStyle}>Monthly Payment</Typography>
                        <Divider sx={{flexGrow: 1}}/>
                        <Typography sx={textStyle}>{(props.ranch.loanPayment).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumSignificantDigits: Math.trunc(Math.abs(props.ranch.loanPayment)).toFixed().length
                        })}</Typography>
                    </div>
                    <Button variant={"contained"} size={"small"} onClick={payoffLoan}>Payoff</Button>
                </> : <Typography>Ranch does not currently have any loans.</Typography>}
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> </DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}} >
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExistingLoan