import "./NewLocation.css";

import {React, useState} from "react";
import {
    Button, Dialog, DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel,
    Input,
    Radio, RadioGroup, TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import {darkTheme, lightTheme} from "../../../../Theme";

const NewLocation = (props) => {
    
    const [type, setType] = useState("");
    
    const handleSetType = (event) => {
        setType(event.target.value);
    }
    
    const [name, setName] = useState("");
    
    const handleSetName = (event) => {
        setName(event.target.value);
    }
    
    const handleBuyNewLocation = () => {
        const data = new FormData();
        data.append("id", props.id);
        data.append("name", name);
        
        if(type === "barn"){
            axios.post("/api/Ranches/newBarn", data, props.formConfig)
                .then(response => {
                    console.log(response.data);
                    setMessage(response.data);
                    props.handleSetUpdated();
                    handleOpen()
                }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpen()
            })
        }
        
        if(type === "pasture"){
            axios.post("/api/Ranches/newPasture", data, props.formConfig)
                .then(response => {
                    console.log(response.data);
                    setMessage(response.data);
                    props.handleSetUpdated();
                    handleOpen()
                }).catch(error => {
                    console.log(error);
                    setMessage(error.response.data);
                    handleOpen()
            })
        }
    }
    
    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const formStyle = {
        minWidth: "85%",
        margin: 1
    }
    
    return(
        <>
            <div style={props.darkMode ? {
                backgroundColor: darkTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px #262626"
            } : {
                backgroundColor: lightTheme.palette.containerBackground.main,
                boxShadow: "5px 5px 5px darkgray"
            }} className={"new-location-container"}>
                <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"new-location-title-container"}>
                    <Typography variant={"h3"}>Buy</Typography>
                </div>
                <FormControl sx={formStyle}>
                    <RadioGroup sx={{display: "flex", flexFlow: "row wrap", justifyContent: "center"}} defaultChecked={"barn"} onChange={handleSetType}>
                        <FormControlLabel value={"barn"} control={<Radio />} label={"Barn"}/>
                        <FormControlLabel value={"pasture"} control={<Radio />} label={"Pasture"}/>
                    </RadioGroup>
                </FormControl>
                <FormControl sx={formStyle}>
                    <TextField id={"locationName"} label={"Name"} size={"small"}
                           onChange={handleSetName} value={name}/>
                </FormControl>
                <Button sx={{alignSelf: "flex-end", margin: "10px"}} variant={"contained"} onClick={handleBuyNewLocation}>Buy</Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Pasture Creation</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewLocation;