import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <div>
            <p>Welcome to Legacy horse game! At Pedigree Games, we take your privacy seriously. This Privacy Policy
                explains how we collect, use, and disclose your personal information in connection with our game. By
                accessing or using our website and game, you consent to the collection and use of your personal
                information as described in this Privacy Policy.</p>
            <List>
                <ListItem>
                    <ListItemText primary={"1. Information we collect"}
                                  secondary={<React.Fragment>
                                      <p>We collect the following types of information:</p>
                                      <List dense={"dense"}>
                                          <ListItem>
                                              <p>Personal information: We collect information that identifies you, such as your name and email address.</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Game information: We collect information about your game play, such as your horse names, breed, and achievements.</p>
                                          </ListItem>
                                      </List>
                                  </React.Fragment>}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"2. How we use your information"}
                                  secondary={<React.Fragment>
                                      <p>We use your personal information for the following purposes:</p>
                                      <List dense={"dense"}>
                                          <ListItem>
                                              <p>To provide and improve our game</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>To communicate with you about our game, such as game updates and promotions</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>To prevent fraud and abuse</p>
                                          </ListItem>
                                      </List>
                                      <p>We may also use your personal information for other purposes that are compatible with the above purposes.</p>
                                  </React.Fragment>}
                                      />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"3. How we share your information"}
                                  secondary={<React.Fragment>
                                      <p>We may share your personal information with the following third parties:</p>
                                      <List dense={"dense"}>
                                      <ListItem>
                                      <p>Service providers: We may share your information with third-party service providers that perform services on our behalf, such as hosting and customer support.</p>
                                      </ListItem>
                                      <ListItem>
                                      <p>Legal purposes: We may disclose your information if required by law or in response to a legal request.</p>
                                      </ListItem>
                                      <ListItem>
                                      <p>Business transfers: We may transfer your information in connection with a merger, acquisition, or sale of our assets.</p>
                                      </ListItem>
                                      </List>
                                      </React.Fragment>}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"4. Security"}
                                  secondary={"We take reasonable measures to protect your personal information from unauthorized access, " +
                                      "disclosure, and misuse. However, no security system is perfect, and we cannot guarantee the security of your information."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"5. Your rights"}
                                  secondary={<React.Fragment>
                                      <p>You have the following rights with respect to your personal information:</p>
                                      <List dense={"dense"}>
                                          <ListItem>
                                              <p>Access: You have the right to access the personal information we hold
                                                  about you.</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Correction: You have the right to correct any inaccuracies in your
                                                  personal information.</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Deletion: You have the right to request the deletion of your personal
                                                  information.</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Objection: You have the right to object to the processing of your
                                                  personal information.</p>
                                          </ListItem>
                                          <ListItem>
                                              <p>Withdrawal of consent: You have the right to withdraw your consent to
                                                  the processing of your personal information at any time.</p>
                                          </ListItem>
                                      </List>
                                  </React.Fragment>}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"6. Children's privacy"}
                                  secondary={"Our game is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"7. Changes to this Privacy Policy"}
                                  secondary={"We may modify this Privacy Policy at any time. We will notify you of any material changes by posting a notice on our website or by email."}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"8. Contact Us"}
                                  secondary={"If you have any questions or concerns about this Privacy Policy, please contact us at info@legacyhorsegame.com."}
                    />
                </ListItem>
            </List>
            <p>Thank you for reading our Privacy Policy. We hope you enjoy Legacy!</p>
        </div>
    )
}

export default PrivacyPolicy