import "./DisciplineRibbons.css";

import React from "react";
import Ribbon from "./Ribbon";
import {Divider, Typography} from "@mui/material";
import {Title} from "../../../../enums/Title";

const DisciplineRibbons = (props) => {
    
    const infoTitleStyle = {
        fontWeight: "bold",
        marginRight: "10px"
    }
    
    const infoTextStyle = {
        marginLeft: "10px"
    }
    
    return (
        <div className={props.darkMode ? "discipline-ribbons-container-dark" : "discipline-ribbons-container"}>
            <div className={"discipline-ribbons-title"}>
                <Typography variant={"h3"}>{props.title}</Typography>
            </div>
            <div className={"ribbons-container"}>
                <Ribbon placement={"first"} total={props.firsts}/>
                <Ribbon placement={"second"} total={props.seconds}/>
                <Ribbon placement={"third"} total={props.thirds}/>
            </div>
            <div className={"discipline-ribbons-info-container"}>
                <Typography sx={infoTitleStyle}>Points</Typography>
                <Divider sx={{flexGrow: 1}}/>
                <Typography sx={infoTextStyle}>{props.points}</Typography>
            </div>
            <div className={"discipline-ribbons-info-container"}>
                <Typography sx={infoTitleStyle}>Earnings</Typography>
                <Divider sx={{flexGrow: 1}}/>
                <Typography sx={infoTextStyle}>{(props.earnings).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumSignificantDigits: Math.trunc(Math.abs(props.earnings)).toFixed().length
                })}</Typography>
            </div>
            <Typography sx={infoTitleStyle}>Titles</Typography>
            {props.titles.length == 0 ? <Typography>No earned titles</Typography> :
                props.titles.map(t =>
                        <Typography>{Title[t.titleType]}</Typography>
                    )
            }
        </div>
    )
}

export default DisciplineRibbons;