import "./NavBar.css";
import React, {useEffect, useState} from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import {Link} from "react-router-dom";
import {MailRounded, Refresh} from "@mui/icons-material";
import {Months} from "../../enums/Months";
import {Switch} from "@mui/material";
import {lightTheme} from "../../Theme";


function NavBar(props){
    const ranchId = props.ranchId;

    const pagesLoggedIn = [
        {
            name: 'Home',
            path: "/home",
            active: true
        },
        {
            name: 'Ranch',
            path: '/ranches/' + ranchId,
            active: "ranch" in localStorage
        },
        {
            name: 'My Horses',
            path: '/horses',
            active: "ranch" in localStorage
        },
        {
            name: 'Events',
            path: '/shows',
            active: "ranch" in localStorage
        },
        {
            name: 'Studs',
            path: '/studs',
            active: "ranch" in localStorage
        },
        {
            name: 'Sales',
            path: '/sales',
            active: "ranch" in localStorage
        },
        {
            name: 'Clubs',
            path: '/clubs',
            active: true
        },
        {
            name: 'Searches',
            path: '/search',
            active: true
        },
        {
            name: 'Rankings',
            path: '/rankings',
            active: true
        },
        {
            name: 'Forum',
            path: '/forum',
            active: true
        },
        {
            name: 'Blog',
            path: 'https://blog.legacyhorsegame.com',
            active: true
        },
        {
            name: 'Wiki',
            path: 'https://wiki.legacyhorsegame.com',
            active: true
        },
        {
            name: 'Support',
            path: 'https://legacyhorsegame.freshdesk.com/support/home',
            active: true
        }
    ];

    const pagesLoggedOut = [
        {
            name: 'Welcome',
            path: "/welcome"
        },
        {
            name: 'Clubs',
            path: '/clubs',
            active: true
        },
        {
            name: 'Horses',
            path: '/search',
            active: true
        },
        {
            name: 'Rankings',
            path: '/rankings',
            active: true
        },
        {
            name: 'Blog',
            path: 'https://blog.legacyhorsegame.com',
            active: true
        },
        {
            name: 'Wiki',
            path: 'https://wiki.legacyhorsegame.com'
        },
        {
            name: 'Support',
            path: 'https://legacyhorsegame.freshdesk.com/support/home',
            active: true
        }
    ];
    
    const settings = ['Account', 'Inbox', 'Logout'];

    const [user, setUser] = useState();

    useEffect(() => {
        if(props.isLogged){
            axios.get('/api/Account', props.config)
                .then(response => {
                    setUser(response.data);
                });
        }
    }, [props.token]);
    
    const [ranch, setRanch] = useState(null);

    useEffect(() => {
        if(props.isLogged){
            axios.get('/api/Ranches/ranch', {
                params: {
                    id: ranchId
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }})
                .then(response => {
                    setRanch(response.data);
                });
        }
    }, [ranchId, props.refreshed]);
    
    const [newMessages, setNewMessages] = useState(false);
    
    useEffect(() => {
        if(props.isLogged){
            axios.get('/api/Messages/newMessages', {
                params: {
                    email: user?.email
                },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }})
                .then(response => {
                    setNewMessages(response.data);
                });
        }
    }, [user])

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const formatDate = (date) => {
        const month = date % 12 == 0 ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }
    
    const handleSetting = async setting => {
        switch(setting){
            case "Logout":
                props.handleLogout();
                break;
            case "Inbox":
                props.history("/inbox")
                break;
            case "Account":
                props.history("/account")
                break;
        }
    }
    
    const displaySeason = () => {
        switch(Months[(props.month % 12) - 1]){
            case "December":
            case "January":
            case "February":
                return <img className={"seasons"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/seasons/winter.png"} alt={"winter"}/>;
            case "March":
            case "April":
            case "May":
                return <img className={"seasons"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/seasons/spring.png"} alt={"spring"}/>;
            case "June":
            case "July":
            case "August":
                return <img className={"seasons"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/seasons/summer.png"} alt={"summer"}/>;
            case "September":
            case "October":
            case "November":
                return <img className={"seasons"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/seasons/fall.png"} alt={"fall"}/>;
        }
    }

    const displayBreedingSeason = () => {
        switch(Months[(props.month % 12) - 1]){
            case "December":
            case "January":
            case "February":
            case "March":
            case "April":
            case "October":
            case "November":
                return "";
            case "May":
            case "June":
            case "July":
            case "August":
            case "September":
                return <img className={"seasons"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/seasons/breeding_season.png"} alt={"breeding season"}/>;
            default:
                return "";
        }
    }
    
    const dateDisplayTextStyle = {
        margin: "0 5px 0 0",
        color: "white",
        fontWeight: 300
    }

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatEstTime = (date) => {
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York', // Specify the time zone (EST)
        });
    };


    const [rolloverActive, setRolloverActive] = useState(false);

    useEffect(() => {
        const getRolloverActive = () => {
            axios.get("api/Application/rollover", props.config)
                .then(response => {
                    setRolloverActive(response.data);
                }).catch(error => {
                    console.log(error);
            })
        }
        
        // Update the time every second
        const intervalId = setInterval(() => {
            getRolloverActive();
        }, 5 * 60 * 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);
    
    const avatar = <Box sx={{ flexGrow: 0, display: "flex", flexFlow: "row nowrap", top: {sm: 5, md: 5}, justifyContent: "flex-end"}}>
        <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={`https://media.legacyhorsegame.com/profileimages/${props.username}.png`} sx={{height: {md: 35}, width: {md: 35}}}/>
            </IconButton>
        </Tooltip>
        <Menu
            sx={{ mt: '10px'}}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            {settings.map(setting => <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center" onClick={handleSetting.bind(this, setting)}>{setting}</Typography>
                </MenuItem>)}
        </Menu>
    </Box>
    
    const login = (
        <div className={"login-register-links-container"}>
            <Link className={"login-register-link"} to={"/register"}>
                <Typography textAlign="center">Register</Typography>
            </Link>
            <Link className={"login-register-link"} to={"/login"}>
                <Typography textAlign="center">Login</Typography>
            </Link>
        </div>
    )
    
    return (
        <div className={"nav-bar-container"}>
            {props.isLogged && <div className={"navbar-upper-container"}>
                <img className={"navbar-logo-img"} src={"https://media.legacyhorsegame.com/misc/logoWhite.png"} alt={"Legacy Logo"}/>
                {ranch && <div className={"navbar-ranch-info-container"}>
                    <Typography sx={{color: "white", [lightTheme.breakpoints.down('sm')]: {fontSize: "18px", textAlign: "center"}, [lightTheme.breakpoints.down('md')]: {textAlign: "left"}}} variant={"h3"}>{ranch.name}</Typography>
                    <Typography sx={{color: "white", fontWeight: "400"}}>{typeof ranch.balance == 'number' ? (ranch.balance).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumSignificantDigits: Math.trunc(Math.abs(ranch.balance)).toFixed().length
                    }): ""}</Typography>
                </div>}
                <div className={"navbar-info-container"}>
                    <div className={"navbar-seasons-container"}>
                        {displayBreedingSeason()}
                        {displaySeason()}
                    </div>
                    <div className={"navbar-date-time-container"}>
                        <Typography sx={dateDisplayTextStyle}>{formatEstTime(time)}</Typography>
                        <Typography sx={dateDisplayTextStyle}>{formatDate(props.month)} </Typography>
                    </div>
                    {newMessages ? <Link to={'/inbox'} className={'email-notification-link'}><MailRounded sx={{m: 2, fontSize: 30}}/></Link> : ""}
                    <div className={"navbar-avatar-container"}>
                        {avatar}
                        {user && <Typography sx={{color: "white", fontWeight: 200}}>{user.displayName}</Typography>}
                    </div>
                </div>
            </div>}
            <AppBar position="static" sx={{height: {md: 50}, display: "flex", flexFlow: "row", alignItems: "center"}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{py: {md: 0}, minHeight: {md: 35}}}>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'}
                                }}
                                PaperProps={{
                                    sx: {
                                        padding: '10px',
                                        backgroundColor: 'rgba(256, 256, 256, .95)',
                                    },
                                }}
                            >
                                {props.isLogged ? pagesLoggedIn.map(page =>
                                    page.active ?
                                        <Link key={page.name} to={page.path}>
                                            <Typography textAlign="center" sx={{
                                                padding: "10px 10px",
                                                fontFamily: "Roboto, san-serif",
                                                fontWeight: "bold"
                                            }}>{page.name}</Typography>
                                        </Link> : ""
                                ) : pagesLoggedOut.map(page =>
                                    <Link key={page.name} to={page.path}>
                                        <Typography textAlign="center" sx={{
                                            padding: "10px 10px",
                                            fontFamily: "Roboto, san-serif",
                                            fontWeight: "bold"
                                        }}>{page.name}</Typography>
                                    </Link>
                                )}
                            </Menu>
                        </Box>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {props.isLogged ? pagesLoggedIn.map(page => page.active &&
                                <Link key={page.name} to={page.path}>
                                    <Button sx={{my: 0, color: 'white', display: 'block', '&:focus': {outline: 'none'}}}
                                            variant={"navigation"}>
                                        {page.name}
                                    </Button>
                                </Link>
                            ) : pagesLoggedOut.map(page => <Link key={page.name} to={page.path}>
                                <Button sx={{my: 0, color: 'white', display: 'block', '&:focus': {outline: 'none'}}}
                                        variant={"navigation"}>
                                    {page.name}
                                </Button>
                            </Link>)}
                        </Box>
                        {!props.isLogged ? login : ""}
                        <Switch checked={props.darkMode} onChange={props.toggleDarkMode}/>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    )
}

export default NavBar;