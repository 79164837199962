import "./Sales.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Autocomplete, 
    Checkbox,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl, 
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";

const Sales = (props) => {
    
    const inputStyle = {
        margin: "0 5px 10px 5px",
        width: "90%",
    }
    
    const checkboxStyle = {
        padding: "2px"
    }
    
    const [price, setPrice] = useState(0);

    const handleSetPrice = (event) => {
        setPrice(event.target.value);
    }

    const [recipient, setRecipient] = useState(null);

    const handleSetRecipient = (event, values) => {
        setRecipient(values.ranchId);
    }

    const [requiresApproval, setRequiresApproval] = useState(false);
    const [isAuction, setIsAuction] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [isLease, setIsLease] = useState(false);

    const handleSetRequiresApproval = (event) => {
        setRequiresApproval(event.target.checked);
    }
    
    const handleSetIsAuction = (event) => {
        setIsAuction(event.target.checked);
        setIsPrivate(false);
        setIsLease(false);
    }

    const handleSetIsPrivate = (event) => {
        setIsPrivate(event.target.checked);
        setIsAuction(false);
        setIsLease(false);
    }

    const handleSetIsLease = (event) => {
        setIsLease(event.target.checked);
        setIsPrivate(false);
        setIsAuction(false);
    }
    
    const [length, setLength] = useState(null);
    
    const handleSetLength = (event) => {
        setLength(event.target.value)
    }
    
    const [allowBreeding, setAllowBreeding] = useState(false);
    
    const handleSetAllowBreeding = (event) => {
        setAllowBreeding(event.target.checked);
    }

    const [allowChangeJob, setAllowChangeJob] = useState(false);

    const handleSetAllowChangeJob = (event) => {
        setAllowChangeJob(event.target.checked);
    }

    const [allowTraining, setAllowTraining] = useState(false);

    const handleSetAllowTraining = (event) => {
        setAllowTraining(event.target.checked);
    }

    const [allowShowing, setAllowShowing] = useState(false);

    const handleSetAllowShowing = (event) => {
        setAllowShowing(event.target.checked);
    }
    
    const resetForm = () => {
        setPrice("");
        setIsAuction(false);
        setIsLease(false);
        setIsPrivate(false);
        setRecipient("");
        setAllowBreeding(false);
        setAllowShowing(false);
        setAllowTraining(false);
        setAllowChangeJob(false);
    }
    
    const handleSellOrAuction = () => {
        var formData = new FormData();
        formData.append("id", props.id);
        formData.append("price", price);
        formData.append("requiresApproval", requiresApproval);
        formData.append("isAuction", isAuction);
        formData.append("isPrivate", isPrivate);
        formData.append("isLease", isLease);
        formData.append("length", length);
        formData.append("recipient", recipient);
        formData.append("allowBreeding", allowBreeding);
        formData.append("allowTraining", allowTraining);
        formData.append("allowChangeJob", allowChangeJob);
        formData.append("allowShowing", allowShowing);

        axios.post('/api/sales/setUpSaleOrLease', formData, props.formConfig)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
                resetForm();
            }).catch(function (error) {
                console.log(error);
                resetForm();
        });
    }

    const [ranches, setRanches] = useState([]);

    function filterRanches(ranches){
        return ranches.filter(r => r.name != "Golden Acres" &&  r.name != "Rainbow Bridge");
    }

    useEffect(() => {
        axios.get('api/Ranches', props.config)
            .then(response => {
                setRanches(filterRanches(response.data));
            }).catch(error => {
            console.log(error);
        })
    }, [])

    const handleRemoveFromSale = () => {
        axios.post("/api/Sales/removeHorseFromSale", props.id, props.config)
            .then(response => {
                console.log(response.data);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }

    const handleRemoveFromLease = () => {
        axios.post("/api/Sales/removeHorseFromLease", props.id, props.config)
            .then(response => {
                console.log(response.data);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
    }
    
    const handleRehomeHorse = () => {
        handleOpenConfirmation();
        axios.post('/api/Horses/rehomeHorse', props.id, props.config)
            .then(function (response) {
                console.log(response);
                props.handleSetUpdated();
                setMessage(response.data);
                handleOpen();
            }).catch(function (error) {
            console.log(error);
        });
    }
    
    const approveSale = () => {
        axios.post("api/Sales/approve", props.id, props.config)
            .then(response => {
                console.log(response);
                setMessage(response.data);
                props.handleSetUpdated();
                handleOpen();
            }).catch(error => {
                console.log(error);
                setMessage(error.response.data);
                handleOpen();
        })
    }

    const [message, setMessage] = useState("");
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }
    
    const saleForm = (
        <>
            <FormControl sx={inputStyle}>
                <TextField label={"Price"} type={"number"} size={"small"} onChange={handleSetPrice}/>
            </FormControl>
            <div className={"horse-sales-checkboxes"}>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={requiresApproval} onChange={handleSetRequiresApproval}/>}
                        label={"Approval"}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={isPrivate} onChange={handleSetIsPrivate}/>}
                        label={"Private"}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={isAuction} onChange={handleSetIsAuction}/>}
                        label={"Auction"}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={isLease} onChange={handleSetIsLease}/>}
                        label={"Lease"}/>
                </FormControl>
            </div>
            {isLease || isPrivate ? <FormControl sx={inputStyle}>
                <Autocomplete required
                              label={"Recipient"}
                              options={ranches}
                              getOptionLabel={ranch => ranch.name}
                              onChange={handleSetRecipient}
                              renderInput={(params) => (
                                  <TextField {...params} label={"Recipient ranch"}
                                             variant={"outlined"} size={"small"}/>)}/>
            </FormControl> : ""}
            {isLease ? <div className={"horse-sales-checkboxes"}>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={allowBreeding} onChange={handleSetAllowBreeding}/>}
                        label={"Allow Breeding"}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={allowChangeJob} onChange={handleSetAllowChangeJob}/>}
                        label={"Allow Job Change"}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={allowShowing} onChange={handleSetAllowShowing}/>}
                        label={"Allow Showing"}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox sx={checkboxStyle} checked={allowTraining} onChange={handleSetAllowTraining}/>}
                        label={"Allow Training"}/>
                </FormControl>
            </div> : ""}
            {isLease && <FormControl sx={inputStyle}>
                <TextField size={"small"} type={"number"} label={"Length In Months"} onChange={handleSetLength}/>
            </FormControl>}
            <div className={"horse-sales-btns"}>
                <Button onClick={handleOpenConfirmation} color={"warning"} variant={"contained"}>Rehome</Button>
                <Button onClick={handleSellOrAuction} variant={"contained"}>Confirm</Button>
            </div>
        </>
    )

    const removeSaleForm = (
        <Button variant={"contained"} onClick={handleRemoveFromSale}>Remove From Sale</Button>
    )

    const removeLeaseForm = (
        <Button variant={"contained"} onClick={handleRemoveFromLease}>Remove From Lease</Button>
    )

    const approveSaleForm = (
        <Button variant={"contained"} onClick={approveSale}>Approve Sale</Button>
    )

    return (
        <>
            <div className={props.darkMode ? "horse-sales-container-dark" :"horse-sales-container"}>
                <div className={"horse-sales-title-container"}>
                    <Typography variant={"h3"}>Sell & Lease</Typography>
                </div>
                {props.horse.sale.isForSale || props.horse.sale.isForPrivateSale? removeSaleForm : props.horse.sale.isForLease ? removeLeaseForm : saleForm}
                {props.horse.sale.isPendingApproval ? approveSaleForm : ""}
            </div>
            
            <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Rehome Horse</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    Rehoming a horse is final and cannot be undone. Are you sure?
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>No</Button>
                    <Button onClick={handleRehomeHorse} color={"warning"} variant={"contained"}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                    {message}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Sales