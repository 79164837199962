import "./PrivateSales.css";

import React, {useEffect, useState} from "react";
import axios from "axios";
import PrivateSaleCard from "./PrivateSaleCard";
import {Pagination, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../../../../Theme";

const PrivateSales = (props) => {
    
    const [updated, setUpdated] = useState(0);
    
    const handleSetUpdated = () => {
        setUpdated(updated + 1);
        props.handleSetRefreshed();
    }
    
    const [saleHorses, setSaleHorses] = useState([]);

    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const handleSetPageNumber = (event, value) => {
        setPageNumber(value);
    }

    useEffect(() => {
        axios.get('/api/Sales/privateSales', 
            {
                params: 
                    {
                        id: props.ranchId,
                        page: pageNumber
                    }, 
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
            .then(response => {
                setSaleHorses(response.data.sort(function(a, b){
                    return parseInt(a.horseId) - parseInt(b.horseId);
                }));
            }).catch(error => {
                console.log(error);
        })
    }, [pageNumber, updated]);

    useEffect(() => {
        axios.get('/api/Sales/privateSalesTotalPages',
            {
                params:
                    {
                        id: props.ranchId,
                        page: pageNumber
                    },
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': props.token,
                    "Authentication": props.api,
                }
            })
            .then(response => {
               setPagesTotal(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, [pageNumber, updated]);


    return (
        <>
            {saleHorses && 
                <div style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}} className={"private-sales-container"}>
                    <div style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}} className={"private-sales-title-container"}>
                        <Pagination variant={"outlined"}
                                    shape={"rounded"}
                                    boundaryCount={2}
                                    page={pageNumber}
                                    count={pagesTotal}
                                    onChange={handleSetPageNumber}
                        />
                    </div>
                    <div className={"private-sales-sub-container"}>
                        {saleHorses.length === 0 ? <Typography sx={{margin: "50px"}}>No pending private sales.</Typography> : ""}
                        {saleHorses.map(horse =>
                            <PrivateSaleCard key={horse.horseId}
                                             horse={horse}
                                             id={horse.horseId}
                                             ranchId={props.ranchId}
                                             email={props.email}
                                             api={props.api}
                                             token={props.token}
                                             formConfig={props.formConfig}
                                             config={props.config}
                                             handleSetUpdated={handleSetUpdated}/>
                        )}
                    </div>
                </div>}
        </>
    )
}

export default PrivateSales;