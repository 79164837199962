import "./ActiveClubCard.css";

import React from "react";
import {Divider, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../../Theme";
import {Link} from "react-router-dom";

const ActiveClubCard = (props) => {
    
    const infoTitleStyle = {
        marginRight: 1,
        fontWeight: "bold"
    }

    const infoTextStyle = {
        marginLeft: 1
    }

    return (
       <>
           <Link className={"active-club-link"} to={`/clubs/${props.club.clubId}`}>
               <div style={{
                   backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main :
                       lightTheme.palette.containerBackground.main
               }}
                    className={"active-club-card-container"}>
                   <div style={{
                       backgroundColor: props.darkMode ? darkTheme.palette.secondary.main :
                           lightTheme.palette.secondary.main
                   }}
                        className={"active-club-card-title-container"}>
                       <Typography style={{fontSize: "18px"}} variant={"h3"}>{props.club.name}</Typography>
                   </div>
                   <div className={"active-club-info-container"}>
                       <img className={"active-club-logo-img"}
                            src={`https://media.legacyhorsegame.com/misc/clublogos/${props.club.image}.png`}
                            alt={"club logo"}/>
                       <div className={"club-info-item-container"}>
                           <Typography sx={infoTitleStyle}>Total Members</Typography>
                           <Divider sx={{flexGrow: 1}}/>
                           <Typography sx={infoTextStyle}>{props.club.members.length}</Typography>
                       </div>
                       <div className={"club-info-item-container"}>
                           <Typography sx={infoTitleStyle}>Lifetime Horses</Typography>
                           <Divider sx={{flexGrow: 1}}/>
                           <Typography sx={infoTextStyle}>{props.club.lifetimeHorses}</Typography>
                       </div>
                       <div className={"club-info-item-container"}>
                           <Typography sx={infoTitleStyle}>Active Horses</Typography>
                           <Divider sx={{flexGrow: 1}}/>
                           <Typography sx={infoTextStyle}>{props.club.activeHorses}</Typography>
                       </div>
                   </div>
               </div>
           </Link>
       </>
    )
}

export default ActiveClubCard