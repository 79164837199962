import './Exercising.css';

import {React, useEffect, useState} from "react";
import Exercise from "./Excersize";
import {Button, Switch} from "@mui/material";
import axios from "axios";
import {useCookies} from "react-cookie";

const Exercising = (props) => {
    const exercises = [
        {
            key: 1,
            title: "Aquatrainer",
            endpoint: "aquatrainer",
        },
        {
            key: 2,
            title: "Breezing",
            endpoint: "breezing",
        },
        {
            key: 3,
            title: "Trail Ride",
            endpoint: "trailRide",
        },
        {
            key: 4,
            title: "Interval Training",
            endpoint: "intervalTraining",
        },
        {
            key: 5,
            title: "Arena Obstacle Course",
            endpoint: "obstacleCourse",
        },
        {
            key: 6,
            title: "Work Over Jumps",
            endpoint: "jumps",
        },
        {
            key: 7,
            title: "Walker",
            endpoint: "walker",
        }
    ]


    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        if(props.owner == props.ranchId){
            setIsOwner(true);
        }
    }, [props.owner]);
    
    const[toggle, setToggle] = useState();

    useEffect(() => {
        setToggle(props.toggle);
    }, [props])
    
    const handleSetToggle = async () => {
        setToggle(!toggle);
        await axios.post('/api/Training/toggleExerciser', props.id, props.config)
            .then(function (response) {
                console.log(response);
            }).catch(function (error) {
                console.log(error);
            });
    };

    const handleRest = () => {
        props.handleRest(props.id);
    }

    return (
        <div>
           <div className={"title-section"}>
               <div className={"title-subsection"}>
                   <p className={"exercising-title"}>Exercises</p>
                   {isOwner && <Switch checked={toggle} onChange={handleSetToggle}/>}
               </div>
               {isOwner && <div className={"rest-section"}>
                   <p className={"total-sessions"}>Sessions remaining: {props.totalSessions}</p>
               </div>}
           </div>
            <div className={"exercising-section"}>
                {exercises.map(e =>
                    <Exercise key={e.key} 
                              horse={props.horse}
                              title={e.title} 
                              endpoint={e.endpoint} 
                              id={props.id} 
                              underage={props.underage} 
                              isInjured={props.isInjured} 
                              handleExercise={props.handleExercise} 
                              isOwner={isOwner}/>
                )}
            </div>
        </div>
    )
}

export default Exercising;