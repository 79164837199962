import "./NewMessage.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Autocomplete, Chip, FormControl, TextField, Typography} from "@mui/material";
import {Close, Send} from "@mui/icons-material";
import axios from "axios";

const NewMessage = (props) => {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('api/Account/otherUsers', {
            params: {
                email: props.email
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        }).then(response => {
            setUsers(response.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])
    
    const [recipients, setRecipients] = useState(props.recipient ? [props.recipient] : []);

    const handleSetRecipients = (event, value) => {
        setRecipients(value);
    }

    const [subject, setSubject] = useState("");

    const handleSetSubject = (event) => {
        setSubject(event.target.value);
    }

    const [content, setContent] = useState("");

    const handleSetContent = (event) => {
        setContent(event.target.value);
    }

    const newMessageInputStyle = {
        width: "95%",
        margin: 1
    }

    const sendMessage = () => {
        console.log(recipients);

        const message = {
            senderId: props.id,
            recipients: recipients,
            title: subject,
            body: content
        }

        axios.post("api/Messages/send", message, props.config)
            .then(response => {
                console.log(response);
                props.closeNewMessage();
            }).catch(error => {
            console.log(error);
        })
    }
    
    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={"new-message-form-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"new-message-form-header-container"}>
                <Typography sx={{flexGrow: 1, textAlign: "center", marginRight: "20px"}} variant={"h3"}>
                    New Message
                </Typography>
                <Close onClick={props.closeNewMessage}/>
            </div>
            <FormControl sx={newMessageInputStyle}>
                <Autocomplete multiple
                              required
                              label={"Recipient"}
                              value={recipients}
                              options={users.map((option) => option)}
                              
                              getOptionLabel={(option) => option.displayName}
                              onChange={handleSetRecipients}
                              renderTags={(recipients, getTagProps) =>
                                  recipients.map((option, index) => (
                                      <Chip variant="outlined"
                                            label={option.displayName} {...getTagProps({index})} />
                                  ))
                              }
                              renderInput={(params) => (
                                  <TextField {...params} label={"Recipient"} variant={"outlined"}
                                             size={"small"}/>)}/>
            </FormControl>
            <FormControl sx={newMessageInputStyle}>
                <TextField size={"small"} label={"Subject"} onChange={handleSetSubject}/>
            </FormControl>
            <FormControl sx={newMessageInputStyle}>
                <TextField multiline minRows={10} maxRows={15} onChange={handleSetContent}/>
            </FormControl>
            {/*<ReactQuill theme="snow" value={content} onChange={handleSetContent}/>*/}
            <Send sx={{alignSelf: "flex-end", margin: "10px 40px 20px 0"}} onClick={sendMessage}/>
        </div>
    )
}

export default NewMessage