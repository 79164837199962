import './SubCategoryCard.css';

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Typography} from "@mui/material";

const SubCategoryCard = (props) => {
    
    const countTitleStyle = {
        fontWeight: "bold",
        marginBottom: 1
    }
    
    return (
        <div className={"sub-category-card-container"}>
            <div className={"sub-category-card-info-container"}>
                <Typography sx={{fontSize: "24px"}} variant={"h3"}>{props.subcategory.title}</Typography>
                <Typography>{props.subcategory.description}</Typography>
            </div>
            <div className={"sub-category-card-counts-container"}>
                <Typography sx={countTitleStyle}>Unread</Typography>
                <Typography>{props.subcategory.unreadCount}</Typography>
            </div>
            <div className={"sub-category-card-counts-container"}>
                <Typography sx={countTitleStyle}>Total</Typography>
                <Typography>{props.subcategory.postCount}</Typography>
            </div>
        </div>
    )
}

export default SubCategoryCard;