import "./NewLoan.css";

import React, {useState} from "react";
import {Divider, FormControl, Select, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {darkTheme, lightTheme} from "../../../../Theme";
import Button from "@mui/material/Button";
import axios from "axios";

const NewLoan = (props) => {
    
    const loans = [
        {
            amount: 10000,
            totalPaid: 10500,
            length: 120,
            payment: 88
        },
        {
            amount: 50000,
            totalPaid: 52500,
            length: 240,
            payment: 220
        },
        {
            amount: 100000,
            totalPaid: 105000,
            length: 360,
            payment: 292
        },
    ]

    const [loan, setLoan] = useState(null);

    const handleSetLoan = (event) => {
        setLoan(event.target.value);
    }
    
    const submitLoan = () => {
        const data = new FormData();
        data.append("id", props.ranch.ranchId);
        data.append("amount", loan.amount);
        data.append("length", loan.length);
        
        axios.post("api/Ranches/takeLoan", data, props.formConfig)
            .then(response => {
                console.log(response);
                props.handleRefresh();
                setLoan(null);
            }).catch(error => {
                console.log(error);
        })
    }

    const titleTextStyle = {
        fontWeight: "bold",
        marginRight: "10px"
    }

    const textStyle = {
        marginLeft: "10px"
    }

    const selectStyle = {
        minWidth: "50%",
        margin: 1
    }

    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "new-loan-container-dark new-loan-container" : "new-loan-container"}>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                className={"new-loan-title-container"}>
                <Typography variant={"h3"}>
                    New Loan
                </Typography>
            </div>
            {props.ranch.loanAmount == 0 ? <FormControl sx={selectStyle}>
                <Select label={"Amount"} size={"small"} onChange={handleSetLoan}>
                    {loans.map((loan, i) =>
                        <MenuItem key={i} value={loan}>{(loan.amount).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumSignificantDigits: Math.trunc(Math.abs(loan.amount)).toFixed().length
                        })}</MenuItem>
                    )}
                </Select>
            </FormControl> : <Typography>Ranch already has an active loan.</Typography>}
            {loan && <>
                <div className={"new-loan-info-container"}>
                    <Typography sx={titleTextStyle}>Amount</Typography>
                    <Divider sx={{flexGrow: 1}}/>
                    <Typography sx={textStyle}>{(loan.amount).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumSignificantDigits: Math.trunc(Math.abs(loan.amount)).toFixed().length
                    })}</Typography>
                </div>
                <div className={"new-loan-info-container"}>
                    <Typography sx={titleTextStyle}>Total with Interest</Typography>
                    <Divider sx={{flexGrow: 1}}/>
                    <Typography sx={textStyle}>{(loan.totalPaid).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumSignificantDigits: Math.trunc(Math.abs(loan.totalPaid)).toFixed().length
                    })}</Typography>
                </div>
                <div className={"new-loan-info-container"}>
                    <Typography sx={titleTextStyle}>Loan Length</Typography>
                    <Divider sx={{flexGrow: 1}}/>
                    <Typography sx={textStyle}>{loan.length} months</Typography>
                </div>
                <div className={"new-loan-info-container"}>
                    <Typography sx={titleTextStyle}>Monthly Payment</Typography>
                    <Divider sx={{flexGrow: 1}}/>
                    <Typography sx={textStyle}>{(loan.payment).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumSignificantDigits: Math.trunc(Math.abs(loan.payment)).toFixed().length
                    })}</Typography>
                </div>
                <Button variant={"contained"} size={"small"} onClick={submitLoan}>Submit</Button>
            </>}
        </div>
    )
}

export default NewLoan