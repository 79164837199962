import "./StudCard.css";

import React from "react";
import Photo from "../horse/photo/Photo";
import {Breeds} from "../../enums/Breeds";

const StudCard = (props) => {

    const displaySire = () => {
        if(props.horse.sire != null){
            return props.horse.sire.regName;
        }
        return "Unknown";
    }

    const displayDam = () => {
        if(props.horse.dam != null){
            return props.horse.dam.regName;
        }
        return "Unknown";
    }
    
    const female = (<img className={"gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/female.png"}/>)
    const male = (<img className={"gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/male.png"}/>)
    const gelding = (<img className={"gender-img"} src={"https://legacyhorsegame.nyc3.cdn.digitaloceanspaces.com/misc/genders/gelding.png"} alt={"gelding"}/>)

    return (
        <div className={"stud-card-container"} >
            {props.horse.gender == 0 ? female : props.gelded ? gelding : male}
            <Photo className={"horse-image"} id={props.horse.horseId} width={'50%'} left={'50%'} top={'5px'} imgWidth={"20%"} translateX={"-50%"} api={props.api} setTextColor={null}/>
            <div className={"stud-info-container"}>
                <div>
                    <div className={"stud-name-container"}>
                        <p className={"stud-prefix"}>{props.horse.prefix}</p>
                        <p className={"stud-name"}>{props.horse.regName}</p>
                    </div>
                    <p className={"stud-breed"}>{Breeds[props.horse.breed]}</p>
                    <p className={"stud-parents"}>{displaySire()} x {displayDam()}</p>
                </div>
                <p className={"stud-price"}>${props.horse.fertility.publicStudPrice}</p>
            </div>
        </div>
    )
}

export default StudCard;