import "./CreditManagement.css";

import React from "react";
import CreditsList from "./CreditsList";
import SubscriptionCredits from "./SubscriptionCredits";
import CreditTransfers from "./CreditTransfers";
import CreditExchange from "./CreditExchange";

const CreditManagement = (props) => {
    
    
    return (
        <div className={'credit-management-container'}>
            <CreditsList user={props.user} darkMode={props.darkMode}/>
            <SubscriptionCredits user={props.user} ranches={props.ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleUpdate}/>
            <CreditTransfers user={props.user} users={props.users} ranches={props.ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleUpdate}/>
            <CreditExchange user={props.user} ranches={props.ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleUpdate}/>
        </div>
    )
}

export default CreditManagement