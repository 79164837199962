import "./HorseManagement.css";

import React from "react";
import BackgroundCredits from "./BackgroundCredits";
import TackCredits from "./TackCredits";

const HorseManagement = (props) => {
    
    return (
        <div className={"horse-management-container"}>
            <BackgroundCredits ranches={props.ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleUpdate}/>
            <TackCredits ranches={props.ranches} api={props.api} token={props.token} config={props.config} formConfig={props.formConfig} darkMode={props.darkMode} handleUpdate={props.handleUpdate}/>
        </div>
    )
}

export default HorseManagement;
