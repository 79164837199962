import "./Offspring.css";

import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import axios from "axios";
import {Link} from "react-router-dom";
import HorseCard from "../../../horses/HorseCard";

const Offspring = (props) => {

    const [cookies, setCookies] = useCookies(["jwt", "ranch"]);
    const token = 'Bearer ' + cookies.jwt;

    const config = {
        headers: {
            'Content-Type': 'application/json-patch+json',
            'Authorization': token
        }
    }
    
    const [offspring, setOffspring] = useState([]);

    useEffect(() => {
        axios.get('/api/horses/offspring', {
            params: {
                id: props.id
            }, 
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }})
            .then(response => {
                setOffspring(response.data)
            })
    }, [props.id]);

    const [indexStart, setIndexStart] = useState(0);
    const [indexEnd, setIndexEnd] = useState(30);

    const moveForward = (event) => {
        setIndexStart(indexStart + 30);
        setIndexEnd(indexEnd + 30);
    }

    const moveBackward = (event) => {
        setIndexStart(indexStart - 30);
        setIndexEnd(indexEnd - 30);
    }
    
    return (
        <div style={{backgroundColor: "lightgray", margin: 0}}>
            <div className={"page-nav-section"}>
                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                {indexEnd > offspring.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
            </div>
            <div className={"offspring"}>
                {offspring.slice(indexStart, indexEnd).map(horse =>
                    <Link key={horse.horseId} className={"offspring-link"} to={`/horses/${horse.horseId}`}>
                        <HorseCard horse={horse} id={horse.horseId} config={props.config} api={props.api} token={props.token}/>
                    </Link>
                )}
            </div>
            <div className={"page-nav-section"}>
                {indexStart == 0 ? "" : <p className={"page-nav"} onClick={moveBackward}>Previous</p>}
                {indexEnd > offspring.length ? "" : <p className={"page-nav"} onClick={moveForward}>Next</p>}
            </div>
        </div>
    )
}

export default Offspring;