import "./Illness.css";

import {React} from "react";
import {Months} from "../../../../enums/Months";
import {Divider, Typography} from "@mui/material";

const Illness = (props) => {

    const formatDate = (date) => {
        const month = date % 12 == 0  ? Months[11] : Months[(date % 12) - 1];
        const year = date % 12 == 0 ? Math.floor(date / 12) - 1 : Math.floor(date / 12);
        return month + ", Y" + year;
    }

    return (
        <div className={"illness-container"}>
            {props.currentDate ? <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", fontWeight: "bold", marginRight: "10px"
            }}
            >{formatDate(props.currentDate)}</Typography> : <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", fontWeight: "bold", marginRight: "10px"
            }}
            >Permanent</Typography>}
            <Divider sx={{flexGrow: 1}}/>
            <Typography sx={{
                fontFamily: "Roboto Light, sans-serif", fontSize: "16px", marginLeft: "10px"
            }}>{props.title}</Typography>
        </div>
    )
}

export default Illness;