import "./RanchesInfo.css";

import React from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";

const RanchesInfo = (props) => {
    
    return (
        <div
            style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
            className={props.darkMode ? "ranches-info-container ranches-info-container-dark" : "ranches-info-container"}>
            <div className={"ranches-info-header-container"}>
                <Typography variant={"h3"}>Ranch Information</Typography>
            </div>
            <Table>
                <TableHead>
                    <TableRow sx={{fontWeight: "bolder"}}>
                        <TableCell>Ranch Name</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Sub Expiration</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.ranches.map(ranch =>
                        <TableRow key={ranch.ranchId}>
                            <TableCell>{ranch.name}</TableCell>
                            <TableCell>{ranch.active ? "Active" : "Inactive"}</TableCell>
                            <TableCell>{new Date(ranch.expirationDate).toLocaleDateString('en-US', {
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                            })}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default RanchesInfo