import "./PublicNote.css"
import React, {useState} from "react";
import {Delete, Edit, Save} from "@mui/icons-material";
import {Checkbox, FormControl, FormControlLabel, TextField, Typography} from "@mui/material";
import axios from "axios";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import Button from "@mui/material/Button";

const PrivateNote = (props) => {

    const [editing, setEditing] = useState(false);
    
    const handleSetEditing = () => {
        setEditing(true);
    }

    const [title, setTitle] = useState(props.note.title || "");

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }

    const [content, setContent] = useState(props.note.content || "");

    const handleSetContent = (content, delta, source, editor) => {
        setContent(DOMPurify.sanitize(content, {
            USE_PROFILES: {html: true}
        }))
    }

    const handleEditNote = () => {
        const data = new FormData();
        data.append("id", props.note.noteId);
        data.append("email", props.owner.email);
        data.append("title", title);
        data.append("content", content);

        axios.post('api/Application/editNote', data, props.formConfig)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })
        
        setEditing(false);
    }

    const handleDeleteNote = () => {
        console.log(props.id)
        axios.post('api/Application/deleteNote', props.note.noteId, props.config)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
            console.log(error);
        })

        setEditing(false);
    }
    
    return (
        <div className={props.darkMode ? "public-note-container public-note-container-dark" : "public-note-container"}>
            <Typography sx={{paddingBottom: "15px"}} variant={"h3"}>{props.note.title}</Typography>
            <Typography dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.note.content, {
                    USE_PROFILES: {html: true}
                })
            }}/>
            {props.isOwner && <div className={"public-note-btns"}>
                <Edit color={"primary"} onClick={handleSetEditing}/>
                <Delete color={"warning"} onClick={handleDeleteNote}/>
            </div>}
            {editing && <div className={"edit-public-note-container"}>
                <FormControl sx={{width: "100%", paddingBottom: "20px"}} >
                    <TextField label={"Title"} sx={{borderRadius: "3px"}} variant={"outlined"} size={"small"} value={title} onChange={handleSetTitle}/>
                </FormControl>
                <ReactQuill style={props.darkMode ? {backgroundColor: "transparent"} : {}} value={content} onChange={handleSetContent} dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.note.content, {
                        USE_PROFILES: {html: true}
                    })
                }}/>
                <Button sx={{alignSelf: "flex-end"}} variant={"contained"} onClick={handleEditNote}>Save</Button>
            </div>}
        </div>
    )
}

export default PrivateNote;