import React, {useEffect, useState} from 'react';
import axios from "axios";
import Chart from 'chart.js/auto';

function Talents(props) {

    const id = props.id;

    const [horse, setHorse] = useState(null);

    useEffect(() => {
        axios.get('/api/Horses/Talents', {
            params: {
                id: id
            },
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Authorization': props.token,
                "Authentication": props.api,
            }
        })
            .then(response => {
                setHorse(response.data);
            });
    }, [id]);

    useEffect(() => {
        if (horse != null) {
            const data = {
                labels: [
                    'Charisma',
                    'Cow Sense',
                    'Determination',
                    'Grit',
                    'Judgement',
                    'Steady'
                ],
                datasets: [{
                    data: [
                        horse.charisma.finalValue,
                        horse.cowSense.finalValue,
                        horse.determination.finalValue,
                        horse.grit.finalValue,
                        horse.judgement.finalValue,
                        horse.steady.finalValue
                    ]
                }]
            }

            const config = {
                type: 'radar',
                data: data,
                options: {
                    layout: {
                      padding: "10px"  
                    },
                    elements: {
                        line: {
                            borderWidth: 1,
                            backgroundColor: "#0C575780",
                            borderColor: "#044242",
                            borderJoinStyle: "rounded"
                        },
                        point: {
                            pointStyle: false,
                        }
                    },
                    scales: {
                        r: {
                            min: 0,
                            max: 1,
                            ticks: {
                                display: false,
                                count: 10,
                                stepSize: .1,
                            },
                            grid: {
                                circular: true,
                                color: props.darkMode ? "#646464" : "#e3e3e3",
                            },
                            angleLines: {
                                color: props.darkMode ? "#646464" : "#e3e3e3",  
                            },
                            pointLabels: {
                                color: props.darkMode ? "#fff" : "black"
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            }

            if(Chart.getChart(props.name)){
                Chart.getChart(props.name).destroy();
            }

            new Chart(document.getElementById(props.name), config);
        }
    }, [horse, props.darkMode]);

    return (
        <>
            <canvas id={props.name}></canvas>
        </>
    )
}

export default Talents;