import "./NewPrivateNote.css"
import 'react-quill/dist/quill.snow.css';

import React, {useEffect, useState} from "react";
import axios from "axios";
import {FormControl, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import ReactQuill, {Quill} from "react-quill";
import DOMPurify from "dompurify";

const NewPrivateNote = (props) => {

    const [title, setTitle] = useState("");

    const handleSetTitle = (event) => {
        setTitle(event.target.value);
    }
    
    const [content, setContent] = useState("");

    const handleSetContent = (content, delta, source, editor) => {
        setContent(DOMPurify.sanitize(content, {
            USE_PROFILES: {html: true}
        }))
    }

    const [color, setColor] = useState("");

    const handleSetColor = (event) => {
        setColor(event.target.value);
    }

    const handleNewNote = () => {
        const data = new FormData();
        data.append("horseId", props.id);
        data.append("email", props.email);
        data.append("title", title);
        data.append("content", content);
        data.append("color", color);
        data.append("isPublic", false);
        
        axios.post('api/Application/newNote', data, props.formConfig)
            .then(response => {
                console.log(response);
                props.handleSetUpdated();
            }).catch(error => {
                console.log(error);
        })
    }
    
    const themes = {
        'snow-dark': {
            'toolbar': {
                'background': '#1e1e1e',
                'color': '#fff',
            },
        },
    };

    Quill.register(themes, true)
    
    return (
        <div className={"new-private-note-container"}>
            <div className={"new-private-note-title-container"}>
                <FormControl sx={{width: "90%", paddingBottom: "20px"}} >
                    <TextField label={"Title"} variant={"outlined"} size={"small"} onChange={handleSetTitle}/>
                </FormControl>
                <FormControl sx={{width: "50px", paddingBottom: "20px"}} >
                    <TextField type={"color"} variant={"outlined"} size={"small"} onChange={handleSetColor}/>
                </FormControl>
            </div>
            <ReactQuill style={props.darkMode ? {backgroundColor: "#414042", color: "white", width: '100%', height: "300px"} : {width: '100%', height: "300px"}} value={content} onChange={handleSetContent}/>
            <Button sx={{alignSelf: "flex-end", marginTop: "15px"}} variant={"contained"} onClick={handleNewNote}>Save</Button>
        </div>
    )
}

export default NewPrivateNote;