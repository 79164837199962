import "./SubscriptionCredits.css";

import React, {useState} from "react";
import {darkTheme, lightTheme} from "../../../Theme";
import {Dialog, DialogActions, DialogTitle, FormControl, Input, InputLabel, Select, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Button from "@mui/material/Button";

const SubscriptionCredits = (props) => {

    const [ranch, setRanch] = useState(null);

    const handleSetRanch = (event) => {
        setRanch(event.target.value);
    }
    
    const [ranchName, setRanchName] = useState("");
    
    const handleSetRanchName = (event) => {
        setRanchName(event.target.value);
    }

    const [subAmount, setSubAmount] = useState(null);

    const handleSetSubAmount = (event) => {
        setSubAmount(event.target.value);
    }

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleRedeemSubCredit = () => {
        const data = new FormData();
        data.append("email", props.user.email);
        data.append("ranchId", ranch.ranchId);
        data.append("name", ranchName);
        data.append("amount", subAmount);

        axios.post("/api/Account/redeemSubCredits", data, props.formConfig)
            .then(response => {
                console.log(response);
                setMessage("Redemption was successful.")
                setSubAmount("");
                setOpen(true);
            }).catch(error => {
            console.log(error);
        })
    }
    
    const inputStyle = {
        margin: 1,
        width: "65%"
    }
    
    return (
        <>
            <div
                style={{backgroundColor: props.darkMode ? darkTheme.palette.containerBackground.main : lightTheme.palette.containerBackground.main}}
                className={props.darkMode ? "subscription-credits-container subscription-credits-container-dark" : "subscription-credits-container"}
            >
                <div
                    style={{backgroundColor: props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main}}
                    className={"subscription-credits-header-container"}
                >
                    <Typography sx={{paddingLeft: "20px", flexGrow: 1, textAlign: "center"}} variant={"h3"}>
                        Subscriptions
                    </Typography>
                    <Typography sx={{marginRight: "10px"}}>
                        {props.user.subscriptionCredits}
                    </Typography>
                </div>
                <FormControl sx={inputStyle}>
                    <Select label={"Ranch"} value={ranch} onChange={handleSetRanch} size={"small"}>
                        <MenuItem value={{ranchId: 0}}>New Ranch</MenuItem>
                        {props.ranches.map(r =>
                            <MenuItem key={r.ranchId} value={r}>{r.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {ranch && <>
                    {ranch.ranchId == 0 ? <FormControl sx={inputStyle}>
                        <InputLabel id={"feed-select"}>Ranch Name</InputLabel>
                        <Input variant={"outlined"} size={"small"} onChange={handleSetRanchName} value={ranchName}/>
                    </FormControl> : ""}

                    <FormControl sx={inputStyle}>
                        <Select label={"Ranch"} onChange={handleSetSubAmount} size={"small"}>
                            {Array.apply(null, {length: (props.user.subscriptionCredits)}).map(Number.call, Number).map(amount =>
                                <MenuItem key={amount} value={amount + 1}>{amount + 1}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </>}
                <Button sx={{alignSelf: "flex-end", marginRight: {lg: "50px", sm: "10px"}}} variant={"contained"}
                        onClick={handleRedeemSubCredit}>Submit</Button>
            </div>
            <Dialog sx={{paddingLeft: 4, paddingRight: 4}} open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{message?.title}</DialogTitle>
                <p style={{padding: 20}}>{message}</p>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SubscriptionCredits