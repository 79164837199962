import "./PostCard.css";

import React, {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "../../Theme";
import {Typography} from "@mui/material";
import moment from "moment";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import DOMPurify from "dompurify";
import axios from "axios";
import {Comment, ModeComment, ThumbDown, ThumbUp} from "@mui/icons-material";

const PostCard = (props) => {
    
    const markRead = () => {
        const data = new FormData();
        data.append("postId", props.post.postId);
        data.append("userId", props.user.id);
        
        axios.post("api/Forum/markRead", data, props.formConfig)
            .then(response => {
                console.log(response);
            }).catch(error => {
            console.log(error)
        });
    }
    
    const config = {
        ALLOWED_TAGS: ['p', '#text'],
        KEEP_CONTENT: true
    }    
    
    return (
        <div style={props.darkMode ? {
            backgroundColor: darkTheme.palette.containerBackground.main,
            boxShadow: "5px 5px 5px #262626"
        } : {
            backgroundColor: lightTheme.palette.containerBackground.main,
            boxShadow: "5px 5px 5px darkgray"
        }} className={"post-card-container"}>
            <div
                style={{backgroundColor: props.post.isActive ? (props.darkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main) : "lightgray"}}
                className={"post-card-header-container"}>
                <Typography sx={{fontWeight: props.post.isRead ? 300 : ""}} variant={"h3"}>{props.post.title}</Typography>
                <div className={"post-card-title-info-container"}>
                    <div className={"post-card-title-info-text-container"}>
                        <Typography>{moment(props.post.createdDate).format('MMMM DD, YYYY, h:mm a')}</Typography>
                        <Link to={`/users/${props.post.author.displayName}`}>
                            <Typography sx={{fontWeight: "bold"}}>{props.post.author.displayName}</Typography>
                        </Link>
                    </div>
                    <IconButton sx={{p: 0}}>
                        <Avatar alt="Remy Sharp"
                                src={`https://media.legacyhorsegame.com/profileimages/${props.post.author.displayName}.png`}
                                sx={{height: {md: 50}, width: {md: 50}}}/>
                    </IconButton>
                </div>
            </div>
            <div className={"post-card-content-container"}>
                <div className={"post-card-content-subcontainer"}>
                    <Typography dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.post.content, config)}}/>
                </div>
                <div className={"post-card-bottom-container"}>
                    <div className={"post-card-icons-container"}>
                        <div className={"post-card-comment-count-container"}>
                            <Typography sx={{
                                color: "white",
                                fontWeight: 700,
                                position: "absolute",
                                top: "45%",
                                left: "40%",
                                transform: "translate(-45%, -50%)",
                                zIndex: 1,
                            }}>{props.post.commentCount}</Typography>
                            <ModeComment sx={{
                                fontSize: "40px",
                                marginRight: 1
                            }}/>
                        </div>
                        <ThumbUp/>
                        <Typography sx={{margin: 1, fontWeight: 700}}>{props.post.voteCount}</Typography>
                        <ThumbDown/>
                    </div>
                    <Link style={{alignSelf: "flex-end"}} to={`/forum/posts/${props.post.postId}`}>
                        <Typography onClick={markRead}>Read More</Typography>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PostCard